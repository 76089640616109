import { useState, useEffect, useRef } from 'react';
// import * as faceapi from '@vladmandic/face-api';

export function useVideoStream(faceLoginEnabled = false) {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  // Face regonition
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [referenceImage, setReferenceImage] = useState(null);
  const [detectedUser, setDetectedUser] = useState(null);

  const referenceDescriptorRef = useRef(null);

  const videoWidth = 640;
  const videoHeight = 360;

  // Face regonition

  useEffect(() => {
    //if (faceLoginEnabled)
    //   loadModels();
    return () => {
      stopCamera();
    };
  }, []);

  // Face regonition
  // const loadModels = async () => {
  //   try {
  //     await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
  //     await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
  //     await faceapi.nets.faceRecognitionNet.loadFromUri('/models');


  //     setIsModelLoaded(true);
  //     console.log('Face-api models loaded');
  //   } catch (error) {
  //     console.error('Error loading face-api models:', error);
  //   }
  // };
  // Face regonition


  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setIsVideoOn(true);
      // Face regonition
      if (isModelLoaded) {
        // if (!referenceImage) {
        //   await captureReferenceImage();
        // }
        //startFaceRecognition();
      }
      //
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setIsVideoOn(false);
  };

  const captureReferenceImage = async () => {
    if (!videoRef.current) return;

    // Wait for the video to be ready
    await new Promise((resolve) => {
      videoRef.current.onloadedmetadata = resolve;
    });

    setTimeout(async () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoWidth
      canvas.height = videoHeight
      canvas.getContext('2d').drawImage(videoRef.current, 0, 0);

      const capturedImage = canvas.toDataURL('image/jpeg');
      setReferenceImage(capturedImage);
      console.log('Reference image captured');

      // Process the reference image
      await processReferenceImage(capturedImage);
    }, 2000)


  };

  const processReferenceImage = async (imageSource) => {
    const img = await faceapi.fetchImage(imageSource);
    const detection = await faceapi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor();
    if (detection) {
      referenceDescriptorRef.current = detection.descriptor;
      console.log('Reference face descriptor created');
    } else {
      console.error('No face detected in the reference image');
    }
  };

  const startFaceRecognition = async () => {
    if (!videoRef.current || !referenceDescriptorRef.current) {
      console.error('Video element or reference descriptor not found.');

      return

    };

    const canvas = faceapi.createCanvasFromMedia(videoRef.current);
    // document.body.append(canvas);
    const displaySize = { width: videoWidth, height: videoHeight };
    faceapi.matchDimensions(canvas, displaySize);
    const detections = await faceapi.detectAllFaces(videoRef.current).withFaceLandmarks().withFaceDescriptors();
    const resizedDetections = faceapi.resizeResults(detections, displaySize);

    canvas.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
    faceapi.draw.drawDetections(canvas, resizedDetections);

    let match = null
    if (detections.length > 0 && referenceDescriptorRef.current) {
      const faceMatcher = new faceapi.FaceMatcher([referenceDescriptorRef.current], 0.6); // Second parameter is tolerance

      // Check if any detected face matches the reference descriptor
      let referenceImageDetected = false;
      detections.forEach(detection => {
        match = faceMatcher.findBestMatch(detection.descriptor);
        if (match.label === 'person 1') { // person 1 corresponds to referenceDescriptorRef.current
          referenceImageDetected = true;
          setDetectedUser(match)
        }
      });

      if (referenceImageDetected) {
        console.log('Reference image user detected!');
      } else {
        console.log('Reference image user not detected');
      }

      // Draw the matched results
      detections.forEach((detection, i) => {
        const box = resizedDetections[i].detection.box;
        const drawBox = new faceapi.draw.DrawBox(box, { label: `Detected: ${referenceImageDetected ? 'Match' : 'No Match'}` });
        drawBox.draw(canvas);
      });
      return match
    } else {
      return null
      console.log('No face detected in the current frame.');
    }

  };


  // setInterval(() => {
  //   startFaceRecognition()

  // }, 10000)


  const setUserProvidedReferenceImage = async (imageFile) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      setReferenceImage(e.target.result);
      await processReferenceImage(e.target.result);
    };
    reader.readAsDataURL(imageFile);
  }

  // Function to handle image from URL
  const setUserProvidedReferenceImageFromUrl = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const imageToProcess = await blobToBase64(blob);

      setReferenceImage(imageToProcess); // Store the reference image
      await processReferenceImage(imageToProcess); // Process it for face recognition
    } catch (error) {
      console.error('Error processing image from URL:', error);
    }
  };



  const captureFrame = () => {
    return new Promise((resolve, reject) => {
      if (videoRef.current && videoRef.current.srcObject && videoRef.current.videoWidth > 0) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async blob => {
          const image = await blobToBase64(blob);
          resolve(image);
        });
      } else {
        resolve(null);  // Handle no video source available
      }
    });
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };



  return {
    isVideoOn,
    videoRef,
    startCamera,
    stopCamera,
    setUserProvidedReferenceImage,
    setUserProvidedReferenceImageFromUrl,
    referenceImage,
    captureFrame,
    detectedUser,
    startFaceRecognition
  };
}