import React, { useState, useEffect } from 'react';
import { X, Check, ArrowRight, Sparkles, CheckCircle, Loader, Calendar, ChevronRight, ChevronLeft, Clock, User, Lock } from 'lucide-react';
import { dbUtils } from '../../../services/core/dbService';
import { sessionTemplateService } from '../../../services/ai/SessionGenerator';
import { useAuth } from '../../../AuthContext';
import CoachCard from '../../components/cards/CoachCard';
import BundlePricingModal from '../BundlePricingModal';

const CreateSessionModal = ({
  isOpen,
  onClose,
  onSessionCreated,
  preSelectedTemplateId,
  preSelectedCoachId = '927d7dc7-a33e-4f77-ba5b-8ed787a32c20',
  preSelectedTopics,
  startAtStep = 'sessionDetails' }) => {
  const [currentStepKey, setCurrentStepKey] = useState(startAtStep);
  const [templates, setTemplates] = useState([]);
  const [coaches, setCoaches] = useState([]);
  // Add this state near the top with other states
  const [steps, setSteps] = useState([
    { label: 'Session Details', key: 'sessionDetails', },
    { label: 'Template Selection', key: 'templateSelection', },
    { label: 'Topic Selection', key: 'topicSelection', },

    { label: 'Coach Selection', key: 'coachSelection', },
    { label: 'Additional Notes', key: 'notes' }
  ]);

  // Replace the getApplicableSteps function with this:
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([])
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [userInput, setUserInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const [lockedTopics, setLockedTopics] = useState([]);

  const INITIAL_UNLOCKED_COUNT = 2; // Number of topics to unlock initially

  const getInitialUnlockedTopics = (topics = []) => {
    // Shuffle array and take first N elements
    return [...topics]
      .sort(() => Math.random() - 0.5)
      .slice(0, Math.min(INITIAL_UNLOCKED_COUNT, topics.length))
      .map(topic => topic.id);
  };


  // Then update the useEffect in the CreateSessionModal component:
  useEffect(() => {
    const fetchLockedTopics = async () => {
      try {
        if (!selectedTemplate?.id) return;

        // Get completed topics
        const completedTopics = await dbUtils.sessions.getCompletedTopics(user.id, selectedTemplate.id);
        console.log('Completed topics:', completedTopics);

        // Extract topic IDs from completed topics
        const completedTopicIds = completedTopics.map(topic => topic.id);
        console.log('Completed topic IDs:', completedTopicIds);

        const allTopicIds = selectedTemplate.topics.map(topic => topic.id);

        // If no completed topics, unlock initial set
        if (!completedTopicIds || completedTopicIds.length === 0) {
          console.log('No completed topics found, unlocking initial set');
          const initialUnlockedTopics = getInitialUnlockedTopics(selectedTemplate.topics);
          const lockedTopicIds = allTopicIds.filter(id => !initialUnlockedTopics.includes(id));
          setLockedTopics(lockedTopicIds);
        } else {
          // If there are completed topics, unlock those plus 2 more
          console.log('Found completed topics, calculating locked/unlocked state');
          const unlockedTopicIds = new Set(completedTopicIds);

          // Get topics that aren't completed yet
          const remainingTopics = selectedTemplate.topics
            .filter(topic => !completedTopicIds.includes(topic.id));

          console.log('Remaining topics:', remainingTopics);

          // Randomly unlock 2 more from remaining topics if there are any remaining
          if (remainingTopics.length > 0) {
            const additionalUnlocked = getInitialUnlockedTopics(remainingTopics);
            additionalUnlocked.forEach(id => unlockedTopicIds.add(id));
          }

          // Lock all topics that aren't in unlockedTopicIds
          const lockedTopicIds = allTopicIds
            .filter(id => !unlockedTopicIds.has(id));

          console.log('Unlocked topic IDs:', Array.from(unlockedTopicIds));
          console.log('Setting locked topics:', lockedTopicIds);
          setLockedTopics(lockedTopicIds);
        }
      } catch (error) {
        console.error('Error fetching locked topics:', error);
        // Default to initial unlock behavior on error
        const initialUnlockedTopics = getInitialUnlockedTopics(selectedTemplate.topics);
        const lockedTopicIds = selectedTemplate.topics
          .map(topic => topic.id)
          .filter(id => !initialUnlockedTopics.includes(id));
        setLockedTopics(lockedTopicIds);
      }
    };

    if (selectedTemplate) {
      fetchLockedTopics();
    }
  }, [selectedTemplate, user.id]);
  useEffect(() => {
    if (isOpen) {

      fetchTemplates();
      fetchCoaches();
      setCurrentStepKey(startAtStep);
      const template = templates.find(t => t.id === preSelectedTemplateId);
      setSelectedTemplate(template);

      const coach = coaches.find(t => t.id === preSelectedCoachId);
      setSelectedCoach(coach);
      setSelectedTopics([]);
      setUserInput('');

    }
  }, [isOpen]);

  useEffect(() => {
    if (startAtStep != 'sessionDetails') {

      setSteps([{
        'label': '',
        'key': 'successPayment'
      },
      {
        'label': '',
        'key': 'notes'
      }
      ]);

    }

  }, [startAtStep])

  // Update the template selection effect to handle preSelectedTopics
  useEffect(() => {
    if (templates.length > 0 && preSelectedTemplateId) {
      const template = templates.find(t => t.id === preSelectedTemplateId);
      if (template) {
        setSelectedTemplate(template);

        // Convert comma-separated string to array of numbers
        if (preSelectedTopics && typeof preSelectedTopics === 'string' && preSelectedTopics.length > 0) {
          const topicIds = preSelectedTopics.split(',').map(id => parseInt(id.trim(), 10));
          setSelectedTopics(topicIds);
        } else if (template.topics && (!selectedTopics || selectedTopics.length === 0)) {
          // Use default selected topics only if no topics are currently selected
          setSelectedTopics(template.topics
            .filter(topic => topic.default_selected)
            .map(topic => topic.id)
          );
        }
      }
    }
  }, [templates, preSelectedTemplateId, preSelectedTopics]);


  useEffect(() => {
    if (coaches.length > 0 && preSelectedCoachId) {
      const coach = coaches.find(c => c.id === preSelectedCoachId);
      if (coach) {
        setSelectedCoach(coach);
      }
    }
  }, [coaches, preSelectedCoachId]);


  // When template is selected, initialize default topics
  useEffect(() => {
    if (selectedTemplate?.topics && !preSelectedTopics) {
      setSelectedTopics(selectedTemplate.topics.filter(topic => topic.default_selected).map(topic => topic.id));
    }
  }, [selectedTemplate]);

  const handleTopicToggle = (topicId) => {
    setSelectedTopics(prev => {
      if (prev.includes(topicId)) {
        return prev.filter(id => id !== topicId);
      } else {
        return [...prev, topicId];
      }
    });
  };

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.sessionTemplates.getAll();
      setTemplates(data);
    } catch (err) {
      setError('Failed to load session templates');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCoaches = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.coaches.getAll();
      setCoaches(data);
    } catch (err) {
      setError('Failed to load coaches');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSession = async () => {
    if (!selectedTemplate || !selectedCoach) return;

    try {
      setLoading(true);
      const purchasedBundle = await dbUtils.bundles.getLatestPurchasedBundleForTemplate(user.id, selectedTemplate.id)


      if (purchasedBundle?.sessions_remaining > 0) {

        const selectedTopicObjects = selectedTemplate.topics.filter(topic =>
          selectedTopics.includes(topic.id)
        );
        const newSession = await sessionTemplateService.createSessionFromTemplate(selectedTemplate.id, user.id, selectedCoach.id, userInput, selectedTopicObjects);
        await dbUtils.bundles.updateSessionsRemaining(purchasedBundle.id, user.id, 1)
        onSessionCreated(newSession);
        onClose();
      }
      else {
        setCurrentStepKey('bundles');

      }
    } catch (err) {
      setError('Failed to create session');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const goToNextStep = () => {
    const currentIndex = steps.findIndex(s => s.key === currentStepKey);
    if (currentIndex < steps.length - 1) {
      setCurrentStepKey(steps[currentIndex + 1].key);
    }
  };

  const goToPreviousStep = () => {
    const currentIndex = steps.findIndex(s => s.key === currentStepKey);
    if (currentIndex > 0) {
      setCurrentStepKey(steps[currentIndex - 1].key);
    }
  };
  const renderStep = () => {
    switch (currentStepKey) {
      case 'sessionDetails':
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-[#E7C6EF] mb-6">When would you like to start your session?</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <button
                className="p-6 bg-[#3D2E5E] hover:bg-[#4D3E6E] rounded-xl text-white text-left transition-all duration-200 shadow-lg"
                onClick={() => goToNextStep()}
              >
                <h4 className="text-xl font-medium mb-2">Start Now</h4>
                <p className="text-[#E7C6EF]">Begin your session immediately</p>
              </button>
              <button
                className="p-6 bg-[#2A1F45] rounded-xl text-gray-400 text-left cursor-not-allowed"
                disabled
              >
                <h4 className="text-xl font-medium mb-2">Schedule for Later</h4>
                <p>Choose a future date and time (Coming soon)</p>
              </button>
            </div>
          </div>
        );
      case 'templateSelection':
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-[#E7C6EF] mb-6">What would you like to discuss?</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-h-[50vh] overflow-y-auto pr-2">
              {templates.map((template) => (
                <button
                  key={template.id}
                  className={`relative p-6 rounded-xl text-left transition-all duration-200 group
                    ${template.is_locked
                      ? 'bg-[#2A1F45] text-gray-400 cursor-not-allowed opacity-80'
                      : selectedTemplate?.id === template.id
                        ? 'bg-[#3D2E5E] text-white shadow-lg transform scale-105'
                        : 'bg-[#2A1F45] text-white hover:bg-[#352A50]'
                    }`}
                  onClick={() => {
                    if (!template.is_locked) {
                      setSelectedTemplate(template);
                      goToNextStep();
                    }
                  }}
                  disabled={template.is_locked}
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, ${template.is_locked ? '0.8' : '0.7'}), rgba(0, 0, 0, ${template.is_locked ? '0.8' : '0.7'})), url(${template.backgroundImage || 'https://source.unsplash.com/random/400x300?business'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <h4 className="text-xl font-medium">{template.title}</h4>
                      {template.is_locked && (
                        <span className="text-sm px-2 py-1 bg-[#3D2E5E] rounded-full text-[#E7C6EF]">
                          Premium
                        </span>
                      )}
                    </div>
                    <p className="text-sm opacity-80">{template.description}</p>
                  </div>

                  {template.is_locked && (
                    <>
                      <div className="absolute inset-0 flex items-center justify-center">
                        {/* <div className="bg-[#231639] p-3 rounded-full">
                          <Lock className="text-[#E7C6EF]" size={24} />
                        </div> */}
                      </div>
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-75 rounded-xl flex items-center justify-center transition-all duration-200 opacity-0 group-hover:opacity-100">
                        <div className="text-center p-4">
                          <Lock className="mx-auto mb-2 text-[#E7C6EF]" size={24} />
                          <p className="text-[#E7C6EF] font-medium">Upgrade to unlock</p>
                        </div>
                      </div>
                    </>
                  )}
                </button>
              ))}
            </div>
          </div>
        );
      case 'topicSelection':
        return (
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-2xl font-semibold text-[#E7C6EF]">Select topics you'd like to focus on</h3>
              <span className="text-sm text-[#E7C6EF]">Selected: {selectedTopics.length}</span>
            </div>
            <p className="text-gray-400">Choose the topics you want to discuss during your session</p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[50vh] overflow-y-auto pr-2">
              {selectedTemplate?.topics?.map((topic) => {
                const isLocked = lockedTopics.includes(topic.id);
                const prerequisites = topic.prerequisites || [];

                return (
                  <button
                    key={topic.id}
                    onClick={() => !isLocked && handleTopicToggle(topic.id)}
                    className={`relative p-4 rounded-xl text-left transition-all duration-200 border-2 group
                ${isLocked
                        ? 'bg-[#2A1F45] text-gray-400 cursor-not-allowed opacity-80 border-transparent'
                        : selectedTopics.includes(topic.id)
                          ? 'bg-[#3D2E5E] border-[#E7C6EF] text-white shadow-lg'
                          : 'bg-[#2A1F45] border-transparent text-white hover:bg-[#352A50] hover:border-[#E7C6EF]/50'
                      }`}
                    disabled={isLocked}
                    style={{
                      backgroundImage: topic.backgroundImage ?
                        `linear-gradient(rgba(0, 0, 0, ${isLocked ? '0.8' : '0.7'}), rgba(0, 0, 0, ${isLocked ? '0.8' : '0.7'})), url(${topic.backgroundImage})` :
                        undefined,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="flex items-start justify-between">
                      <div className="flex-grow">
                        <div className="flex items-center justify-between">
                          <h4 className="text-lg font-medium mb-1">{topic.title}</h4>
                          {isLocked && (
                            <span className="text-sm px-2 py-1 bg-[#3D2E5E] rounded-full text-[#E7C6EF]">
                              Locked
                            </span>
                          )}
                        </div>
                        <p className="text-sm opacity-80">{topic.description}</p>
                        {isLocked && prerequisites.length > 0 && (
                          <p className="text-sm text-[#E7C6EF] mt-2">
                            Complete previous topics to unlock
                          </p>
                        )}
                      </div>
                      {!isLocked && (
                        <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center flex-shrink-0 ml-4 mt-1
                    ${selectedTopics.includes(topic.id)
                            ? 'bg-[#E7C6EF] border-[#E7C6EF]'
                            : 'border-gray-400'
                          }`}
                        >
                          {selectedTopics.includes(topic.id) && (
                            <Check size={14} className="text-[#231639]" />
                          )}
                        </div>
                      )}
                    </div>

                    {isLocked && (
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-75 rounded-xl flex items-center justify-center transition-all duration-200 opacity-0 group-hover:opacity-100">
                        <div className="text-center p-4">
                          <Lock className="mx-auto mb-2 text-[#E7C6EF]" size={24} />
                          <p className="text-[#E7C6EF] font-medium">
                            {prerequisites.length > 0
                              ? 'Complete previous topics to unlock'
                              : 'Locked'}
                          </p>
                        </div>
                      </div>
                    )}
                  </button>
                );
              })}
            </div>

            <div className="pt-4">
              <button
                onClick={() => goToNextStep()}
                disabled={selectedTopics.length === 0}
                className={`w-full py-3 rounded-lg transition-all duration-200 flex items-center justify-center
            ${selectedTopics.length > 0
                    ? 'bg-[#3D2E5E] text-white hover:bg-[#4D3E6E]'
                    : 'bg-[#2A1F45] text-gray-400 cursor-not-allowed'
                  }`}
              >
                Continue with {selectedTopics.length} topics
                <ChevronRight size={20} className="ml-2" />
              </button>
            </div>
          </div>
        );
      case 'coachSelection':
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-[#E7C6EF] mb-6">Choose your coach</h3>
            <div className="grid grid-cols-1 gap-6 max-h-[50vh] overflow-y-auto pr-2">
              {coaches.map((coach) => (
                <CoachCard
                  key={coach.id}
                  coach={coach}
                  isSelected={selectedCoach?.id === coach.id}
                  onClick={() => {
                    setSelectedCoach(coach);
                    goToNextStep();
                  }}
                />
              ))}
            </div>
          </div>
        );
      case 'successPayment':
        const selectedTopicDetails = selectedTemplate?.topics?.filter(topic =>
          selectedTopics.includes(topic.id)
        ) || [];

        return (
          <div className="space-y-6">
            <div className="bg-[#2A1F45] rounded-xl p-8 border border-[#4ADE80]/20 shadow-lg">
              {/* Success Icon & Header */}
              <div className="flex items-center gap-6 mb-8">
                <div className="relative">
                  <div className="w-16 h-16 rounded-full bg-[#4ADE80]/20 flex items-center justify-center animate-pulse">
                    <CheckCircle className="text-[#4ADE80] w-8 h-8" />
                  </div>
                  <div className="absolute -top-1 -right-1">
                    <Sparkles className="text-[#4ADE80] w-5 h-5 animate-bounce" />
                  </div>
                </div>
                <div className="space-y-1">
                  <h2 className="text-3xl font-bold text-[#4ADE80]">
                    Purchase Successful!
                  </h2>
                  <p className="text-gray-400">
                    Your coaching bundle is ready to elevate your journey
                  </p>
                </div>
              </div>

              {/* Session Details Section */}
              <div className="bg-[#231639] rounded-lg p-6 mb-6 border border-[#4ADE80]/20">
                <div className="space-y-6">
                  {/* Template Info */}
                  <div className="space-y-2">
                    <h3 className="text-[#E7C6EF] text-lg font-semibold flex items-center gap-2">
                      <Calendar className="w-5 h-5 text-[#4ADE80]" />
                      Session Details
                    </h3>
                    <div className="grid grid-cols-2 gap-6">
                      <div className="space-y-2">
                        <p className="text-[#E7C6EF]/60 text-sm">Template</p>
                        <p className="text-white font-medium">{selectedTemplate?.title}</p>
                      </div>
                      <div className="space-y-2">
                        <p className="text-[#E7C6EF]/60 text-sm">Sessions Included</p>
                        <div className="flex items-center gap-2">
                          <span className="text-white font-medium">3 Coaching Sessions</span>
                          <span className="text-[#4ADE80] text-sm px-2 py-0.5 bg-[#4ADE80]/10 rounded-full">Active</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Topics Section */}
                  <div className="space-y-3">
                    <h3 className="text-[#E7C6EF] text-lg font-semibold flex items-center gap-2">
                      <CheckCircle className="w-5 h-5 text-[#4ADE80]" />
                      Selected Topics
                    </h3>
                    <div className="grid gap-3">
                      {selectedTopicDetails.map((topic, index) => (
                        <div
                          key={topic.id}
                          className="bg-[#231639] p-4 rounded-lg border border-[#E7C6EF]/20 flex items-center gap-3 group hover:border-[#4ADE80]/40 transition-colors duration-200"
                        >
                          <div className="w-6 h-6 rounded-full bg-[#4ADE80]/20 flex items-center justify-center flex-shrink-0 group-hover:bg-[#4ADE80]/30 transition-colors duration-200">
                            <span className="text-[#4ADE80] text-sm font-medium">{index + 1}</span>
                          </div>
                          <div>
                            <p className="text-white font-medium group-hover:text-[#4ADE80] transition-colors duration-200">
                              {topic.title}
                            </p>
                            <p className="text-gray-400 text-sm">{topic.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Next Steps Section */}
                  <div className="space-y-2">
                    <h3 className="text-[#E7C6EF] text-lg font-semibold flex items-center gap-2">
                      <ArrowRight className="w-5 h-5 text-[#4ADE80]" />
                      Next Steps
                    </h3>
                    <div className="bg-[#4ADE80]/10 p-4 rounded-lg border border-[#4ADE80]/20">
                      <p className="text-gray-400">
                        Please add any additional notes on the next screen to help your coach prepare for your session.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'notes':
        return (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-[#E7C6EF] mb-6">Any additional notes before we begin?</h3>
            <textarea
              className="w-full p-4 bg-[#2A1F45] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E7C6EF] resize-none"
              rows="5"
              placeholder="Add any context or specific points you'd like to discuss..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
            <button
              className="w-full py-3 bg-[#3D2E5E] text-white rounded-lg hover:bg-[#4D3E6E] transition-all duration-200 flex items-center justify-center"
              onClick={handleCreateSession}
            >
              {loading ? <Loader className="animate-spin mr-2" size={20} /> : <Check size={20} className="mr-2" />}
              Start Session
            </button>
          </div>
        );
      case 'bundles':
        return (
          <BundlePricingModal selectedSession={
            {
              template: selectedTemplate?.id,
              topics: selectedTopics,
              coach: selectedCoach?.id,
              userInput: userInput
            }
          } user={user} mode={'page'} isOpen={true} />
        )
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#231639] rounded-xl p-8 w-full max-w-4xl h-[80vh] flex flex-col shadow-2xl">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-[#E7C6EF]">Create New Session</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white transition-colors duration-200">
            <X size={24} />
          </button>
        </div>

        <div className="flex-grow overflow-y-auto">
          {loading && currentStepKey === 'sessionDetails' ? (
            <div className="flex justify-center items-center h-full">
              <Loader className="animate-spin text-[#E7C6EF]" size={48} />
            </div>
          ) : error ? (
            <div className="text-red-400 text-center py-4">{error}</div>
          ) : (
            renderStep()
          )}
        </div>

        <div className="flex justify-between mt-6">
          {currentStepKey !== steps[0]?.key && (
            <button
              onClick={goToPreviousStep}
              className="px-4 py-2 bg-[#2A1F45] text-white rounded-lg hover:bg-[#352A50] transition-all duration-200 flex items-center"
            >
              <ChevronLeft size={20} className="mr-2" />
              Back
            </button>
          )}
          {currentStepKey !== steps[steps.length - 1]?.key && (
            <button
              onClick={goToNextStep}
              className="px-4 py-2 bg-[#3D2E5E] text-white rounded-lg hover:bg-[#4D3E6E] transition-all duration-200 flex items-center ml-auto"
            >
              Next
              <ChevronRight size={20} className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSessionModal;