// src/services/core/supabaseClient.js
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// Create regular client for frontend use
const supabase = createClient(supabaseUrl, supabaseAnonKey)

// Create admin client only if service key exists (server-side)
const getAdminSupabase = () => {
  // Only create admin client if service key exists
  if (process.env.SUPABASE_SERVICE_KEY) {
    return createClient(supabaseUrl, process.env.SUPABASE_SERVICE_KEY)
  }
  return null
}

// For server-side usage (in API routes)
const supabaseAdmin = getAdminSupabase()

// Export named exports for flexibility
export { supabase, supabaseAdmin }
