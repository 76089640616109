import Groq from "groq-sdk";
const groq = new Groq({ apiKey: process.env.REACT_APP_GROQ_API_KEY, dangerouslyAllowBrowser: true });

export async function transcribeSpeech(input) {

  if (typeof input === "string") return input;

  try {
    // console.log("Attempting transcription...");
    // console.log("Input type:", input.type);
    // console.log("Input size:", input.size);

    // Create a File object from the Blob
    const file = new File([input], "audio.wav", { type: "audio/wav" });

    const { text } = await groq.audio.transcriptions.create({
      file: file,
      model: "whisper-large-v3"
    });

    console.log("Transcription result:", text);

    return text.trim() || null;
  } catch (error) {
    console.error("Transcription error:", error);
    if (error.response) {
      console.error("API response:", error.response.data);
    }
    return null;
  }
}

export async function transcribeSpeech_deepgram(input) {
  if (typeof input === 'string') return input;

  const API_URL = process.env.REACT_APP_TRANSCRIBE_API_URL || '/api/transcribe';
  try {
    // Convert the Blob or File object to a base64 string
    const base64Audio = await fileToBase64(input);

    const response = await fetch('/api/transcribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ audio: base64Audio }),
    });

    if (!response.ok) {
      throw new Error('Failed to transcribe audio');
    }

    const data = await response.json();
    return data.transcription || null;
  } catch (error) {
    console.error('Transcription error:', error);
    return null;
  }
}

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
