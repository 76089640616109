import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Edit, Trash2, Plus, Copy, Check, Loader } from 'lucide-react';
import SessionTemplateEdit from './SessionTemplateEdit';

const SessionTemplateList = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [copiedId, setCopiedId] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.sessionTemplates.getAll();
      setTemplates(data);
    } catch (err) {
      setError('Failed to load templates');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (templateId) => {
    setEditingTemplateId(templateId);
  };

  const handleEditSave = () => {
    setEditingTemplateId(null);
    fetchTemplates();
  };

  const handleEditCancel = () => {
    setEditingTemplateId(null);
  };

  const handleDeleteClick = async (templateId) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await dbUtils.sessionTemplates.delete(templateId);
        fetchTemplates();
      } catch (err) {
        setError('Failed to delete template');
        console.error(err);
      }
    }
  };

  const copyTemplateDetailsToClipboard = async (template) => {
    const details = `Template: ${template.title}\nDomain: ${template.domain}\nUse Case: ${template.use_case}`;
    try {
      await navigator.clipboard.writeText(details);
      setCopiedId(template.id);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const filteredTemplates = templates.filter(template =>
    template.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.domain.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderTemplateCard = (template) => (
    <div key={template.id} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex justify-between items-center p-4 mb-4">
      <div className="flex-grow">
        <h3 className="text-xl font-semibold text-white mb-1">{template.title}</h3>
        <p className="text-sm text-gray-400 mb-2">{template.use_case}</p>
        <div className="flex items-center space-x-2">
          <span className="px-2 py-1 bg-purple-600 text-xs text-white rounded-full">{template.domain}</span>
          <span className="text-xs text-gray-500">{template.topic}</span>
        </div>
      </div>
      <div className="flex space-x-2">
        <button
          onClick={() => copyTemplateDetailsToClipboard(template)}
          className="p-2 bg-yellow-500 rounded hover:bg-yellow-600 transition-colors"
          title="Copy template details"
        >
          {copiedId === template.id ? <Check size={16} /> : <Copy size={16} />}
        </button>
        <button
          onClick={() => handleEditClick(template.id)}
          className="p-2 bg-blue-500 rounded hover:bg-blue-600 transition-colors"
          title="Edit template"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => handleDeleteClick(template.id)}
          className="p-2 bg-red-500 rounded hover:bg-red-600 transition-colors"
          title="Delete template"
        >
          <Trash2 size={16} />
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading templates...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-10 bg-red-100 border border-red-300 rounded-lg">
        <p className="text-lg font-semibold">{error}</p>
        <button
          onClick={fetchTemplates}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  if (editingTemplateId) {
    return (
      <SessionTemplateEdit
        templateId={editingTemplateId}
        onSave={handleEditSave}
        onCancel={handleEditCancel}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-white">Session Template Management</h2>
        <button
          onClick={() => handleEditClick('new')}
          className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center hover:bg-green-600 transition-colors"
        >
          <Plus className="mr-2" /> New Template
        </button>
      </div>

      <div className="relative flex-grow mb-4">
        <input
          type="text"
          placeholder="Search templates..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pl-10 bg-gray-700 rounded-lg"
        />
      </div>

      <div className="space-y-4">
        {filteredTemplates.map(renderTemplateCard)}
      </div>
    </div>
  );
};

export default SessionTemplateList;