import TypeWriter from "./TypeWriter";
import React, { useState, useCallback, useEffect, useRef } from 'react';
import IFrameWithLoading from "./IFrameWithLoading";
import Questionnaire from "./cards/Questionnaire";
import SessionReportPage from "../pages/SessionReportPage";
import RoleplayEvaluationCard from "./cards/RoleplayEvaluationCard";
import RolePlayCard from "./RoleplayCard";
import SessionInfoCard from "./cards/SessionInfoCard";
import MiroLikeMentalExerciseCard from "./cards/miroboard/MiroBoard";
import EnhancedMiroBoard from "./cards/miroboard/MiroBoard";
import { useNavigate } from 'react-router-dom';
import CreateSessionModal from "../pages/user/CreateSessionModal";
import CoFounderCard from "./cards/CofounderCard";
import CapTableSimulator from "./cards/CapTableSimulator";
import PriorityMatrix from "./cards/PriorityMatrix";

const Card = (props) => {
  const navigate = useNavigate();

  const {
    sessionData,
    content,
    conversationState,
    audioLevel,
    endRoleplay,
    updateUserData,
    startRoleplay,
    sendResponse,
    setCurrentCard,
    currentAIResponse,
    updateRoomConfiguration,
    updateFeedbackResponse
  } = props;

  const [state, setState] = useState({});


  useEffect(() => {
    if (content && content.onLoad) {
      executeLoadFunction(content.onLoad);
    }
  }, [content]);

  const executeLoadFunction = (onLoadContent) => {
    if (typeof onLoadContent === 'function') {
      // If it's already a function, just call it
      onLoadContent(props);
    } else if (typeof onLoadContent === 'string') {
      // If it's a string, we need to evaluate it safely
      try {
        // Create a new function from the string
        const safeFunction = new Function('props', onLoadContent);
        safeFunction(props);
      } catch (error) {
        console.error('Error executing onLoad function:', error);
      }
    }
  };


  const executeSaveFunction = (onSaveContent, answers) => {
    if (typeof onSaveContent === 'function') {
      // If it's already a function, just call it
      onSaveContent(answers, props);
    } else if (typeof onSaveContent === 'string') {
      // If it's a string, we need to evaluate it safely
      try {
        // Create a new function from the string
        const safeFunction = new Function('answers', 'props', onSaveContent);
        safeFunction(answers, { ...props, navigate });
      } catch (error) {
        console.error('Error executing onSave function:', error);
        // Fallback to default behavior
        setCurrentCard(null);
        sendResponse(`Here are the survey answers. Reflect on them and move to the next: ${JSON.stringify(answers)}`, 'audio');
      }
    } else {
      // If it's neither a function nor a string, use default behavior
      setCurrentCard(null);
      sendResponse(`Here are the survey answers. Reflect on them and move to the next: ${JSON.stringify(answers)}`, 'audio');
    }
    // Always enable user input after questionnaire completion
    //enableUserInput();
  };



  const renderCardContent = () => {
    if (!content)
      return

    switch (content.type) {

      case 'text':
        return (
          <div>
            <TypeWriter text={content.text} onComplete={() => setState({ showImage: true })} />
            {content.image && state?.showImage && (

              <img
                src={content.image.src}
                alt={content.image.alt}
                className="w-full rounded-lg"
              />
            )}
          </div>
        );


      case 'questionnaire':
        return (
          <Questionnaire
            survey={content?.inputData?.questions}
            onSave={(answers) => executeSaveFunction(content?.onSave, answers)}

            config={content?.inputData?.config}
          />
        );
      case 'miroExercise':
        return (
          <div className="absolute inset-0 w-full h-full">
            <EnhancedMiroBoard
              {...content?.inputData}
              canvasClassName="w-full h-full overflow-hidden bg-white relative"
            />
          </div>
        );

      case 'sessionReport':
        return (

          <SessionReportPage isCard={true} />

        );

      case 'cofounderCard':
        return (
          <>

            <CoFounderCard />

          </>


        );
      case 'capTable':
        return (
          <>

            <CapTableSimulator />

          </>


        );
      case 'priorityMatrix':
        return (
          <>

            <PriorityMatrix />

          </>


        );
      case 'createSession':
        return (

          <CreateSessionModal isOpen={true} onSessionCreated={(newSession) => {

            navigate(`/session/room/${newSession?.id}`);

          }} onClose={() => { }} />

        );
      case 'image':
        return (
          <div>
            <TypeWriter text={content.text} onComplete={() => setState({ showImage: true })} />
            {content.image && state?.showImage && (

              <img
                src={content.image.src}
                alt={content.image.alt}
                className="w-full rounded-lg"
              />
            )}
          </div>
        );
      case 'sessioninfo':
        return (
          <>
            <SessionInfoCard sessionData={content.inputData} />




          </>

        );
      case 'webview':
        return (
          <div className="w-full h-screen flex flex-col overflow-y-auto">
            {content.inputData && (

              <IFrameWithLoading content={content} />

            )}
          </div>
        );
      case 'custom':
        return (
          <div className="w-full h-screen flex flex-col overflow-y-auto">
            {content.inputData && (

              <IFrameWithLoading content={content} />

            )}
          </div>
        );
      case 'roleplayEvaluation':
        return <RoleplayEvaluationCard inputData={content.inputData} setOutputData={() => { }} />


      default:
        return null;
    }
  };

  if (content?.type == 'roleplay')
    return <RolePlayCard
      card={content}
      sessionData={sessionData}
      conversationState={conversationState}
      audioLevel={audioLevel}
      endRoleplay={endRoleplay}
      startRoleplay={startRoleplay}
      currentAIResponse={currentAIResponse}
    />


  return (
    <>

      <div id="card-container" className={`p-6 max-w-4xl mx-auto my-8`}>
        {renderCardContent()}
      </div>
    </>
  );
};

export default Card