import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, CheckCircle } from 'lucide-react';

const RatingQuestion = ({ question, value, onChange }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex space-x-2 mt-4">
        {[...Array(question.scale.max - question.scale.min + 1)].map((_, index) => (
          <button
            key={index}
            className={`w-10 h-10 rounded-full ${value === index + question.scale.min
              ? 'bg-purple-600 text-white'
              : 'bg-gray-200 text-gray-700 hover:bg-purple-200'
              }`}
            onClick={() => onChange(index + question.scale.min)}
          >
            {index + question.scale.min}
          </button>
        ))}
      </div>
    </div>
  );
};

const MultipleChoiceQuestion = ({ question, value, onChange }) => {
  return (
    <div className="flex flex-col space-y-2 mt-4">
      {question.options.map((option) => (
        <button
          key={option.value}
          className={`p-3 rounded-lg ${value === option.value
            ? 'bg-purple-600 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-purple-200'
            }`}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

const TextInputQuestion = ({ value, onChange }) => {
  return (
    <textarea
      className="w-full p-3 mt-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
      rows={4}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Type your answer here..."
    />
  );
};

const Questionnaire = ({
  survey = [],
  onSave,
  config = {} // Adding the config prop to hold all customization values

}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [answers, setAnswers] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const {
    landingTitle = "🚀  Your Session Report is Ready !",
    landingMessage = "Thanks for testing the first version of Mentara! Your feedback will shape the future. Before you dive into your report, can you fill out a quick feedback form.",
    landingCTA = "Sure, I'll help out!",
    completionTitle = "You're awesome, thanks!",
    completionMessage = "We’ve got your feedback—it means a lot! Now, your session report is ready. Ready to explore?",
    completionCTA = "Show me my report!"
  } = config; // Destructuring config with default values

  const currentQuestion = survey[currentQuestionIndex];

  const handleAnswer = (value) => {
    setAnswers((prev) => ({ ...prev, [currentQuestion.question_id]: value }));
    if (currentQuestion.type === 'multiple_choice' || currentQuestion.type === 'rating') {
      goToNextQuestion();
    }

  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < survey.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setIsComplete(true);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  useEffect(() => {
    // When the survey prop changes, reset the questionnaire
    setCurrentQuestionIndex(-1);
    setAnswers({});
    setIsComplete(false);
  }, [survey]);

  useEffect(() => {
    if (isComplete) {
      onSave(answers);
    }
  }, [isComplete, answers]);

  const renderQuestion = () => {
    switch (currentQuestion.type) {
      case 'rating':
        return (
          <RatingQuestion
            question={currentQuestion}
            value={answers[currentQuestion.question_id]}
            onChange={handleAnswer}
          />
        );
      case 'multiple_choice':
        return (
          <MultipleChoiceQuestion
            question={currentQuestion}
            value={answers[currentQuestion.question_id]}
            onChange={handleAnswer}
          />
        );
      case 'text_input':
        return (
          <TextInputQuestion
            value={answers[currentQuestion.question_id]}
            onChange={handleAnswer}
          />
        );
      default:
        return null;
    }
  };
  if (currentQuestionIndex === -1) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] bg-gradient-to-br from-purple-600 to-indigo-700 text-white rounded-lg shadow-2xl p-8">
        <h2 className="text-3xl font-bold mb-4 text-center">{landingTitle}</h2>
        <p className="text-xl text-center mb-8">{landingMessage}</p>
        <button
          onClick={() => setCurrentQuestionIndex(0)}
          className="bg-white text-purple-600 px-6 py-3 rounded-full font-semibold hover:bg-purple-100 transition duration-300"
        >
          {landingCTA}
        </button>
      </div>
    );
  }
  if (isComplete) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] bg-gradient-to-br from-purple-600 to-indigo-700 text-white rounded-lg shadow-2xl p-8">
        <CheckCircle size={64} className="text-green-400 mb-6" />
        <h2 className="text-3xl font-bold mb-4 text-center">{completionTitle}</h2>
        <p className="text-xl text-center mb-8">{completionMessage}</p>
        <button
          onClick={() => onSave(answers)}
          className="bg-white text-purple-600 px-6 py-3 rounded-full font-semibold hover:bg-purple-100 transition duration-300"
        >
          {completionCTA}
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-xl">
      <div className="mb-8">
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-purple-600 h-2.5 rounded-full"
            style={{ width: `${((currentQuestionIndex + 1) / survey.length) * 100}%` }}
          ></div>
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-6">{currentQuestion?.question}</h2>
      {renderQuestion()}
      <div className="flex justify-between mt-8">
        <button
          onClick={goToPreviousQuestion}
          disabled={currentQuestionIndex === 0}
          className={`flex items-center ${currentQuestionIndex === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-purple-600 hover:text-purple-800'
            }`}
        >
          <ChevronLeft size={20} />
          Previous
        </button>
        <button
          onClick={goToNextQuestion}
          className="bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 flex items-center"
        >
          {currentQuestionIndex === survey.length - 1 ? 'Finish' : 'Next'}
          <ChevronRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default Questionnaire;