// Import loadStripe from @stripe/stripe-js
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe outside of the component for reusability
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeCheckoutButton() {
  const handleCheckout = async () => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bundle_id: 'dff94fef-49e0-4353-b9de-5f6768716368', // Replace with the actual bundle ID
          user_id: '8a6133b1-2fd1-49eb-9415-71c7b27ebc3c', // Replace with dynamic user ID if needed
          success_url: `${window.location.origin}/user/payment/success`,
          cancel_url: `${window.location.origin}/user/payment/cancel`,
        }),
      });

      const session = await response.json();

      if (session.id) {
        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (error) {
          console.error('Stripe Checkout Error:', error);
          alert(`Checkout error: ${error.message}`);
        }
      } else {
        console.error('Session creation failed');
        alert('Failed to create a checkout session');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      alert('Error initiating checkout. Check console for details.');
    }
  };

  return (
    <button onClick={handleCheckout}>Test Checkout</button>
  );
}

export default StripeCheckoutButton;
