import React, { useState, useEffect } from 'react';
import { useGenerator } from '../../ContentGenerator';
import { dbUtils } from '../../services/core/dbService';
import { sendEmail } from '../../services/core/emailService';
import { Settings, ChevronDown, ChevronRight, RefreshCw, Mail, Wrench } from 'lucide-react';

const ConfigSection = ({ label, isOpen, onToggle, children }) => {
  return (
    <div className="mb-4 bg-opacity-50 rounded-lg overflow-hidden">
      <button
        className="w-full p-3 text-left bg-gray-800 bg-opacity-50 hover:bg-opacity-70 transition-colors flex items-center justify-between"
        onClick={onToggle}
      >
        <span className="font-medium text-gray-200 flex items-center">
          {isOpen ? <ChevronDown className="w-4 h-4 mr-2" /> : <ChevronRight className="w-4 h-4 mr-2" />}
          {label}
        </span>
      </button>
      {isOpen && <div className="p-4 bg-gray-800 bg-opacity-30">{children}</div>}
    </div>
  );
};

const ControlToggle = ({ label, hideValue, disableValue, onHideChange, onDisableChange }) => (
  <div className="py-2">
    <div className="text-sm text-gray-300 mb-2">{label}</div>
    <div className="flex items-center space-x-2">
      <button
        onClick={() => onHideChange(!hideValue)}
        className={`px-3 py-1 rounded-lg text-xs transition-colors ${hideValue
          ? 'bg-purple-600 hover:bg-purple-700 text-white'
          : 'bg-gray-700 hover:bg-gray-600 text-gray-300'
          }`}
      >
        {hideValue ? 'Hidden' : 'Visible'}
      </button>
      <button
        onClick={() => onDisableChange(!disableValue)}
        className={`px-3 py-1 rounded-lg text-xs transition-colors ${disableValue
          ? 'bg-red-600 hover:bg-red-700 text-white'
          : 'bg-gray-700 hover:bg-gray-600 text-gray-300'
          }`}
      >
        {disableValue ? 'Disabled' : 'Enabled'}
      </button>
    </div>
  </div>
);

const DevConsole = ({ sessionData, updateSessionData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [openSections, setOpenSections] = useState({
    roomConfig: true,
    tools: false,
    debug: false
  });
  const { generateCard } = useGenerator();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === '*') {
        setIsVisible(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const updateRoomConfig = async (key, value) => {
    try {
      updateSessionData('room_configuration', prevConfig => ({
        ...prevConfig,
        [key]: value
      }));

      if (sessionData?.id) {
        await dbUtils.sessions.update(sessionData.id, {
          room_configuration: {
            ...sessionData.room_configuration,
            [key]: value
          }
        });
      }
    } catch (error) {
      console.error('Error updating room configuration:', error);
    }
  };

  const resetSession = async () => {
    if (window.confirm('Are you sure you want to reset the session?')) {
      try {
        await dbUtils.sessions.resetSession(sessionData?.id);
        window.location.reload();
      } catch (error) {
        console.error('Error resetting session:', error);
      }
    }
  };

  const sendTestEmail = async () => {
    try {
      await sendEmail(

        {
          "to": "ahmed@behairy.me",
          "subject": "Your test Subject",
          "text": "The main body of your message",
          "name": "Test Email",
          "email": "founders@mentara.io"
        }

      );
      alert('Test email sent!');
    } catch (error) {
      console.error('Error sending test email:', error);
    }
  };

  const generateTestCard = async () => {
    try {
      const card = await generateCard('Generate a test card for development');
      updateSessionData("currentContent", card);
    } catch (error) {
      console.error('Error generating test card:', error);
    }
  };

  const controls = [
    {
      label: "Header Control",
      hideKey: "hideHeader",
      disableKey: "disableHeader"
    },
    {
      label: "Mic Control",
      hideKey: "hideMicControl",
      disableKey: "disableMicControl"
    },
    {
      label: "Camera Control",
      hideKey: "hideVideoControl",
      disableKey: "disableVideoControl"
    },
    {
      label: "Cards Control",
      hideKey: "hideCardControl",
      disableKey: "disableCardControl"
    },
    {
      label: "Screenshare Control",
      hideKey: "hideScreenshareControl",
      disableKey: "disableScreenshareControl"
    }
  ];

  return (
    <div
      className={`fixed top-0 left-0 h-full w-80 bg-gray-900 bg-opacity-95 backdrop-blur-sm text-white shadow-2xl transform transition-transform duration-300 ease-in-out z-50 ${isVisible ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <div className="h-full flex flex-col">
        <div className="p-4 border-b border-gray-800">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Settings className="w-5 h-5 text-purple-400" />
              <h2 className="text-lg font-semibold text-purple-400">Developer Console</h2>
            </div>
            <span className="text-xs text-gray-500">v1.2</span>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          <ConfigSection
            label="Room Configuration"
            isOpen={openSections.roomConfig}
            onToggle={() => toggleSection('roomConfig')}
          >
            <div className="space-y-2">
              {controls.map((control, index) => (
                <ControlToggle
                  key={index}
                  label={control.label}
                  hideValue={sessionData?.room_configuration?.[control.hideKey] || false}
                  disableValue={sessionData?.room_configuration?.[control.disableKey] || false}
                  onHideChange={(value) => updateRoomConfig(control.hideKey, value)}
                  onDisableChange={(value) => updateRoomConfig(control.disableKey, value)}
                />
              ))}
            </div>
          </ConfigSection>

          <ConfigSection
            label="Tools"
            isOpen={openSections.tools}
            onToggle={() => toggleSection('tools')}
          >
            <div className="space-y-2">
              <button
                onClick={generateTestCard}
                className="w-full px-4 py-2 bg-purple-600 hover:bg-purple-700 rounded-lg flex items-center justify-center text-sm"
              >
                <Wrench className="w-4 h-4 mr-2" />
                Generate Test Card
              </button>
              <button
                onClick={sendTestEmail}
                className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg flex items-center justify-center text-sm"
              >
                <Mail className="w-4 h-4 mr-2" />
                Send Test Email
              </button>
              <button
                onClick={resetSession}
                className="w-full px-4 py-2 bg-red-600 hover:bg-red-700 rounded-lg flex items-center justify-center text-sm"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Reset Session
              </button>
            </div>
          </ConfigSection>

          <ConfigSection
            label="Debug Information"
            isOpen={openSections.debug}
            onToggle={() => toggleSection('debug')}
          >
            <div className="text-xs font-mono bg-black bg-opacity-30 p-4 rounded-lg overflow-x-auto">
              <pre className="text-gray-300 whitespace-pre-wrap">
                {JSON.stringify(sessionData?.room_configuration, null, 2)}
              </pre>
            </div>
          </ConfigSection>
        </div>

        <div className="p-4 border-t border-gray-800">
          <p className="text-xs text-gray-500">Press * to toggle console</p>
        </div>
      </div>
    </div>
  );
};

export default DevConsole;