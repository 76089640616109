import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Save, X, Loader, Plus, Minus } from 'lucide-react';

const CardEdit = ({ cardId, onSave, onCancel }) => {
  const [card, setCard] = useState({
    type: '',
    name: '',
    description: '',
    input_fields: [],  // Assuming this will be an array or object structure.
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCard = async () => {
      if (cardId === 'new') return;
      try {
        setLoading(true);
        const data = await dbUtils.cardTemplates.getById(cardId);
        setCard(data);
      } catch (err) {
        setError('Failed to load card');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCard();
  }, [cardId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCard(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFieldsChange = (index, key, value) => {
    setCard(prev => {
      const updatedFields = [...prev.input_fields];
      updatedFields[index] = { ...updatedFields[index], [key]: value };
      return { ...prev, input_fields: updatedFields };
    });
  };

  const addInputField = () => {
    setCard(prev => ({
      ...prev,
      input_fields: [...prev.input_fields, { key: '', value: '' }],
    }));
  };

  const removeInputField = (index) => {
    setCard(prev => ({
      ...prev,
      input_fields: prev.input_fields.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (cardId === 'new') {
        await dbUtils.cardTemplates.create(card);
      } else {
        await dbUtils.cardTemplates.update(cardId, card);
      }
      onSave();
    } catch (err) {
      setError('Failed to save card');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading card...</span>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">
          {cardId === 'new' ? 'Create New Card' : 'Edit Card'}
        </h2>
        <div className="space-x-2">
          <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
            <X className="inline-block mr-1" /> Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
            <Save className="inline-block mr-1" /> Save
          </button>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Type</label>
          <input
            type="text"
            name="type"
            value={card.type}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={card.name}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            name="description"
            value={card.description}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded h-32"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Input Fields</label>
          {card.input_fields.map((field, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={field.key}
                onChange={(e) => handleInputFieldsChange(index, 'key', e.target.value)}
                placeholder="Field Name"
                className="flex-grow p-2 bg-gray-700 rounded"
              />
              <input
                type="text"
                value={field.value}
                onChange={(e) => handleInputFieldsChange(index, 'value', e.target.value)}
                placeholder="Field Value"
                className="flex-grow p-2 bg-gray-700 rounded"
              />
              <button type="button" onClick={() => removeInputField(index)} className="p-2 bg-red-500 rounded">
                <Minus size={16} />
              </button>
            </div>
          ))}
          <button type="button" onClick={addInputField} className="mt-2 px-4 py-2 bg-blue-500 rounded-lg">
            <Plus className="inline-block mr-1" /> Add Input Field
          </button>
        </div>
      </div>
    </form>
  );
};

export default CardEdit;
