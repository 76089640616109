import React, { useState, useCallback, useEffect, useRef } from 'react';

const TypeWriter = ({ text, speed = 50, onComplete }) => {
  if (!text)
    return <></>
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, speed);

      return () => clearTimeout(timer);
    } else if (currentIndex === text.length && onComplete) {
      onComplete()

    }
  }, [currentIndex, text, speed]);

  return <p className="text-lg">{displayedText}</p>;
};
export default TypeWriter