import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Save, X, Edit, Trash2, Loader, Plus, Minus, ArrowRight, ArrowLeft } from 'lucide-react';
import PromptTemplateEditor from '../../../components/PromptTemplateEditor';
import PromptTemplateSelector from '../../../components/PromptTemplateSelector';
import CardCreatorModal from '../../../components/CardCreatorModal';
import RoomConfigurationEditor from '../RoomConfigurationEditor';

const steps = [
  { label: 'Template Details', key: 'templateDetails' },
  { label: 'Coach Selection', key: 'coachSelection' },
  { label: 'Content', key: 'content' },
  { label: 'Prompt Template', key: 'promptTemplate' },
  { label: 'Room Configuration', key: 'roomConfiguration' }, // New Step

  { label: 'Cards', key: 'cards' },
];

const templateFlags = {
  is_locked: {
    label: "Lock Template",
    description: "Prevent modifications to this template"
  },
  published: {
    label: "Publish Template",
    description: "Make this template available for use"
  },
  is_demo: {
    label: "Demo Mode",
    description: "Template is for demo purposes"
  }
};

const SessionTemplateEdit = ({ templateId, onSave, onCancel }) => {
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [coaches, setCoaches] = useState([]);
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const [promptTemplate, setPromptTemplate] = useState('');
  const [promptVariables, setPromptVariables] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCardIndex, setEditingCardIndex] = useState(null);
  const [roomConfiguration, setRoomConfiguration] = useState({});

  useEffect(() => {
    const fetchTemplateAndCoaches = async () => {
      try {
        setLoading(true);
        const coachesList = await dbUtils.coaches.getAll();
        setCoaches(coachesList);

        if (templateId === 'new') {
          setTemplate({
            title: '',
            description: '',
            objectives: [],
            cards: [],
            coach_id: '',
            prompt_id: null,
            content: '',
          });
        } else {
          const data = await dbUtils.sessionTemplates.getById(templateId);
          setTemplate(data);
          setSelectedPromptId(data.prompt_id);
          setPromptTemplate(data.content || '');
        }
      } catch (err) {
        setError('Failed to load template or coaches');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplateAndCoaches();
  }, [templateId]);

  const handleChange = (e, field) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setTemplate(prev => ({ ...prev, [field]: value }));
  };

  const handleRoomConfigChange = (config) => {
    setTemplate(prev => ({
      ...prev,
      room_configuration: config
    }));
  };

  const handleArrayChange = (field, index, value) => {
    setTemplate(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field, item = '') => {
    setTemplate(prev => ({
      ...prev,
      [field]: [...prev[field], item]
    }));
  };

  const removeArrayItem = (field, index) => {
    setTemplate(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handlePromptSelect = async (promptId) => {
    setSelectedPromptId(promptId);
    if (promptId) {
      const promptData = await dbUtils.prompts.getById(promptId);
      setPromptTemplate(promptData.content);
      setTemplate(prev => ({ ...prev, prompt_id: promptId }));

      const variableRegex = /\{\{(\w+)\}\}/g;
      const matches = promptData.content.matchAll(variableRegex);
      const extractedVariables = {};
      for (const match of matches) {
        extractedVariables[match[1]] = '';
      }
      setPromptVariables(extractedVariables);
    }
  };

  const handlePromptTemplateSave = (newTemplate, newVariables) => {
    setPromptTemplate(newTemplate);
    setTemplate(prev => ({
      ...prev,
      content: newTemplate,
    }));
  };

  const handleAddCard = () => {
    setEditingCardIndex(null);
    setIsModalOpen(true);
  };

  const handleEditCard = (index) => {
    setEditingCardIndex(index);
    setIsModalOpen(true);
  };

  const handleDeleteCard = (index) => {
    setTemplate(prevTemplate => ({
      ...prevTemplate,
      cards: prevTemplate.cards.filter((_, i) => i !== index)
    }));
  };

  const handleSaveCard = (cardData) => {
    setTemplate(prevTemplate => {
      const newCards = [...prevTemplate.cards];
      if (editingCardIndex !== null) {
        newCards[editingCardIndex] = cardData;
      } else {
        newCards.push(cardData);
      }
      return { ...prevTemplate, cards: newCards };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!template.coach_id) {
      setError('Please select a coach.');
      return;
    }


    delete template.prompt
    delete template.content

    //template.room_configuration = parsedRoomConfig

    try {
      if (templateId === 'new') {
        await dbUtils.sessionTemplates.create(template);
      } else {
        await dbUtils.sessionTemplates.update(templateId, template);
      }
      onSave();
    } catch (err) {
      setError('Failed to save session template');
      console.error(err);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 'templateDetails':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Title</label>
              <input
                type="text"
                value={template.title}
                onChange={(e) => handleChange(e, 'title')}
                className="w-full p-2 bg-gray-700 rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Description</label>
              <textarea
                value={template.description}
                required
                onChange={(e) => handleChange(e, 'description')}
                className="w-full p-2 bg-gray-700 rounded"
                rows="3"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Use Case</label>
              <input
                type="text"
                required
                value={template.use_case}
                onChange={(e) => handleChange(e, 'use_case')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Domain</label>
              <input
                type="text"
                value={template.domain}
                onChange={(e) => handleChange(e, 'domain')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Industry</label>
              <input
                type="text"
                value={template.industry}
                onChange={(e) => handleChange(e, 'industry')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Theme</label>
              <input
                type="text"
                value={template.theme}
                onChange={(e) => handleChange(e, 'theme')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
          </div>
        );
      case 'roomConfiguration':
        return (
          <div className="space-y-6">
            <div className="bg-gray-800 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-white mb-4">Template Flags</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(templateFlags).map(([key, flag]) => (
                  <div key={key} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={template[key] || false}
                      onChange={(e) => handleChange(e, key)}
                      className="rounded border-gray-300 text-purple-600"
                    />
                    <div>
                      <label className="text-sm text-white">{flag.label}</label>
                      <p className="text-xs text-gray-400">{flag.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <RoomConfigurationEditor
              value={template.room_configuration}
              onChange={handleRoomConfigChange}
            />
          </div>
        );
      case 'coachSelection':
        return (
          <div>
            <label className="block text-sm font-medium mb-1">Select Coach</label>
            <select
              value={template.coach_id}
              onChange={(e) => handleChange(e, 'coach_id')}
              className="w-full p-2 bg-gray-700 rounded"
            >
              <option value="">-- Select Coach --</option>
              {coaches.map(coach => (
                <option key={coach.id} value={coach.id}>{coach.name}</option>
              ))}
            </select>
          </div>
        );
      case 'content':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Topic</label>
              <input
                type="text"
                value={template.topic}
                required
                onChange={(e) => handleChange(e, 'topic')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Session Objectives</label>
              {template.objectives?.map((objective, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="text"
                    value={objective}
                    onChange={(e) => handleArrayChange('objectives', index, e.target.value)}
                    className="flex-grow p-2 bg-gray-700 rounded"
                  />
                  <button type="button" onClick={() => removeArrayItem('objectives', index)} className="p-2 bg-red-500 rounded">
                    <Minus size={16} />
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => addArrayItem('objectives')} className="mt-2 px-4 py-2 bg-blue-500 rounded-lg">
                <Plus className="inline-block mr-1" /> Add Objective
              </button>
            </div>
          </div>
        );

      case 'promptTemplate':
        return (
          <div className="space-y-6">
            <PromptTemplateSelector
              selectedPromptId={selectedPromptId}
              onSelect={handlePromptSelect}
            />
            {selectedPromptId && (
              <PromptTemplateEditor
                template={promptTemplate}
                variables={promptVariables}
                onSave={handlePromptTemplateSave}
              />
            )}
          </div>
        );
      case 'cards':
        return (
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-purple-300 mb-2">Template Cards</h3>
            {template.cards.map((card, index) => (
              <div key={index} className="bg-gray-700 rounded-lg p-4 flex justify-between items-center">
                <div>
                  <h4 className="text-lg font-medium">{card.type}</h4>
                  <p className="text-sm text-gray-300">
                    {Object.keys(card.inputData || {}).length} input field(s)
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    type="button"
                    onClick={() => handleEditCard(index)}
                    className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
                  >
                    <Edit size={16} />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleDeleteCard(index)}
                    className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddCard}
              className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
            >
              <Plus size={20} className="mr-2" /> Add New Card
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) return <div className="flex justify-center items-center h-64"><Loader className="animate-spin" /></div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
  if (!template) return <div className="text-center py-4">Template not found</div>;

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">{templateId === 'new' ? 'Create New Session Template' : 'Edit Session Template'}</h2>
          <div className="space-x-2">
            <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
              <X className="inline-block mr-1" /> Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
              <Save className="inline-block mr-1" /> Save
            </button>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex space-x-4">
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => setCurrentStep(index)}
                className={`p-2 cursor-pointer ${index === currentStep ? 'bg-blue-600' : 'bg-gray-600'} rounded-lg text-white`}
              >
                {step.label}
              </div>
            ))}
          </div>
        </div>

        <div>{renderStepContent(steps[currentStep].key)}</div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
            className="px-4 py-2 bg-gray-600 rounded-lg"
          >
            <ArrowLeft className="inline-block mr-1" /> Previous
          </button>
          <button
            type="button"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === steps.length - 1}
            className="px-4 py-2 bg-blue-500 rounded-lg"
          >
            Next <ArrowRight className="inline-block ml-1" />
          </button>
        </div>
      </form>

      <CardCreatorModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveCard}
        initialCard={editingCardIndex !== null ? template.cards[editingCardIndex] : null}
      />
    </>
  );
};

export default SessionTemplateEdit;