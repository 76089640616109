// src/services/emailService.js

const webhookURL = '/api/send-email';

/**
 * Sends data to the webhook endpoint.
 * @param {object} formData - The data to send to the webhook, typically from a form.
 * @returns {Promise} - A promise that resolves with the webhook response or an error.
 */
export const sendEmail = async (formData) => {
  try {
    const response = await fetch(webhookURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`Failed to send data: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('SUCCESS!', data);
    return data;
  } catch (error) {
    console.error('FAILED...', error);
    throw error;
  }
};
