import React, { useState, useEffect } from 'react';
import { Package, Clock, Archive, BarChart2, Zap, PlayCircle, ArrowRight, Plus, CheckCircle, AlertCircle, Sparkles } from 'lucide-react';
import { dbUtils } from '../../../services/core/dbService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import BundlePricingModal from '../BundlePricingModal';
import CreateSessionModal from './CreateSessionModal';



const ProgressRing = ({ progress, size = 80, strokeWidth = 8 }) => {
  const getColor = () => {
    if (progress <= 25) return 'text-red-500';
    if (progress <= 50) return 'text-yellow-500';
    return 'text-emerald-500';
  };

  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative inline-flex items-center justify-center">
      <svg width={size} height={size} className="transform -rotate-90">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="none"
          className="text-gray-700"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="currentColor"
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          className={`${getColor()} transition-all duration-1000 ease-in-out`}
        />
      </svg>
      <div className="absolute flex flex-col items-center justify-center text-white">
        <span className={`text-lg font-bold ${getColor()}`}>{Math.round(progress)}%</span>
      </div>
    </div>
  );
};


const CheckoutSuccess = ({ onClose }) => (
  <div className="bg-[#2A1F45] rounded-xl p-8 border border-emerald-500/30 space-y-6">
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 rounded-full bg-emerald-500/20 flex items-center justify-center">
        <CheckCircle className="text-emerald-500" size={24} />
      </div>
      <div>
        <h2 className="text-2xl font-bold text-[#E7C6EF]">Purchase Successful!</h2>
        <p className="text-gray-400">Your new practice bundle is ready to use</p>
      </div>
    </div>
    <button
      onClick={onClose}
      className="w-full bg-emerald-500/20 text-emerald-500 px-6 py-3 rounded-lg hover:bg-emerald-500/30 transition-all duration-300 flex items-center justify-center gap-2"
    >
      <Sparkles size={20} />
      Start Using Your Bundle
    </button>
  </div>
);

const CheckoutError = ({ onClose }) => (
  <div className="bg-[#2A1F45] rounded-xl p-8 border border-red-500/30 space-y-6">
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 rounded-full bg-red-500/20 flex items-center justify-center">
        <AlertCircle className="text-red-500" size={24} />
      </div>
      <div>
        <h2 className="text-2xl font-bold text-[#E7C6EF]">Purchase Failed</h2>
        <p className="text-gray-400">There was an issue processing your payment</p>
      </div>
    </div>
    <button
      onClick={onClose}
      className="w-full bg-red-500/20 text-red-500 px-6 py-3 rounded-lg hover:bg-red-500/30 transition-all duration-300 flex items-center justify-center gap-2"
    >
      Try Again
    </button>
  </div>
);


const ActiveBundleCard = ({ bundle }) => {
  bundle.sessions_remaining = bundle.sessions_remaining || bundle.sessions_included;
  const progressPercentage = (bundle.sessions_remaining / bundle.bundle.sessions_included) * 100;
  const sessionRate = (bundle.sessions_included - bundle.sessions_remaining) / 4;

  return (
    <div className="bg-gradient-to-br from-purple-900/40 to-indigo-900/40 rounded-xl shadow-lg overflow-hidden border border-purple-500/20">
      <div className="p-6">
        <div className="flex items-start gap-6">
          <div className="flex-shrink-0">
            <ProgressRing progress={progressPercentage} />
          </div>

          <div className="flex-grow space-y-4">
            <div>
              <h3 className="text-xl font-bold text-purple-200">Current Active Bundle</h3>
              <p className="text-purple-300/70">{bundle.bundle?.name}</p>
            </div>

            <div className="flex gap-8">
              <div>
                <p className="text-sm text-gray-400">Sessions Left</p>
                <p className="text-2xl font-semibold text-white">{bundle.sessions_remaining}/{bundle.bundle.sessions_included}</p>
              </div>

              <div>
                <p className="text-sm text-gray-400">Expires In</p>
                <p className="text-2xl font-semibold text-white">30 days</p>
              </div>
              {/* 
              <div>
                <p className="text-sm text-gray-400">Usage Rate</p>
                <p className="text-2xl font-semibold text-white">{sessionRate.toFixed(1)}/week</p>
              </div> */}
            </div>
          </div>

          <button onClick={() => window.location = '/user/sessions'} className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2">
            Book Session
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const BundleTable = ({ bundles }) => (
  <div className="bg-gray-800/50 rounded-xl overflow-hidden">
    <table className="w-full">
      <thead>
        <tr className="bg-gray-900/50">
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Bundle</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Sessions</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Status</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Expiry</th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-800">
        {bundles.map((bundle, index) => (
          <tr key={bundle.id} className={index % 2 === 0 ? 'bg-gray-800/30' : 'bg-gray-800/10'}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-white">{bundle.bundle?.name}</div>
              <div className="text-sm text-gray-400">#{bundle.id}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-white">{bundle.sessions_remaining}/{bundle.bundle.sessions_included}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-500/20 text-yellow-400">
                Active
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
              30 days
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button className="text-purple-400 hover:text-purple-300">View Details</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);





export default function UserBillingPage({ }) {


  const [purchasedBundles, setPurchasedBundles] = useState([]);
  const [sessions, setSessions] = useState([]);

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isCreateSessionModalOpen, setIsCreateSessionModalOpen] = useState(true);

  // Extract URL parameters
  const getUrlParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      coachId: searchParams.get('coach_id'),
      templateId: searchParams.get('template_id'),
      sessionId: searchParams.get('session_id'),
      topics: searchParams.get('topics')
    };
  };
  const { coachId, templateId, sessionId, topics } = getUrlParams();


  const { user, signOut } = useAuth();

  // Check if we're on a success/failure route
  const isSuccess = location.pathname.includes('/success');
  const isError = location.pathname.includes('/error');


  useEffect(() => {
    fetchSessions()
    fetchPurchasedBundles();
  }, []);



  const fetchSessions = async () => {
    try {
      setLoading(true);
      const sessions = await dbUtils.sessions.getUpcoming(user.id);

      // Calculate total sessions used
      const sessionsUsed = sessions.filter(session => session.status === 'completed').length;
      setSessions(sessions)

    } catch (error) {
      console.error('Error fetching billing statistics:', error);
    } finally {
      setLoading(false);
    }
  };


  const fetchPurchasedBundles = async () => {
    try {
      setLoading(true);
      const bundles = await dbUtils.bundles.getUserBundles(user?.id);
      setPurchasedBundles(bundles);
    } catch (error) {
      console.error('Error fetching purchased bundles:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleCreateSession = (newSession) => {
    navigate(`/session/room/${newSession?.id}`);
  };

  const handleCloseCheckoutStatus = () => {
    navigate('/user/billing');
  };

  if (isSuccess) {
    return (
      <div className="max-w-2xl mx-auto pt-12">
        <CheckoutSuccess onClose={handleCloseCheckoutStatus} />

        <CreateSessionModal
          isOpen={true}
          preSelectedCoachId={coachId}
          preSelectedTemplateId={templateId}
          preSelectedTopics={topics}

          startAtStep={'successPayment'}
          onClose={() => setIsCreateSessionModalOpen(false)}
          onSessionCreated={handleCreateSession}
        />


      </div>
    );
  }

  if (isError) {
    return (
      <div className="max-w-2xl mx-auto pt-12">
        <CheckoutError onClose={handleCloseCheckoutStatus} />
      </div>
    );
  }

  const activeBundles = purchasedBundles.filter(b => b.sessions_remaining > 0);
  const totalRemaining = purchasedBundles.map(b => b.sessions_remaining)
  const latestBundle = activeBundles[0];
  const otherBundles = activeBundles?.slice(1);

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-white">Billing & Credits</h1>
          <p className="text-gray-400 mt-1">Manage your practice bundles and sessions</p>
        </div>

        <button
          onClick={() => setIsPricingModalOpen(true)}
          className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Purchase Bundle
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="bg-gray-800/50 rounded-lg p-4 flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-purple-500/20 flex items-center justify-center">
            <PlayCircle className="w-5 h-5 text-purple-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Total Used Sessions</p>
            <p className="text-xl font-bold text-white">{sessions.length}</p>
          </div>
        </div>

        <div className="bg-gray-800/50 rounded-lg p-4 flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-blue-500/20 flex items-center justify-center">
            <Clock className="w-5 h-5 text-blue-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Total Remaining Sessions</p>
            <p className="text-xl font-bold text-white">{totalRemaining}</p>
          </div>
        </div>

        <div className="bg-gray-800/50 rounded-lg p-4 flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-emerald-500/20 flex items-center justify-center">
            <Package className="w-5 h-5 text-emerald-400" />
          </div>
          <div>
            <p className="text-sm text-gray-400">Active Bundles</p>
            <p className="text-xl font-bold text-white">{activeBundles.length}</p>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500 mx-auto"></div>
          <p className="text-gray-400 mt-4">Loading your bundles...</p>
        </div>
      ) : (
        <div className="space-y-6">
          {latestBundle && <ActiveBundleCard bundle={latestBundle} />}

          {otherBundles.length > 0 && (
            <div className="space-y-4">
              <h2 className="text-lg font-medium text-white flex items-center gap-2">
                <Archive className="w-4 h-4" />
                Other Active Bundles
              </h2>
              <BundleTable bundles={otherBundles} />
            </div>
          )}
        </div>
      )}

      <BundlePricingModal user={user} mode={'modal'} isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
    </div>
  );
}