import React, { useState, useEffect } from 'react';
import { Search, Calendar, Clock, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { dbUtils } from '../../../services/core/dbService';
import CreateSessionModal from './CreateSessionModal';

// Updated insights data structure
const SAMPLE_INSIGHTS = {
  summary: {
    totalSessions: 24,
    // goalProgress: 85,
    sessionEffectiveness: 4.8,
    momentumScore: 92
  },
  patterns: [
    {
      id: 1,
      type: 'breakthrough',
      area: 'Leadership Presence',
      change: '+15%',
      description: 'Notable shift in executive communication style and confidence across recent sessions'
    },
    {
      id: 2,
      type: 'strength',
      area: 'Strategic Thinking',
      change: '+12%',
      description: 'Consistent application of frameworks and decisive decision-making'
    },
    {
      id: 3,
      type: 'focus',
      area: 'Stakeholder Management',
      change: '-8%',
      description: 'Opportunity identified to enhance influence across key relationships'
    }
  ],
  nextSteps: [
    {
      id: 1,
      title: 'Elevate Executive Presence',
      description: 'Based on your progress, focus on advanced stakeholder management techniques',
      impact: 95
    },
    {
      id: 2,
      title: 'Strategic Decision-Making',
      description: 'Leverage your analytical strengths to drive organizational impact',
      impact: 88
    }
  ]
};

const InsightCard = ({ title, children, className = "" }) => (
  <div className={`bg-[#231639] rounded-xl p-6 backdrop-blur-lg ${className}`}>
    <h3 className="text-xl font-semibold text-[#E7C6EF] mb-4">{title}</h3>
    {children}
  </div>
);

const StatBox = ({ label, value, unit = "" }) => (
  <div className="text-center p-4 bg-[#2A1F45] rounded-lg">
    <div className="text-2xl font-bold text-[#E7C6EF]">
      {value}{unit}
    </div>
    <div className="text-sm text-gray-400 mt-1">{label}</div>
  </div>
);

const PatternCard = ({ pattern }) => {
  const getColorClass = (type) => {
    switch (type) {
      case 'breakthrough': return 'text-green-400';
      case 'strength': return 'text-blue-400';
      case 'focus': return 'text-yellow-400';
      default: return 'text-gray-400';
    }
  };

  return (
    <div className="bg-[#2A1F45] p-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold text-white">{pattern.area}</span>
        <span className={`font-bold ${getColorClass(pattern.type)}`}>{pattern.change}</span>
      </div>
      <p className="text-sm text-gray-400">{pattern.description}</p>
    </div>
  );
};

const RecommendationCard = ({ recommendation }) => (
  <div className="bg-[#2A1F45] p-4 rounded-lg flex justify-between items-center">
    <div className="flex-1">
      <h4 className="font-semibold text-white mb-1">{recommendation.title}</h4>
      <p className="text-sm text-gray-400">{recommendation.description}</p>
    </div>
    <div className="ml-4 flex flex-col items-center">
      <div className="text-[#E7C6EF] font-bold">{recommendation.impact}%</div>
      <div className="text-xs text-gray-400">impact</div>
    </div>
  </div>
);

const UserInsights = () => {
  const { user } = useAuth();
  const [insights, setInsights] = useState(SAMPLE_INSIGHTS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // In real implementation, fetch insights data here
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      setInsights(SAMPLE_INSIGHTS);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-lg text-gray-300">{error}</p>
        <button className="mt-4 px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors duration-300">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="relative max-w-7xl mx-auto px-4 py-8">
      {/* Main content with reduced opacity when locked */}
      <div className={`${!user?.isPremium ? 'opacity-50' : ''}`}>
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-[#E7C6EF] mb-2">Your Insights</h1>
          <p className="text-gray-400">Track your progress and discover opportunities for growth</p>
        </div>

        {/* Summary Statistics */}
        <InsightCard title="Overview" className="mb-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <StatBox label="Total Sessions" value={insights.summary.totalSessions} />
            <StatBox label="Average Rating" value={insights.summary.sessionEffectiveness} unit="/5" />
            <StatBox label="Completion Rate" value={insights.summary.momentumScore} unit="%" />
          </div>
        </InsightCard>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Patterns */}
          <InsightCard title="Coaching Patterns">
            <div className="space-y-4">
              {insights.patterns.map(pattern => (
                <PatternCard key={pattern.id} pattern={pattern} />
              ))}
            </div>
          </InsightCard>

          {/* Recommendations */}
          <InsightCard title="Personalized Recommendations">
            <div className="space-y-4">
              {insights.nextSteps.map(recommendation => (
                <RecommendationCard key={recommendation.id} recommendation={recommendation} />
              ))}
            </div>
          </InsightCard>
        </div>
      </div>

      {/* Premium lock overlay */}
      {!user?.isPremium && (
        <div className="absolute inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center rounded-xl">
          <div className="text-center p-8">
            <Lock className="mx-auto mb-4 text-[#E7C6EF]" size={48} />
            <h2 className="text-2xl font-bold text-[#E7C6EF] mb-3">Premium Feature</h2>
            <p className="text-gray-300 mb-6 max-w-md">
              Upgrade your account to unlock detailed insights and personalized recommendations
            </p>
            <button className="px-6 py-3 bg-[#3D2E5E] text-white rounded-lg hover:bg-[#4D3E6E] transition-all duration-200">
              Upgrade Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


export default UserInsights
