import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { dbUtils } from '../../services/core/dbService';
import SessionRoom from './SessionRoom';
import { debounce } from 'lodash';

const SessionRoomPage = ({ sessionId }) => {
  let { id } = useParams();
  id = sessionId || id
  const [sessionData, setSessionData] = useState(null);
  const [roomConfig, setRoomConfig] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const sessionDataRef = useRef(null); // To keep track of the current state

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setLoading(true);
        const data = await dbUtils.sessions.getById(id);
        let participants = [];

        if (!data.is_demo)
          participants.push({
            id: 'user1',
            type: 'user',
            name: data.user?.name,
            isMicOn: false,
            isVideoOn: false,
            isScreenSharingOn: false,
            status: null,
            //micAudioLevel: micAudioLevel, // audio levels could be between 0-1
            videoRef: null,
            screenRef: null,
            userImage: data.user?.photo?.replace(/s\d+-c/, 's400') // optional avatar image
          })

        participants.push({
          id: 'ai',
          type: 'ai',
          status: null,

          name: `Coach ${data?.coach?.name} (AI)` || 'Mentara',
          isMicOn: false,
          isVideoOn: false,
          isScreenSharingOn: false,
          // micAudioLevel: 0.7, // audio levels could be between 0-1
          videoRef: null,
          screenRef: null,
          userImage: data?.coach?.avatar, // optional avatar image
          backgroundImage: data.coach?.backgroundImage
        })



        setRoomConfig({
          hideHeader: data.is_demo,

          fullScreen: !data.is_demo,

          participants: participants,
          ...data.room_configuration
        })

        setSessionData(data);
        sessionDataRef.current = data; // Save initial sessionData
      } catch (error) {
        console.error(error);
        setError('Error fetching session data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
  }, [id]);

  // Debounce save function to save session data when history or roleplay is updated
  const saveSessionData = useCallback(
    debounce(async (data) => {
      try {
        const currentSession = sessionDataRef.current;


        // Get the current history and roleplay details from the sessionData reference
        const currentCards = currentSession?.cards || [];
        const currentHistory = currentSession?.history || [];

        // Check for new roleplay history or overall session history
        const newCards = data.cards || currentCards;
        const newHistory = data.history || currentHistory;


        // Only update if there are actual changes in history or cards
        if (
          JSON.stringify(newCards) !== JSON.stringify(currentCards) ||
          JSON.stringify(newHistory) !== JSON.stringify(currentHistory)
        ) {
          const updatedData = {
            cards: newCards,  // Updates to cards (including roleplay)
            history: newHistory // Updates to session history
          };
          // Update the session with the updated cards and history
          await dbUtils.sessions.update(id, updatedData);

          // Update the session reference with new data
          sessionDataRef.current = {
            ...currentSession,
            cards: newCards,
            history: newHistory,
          };
        }
      } catch (error) {
        console.error('Error saving session data:', error);
      }
    }, 1000),
    [id]
  );

  // Trigger save when sessionData.history or sessionData.cards (roleplay, etc.) changes
  useEffect(() => {
    if (sessionData?.history || sessionData?.cards) {
      saveSessionData(sessionData);
    }
  }, [sessionData?.history, sessionData?.cards, saveSessionData]);

  if (loading) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center bg-gray-900 text-white">
        <p>Loading session data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center bg-gray-900 text-white">
        <div className="text-center">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (sessionData)
    return (
      <>
        <SessionRoom roomConfig={roomConfig} sessionData={sessionData} setSessionData={setSessionData} />
      </>
    );
};

export default SessionRoomPage;
