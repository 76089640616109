import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../services/core/dbService';

const PromptTemplateSelector = ({ onSelect, selectedPromptId }) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const data = await dbUtils.prompts.getAll();
        setTemplates(data);
      } catch (err) {
        setError('Failed to load prompt templates');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  if (loading) return <div>Loading templates...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Select Prompt Template</h3>
      <select
        value={selectedPromptId || ''}

        onChange={(e) => onSelect(e.target.value)}
        className="w-full p-2 bg-gray-700 rounded"
      >
        <option value="">-- Select a template --</option>
        {templates.map((template) => (
          <option key={template.id} value={template.id}>
            {template.name} (Version: {template.version})
          </option>
        ))}
      </select>
    </div>
  );
};

export default PromptTemplateSelector;