import { NavLink } from 'react-router-dom';

const Logo = ({
  size = 'text-3xl', text = 'Mentara', textColor = 'text-[#E7C6EF]' }) => {

  return (
    <div className="flex items-center space-x-2">
      <div className="p-2 rounded-lg shadow-lg bg-white/10 backdrop-blur-md">

        <span className={`${size} font-bold tracking-tight ${textColor}`} >
          <NavLink
            to={'/'}
          >
            {text}
          </NavLink>
        </span>
      </div>
    </div >
  )
}

export default Logo