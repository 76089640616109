import React, { useState, useEffect } from 'react';
import { ArrowLeft, ChevronDown, ChevronUp, Calendar, Star } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { llmSessionReport } from '../../utils/llm';
import { dbUtils } from '../../services/core/dbService';

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="bg-gray-800 bg-opacity-50 rounded-lg p-6 mb-6">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-2xl font-semibold">{title}</h2>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      {isOpen && <div className="mt-4">{children}</div>}
    </div>
  );
};

const SessionReportPage = ({ isCard = false, sessionId }) => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  // Use sessionId prop if provided, otherwise use id from URL params
  const reportId = sessionId || id;

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        setLoading(true);
        let report = await dbUtils.sessions.getSessionReport(reportId);

        if (!report) {
          const sessionData = await dbUtils.sessions.getById(reportId);
          report = await llmSessionReport(sessionData);
          await dbUtils.sessions.saveSessionReport(reportId, report);
        }

        const sessionData = await dbUtils.sessions.getById(reportId);
        setReportData({
          ...report,
          user: sessionData.user,
          userImage: sessionData.user?.photo
        });
      } catch (err) {
        console.error('Error fetching report data:', err);
        setError('Failed to load the session report. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [reportId]);

  if (loading) {
    return (
      <div className={`${isCard ? 'h-full' : 'min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black '} text-white flex items-center justify-center`}>
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500 mx-auto mb-4"></div>
          <p className="text-xl">Loading your session report...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${isCard ? 'h-full' : 'min-h-screen'} bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white flex items-center justify-center`}>
        <div className="text-center">
          <p className="text-xl text-red-500 mb-4">{error}</p>
          {!isCard && (
            <button
              onClick={() => navigate('/dashboard')}
              className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-300"
            >
              Return to Dashboard
            </button>
          )}
        </div>
      </div>
    );
  }

  const ReportContent = () => (
    <>
      {isCard == false && <div className="flex items-center mb-6">
        <img
          src={reportData.userImage || 'https://via.placeholder.com/60'}
          alt={reportData.user?.name}
          className="w-16 h-16 rounded-full mr-4"
        />
        <div>
          <h1 className={`${isCard ? 'text-2xl' : 'text-4xl'} font-bold`}>{reportData.user?.name}'s Coaching Session Report</h1>
          <p className="text-xl text-gray-300">{reportData.session_topic}</p>
        </div>
      </div>}


      <CollapsibleSection title="Session Overview">
        <p><strong>Date:</strong> {new Date(reportData.session_date).toLocaleDateString()}</p>
        <p><strong>Duration:</strong> {reportData.session_duration}</p>
        <p><strong>Coach:</strong> {reportData.coach_name}</p>
      </CollapsibleSection>

      <CollapsibleSection title="Key Realizations">
        <ul className="list-disc pl-5">
          {reportData.achievements.map((achievement, index) => (
            <li key={index}>{achievement}</li>
          ))}
        </ul>
      </CollapsibleSection>

      <CollapsibleSection title="Progress Towards Goals">
        {reportData.goals.map((goal, index) => (
          <div key={index} className="mb-4">
            <p><strong>{goal.name}:</strong> {goal.progress}%</p>
            <div className="bg-gray-700 rounded-full h-4 mt-2">
              <div
                className="bg-purple-600 rounded-full h-4"
                style={{ width: `${goal.progress}%` }}
              ></div>
            </div>
          </div>
        ))}
      </CollapsibleSection>

      <CollapsibleSection title="Action Items">
        <ul className="list-disc pl-5">
          {reportData.action_items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </CollapsibleSection>

      <CollapsibleSection title="Coach's Feedback">
        <p>{reportData.coach_feedback}</p>
      </CollapsibleSection>

      <CollapsibleSection title="Next Steps">
        <p>{reportData.next_steps}</p>
      </CollapsibleSection>

      {!isCard && (
        <div className="flex justify-center space-x-4 mt-8">
          <button
            onClick={() => {/* Handle scheduling next session */ }}
            className="bg-purple-600 text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
          >
            <Calendar className="mr-2" size={20} />
            Schedule Next Session
          </button>
          <button
            onClick={() => {/* Handle giving feedback */ }}
            className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition duration-300 flex items-center"
          >
            <Star className="mr-2" size={20} />
            Give Feedback
          </button>
        </div>
      )}
    </>
  );

  if (isCard) {
    return (
      <div className=" text-white p-8 rounded-lg shadow-lg">
        <ReportContent />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate('/session/room/' + reportId)}
            className="flex items-center text-purple-400 hover:text-purple-300"
          >
            <ArrowLeft size={20} className="mr-2" />
            Back to Session
          </button>
          <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
            Mentara
          </div>
        </div>
        <ReportContent />
      </div>
    </div>
  );
};

export default SessionReportPage;