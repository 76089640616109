import html2canvas from 'html2canvas';

export function useCardScreenshot(currentContent) {

  const captureCardScreenshot = async () => {
    if (currentContent && currentContent.type !== 'text') {
      const cardElement = document.getElementById('card-container');
      if (cardElement) {
        const canvas = await html2canvas(cardElement);
        return canvas.toDataURL('image/png');
      }
    }
    return null;
  };

  return {
    captureCardScreenshot
  }
}

