import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Save, X, Loader, Upload } from 'lucide-react';
import { storageUtils } from '../../../../services/core/fileStorageService';

const CoachEdit = ({ coachId, onSave, onCancel }) => {
  const [coach, setCoach] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  useEffect(() => {
    const fetchCoach = async () => {
      try {
        setLoading(true);
        if (coachId === 'new') {
          setCoach({
            name: '',
            bio: '',
            avatar: '',
            voice: '',
            backgroundImage: '',
            data: {
              certifications: '',
              expertise: '',
            },
          });
        } else {
          const data = await dbUtils.coaches.getById(coachId);
          setCoach(data);
        }
      } catch (err) {
        setError('Failed to load coach');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoach();
  }, [coachId]);

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploadingAvatar(true);
        const extension = file.name.split('.').pop();
        const fileUrl = await storageUtils.saveFile(file, extension, `image/${extension}`);
        setCoach({ ...coach, avatar: fileUrl });
      } catch (err) {
        console.error('Failed to upload avatar:', err);
      } finally {
        setUploadingAvatar(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (coachId === 'new') {
        await dbUtils.coaches.create(coach);
      } else {
        await dbUtils.coaches.update(coachId, coach);
      }
      onSave();
    } catch (err) {
      setError('Failed to save coach');
      console.error(err);
    }
  };

  if (loading) return <div className="flex justify-center items-center h-64"><Loader className="animate-spin" /></div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
  if (!coach) return <div className="text-center py-4">Coach not found</div>;

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">{coachId === 'new' ? 'Create New Coach' : 'Edit Coach'}</h2>
        <div className="space-x-2">
          <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
            <X className="inline-block mr-1" /> Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
            <Save className="inline-block mr-1" /> Save
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            value={coach.name}
            onChange={(e) => setCoach({ ...coach, name: e.target.value })}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Bio</label>
          <textarea
            value={coach.bio}
            onChange={(e) => setCoach({ ...coach, bio: e.target.value })}
            className="w-full p-2 bg-gray-700 rounded"
            rows={3}
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Avatar</label>
          <div className="flex items-center space-x-4">
            <input
              type="text"
              value={coach.avatar}
              onChange={(e) => setCoach({ ...coach, avatar: e.target.value })}
              className="w-full p-2 bg-gray-700 rounded"
            />
            <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
              <Upload className="mr-2" />
              {uploadingAvatar ? 'Uploading...' : 'Upload Image'}
              <input type="file" onChange={handleAvatarUpload} className="hidden" accept="image/*" />
            </label>
          </div>
          {coach.avatar && <img src={coach.avatar} alt="Avatar" className="w-16 h-16 rounded-full mt-2" />}
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Background Image URL</label>
          <input
            type="text"
            value={coach.backgroundIm}
            onChange={(e) => setCoach({ ...coach, backgroundIm: e.target.value })}
            className="w-full p-2 bg-gray-700 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Voice Config (JSON)</label>
          <textarea
            value={JSON.stringify(coach.voice, null, 2)}
            onChange={(e) => setCoach({ ...coach, voice: JSON.parse(e.target.value) })}
            className="w-full p-2 bg-gray-700 rounded"
            rows={3}
          />
        </div>
      </div>

      <div className="space-y-4 mt-6">
        <h3 className="text-lg font-semibold">Additional Data</h3>
        <div>
          <label className="block text-sm font-medium mb-1">Certifications</label>
          <input
            type="text"
            value={coach.data.certifications}
            onChange={(e) => setCoach({ ...coach, data: { ...coach.data, certifications: e.target.value } })}
            className="w-full p-2 bg-gray-700 rounded"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Expertise</label>
          <input
            type="text"
            value={coach.data.expertise}
            onChange={(e) => setCoach({ ...coach, data: { ...coach.data, expertise: e.target.value } })}
            className="w-full p-2 bg-gray-700 rounded"
          />
        </div>
      </div>
    </form>
  );
};

export default CoachEdit;
