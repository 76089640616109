// components/CardGenerator.js
import OpenAI from 'openai';
import { Anthropic } from '@anthropic-ai/sdk';



export const useGenerator = () => {

  const anthropic = new Anthropic({
    apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
    dangerouslyAllowBrowser: true
  });

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });



  const generateCard = async (brief, conversation = [], user) => {

    const componentContent = await generateReactComponent(brief, conversation, user);
    const htmlContent = createHTMLWithReact(componentContent);
    const card = { 'type': 'webview', "text": "I created the card for you", inputData: { html: htmlContent } }
    return card

  };

  const generateReactComponent = async (brief, conversation, user) => {
    const prompt = `
     Based on an exchange between user and a personal coach.

    Create a highly interactive, visually appealing, and personalized standalone React component
    that best fits the current situation and could help them think better or realize things.
    The content could be anything that aligns with the user's current goal, such as:

    - An interactive story with decision points
    - A mini-course with slides and quizzes
    - A comic strip exploring a relevant concept
    - An Instagram-style story with swipeable content
    - A carousel of tips, images, or ideas
    - An e-book excerpt with interactive elements
    - A puzzle or game that teaches a relevant skill
    - An animated infographic
    - A virtual mentor conversation

    The component should be engaging, visually appealing, and beneficial for the user.
    Emphasize hyper-personalization and an aesthetically pleasing theme. Use Tailwind CSS for styling,
    and Lucide icons where appropriate.

    Recent Conversation:
    ${conversation}

    Current Goal: "${brief}"

    Guidelines:
    1. Create a single, self-contained React functional component named InteractiveComponent.
    2. The content should be directly relevant to the user's current goal, interests, or challenges.
    3. Make the component highly interactive - include elements that the user can click, drag, input, or otherwise engage with.
    4. Use creative presentation methods to make the content memorable and impactful.
    5. Ensure the design is visually stunning with a focus on theme aesthetics and personalization.
    6. Use Tailwind CSS classes for styling and layout.
    7. For Lucide icons, use the following format:
       <span className="lucide" data-lucide="icon-name" style={{ marginRight: '8px', color: 'color', width: '24px', height: '24px' }}></span>
    8. For images, use the Lorem Picsum service. The URL format is: "https://picsum.photos/width/height". For example: "https://picsum.photos/400/300"
    9. You can add query parameters to Lorem Picsum URLs for specific images or effects:
        - Specific image: "https://picsum.photos/id/237/200/300"
        - Grayscale: "https://picsum.photos/200/300?grayscale"
        - Blur: "https://picsum.photos/200/300?blur=2"
    10. React hooks like useState,useEffect are imported you can use them like this React.useState()
    11. The component should work without any required props, or provide default values for all props.
    12. Return only the React component code without extra explanations.
    13. Do not include component export statement.

    Example structure:
    const InteractiveComponent = () => {{
      const [state, setState] = React.useState(initialState);

      // Your component logic here

      return (
        <div className="tailwind-classes-here">
          /* Your JSX here */
          <span className="lucide" data-lucide="camera" style={{ marginRight: '8px', color: 'blue', width: '24px', height: '24px' }}></span>
        </div>
      );
    }};

  `;

    try {
      const reactComponent = await llmGenerateCard(prompt);
      return reactComponent;
    } catch (error) {
      console.error("Error generating React component:", error);
      throw error;
    }
  };

  const createHTMLWithReact = (reactComponent) => {
    const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Mentara - Cards</title>
      <script src="https://cdn.tailwindcss.com"></script>
  
      <!-- React and ReactDOM CDN -->
      <script src="https://unpkg.com/react@17/umd/react.development.js"></script>
      <script src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"></script>
  
      <!-- Babel Standalone CDN to transform JSX -->
      <script src="https://unpkg.com/@babel/standalone/babel.min.js"></script>
      <!-- Lucide JS -->
      <script src="https://unpkg.com/lucide@latest/dist/umd/lucide.min.js"></script>
    </head>
    
    <body class="h-screen flex">
      <div id="root"></div>
  
      <!-- React component using JSX with spread operator and conditional rendering -->
      <script type="text/babel">
        ${reactComponent}
        // Render the component
        ReactDOM.render(<InteractiveComponent />, document.getElementById('root'));
      </script>
  
      <!-- Babel Configuration to Enable Modern JS Features -->
      <script>
        Babel.registerPreset('env', Babel.availablePresets['env']);
        Babel.registerPreset('react', Babel.availablePresets['react']);
      </script>
    </body>
    
    </html>
  `;
    return htmlContent
  };


  const llmGenerateCard = async (prompt) => {
    try {
      const message = await anthropic.messages.create({
        model: "claude-3-5-sonnet-20240620",
        max_tokens: 4096,
        messages: [{ role: "user", content: prompt }]
      });
      return message.content[0].text;
    } catch (error) {
      console.error("Error calling Claude API:", error);
      throw error;
    }
  };

  const generateImage = async (prompt) => {

    const response = await openai.images.generate({
      model: "dall-e-3",
      prompt: prompt,
      n: 1,
      size: "1024x1024",
    });
    const image_url = response.data[0].url;



    return image_url
  }

  return {
    generateCard,
    generateImage,
  };
};