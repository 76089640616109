import { Pinecone } from "@pinecone-database/pinecone";
import OpenAI from 'openai';
const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

const pinecone = new Pinecone({
  apiKey: 'your_api_key',
});


const index = pinecone.Index('5092303244-mentara-index');


async function generateEmbedding(text) {
  const response = await openai.embeddings.create({
    model: "text-embedding-ada-002",
    input: text,
  });
  return response.data[0].embedding;
}


async function getUserMemory(userId, options = {}) {
  try {
    const {
      sessionId,
      sessionTopic,
      startDate,
      endDate,
      source,
      limit
    } = options;

    const queryParams = {
      userId,
      ...(sessionId && { sessionId }),
      ...(sessionTopic && { sessionTopic }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(source && { source }),
      ...(limit && { limit })
    };

    const response = await fetch('/api/get-all-pinecone', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(queryParams),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.vectors;
  } catch (error) {
    console.error('Error fetching memories:', error);
    throw error;
  }
}


async function updateUserMemory(texts, userId, sessionId, sessionTopic, source = 'sessions') {
  const response = await fetch('/api/store-pinecone', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ texts, userId, sessionId, sessionTopic, source }),
  });

  if (!response.ok) {
    throw new Error('Failed to store embeddings in Pinecone');
  }

  const data = await response.json();
  console.log('Store response:', data);
}



async function retrieveContext(embedding, userId, topK = 5) {
  const response = await fetch('/api/query-pinecone', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ vector: embedding, userId: userId, topK }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  // Format the retrieved context
  const formattedContext = data.matches.map((match, index) => {
    const metadata = match.metadata;
    return `Context ${index + 1}:
Text: ${metadata.text || metadata.description || 'N/A'}
Timestamp: ${new Date(metadata.timestamp).toLocaleString()}
Score: ${match.score.toFixed(4)}
---`;
  }).join('\n\n');

  return `Retrieved Context:\n\n${formattedContext}`;
}

export { retrieveContext, updateUserMemory, getUserMemory, generateEmbedding }