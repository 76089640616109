// src/utils/supabaseDb.js
import { supabase, supabaseAdmin } from './supabaseClient';

export const dbUtils = {
  sessions: {
    async getById(id) {
      const { data, error } = await supabase
        .from('sessions')
        .select(`
          *,
        user:users(id, email, name, photo),
          coach:coaches(*),
          prompt:prompts(*)
        `)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },
    async delete(id) {
      const { data, error } = await supabase
        .from('sessions')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    },
    async getLastSession(userId, currentSessionId = null) {
      try {
        const { data, error } = await supabase
          .from('sessions')
          .select('*')
          .eq('user_id', userId)
          .neq('id', currentSessionId) // Exclude current session if provided
          .order('created_at', { ascending: false })
          .limit(1)
          .single();

        if (error) throw error;
        return data;
      } catch (error) {
        console.error('Error fetching last session:', error);
        return null;
      }
    },

    async getUpcoming(userId) {
      const { data, error } = await supabase
        .from('sessions')
        .select('*,user:users(*)')
        .eq('user_id', userId)
        // .gt('datetime', new Date().toISOString())
        .order('datetime', { ascending: true });

      if (error) throw error;
      return data;
    },

    async create(sessionData) {
      const { data, error } = await supabase
        .from('sessions')
        .insert([sessionData])
        .select();


      if (error) throw error;
      return data[0];
    },
    async getAIEvaluation(sessionId) {
      const { data, error } = await supabase
        .from('sessions')
        .select('ai_evaluation')
        .eq('id', sessionId)
        .single();

      if (error) throw error;
      return data.ai_evaluation;
    },
    async saveSessionReport(id, report) {
      const { data, error } = await supabase
        .from('sessions')
        .update({ session_report: report })
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async getSessionReport(id) {
      const { data, error } = await supabase
        .from('sessions')
        .select('session_report')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data.session_report;
    },

    async setAIEvaluation(sessionId, evaluation) {
      const { data, error } = await supabase
        .from('sessions')
        .update({ ai_evaluation: evaluation })
        .eq('id', sessionId)
        .single();

      if (error) throw error;
      return data;
    },
    async getCompletedTopics(userId, templateId) {
      const { data, error } = await supabase
        .from('sessions')
        .select(`
      room_configuration,
      status
    `)
        .eq('user_id', userId)
        // .eq('session_template_id', templateId)
        .eq('status', 'completed');

      if (error) throw error;

      // Get all completed topics from the room_configuration
      const completedTopics = data
        .filter(session => session.status === 'completed' && session.room_configuration?.topics)
        .flatMap(session => {
          // Make sure to handle both array and object formats
          const topics = session.room_configuration.topics;
          if (Array.isArray(topics)) {
            return topics;
          } else if (typeof topics === 'object') {
            return Object.values(topics);
          }
          return [];
        });

      // Log for debugging
      console.log('Found completed topics:', completedTopics);

      return [...new Set(completedTopics)]; // Return unique topic IDs
    },
    async update(id, updates) {
      const { data, error } = await supabase
        .from('sessions')
        .update(updates)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async resetSession(id) {
      // First, fetch the current session data
      const { data: currentSession, error: fetchError } = await supabase
        .from('sessions')
        .select('cards')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Reset history in each card object
      const updatedCards = currentSession.cards.map(card => {
        if (card.history) {
          return {
            ...card,
            status: 'not-started',
            history: [] // Reset the history of the card
          };
        }
        return card;
      });

      // Update the session with the modified cards (reset history in cards)
      const { data: updatedSession, error } = await supabase
        .from('sessions')
        .update({ cards: updatedCards, configuration: {}, history: [], status: 'new', feedback_response: null, ai_evaluation: null })
        .eq('id', id)
        .single();

      if (error) throw error;
      return updatedSession;
    },

    async getAll() {
      const { data, error } = await supabase
        .from('sessions')
        .select(`*,
           user:users(*),
           coach:coaches(*),
           prompt:prompts(*)
          `).order('created_at', { ascending: false });


      if (error) throw error;
      return data;
    },
  },

  coaches: {

    async getAll() {
      const { data, error } = await supabase
        .from('coaches')
        .select(`*`)
        .eq('published', true) // Only return published 


      if (error) throw error;
      return data;
    },
    async getById(id) {
      const { data, error } = await supabase
        .from('coaches')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async update(id, updates) {
      const { data, error } = await supabase
        .from('coaches')
        .update(updates)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async create(coachData) {
      const { data, error } = await supabase
        .from('coaches')
        .insert([coachData])
        .single();

      if (error) throw error;
      return data;
    },
    async delete(id) {
      const { data, error } = await supabase
        .from('coaches')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    },
  },

  bundles: {
    async getLatestPurchasedBundleForTemplate(userId, sessionTemplateId) {
      const { data, error } = await supabase
        .from('purchased_bundles')
        .select(`
          *,
          bundle:bundles(
            session_template_id
          )
        `)
        .eq('user_id', userId)
        .eq('status', 'active')
        .filter('bundle.session_template_id', 'eq', sessionTemplateId)
        .order('purchased_at', { ascending: false }) // Order by purchase date descending
        .limit(1)
        .single(); // Get the latest bundle

      if (error) {
        console.error('Error fetching the latest purchased bundle:', error);
        return null
      }

      return data;
    },

    async getAll() {
      const { data, error } = await supabase
        .from('bundles')
        .select('*')
        .eq('published', true); // Add this filter to exclude null price_Id values

      if (error) throw error;
      return data;
    },
    async getByStripeSessionId(stripeSessionId) {
      const { data, error } = await supabase
        .from('purchased_bundles')
        .select(`
        *,
        bundle:bundles(*)
      `)
        .eq('stripe_session_id', stripeSessionId)
        .single();

      if (error) {
        console.error('Error fetching bundle by stripe session:', error);
        throw error;
      }
      return data;
    },
    async recordPurchase(userId, bundleId, stripePaymentId, sessionsGranted, stripSessionId, metadata) {
      const { data, error } = await supabaseAdmin
        .from('purchased_bundles')
        .insert([{
          user_id: userId,
          bundle_id: bundleId,
          sessions_remaining: sessionsGranted,
          stripe_payment_id: stripePaymentId,
          stripe_session_id: stripSessionId,
          metadata: metadata,
          status: 'active',
          purchased_at: new Date().toISOString(),
        }])
        .single();

      if (error) {
        console.error('Error recording purchase:', error);
        throw error;
      }
      return data;
    },

    async getUserBundle(userId, bundleId) {
      const { data, error } = await supabase
        .from('purchased_bundles')
        .select('*')
        .eq('user_id', userId)
        .eq('bundle_id', bundleId)
        .eq('status', 'active')
        .single();

      if (error && error.code !== 'PGRST116') { // Ignore 'no rows found' errors
        console.error('Error fetching user bundle:', error);
        throw error;
      }
      return data || null;
    },
    async getUserBundles(userId) {
      const { data, error } = await supabase
        .from('purchased_bundles')
        .select('*,bundle:bundles(*)')
        .eq('user_id', userId)
      // .eq('status', 'active')

      if (error && error.code !== 'PGRST116') { // Ignore 'no rows found' errors
        console.error('Error fetching user bundle:', error);
        throw error;
      }
      return data || null;
    },
    async updateSessionsRemaining(bundleId, userId, sessionsUsed) {
      const { data, error } = await supabase
        .rpc('decrement_sessions', {
          param_bundle_id: bundleId,
          param_user_id: userId,
          param_sessions_used: sessionsUsed
        });

      if (error) {
        console.error('Error updating sessions remaining:', error);
        throw error;
      }

      return data;
    },

    async markBundleAsExpired(bundleId) {
      const { data, error } = await supabase
        .from('purchased_bundles')
        .update({ status: 'expired' })
        .eq('id', bundleId)
        .single();

      if (error) {
        console.error('Error marking bundle as expired:', error);
        throw error;
      }
      return data;
    },
  },

  sessionTemplates: {

    async getAll(publishedStatus = true) {
      let query = supabase
        .from('session_templates')
        .select('*')
        // .order('created_at', { ascending: false })
        .order('order', { ascending: true });

      // Apply the `published` filter only if `publishedStatus` is not `null` or `undefined`
      if (publishedStatus !== null && publishedStatus !== undefined) {
        query = query.eq('published', publishedStatus);
      }

      const { data, error } = await query;

      if (error) throw error;
      return data;
    },



    async getById(id) {
      const { data, error } = await supabase
        .from('session_templates')
        .select('*,prompt:prompts(*)')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async delete(id) {
      const { data, error } = await supabase
        .from('session_templates')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    },

    async create(templateData) {
      const { data, error } = await supabase
        .from('session_templates')
        .insert([templateData])
        .single();

      if (error) throw error;
      return data;
    },

    async update(id, updates) {
      const { data, error } = await supabase
        .from('session_templates')
        .update(updates)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },
  },
  cardTemplates: {
    async getAll() {
      const { data, error } = await supabase
        .from('card_templates')
        .select('*')
        .order('type', { ascending: false });

      if (error) throw error;
      return data;
    },

    async getById(id) {
      const { data, error } = await supabase
        .from('card_templates')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async create(cardData) {
      const { data, error } = await supabase
        .from('card_templates')
        .insert([cardData])
        .single();

      if (error) throw error;
      return data;
    },

    async update(id, updates) {
      const { data, error } = await supabase
        .from('card_templates')
        .update(updates)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async delete(id) {
      const { data, error } = await supabase
        .from('card_templates')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    }
  },

  prompts: {
    async getAll() {
      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data;
    },

    async getById(id) {
      const { data, error } = await supabase
        .from('prompts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },

    async create(promptData) {
      const { data, error } = await supabase
        .from('prompts')
        .insert([promptData])
        .single();

      if (error) throw error;
      return data;
    },

    async update(id, updates) {
      const { data, error } = await supabase
        .from('prompts')
        .update(updates)
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    },
    async delete(id) {
      const { data, error } = await supabase
        .from('prompts')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    },
  },
  usersData: {
    getAllFounderProfiles: async () => {
      try {
        const { data, error } = await supabase
          .from('users_data')
          .select('data')
          .eq('key', 'founder_profile')

        if (error) {
          if (error.code === 'PGRST116') {
            // No data found for this key, return null
            return null;
          }
          throw error;
        }
        return data?.data || null;
      } catch (error) {
        console.error(`Error fetching user data for key ${key}:`, error);
        throw error;
      }
    },

    get: async (userId, key) => {
      try {
        const { data, error } = await supabase
          .from('users_data')
          .select('data')
          .eq('user_id', userId)
          .eq('key', key)
          .single();

        if (error) {
          if (error.code === 'PGRST116') {
            // No data found for this key, return null
            return null;
          }
          throw error;
        }
        return data?.data || null;
      } catch (error) {
        console.error(`Error fetching user data for key ${key}:`, error);
        throw error;
      }
    },

    set: async (userId, key, newData) => {
      try {
        const { data, error } = await supabase
          .from('users_data')
          .upsert({ user_id: userId, key, data: newData }, { onConflict: 'user_id,key' })
          .select();

        if (error) throw error;
        return data[0].data;
      } catch (error) {
        console.error(`Error setting user data for key ${key}:`, error);
        throw error;
      }
    },

    getAllKeys: async (userId) => {
      try {
        const { data, error } = await supabase
          .from('users_data')
          .select('key')
          .eq('user_id', userId);

        if (error) throw error;
        return data.map(item => item.key);
      } catch (error) {
        console.error('Error fetching all user data keys:', error);
        throw error;
      }
    }
  },

  auth: {
    async updateUserMetadata(metadata) {
      try {
        const { data, error } = await supabase.auth.updateUser({
          data: {
            ...metadata
          }
        });

        if (error) {
          throw error;
        }

        // Also update the local users table if needed
        // await this.updateProfile(userId, { data: metadata });

        return { data, error: null };
      } catch (error) {
        console.error('Error updating user metadata:', error.message);
        return { data: null, error };
      }
    },


    async setOnboardingStatus(status) {
      return await this.updateUserMetadata({ onboarded: status });
    },

    async getOnboardingStatus(userId) {
      const { data, error } = await supabase.auth.getUser(userId);
      if (error) {
        console.error('Error getting user metadata:', error.message);
        return false;
      }
      return data?.user?.user_metadata?.onboarded || false;
    },
  },

  users: {

    async delete(id) {
      const { data, error } = await supabase
        .from('users')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return data;
    },
    async getAll() {
      const { data, error } = await supabase
        .from('users')
        .select('*');

      if (error) throw error;
      return data;
    },

    async create(userData) {
      const { data, error } = await supabase
        .from('users')
        .insert([userData])
        .single();

      if (error) throw error;
      return data;
    },
    async getProfile(userId) {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();



      if (error) throw error;

      // If we have a name, split it to get first_name
      if (data) {
        // Default to empty string if name is null/undefined
        const fullName = data.name || '';

        // Clean the name: trim and replace multiple spaces with single space
        const cleanedName = fullName.trim().replace(/\s+/g, ' ');

        // Handle empty name case
        if (!cleanedName) {
          return {
            ...data,
            first_name: '',
            last_name: ''
          };
        }

        // Split the cleaned name
        const nameParts = cleanedName.split(' ');

        // For single word names, use it as first_name
        if (nameParts.length === 1) {
          return {
            ...data,
            first_name: nameParts[0],
            last_name: ''
          };
        }

        // For multiple word names, first word is first_name, rest is last_name
        return {
          ...data,
          first_name: nameParts[0],
          last_name: nameParts.slice(1).join(' ')
        };
      }

      return data;
    },

    async updateProfile(userId, updates) {
      const { data, error } = await supabase
        .from('users')
        .update(updates)
        .eq('id', userId)
        .single();

      if (error) throw error;
      return data;
    },
  },

};