import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Download, ChevronDown, ChevronUp, RefreshCw, Search, FileText, Loader } from 'lucide-react';

const DynamicReports = () => {
  const [availableReports, setAvailableReports] = useState([
    {
      id: 'inProgressRoleplay',
      name: 'In Progress Roleplay Sessions',
      description: 'Shows all roleplay sessions currently in progress',
      function: 'getInProgressRoleplaySessions'
    },
    {
      id: 'userActivity',
      name: 'User Activity Metrics',
      description: 'User adoption, engagement and session completion rates',
      function: 'getUserActivityMetrics'
    },
    {
      id: 'roleplayPerformance',
      name: 'Roleplay Performance Analysis',
      description: 'Analysis of roleplay completion rates and user satisfaction',
      function: 'getRoleplayPerformance'
    },
    {
      id: 'userFeedback',
      name: 'User Feedback Analysis',
      description: 'Session ratings, NPS scores and improvement areas',
      function: 'getUserFeedbackMetrics'
    },
    {
      id: 'founderProfiles',
      name: 'Founder Profile Insights',
      description: 'Analysis of founder demographics and preferences',
      function: 'getFounderProfiles'
    }
  ]);

  const [selectedReport, setSelectedReport] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const fetchReportData = async (reportFunction) => {
    setLoading(true);
    setError(null);
    try {
      const data = await dbUtils.reports[reportFunction]();
      setReportData(data);
    } catch (err) {
      console.error('Error fetching report:', err);
      setError('Failed to load report data');
    } finally {
      setLoading(false);
    }
  };

  const handleReportSelect = (report) => {
    setSelectedReport(report);
    fetchReportData(report.function);
  };

  const downloadCSV = () => {
    if (!reportData || !selectedReport) return;

    // Get headers from the first item
    const headers = Object.keys(reportData[0] || {});

    // Create CSV content
    const csvContent = [
      headers.join(','), // Headers row
      ...reportData.map(row =>
        headers.map(header =>
          // Handle special cases and escape commas
          JSON.stringify(row[header] || '')
        ).join(',')
      )
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedReport.name.toLowerCase().replace(/\s+/g, '-')}-${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleSort = (key) => {
    setSortConfig(prevSort => ({
      key,
      direction: prevSort.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const renderReportData = () => {
    if (!reportData || reportData.length === 0) {
      return (
        <div className="text-center py-8 text-gray-400">
          No data available for this report
        </div>
      );
    }

    const headers = Object.keys(reportData[0]);

    let filteredData = reportData;

    // Apply search filter
    if (searchTerm) {
      filteredData = reportData.filter(item =>
        Object.values(item).some(value =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Apply sorting
    if (sortConfig.key) {
      filteredData = [...filteredData].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return (
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              {headers.map(header => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700"
                  onClick={() => handleSort(header)}
                >
                  <div className="flex items-center space-x-1">
                    <span>{header}</span>
                    {sortConfig.key === header && (
                      sortConfig.direction === 'asc' ?
                        <ChevronUp className="h-4 w-4" /> :
                        <ChevronDown className="h-4 w-4" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {filteredData.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-gray-700">
                {headers.map(header => (
                  <td key={header} className="px-6 py-4 whitespace-nowrap text-sm">
                    {typeof row[header] === 'object' ?
                      JSON.stringify(row[header]) :
                      String(row[header])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-white">Admin Reports</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Reports List */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <h3 className="text-lg font-semibold mb-4 text-purple-400">Available Reports</h3>
          <div className="space-y-2">
            {availableReports.map(report => (
              <button
                key={report.id}
                onClick={() => handleReportSelect(report)}
                className={`w-full text-left p-3 rounded-lg transition-colors ${selectedReport?.id === report.id
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-700 hover:bg-gray-600 text-gray-300'
                  }`}
              >
                <div className="flex items-center">
                  <FileText className="h-5 w-5 mr-2" />
                  <div>
                    <div className="font-medium">{report.name}</div>
                    <div className="text-xs opacity-75">{report.description}</div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Report Content */}
        <div className="md:col-span-3 bg-gray-800 rounded-lg p-6">
          {selectedReport ? (
            <>
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h3 className="text-xl font-bold text-white">{selectedReport.name}</h3>
                  <p className="text-gray-400 text-sm">{selectedReport.description}</p>
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => fetchReportData(selectedReport.function)}
                    className="p-2 bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors"
                    disabled={loading}
                  >
                    <RefreshCw className={`h-5 w-5 ${loading ? 'animate-spin' : ''}`} />
                  </button>
                  <button
                    onClick={downloadCSV}
                    className="p-2 bg-green-500 rounded-lg hover:bg-green-600 transition-colors"
                    disabled={!reportData || loading}
                  >
                    <Download className="h-5 w-5" />
                  </button>
                </div>
              </div>

              <div className="mb-4">
                <div className="relative">
                  <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search report data..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                </div>
              </div>

              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <Loader className="h-8 w-8 animate-spin text-purple-500" />
                </div>
              ) : error ? (
                <div className="text-red-500 text-center py-8">
                  {error}
                  <button
                    onClick={() => fetchReportData(selectedReport.function)}
                    className="block mx-auto mt-4 text-white bg-red-500 px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
                  >
                    Retry
                  </button>
                </div>
              ) : (
                renderReportData()
              )}
            </>
          ) : (
            <div className="flex flex-col items-center justify-center h-64 text-gray-400">
              <FileText className="h-16 w-16 mb-4" />
              <p>Select a report from the list to view data</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DynamicReports;