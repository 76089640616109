import React, { useState, useRef, useEffect } from 'react';
import { ArrowUp, X } from 'lucide-react';

const GrowingTextboxComponent = ({ sendText, onClose }) => {
  const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      sendText(message, 'audio');
      setMessage('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto relative">
      <form onSubmit={handleSubmit} className="flex items-end">
        <textarea
          ref={textareaRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message... (Press Enter to send)"
          className="w-full p-4 pr-24 bg-gray-700 bg-opacity-50 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 resize-none overflow-hidden min-h-[56px] max-h-[200px]"
          rows={1}
        />
        <button
          type="submit"
          className="absolute right-12 bottom-2 bg-purple-600 text-white p-2 rounded-full hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
          disabled={!message.trim()}
        >
          <ArrowUp size={20} />
        </button>
        <button
          type="button"
          onClick={onClose}
          className="absolute right-2 bottom-2 bg-gray-600 text-white p-2 rounded-full hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          <X size={20} />
        </button>
      </form>
    </div>
  );
};

export default GrowingTextboxComponent;