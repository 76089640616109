import React from 'react';
import TypeWriter from './TypeWriter';
import { MicOff } from 'lucide-react';

const Avatar = ({ letter, audioLevel, isHost, photo, conversationState, backgroundImage, captions, isMuted = true }) => {
  const baseScale = 1 + audioLevel * 0.2;
  const rippleScale1 = 1 + audioLevel * 0.5;
  const rippleScale2 = 1 + audioLevel * 0.7;

  return (
    <div className="w-full h-full flex flex-col items-center justify-center relative overflow-hidden">

      {backgroundImage && (

        < div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* Dimming overlay */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

      )}

      <div className="relative w-3/4 max-w-[12rem] aspect-square">
        {/* The main avatar circle */}
        <div
          className={`absolute inset-0 rounded-full flex items-center justify-center ${isHost ? 'bg-green-600' : 'bg-orange-600'
            }`}
          style={{
            transform: `scale(${baseScale})`,
            transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
          }}
        >
          {photo ? (
            <img rel="preload" src={photo} alt="User" className="w-full h-full object-cover rounded-full" />
          ) : (
            <span className="text-white text-[6vmin] font-bold">{letter}</span>
          )}
        </div>

        {/* Ripple Effect 1 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-30"
          style={{
            transform: `scale(${rippleScale1})`,
            transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
            opacity: `${audioLevel * 0.3}`,
          }}
        ></div>

        {/* Ripple Effect 2 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-20"
          style={{
            transform: `scale(${rippleScale2})`,
            transition: 'transform 0.4s ease-out, opacity 0.4s ease-out',
            opacity: `${audioLevel * 0.2}`,
          }}
        ></div>
        {/* Mute icon */}
        {/* {!isMuted && (
          <div className="absolute bottom-0 right-0 bg-gray-800 rounded-full p-2">
            <MicOff size={24} className="text-white" />
          </div>
        )} */}
      </div>

      {/* Generating indicator with jumping dots */}
      {conversationState != null && conversationState !== 'idle' && conversationState !== 'new' && conversationState !== 'responding' && isHost && (
        <div className="mt-4 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg border border-gray-700 shadow-xl rounded-lg text-white px-4 py-2 rounded-full text-sm font-semibold flex items-center">
          {(conversationState == 'processing' ? '' : conversationState) || ''}
          <span className="ml-2 flex space-x-1">
            <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></span>
            <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></span>
            <span className="w-2 h-2 bg-white rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></span>
          </span>
        </div>
      )}

      {conversationState == 'responding' && isHost && (
        <div
          className="mx-4 mt-4 bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg border border-gray-700 shadow-xl rounded-lg text-white px-4 py-2 rounded-full text-sm font-semibold flex items-center">
          <TypeWriter text={captions} />
        </div>

      )}




    </div>
  );
};

export default Avatar;