import React from 'react';
import { X, Play, CheckCircle, PauseCircle, HelpCircle, Globe, FileText, Image, Layers } from 'lucide-react';

const CardPreview = ({ card, isActive, onClick }) => {
  const getStatusIcon = () => {
    if (card.status === 'completed') return <CheckCircle className="text-green-500" size={18} />;
    if (card.status === 'active') return <Play className="text-blue-500" size={18} />;
    return <PauseCircle className="text-gray-500" size={18} />;
  };

  const getStatusText = () => {
    if (card.status === 'completed') return 'Completed';
    if (card.status === 'active') return 'Active';
    return 'Not Started';
  };

  const getCardIcon = () => {
    switch (card.type) {
      case 'roleplay': return <Play size={24} className="text-yellow-500" />;
      case 'questionnaire': return <HelpCircle size={24} className="text-green-500" />;
      case 'webview': return <Globe size={24} className="text-blue-500" />;
      case 'text': return <FileText size={24} className="text-purple-500" />;
      case 'image': return <Image size={24} className="text-pink-500" />;
      case 'carousel': return <Layers size={24} className="text-indigo-500" />;
      default: return <FileText size={24} className="text-gray-500" />;
    }
  };

  const renderCardContent = () => {
    switch (card.type) {
      case 'roleplay':
        return (
          <div className="flex items-center">
            <img src={card.inputData.avatar} alt={card.inputData.name} className="w-12 h-12 rounded-full mr-3" />
            <div>
              <h4 className="font-semibold">{card.inputData.name}</h4>
              <p className="text-sm opacity-70">{card.inputData.prompt.substring(0, 50)}...</p>
            </div>
          </div>
        );
      case 'questionnaire':
        return (
          <div>
            <h4 className="font-semibold">{card.title}</h4>
            <p className="text-sm opacity-70">{card.inputData?.survey?.length} questions</p>
          </div>
        );
      case 'webview':
        return (
          <div>
            <h4 className="font-semibold">{card.text}</h4>
            <p className="text-sm opacity-70">Interactive web content</p>
          </div>
        );
      case 'text':
        return (
          <div>
            <h4 className="font-semibold">Text Card</h4>
            <p className="text-sm opacity-70">{card.text.substring(0, 50)}...</p>
          </div>
        );
      case 'image':
        return (
          <div>
            <h4 className="font-semibold">{card.text}</h4>
            <p className="text-sm opacity-70">Image content</p>
          </div>
        );
      case 'carousel':
        return (
          <div>
            <h4 className="font-semibold">Carousel</h4>
            <p className="text-sm opacity-70">{card.items.length} slides</p>
          </div>
        );
      default:
        return <p>Unknown card type</p>;
    }
  };

  return (
    <div
      className={`mb-4 p-4 rounded-lg cursor-pointer transition-all duration-200 ${isActive ? 'bg-purple-600 text-white' : 'bg-gray-800 text-gray-200 hover:bg-gray-700'
        }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between mb-2">
        <span className="font-semibold text-sm uppercase tracking-wider flex items-center">
          {getCardIcon()}
          <span className="ml-2">{card?.type?.charAt(0)?.toUpperCase() + card?.type?.slice(1)}</span>
        </span>
        <div className="flex items-center">
          {getStatusIcon()}
          <span className="ml-2 text-xs">{getStatusText()}</span>
        </div>
      </div>
      {renderCardContent()}
    </div>
  );
};

const CardsSidebar = ({ cards, isOpen, onClose, onCardSelect, currentCardIndex }) => {
  return (
    <div
      className={`fixed top-0 right-0 h-full w-96 bg-gray-900 shadow-lg transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      style={{ maxHeight: '100vh', zIndex: '50', display: 'flex', flexDirection: 'column' }}
    >
      <div className="flex justify-between items-center p-4 bg-gray-800">
        <h3 className="font-bold text-xl text-white">Cards</h3>
        <button onClick={onClose} className="text-white opacity-80 hover:opacity-100">
          <X size={24} />
        </button>
      </div>

      <div className="flex-grow overflow-y-auto p-4">
        {cards.map((card, index) => (
          <CardPreview
            key={index}
            card={card}
            isActive={currentCardIndex === index}
            onClick={() => onCardSelect(card)}
          />
        ))}
      </div>
    </div>
  );
};

export default CardsSidebar;