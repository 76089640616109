import React, { useState, useEffect } from 'react';
import { Search, Calendar, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { dbUtils } from '../../../services/core/dbService';
import CreateSessionModal from './CreateSessionModal';


const UserSessionList = () => {
  const { user } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) fetchUserSessions(user.id);
  }, [user]);

  const fetchUserSessions = async (userId) => {
    try {
      setLoading(true);
      const data = await dbUtils.sessions.getUpcoming(userId);
      setSessions(data);
    } catch (err) {
      setError('Unable to load your sessions');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleJoinSession = (sessionId, status) => {
    if (status !== 'completed') {
      navigate(`/session/room/${sessionId}`);
    }
  };

  const handleDeleteSession = async (sessionId) => {
    try {
      await dbUtils.sessions.delete(sessionId);
      // After successful deletion, remove the session from the state
      setSessions(sessions.filter(session => session.id !== sessionId));
    } catch (error) {
      console.error('Failed to delete session:', error);
    }
  };

  const handleCreateSession = (newSession) => {
    setSessions([...sessions, newSession]);
    navigate(`/session/room/${newSession?.id}`);
  };

  const filteredSessions = sessions.filter(session =>
    session.topic?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'upcoming': return 'text-blue-400 bg-blue-400';
      case 'in-progress': return 'text-green-400 bg-green-400';
      case 'completed': return 'text-gray-400 bg-gray-400';
      default: return 'text-gray-400 bg-gray-400';
    }
  };


  const renderSessionRow = (session) => {
    const statusColor = getStatusColor(session.status);
    return (
      <div key={session.id} className="bg-white bg-opacity-5 backdrop-blur-lg rounded-xl p-4 sm:p-6 mb-4 transition-all duration-300 hover:bg-opacity-10">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
          <div className="flex items-center space-x-4">
            <div className={`w-10 h-10 sm:w-12 sm:h-12 ${statusColor} bg-opacity-20 rounded-full flex items-center justify-center text-white text-lg font-medium flex-shrink-0`}>
              {session.topic?.charAt(0).toUpperCase()}
            </div>
            <div className="min-w-0">
              <h3 className="text-base sm:text-lg font-medium text-white mb-1 truncate">
                {session.topic}
              </h3>
              <div className="flex flex-col sm:flex-row sm:items-center text-xs sm:text-sm text-gray-300 gap-2 sm:gap-4">
                <div className="flex items-center">
                  <Calendar className="w-3 h-3 sm:w-4 sm:h-4 mr-2" />
                  <span>{new Date(session.datetime).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center">
                  <Clock className="w-3 h-3 sm:w-4 sm:h-4 mr-2" />
                  <span>{new Date(session.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-2 sm:gap-3 mt-2 sm:mt-0">
            <span className={`px-2 py-1 ${statusColor} bg-opacity-20 text-xs font-medium rounded-full capitalize`}>
              {session.status}
            </span>
            <div className="flex gap-2 ml-auto sm:ml-0">
              {session.status !== 'completed' && (
                <button
                  onClick={() => handleJoinSession(session.id, session.status)}
                  className="px-3 py-1.5 sm:px-4 sm:py-2 bg-indigo-500 text-white text-xs sm:text-sm rounded-lg hover:bg-indigo-600 transition-colors duration-300"
                >
                  Join
                </button>
              )}
              <button
                onClick={() => handleDeleteSession(session.id)}
                className="px-3 py-1.5 sm:px-4 sm:py-2 bg-red-500 text-white text-xs sm:text-sm rounded-lg hover:bg-red-600 transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-lg text-gray-300">{error}</p>
        <button
          onClick={() => fetchUserSessions(user.id)}
          className="mt-4 px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-4 sm:py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 sm:mb-8">
        <h2 className="text-2xl sm:text-3xl font-semibold text-white">Your Sessions</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-full sm:w-auto px-4 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors duration-300"
        >
          + New Session
        </button>
      </div>

      <div className="relative mb-6 sm:mb-8">
        <input
          type="text"
          placeholder="Search sessions or coaches..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 sm:p-4 pl-10 sm:pl-12 bg-white bg-opacity-5 backdrop-blur-lg rounded-full text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 text-sm sm:text-base"
        />
        <Search className="absolute left-3 sm:left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
      </div>

      {filteredSessions.length > 0 ? (
        <div className="space-y-4">
          {filteredSessions.map(renderSessionRow)}
        </div>
      ) : (
        <p className="text-center text-gray-400 py-6 sm:py-8 text-sm sm:text-base">
          No sessions found. Create a new session to get started!
        </p>
      )}

      <CreateSessionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSessionCreated={handleCreateSession}
      />
    </div>
  );
};

export default UserSessionList;