import React, { useState } from 'react';
import { Plus, Target, Zap, BarChart2, TrendingUp, ChevronRight, AlertCircle } from 'lucide-react';

const PriorityMatrix = () => {
  const [tasks, setTasks] = useState([
    {
      id: 1,
      title: "Launch Enterprise Plan",
      impact: 85,
      complexity: 35,
      timeline: "Q1 2025",
      category: "revenue",
      status: "planning"
    },
    {
      id: 2,
      title: "Hire Tech Lead",
      impact: 70,
      complexity: 60,
      timeline: "Q1 2025",
      category: "team",
      status: "active"
    },
    {
      id: 3,
      title: "AI Feature Launch",
      impact: 90,
      complexity: 75,
      timeline: "Q1 2025",
      category: "product",
      status: "planning"
    },
    {
      id: 4,
      title: "Security Audit",
      impact: 40,
      complexity: 45,
      timeline: "Q1 2025",
      category: "operations",
      status: "active"
    }
  ]);

  const [showNewTask, setShowNewTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const getQuadrantLabel = (impact, complexity) => {
    if (impact >= 50 && complexity < 50) return "Quick Wins";
    if (impact >= 50 && complexity >= 50) return "Strategic Projects";
    if (impact < 50 && complexity < 50) return "Minor Tasks";
    return "Time Sinks";
  };

  const getCategoryColor = (category) => {
    const colors = {
      revenue: "text-green-400",
      team: "text-blue-400",
      product: "text-purple-400",
      operations: "text-orange-400"
    };
    return colors[category] || "text-gray-400";
  };

  const TaskDot = ({ task, onClick }) => {
    const xPos = task.complexity;
    const yPos = 100 - task.impact; // Invert for correct positioning

    return (
      <div
        className={`absolute w-4 h-4 rounded-full cursor-pointer transform -translate-x-1/2 -translate-y-1/2 
          transition-all duration-300 hover:scale-150
          ${task.impact >= 50 && task.complexity < 50 ? 'bg-green-500' :
            task.impact >= 50 && task.complexity >= 50 ? 'bg-blue-500' :
              task.impact < 50 && task.complexity < 50 ? 'bg-gray-500' :
                'bg-red-500'} 
          ${selectedTask?.id === task.id ? 'ring-2 ring-white scale-150' : ''}`}
        style={{
          left: `${xPos}%`,
          top: `${yPos}%`
        }}
        onClick={() => onClick(task)}
        title={task.title}
      />
    );
  };

  return (
    <div className="w-full h-full bg-gray-900 text-white p-8 rounded-xl">
      <div className="mb-8 flex justify-between items-start">
        <div>
          <h2 className="text-3xl font-bold mb-2">Vision to Milestones</h2>
          <div className="flex items-center text-gray-400">
            <Target className="w-4 h-4 mr-2" />
            <span>Q1 2025 Planning</span>
            <span className="mx-2">•</span>
            <span>{tasks.length} milestones</span>
          </div>
        </div>
        <button
          onClick={() => setShowNewTask(true)}
          className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center"
        >
          <Plus size={20} className="mr-2" />
          Add Milestone
        </button>
      </div>

      <div className="flex gap-8">
        {/* Matrix */}
        <div className="flex-grow bg-gray-800 rounded-xl p-6 relative" style={{ aspectRatio: '1' }}>
          {/* Quadrant Labels */}
          <div className="absolute top-4 right-4 text-sm font-medium text-green-400">
            Quick Wins
          </div>
          <div className="absolute top-4 left-4 text-sm font-medium text-red-400">
            Time Sinks
          </div>
          <div className="absolute bottom-4 right-4 text-sm font-medium text-blue-400">
            Strategic Projects
          </div>
          <div className="absolute bottom-4 left-4 text-sm font-medium text-gray-400">
            Minor Tasks
          </div>

          {/* Axes */}
          <div className="absolute left-0 top-1/2 -translate-y-1/2 -rotate-90 text-sm text-gray-400">
            Impact
          </div>
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 text-sm text-gray-400">
            Complexity
          </div>

          {/* Grid Lines */}
          <div className="absolute left-1/2 top-0 bottom-0 border-l border-gray-700" />
          <div className="absolute top-1/2 left-0 right-0 border-t border-gray-700" />

          {/* Sweet Spot Indicator */}
          <div className="absolute right-[25%] top-[25%] w-16 h-16 border-2 border-green-500 rounded-full opacity-20 animate-pulse" />

          {/* Task Dots */}
          {tasks.map(task => (
            <TaskDot
              key={task.id}
              task={task}
              onClick={setSelectedTask}
            />
          ))}
        </div>

        {/* Selected Task Details */}
        <div className="w-80 bg-gray-800 rounded-xl p-6">
          {selectedTask ? (
            <div>
              <div className="flex items-start justify-between mb-4">
                <h3 className="text-lg font-semibold">{selectedTask.title}</h3>
                <span className={`text-sm ${getCategoryColor(selectedTask.category)}`}>
                  {selectedTask.category.charAt(0).toUpperCase() + selectedTask.category.slice(1)}
                </span>
              </div>

              <div className="space-y-4">
                <div>
                  <div className="text-sm text-gray-400 mb-1">Impact</div>
                  <div className="w-full bg-gray-700 rounded-full h-2">
                    <div
                      className="bg-purple-500 h-2 rounded-full"
                      style={{ width: `${selectedTask.impact}%` }}
                    />
                  </div>
                </div>

                <div>
                  <div className="text-sm text-gray-400 mb-1">Complexity</div>
                  <div className="w-full bg-gray-700 rounded-full h-2">
                    <div
                      className="bg-blue-500 h-2 rounded-full"
                      style={{ width: `${selectedTask.complexity}%` }}
                    />
                  </div>
                </div>

                <div className="pt-4">
                  <div className="text-sm text-gray-400 mb-2">Quadrant Analysis</div>
                  <div className="bg-gray-900 rounded-lg p-4">
                    <div className="flex items-center mb-2">
                      <Zap className="w-4 h-4 mr-2 text-yellow-400" />
                      <span>
                        {getQuadrantLabel(selectedTask.impact, selectedTask.complexity)}
                      </span>
                    </div>
                    <p className="text-sm text-gray-400">
                      {selectedTask.impact >= 50 && selectedTask.complexity < 50
                        ? "High impact, low complexity. Prioritize these tasks."
                        : selectedTask.impact >= 50 && selectedTask.complexity >= 50
                          ? "High impact but complex. Break down into smaller tasks."
                          : selectedTask.impact < 50 && selectedTask.complexity < 50
                            ? "Low priority tasks. Consider delegating."
                            : "High effort, low impact. Avoid or simplify these tasks."
                      }
                    </p>
                  </div>
                </div>

                <div className="flex items-center pt-4">
                  <span className="text-sm text-gray-400 flex items-center">
                    <AlertCircle size={14} className="mr-1" />
                    Status: {selectedTask.status.charAt(0).toUpperCase() + selectedTask.status.slice(1)}
                  </span>
                  <span className="mx-2 text-gray-600">•</span>
                  <span className="text-sm text-gray-400">{selectedTask.timeline}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-full flex flex-col items-center justify-center text-gray-400">
              <Target className="w-8 h-8 mb-2 opacity-50" />
              <p className="text-center">Select a milestone to see details</p>
            </div>
          )}
        </div>
      </div>

      {/* Legend */}
      <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-400">
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-green-500 mr-2" />
          Quick Wins
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-blue-500 mr-2" />
          Strategic Projects
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-red-500 mr-2" />
          Time Sinks
        </div>
        <div className="flex items-center">
          <div className="w-3 h-3 rounded-full bg-gray-500 mr-2" />
          Minor Tasks
        </div>
      </div>
    </div>
  );
};

export default PriorityMatrix;