import React, { useState, useEffect } from 'react';
import { Smartphone, AlertCircle } from 'lucide-react';

const LinkedInRedirectTest = () => {
  const [log, setLog] = useState([]);
  const [deviceType, setDeviceType] = useState(null);

  const addLog = (message) => {
    setLog(prev => [...prev, `${new Date().toLocaleTimeString()}: ${message}`]);
  };

  useEffect(() => {
    // Detect device type from user agent
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('android')) {
      setDeviceType('android');
      addLog('Detected Android device');
    } else if (ua.includes('iphone') || ua.includes('ipad') || ua.includes('ipod')) {
      setDeviceType('ios');
      addLog('Detected iOS device');
    } else {
      setDeviceType('other');
      addLog('Detected other device type');
    }

    // Check if we've already redirected to prevent loops
    const url = new URL(window.location.href);
    const hasRedirected = url.searchParams.get('redirected') === 'true';
    if (!hasRedirected) {
      addLog('No previous redirect detected');
    }
  }, []);

  const attemptRedirect = () => {
    const baseUrl = window.location.origin.replace('https://', '');
    const currentUrl = window.location.href;
    const redirectUrl = new URL(currentUrl);
    redirectUrl.searchParams.set('redirected', 'true');

    addLog('Attempting redirect for ' + deviceType);

    if (deviceType === 'android') {
      // Android: Use Chrome intent
      const intentUrl = `intent://${baseUrl}#Intent;scheme=https;package=com.android.chrome;end`;
      addLog('Redirecting to: ' + intentUrl);
      window.location.href = intentUrl.replace('linkedin-redirect', '');
    }
    else if (deviceType === 'ios') {
      // iOS: Use Safari protocol
      const safariUrl = `x-safari-${window.location.href.replace('linkedin-redirect', '')}`;
      addLog('Redirecting to: ' + safariUrl);
      window.location.href = safariUrl;
    }
    else {
      addLog('Device type not supported for redirect');
    }
  };

  const attemptFallbackRedirect = () => {
    addLog('Attempting fallback redirect...');
    const baseUrl = window.location.href;

    if (deviceType === 'ios') {
      // Try iOS Shortcuts app as fallback
      const shortcutUrl = `shortcuts://x-callback-url/run-shortcut?name=${crypto.randomUUID()}&x-error=${encodeURIComponent(baseUrl)}`;
      addLog('Trying iOS Shortcuts fallback: ' + shortcutUrl);
      window.location.href = shortcutUrl;
    } else if (deviceType === 'android') {
      // Try default Android browser as fallback
      const intentUrl = `intent://${baseUrl.replace('https://', '')}#Intent;end`;
      addLog('Trying Android default browser fallback: ' + intentUrl);
      window.location.href = intentUrl;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold flex items-center gap-2">
            <Smartphone className="text-blue-400" />
            Native Browser Redirect
          </h1>
          <div className="flex items-center gap-2">
            <AlertCircle className={deviceType ? 'text-green-400' : 'text-yellow-400'} />
            <span className="text-sm">
              {deviceType ? `Detected: ${deviceType}` : 'Detecting...'}
            </span>
          </div>
        </div>

        <div className="space-y-4 mb-6">
          {/* Primary Redirect Button */}
          <button
            onClick={attemptRedirect}
            className="w-full bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg font-semibold text-lg"
          >
            Open in Native Browser
          </button>

          {/* Fallback Button */}
          <button
            onClick={attemptFallbackRedirect}
            className="w-full bg-purple-600 hover:bg-purple-700 px-6 py-3 rounded-lg font-semibold text-lg"
          >
            Try Fallback Method
          </button>

          {/* Instant Double-Attempt Button */}
          <button
            onClick={() => {
              attemptRedirect();
              setTimeout(attemptFallbackRedirect, 100);
            }}
            className="w-full bg-green-600 hover:bg-green-700 px-6 py-3 rounded-lg font-semibold text-lg"
          >
            Try Both Methods
          </button>
        </div>

        {/* Log Display */}
        <div className="bg-black rounded-lg p-4">
          <h2 className="text-sm text-gray-400 mb-2">Debug Log:</h2>
          <div className="font-mono text-sm space-y-1">
            {log.map((entry, index) => (
              <div key={index} className="text-green-400">
                {entry}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInRedirectTest;