import { useState, useEffect, useRef } from 'react';

export function useScreenShare() {
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const screenRef = useRef(null);
  const streamRef = useRef(null);

  const startScreenShare = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      streamRef.current = stream;
      if (screenRef.current) {
        screenRef.current.srcObject = stream;
      }
      setIsScreenSharing(true);
    } catch (err) {
      console.error("Error starting screen share:", err);
    }
  };

  const stopScreenShare = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    if (screenRef.current) {
      screenRef.current.srcObject = null;
    }
    setIsScreenSharing(false);
  };

  const captureFrame = () => {
    return new Promise((resolve, reject) => {

      if (screenRef.current && screenRef.current.srcObject && screenRef.current.videoWidth > 0) {

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = screenRef.current.videoWidth;
        canvas.height = screenRef.current.videoHeight;
        context.drawImage(screenRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async blob => {
          const image = await blobToBase64(blob);
          resolve(image);
        });
      } else {
        resolve(null);  // Handle no screen share source available
      }
    });
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    return () => {
      stopScreenShare();
    };
  }, []);

  return {
    isScreenSharing,
    screenRef,
    startScreenShare,
    stopScreenShare,
    captureFrame
  };
}