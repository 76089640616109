import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Home, Calendar, BarChart2, Menu, X, Settings, ListStartIcon, LockKeyhole } from 'lucide-react';
import { useAuth } from '../../../AuthContext';
import Logo from '../../components/Logo';
import LogoutButton from '../../components/LogoutButton';
import UserSettingsModal from './UserSettingsMenu';

const UserLayout = () => {
  const { user, signOut } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const navItems = [
    { icon: Home, label: 'Home', path: '/user/home' },
    { icon: Calendar, label: 'Sessions', path: '/user/sessions' },
    { icon: BarChart2, label: 'Insights', path: '/user/insights' },
  ];
  if (user.user_metadata?.is_admin) {
    navItems.push({ icon: LockKeyhole, label: 'Admin', path: '/admin' },
    )
  }

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="min-h-screen w-full bg-[#1B1130] text-white font-sans overflow-hidden">
      {/* Mobile Header */}
      <header className="md:hidden flex justify-between items-center p-4 bg-[#1B1130] border-b border-white/20">
        <Logo text='Mentara' textColor='text-[#E7C6EF]' />
        <button onClick={toggleSidebar} className="text-white">
          {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </header>

      <div className="flex h-[calc(100vh-4rem)] md:h-screen">
        {/* Sidebar Navigation */}
        <nav className={`
          fixed md:static inset-y-0 left-0 z-30 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0 transition duration-200 ease-in-out
          w-64 bg-[#1B1130] backdrop-blur-md flex flex-col p-6
        `}>
          <div className="mb-10 hidden md:block">
            <Logo text='Mentara' textColor='text-[#E7C6EF]' />
          </div>

          <ul className="space-y-4 flex-grow">
            {navItems.map((item) => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center p-3 rounded-lg transition-all duration-200 ${isActive
                      ? 'bg-[#3D2E5E] text-[#E7C6EF] font-semibold'
                      : 'text-white hover:bg-[#2A1F45]'
                    }`
                  }
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <item.icon className="h-5 w-5 mr-3" />
                  <span className="text-lg">{item.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="mt-auto">
            <div className="flex items-center p-3 rounded-lg bg-[#2A1F45]">
              <img
                src={user?.user_metadata?.avatar_url}
                alt="User avatar"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div className="flex items-center min-w-0">
                <p className="font-medium text-base truncate max-w-[120px]">
                  {user?.user_metadata?.full_name}
                </p>
                <button
                  onClick={() => setIsSettingsOpen(true)}
                  className="ml-2 p-1.5 rounded-full hover:bg-[#3D2E5E] transition-colors"
                >
                  <Settings size={18} className="text-gray-400 hover:text-[#E7C6EF]" />
                </button>

              </div>
            </div>
            {/* <div className="flex items-center p-3 rounded-lg bg-[#2A1F45]">
              <img
                src={user?.user_metadata?.avatar_url}
                alt="User avatar"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <p className="font-medium text-base">{user?.user_metadata?.full_name}</p>
              </div>
            </div> */}
            <LogoutButton />
          </div>
        </nav>

        {/* Main Content Area */}
        <main className="flex-1 overflow-y-auto bg-[#231639] p-6 md:p-8">
          <div className="max-w-7xl mx-auto">
            <Outlet />
          </div>
        </main>
        <UserSettingsModal
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          user={user}
          signOut={signOut}
        />
      </div>
    </div>
  );
};

export default UserLayout;