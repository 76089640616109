// src/services/core/supabaseStorage.js
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';

const BUCKET_NAME = "user_media"

export const storageUtils = {
  async saveFile(file, fileType, contentType) {
    try {
      const fileName = `${uuidv4()}.${fileType}`;

      const { data, error } = await supabase.storage
        .from(BUCKET_NAME)
        .upload(fileName, file, {
          contentType: contentType,
        });

      if (error) throw error;

      const { data: urlData } = supabase.storage
        .from(BUCKET_NAME)
        .getPublicUrl(fileName);

      return urlData.publicUrl;
    } catch (error) {
      console.error('Error saving file:', error);
      throw error;
    }
  },

  async getFileUrl(fileName) {
    const { data } = supabase.storage
      .from('your-bucket-name')
      .getPublicUrl(fileName);

    return data.publicUrl;
  },

  async deleteFile(fileName) {
    const { data, error } = await supabase.storage
      .from('your-bucket-name')
      .remove([fileName]);

    if (error) throw error;
    return data;
  },

  getPreviewUrl(fileUrl) {
    return `https://mentara-641bf3834f29.herokuapp.com/preview?file_url=${encodeURIComponent(fileUrl)}`;
  }
};