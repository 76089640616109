import { useState, useEffect } from 'react';

export const useNativeRedirect = (targetUrl = 'https://mentara.io') => {
  const [deviceType, setDeviceType] = useState(null);
  const [hasRedirected, setHasRedirected] = useState(false);

  // Detect device and check for previous redirect
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.includes('android')) {
      setDeviceType('android');
    } else if (ua.includes('iphone') || ua.includes('ipad') || ua.includes('ipod')) {
      setDeviceType('ios');
    } else {
      setDeviceType('other');
    }

    // Check for previous redirect
    const url = new URL(window.location.href);
    if (url.searchParams.get('redirected') === 'true') {
      setHasRedirected(true);
      // Clean up URL
      url.searchParams.delete('redirected');
      window.history.replaceState({}, '', url.toString());
    }
  }, []);

  const attemptRedirect = () => {
    if (hasRedirected) return;

    const url = new URL(targetUrl);
    url.searchParams.set('redirected', 'true');
    const baseUrl = targetUrl.replace('https://', '');

    if (deviceType === 'android') {
      window.location.href = `intent://${baseUrl}#Intent;scheme=https;package=com.android.chrome;end`;
    } else if (deviceType === 'ios') {
      window.location.href = `x-safari-${targetUrl}`;
    }

    // Set hasRedirected to prevent multiple attempts
    setHasRedirected(true);
  };

  const attemptFallbackRedirect = () => {
    if (hasRedirected) return;

    if (deviceType === 'android') {
      window.location.href = `intent://${targetUrl.replace('https://', '')}#Intent;end`;
    } else if (deviceType === 'ios') {
      // Use iOS Shortcuts as fallback
      window.location.href = `shortcuts://x-callback-url/run-shortcut?name=${crypto.randomUUID()}&x-error=${encodeURIComponent(targetUrl)}`;
    }

    setHasRedirected(true);
  };

  return {
    deviceType,
    hasRedirected,
    attemptRedirect,
    attemptFallbackRedirect
  };
};