import { useState, useEffect } from 'react';

export const useLinkedInDetector = () => {
  const [isLinkedIn, setIsLinkedIn] = useState(false);
  const [detectionResults, setDetectionResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const runTests = () => {
      const results = {};

      // Test 1: Check referrer
      results.referrerTest = {
        result: document.referrer.includes('linkedin.com')
      };

      // Test 2: Check window.parent
      try {
        const hasParent = window.parent !== window;
        const canAccessParent = (() => {
          try {
            return Boolean(window.parent.location.href);
          } catch (e) {
            return false;
          }
        })();
        results.parentWindowTest = {
          result: hasParent && !canAccessParent
        };
      } catch (e) {
        results.parentWindowTest = {
          result: true
        };
      }

      // Test 3: Check LinkedIn specific query parameters
      const urlParams = new URLSearchParams(window.location.search);
      results.queryParamTest = {
        result: urlParams.has('original_referer') || urlParams.has('linkedInShare')
      };

      // Test 4: Check window dimensions
      const isCommonEmbedSize = window.innerWidth === 552 || window.innerHeight === 367;
      results.dimensionsTest = {
        result: isCommonEmbedSize
      };

      // Test 5: Check for LinkedIn-specific objects/globals
      results.globalObjectsTest = {
        result: Boolean(window.IN || window.liap)
      };

      // Test 6: Check iframe context
      results.iframeContextTest = {
        result: window !== window.top
      };

      // Test 7: User Agent Check
      results.userAgentTest = {
        result: navigator.userAgent.toLowerCase().includes('linkedin')
      };

      setDetectionResults(results);

      // Consider it LinkedIn if 2 or more tests pass
      const passedTests = Object.values(results).filter(test => test.result).length;
      setIsLinkedIn(passedTests >= 2 || results.userAgentTest.result);

      setIsLoading(false);
    };

    // Run tests after a short delay
    setTimeout(runTests, 500);
  }, []);

  return { isLinkedIn, isLoading, detectionResults };
};