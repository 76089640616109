import React, { useState } from 'react';
import { Rocket, Target, TrendingUp, ArrowRight, X } from 'lucide-react';
import SessionRoomPage from '../SessionRoomPage';
import { useAuth } from '../../../AuthContext';
import { sessionTemplateService } from '../../../services/ai/SessionGenerator';
import { useLocation } from 'react-router-dom';

const UserOnboarding = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Get template ID from query params
  const query = new URLSearchParams(useLocation().search);
  const templateId = query.get('template') || '4fb00624-cc09-4a32-88f1-95a1d03fa472'; // Default to YC template

  // Mock template data - replace with actual API call later
  const templateData = {
    '4fb00624-cc09-4a32-88f1-95a1d03fa472': {
      title: 'Master Your YC Interview Through AI Practice',
      description: 'Perfect your pitch through multiple attempts during YC application season.',
      buttonText: 'Start with a practice session now.',
      benefits: [
        { icon: Rocket, title: "Ultra-Realistic YC Experience", desc: "Gain insights to scale your startup faster." },
        { icon: Target, title: "Deep, Adaptive Conversations", desc: "Clarify priorities and make data-driven decisions." },
        { icon: TrendingUp, title: "Immediate, Actionable Feedback", desc: "Enhance your leadership and operational efficiency." },
      ]
    }
    // Add more templates here
  };

  const currentTemplate = templateData[templateId];

  // Update handleCreateSession to use dynamic templateId
  const handleCreateSession = async () => {
    try {
      setLoading(true);
      const newSession = await sessionTemplateService.createSessionFromTemplate(templateId, user.id);
      window.location = '/session/room/' + newSession.id;
    } catch (err) {
      setError('Failed to create session');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-lg text-gray-300">{error}</p>
        <button
          onClick={() => handleCreateSession()}
          className="mt-4 px-6 py-2 bg-indigo-500 text-white rounded-lg hover:bg-indigo-600 transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Embedded SessionRoomPage */}
      <div className="absolute inset-0 filter blur-sm">
        <SessionRoomPage demoMode={true} sessionId={'7f3da77d-4968-47be-8833-623dc15013b2'} />
      </div>

      {/* Overlay */}
      {showOverlay && currentTemplate && (
        <div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-2xl p-6 md:p-8 max-w-2xl w-full relative shadow-2xl border border-gray-800">
            <button
              onClick={() => setShowOverlay(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
            >
              <X size={24} />
            </button>
            <h1 className="text-3xl md:text-4xl font-bold mb-2 text-white">
              Hey, {user.user_metadata.full_name}
            </h1>
            <p className="text-base md:text-lg mb-6 text-gray-300">
              {currentTemplate.title}
            </p>
            <div className="space-y-4 mb-8">
              {currentTemplate.benefits.map((benefit, index) => (
                <div key={index} className="flex items-start space-x-4">
                  <div className="bg-gray-800 p-2 rounded-full mt-1">
                    <benefit.icon size={20} className="text-blue-400" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-white">{benefit.title}</h3>
                    <p className="text-sm text-gray-400">{benefit.desc}</p>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={handleCreateSession}
              className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 rounded-lg font-semibold flex items-center justify-center hover:from-blue-600 hover:to-purple-700 transition duration-300"
            >
              {currentTemplate.buttonText} <ArrowRight className="ml-2" size={20} />
            </button>
            <p className="text-xs text-center mt-4 text-gray-500">
              {currentTemplate.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOnboarding; 