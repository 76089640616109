import { useRef, useCallback, useState, useEffect } from "react";

export function useMicrophone(onSpeechStart, onSpeechEnd) {
  const vadRef = useRef(null);
  const [audioLevel, setAudioLevel] = useState(0);

  const startListening = useCallback(async () => {
    if (!vadRef.current) {
      try {
        vadRef.current = await window.vad.MicVAD.new({
          // Increase these values to make the VAD less sensitive
          positiveSpeechThreshold: 0.9,
          negativeSpeechThreshold: 0.8,
          minSpeechFrames: 3,
          preSpeechPadFrames: 15,
          redemptionFrames: 15,
          onSpeechEnd: (audio) => {
            onSpeechEnd(audio);
          },
          onSpeechStart: () => {
            onSpeechStart();
          },
          onFrameProcessed: (probs) => {
            // Update audio level based on voice activity probability
            setAudioLevel(probs.isSpeech ? probs.isSpeech : 0);
          },
        });
      } catch (error) {
        console.error("Error initializing VAD:", error);
      }
    }
    if (vadRef.current) {
      try {
        await vadRef.current.start();
      } catch (error) {
        console.error("Error starting VAD:", error);
      }
    }
  }, [onSpeechEnd, onSpeechStart]);

  const stopListening = useCallback(() => {
    if (vadRef.current) {
      vadRef.current.pause();
      setAudioLevel(0);

    }
  }, []);

  return { startListening, stopListening, audioLevel };
}