import React, { useState, useEffect } from 'react';
import { CheckCircle2, User, Briefcase, Target, ArrowRight } from 'lucide-react';
import CallCard from '../components/CallCard';

const ProfileChecklist = ({ userProfile, checklist }) => {
  return (
    <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-5 sm:p-6 border border-gray-700">
      <div className="flex flex-col sm:flex-row items-center mb-5">
        <div className="w-20 h-20 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full flex items-center justify-center mb-3 sm:mb-0 sm:mr-5 shadow-lg">
          <User className="w-10 h-10 text-white" />
        </div>
        <div className="text-center sm:text-left">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-2">{userProfile.name}</h2>
          <div className="flex items-center justify-center sm:justify-start text-gray-300 mb-1">
            <Briefcase className="w-4 h-4 mr-2" />
            <span>{userProfile.occupation}</span>
          </div>
          <div className="flex items-center justify-center sm:justify-start text-gray-300">
            <Target className="w-4 h-4 mr-2" />
            <span>{userProfile.goals}</span>
          </div>
        </div>
      </div>

      <h3 className="text-white font-semibold mb-3 text-xl">Onboarding Progress</h3>
      <div className="w-full bg-gray-700 rounded-full h-3 mb-5">
        <div
          className="bg-gradient-to-r from-purple-500 to-pink-500 h-3 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${(checklist.filter(item => item.completed).length / checklist.length) * 100}%` }}
        ></div>
      </div>

      <ul className="space-y-3">
        {checklist.map((item) => (
          <li
            key={item.id}
            className={`flex items-center space-x-3 p-3 rounded-lg transition-all duration-300 ease-in-out ${item.completed ? 'bg-purple-900 bg-opacity-20' : 'bg-gray-700 bg-opacity-40'
              }`}
          >
            <CheckCircle2 className={`w-5 h-5 sm:w-6 sm:h-6 ${item.completed ? 'text-purple-400' : 'text-gray-500'}`} />
            <span className={`text-base sm:text-lg ${item.completed ? 'text-purple-300' : 'text-gray-300'}`}>
              {item.text}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const OnboardingPage = () => {
  const [started, setStarted] = useState(false);
  const [userProfile, setUserProfile] = useState({
    name: 'Alex Johnson',
    occupation: 'Software Engineer',
    goals: 'Improve leadership skills',
  });

  const [checklist, setChecklist] = useState([
    { id: 1, text: 'Introduce yourself', completed: false },
    { id: 2, text: 'Share your main goal', completed: false },
    { id: 3, text: 'Discuss your background', completed: false },
    { id: 4, text: 'Identify potential challenges', completed: false },
    { id: 5, text: 'Set initial milestones', completed: false },
  ]);

  useEffect(() => {
    if (started) {
      const simulateConversation = () => {
        setChecklist(prev => {
          const uncompleted = prev.filter(item => !item.completed);
          if (uncompleted.length === 0) return prev;
          const randomIndex = Math.floor(Math.random() * uncompleted.length);
          return prev.map(item =>
            item.id === uncompleted[randomIndex].id ? { ...item, completed: true } : item
          );
        });
      };

      const interval = setInterval(simulateConversation, 2000);
      return () => clearInterval(interval);
    }
  }, [started]);

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-900 via-purple-900 to-black flex items-center justify-center overflow-hidden">
      <div className={`w-full max-w-6xl p-5 sm:p-8 ${started ? 'lg:flex lg:items-start' : ''}`}>
        <div className={`${started ? 'lg:w-1/2 lg:pr-8' : 'w-full max-w-2xl mx-auto'} mb-8 lg:mb-0`}>
          <h1 className="text-4xl sm:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-3">Mentara</h1>
          <p className="text-xl sm:text-2xl text-gray-300 mb-6">
            Accelerate Your Growth with AI-Powered Coaching
          </p>

          <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-4 sm:p-5 mb-6 border border-gray-700">
            <div className="h-64 sm:h-80 lg:h-96">
              <CallCard />
            </div>
          </div>

          <p className="text-base sm:text-lg text-gray-300 mb-6">
            Experience the future of personal development. Engage in natural conversations with our AI to unlock your full potential and achieve your goals faster than ever before.
          </p>

          {!started && (
            <button
              onClick={() => setStarted(true)}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 sm:py-4 px-6 sm:px-8 rounded-full text-lg sm:text-xl transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg flex items-center justify-center w-full"
            >
              Begin Your Journey <ArrowRight className="ml-3 w-5 h-5 sm:w-6 sm:h-6" />
            </button>
          )}
        </div>
        {started && (
          <div className="lg:w-1/2 lg:pl-8">
            <ProfileChecklist userProfile={userProfile} checklist={checklist} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingPage;