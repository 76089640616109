import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/dbService';
import { storageUtils } from '../../../../services/core/fileStorageService';
import { Save, X, Edit, Trash2, Loader, Plus, Minus, Upload, ArrowRight, ArrowLeft } from 'lucide-react';
import PromptTemplateEditor from '../../../components/PromptTemplateEditor';
import PromptTemplateSelector from '../../../components/PromptTemplateSelector';
import TranscriptAnalyzerForPrompt from '../../../components/TranscriptAnalyzerForPrompt';
import CardCreatorModal from '../../../components/CardCreatorModal';

const steps = [
  { label: 'Session Details', key: 'sessionDetails' },
  { label: 'User & Coach Selection', key: 'userCoachSelection' },
  { label: 'Session Content', key: 'sessionContent' },
  { label: 'Prompt Template', key: 'promptTemplate' },
  // { label: 'Cards', key: 'cards' },
  { label: 'Cards', key: 'cards' },

];

const SessionEdit = ({ sessionId, onSave, onCancel }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [users, setUsers] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [analysisCompleted, setAnalysisCompleted] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const [editingCardIndex, setEditingCardIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [promptTemplate, setPromptTemplate] = useState('');
  const [promptVariables, setPromptVariables] = useState({
    coaching_focus_area: '',
    specific_coaching_expertise: '',
    client_name_and_role: '',
    specific_challenges: '',
    key_areas_of_focus: '',
    relevant_framework_or_concept: '',
    specific_goal_of_questioning: '',
    client_details: '',
    client_life_challenges: '',
    session_objectives: '',
    coaching_phase: 'opening',
    coaching_mode: 'supportive',
    formality_level: 'semiformal',
    coaching_pace: 'moderate',
    language_complexity: 'moderate',
    coach_challenging_level: 'moderate',
    coaching_instructions: '',
    example_dialogue: '',
    client_name: '',
    phase_outcomes: '',
  });
  const [selectedPromptId, setSelectedPromptId] = useState(null);

  useEffect(() => {
    const fetchSessionAndLists = async () => {
      try {
        setLoading(true);
        const usersList = await dbUtils.users.getAll();
        const coachesList = await dbUtils.coaches.getAll();
        setUsers(usersList);
        setCoaches(coachesList);

        if (sessionId === 'new') {
          setSession({
            datetime: new Date().toISOString().slice(0, 16),
            topic: '',
            objectives: [],
            cards: [],
            status: 'scheduled',
            user_id: '',
            coach_id: '',
            prompt_id: null,
            session_prompt: '',
            configuration: {},
          });
        } else {
          const data = await dbUtils.sessions.getById(sessionId);
          setSession(data);
          setSelectedPromptId(data.prompt_id);

          setPromptTemplate(data.session_prompt || '');

          // if (data.prompt_id) {
          //   //const promptData = await dbUtils.prompts.getById(data.prompt_id);
          //   //setPromptTemplate(promptData.content);


          //   // Set the promptTemplate with the saved session_prompt
          //   setPromptTemplate(data.session_prompt || '');

          // } else {
          //   setPromptTemplate(data.session_prompt || '');
          // }
        }
      } catch (err) {
        setError('Failed to load session or template');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSessionAndLists();
  }, [sessionId]);

  const handlePromptSelect = async (promptId) => {
    setSelectedPromptId(promptId);
    if (promptId) {
      const promptData = await dbUtils.prompts.getById(promptId);
      setPromptTemplate(promptData.content);
      setSession(prev => ({ ...prev, prompt_id: promptId }));

      // Extract variables from the template
      const variableRegex = /\{\{(\w+)\}\}/g;
      const matches = promptData.content.matchAll(variableRegex);
      const extractedVariables = {};
      for (const match of matches) {
        extractedVariables[match[1]] = '';
      }
      setPromptVariables(extractedVariables);
    }
  };

  const handleAnalysisComplete = (result) => {
    console.log('Raw analysis result:', result);

    // Ensure result is an object
    if (typeof result !== 'object' || result === null) {
      console.error('Invalid analysis result:', result);
      return;
    }

    setAnalysisResult(result);

    setPromptVariables(prev => {
      const updatedVariables = { ...prev };

      // Iterate through the result and update corresponding variables
      Object.entries(result).forEach(([key, value]) => {
        if (updatedVariables.hasOwnProperty(key)) {
          updatedVariables[key] = value;
        }
      });

      console.log('Previous prompt variables:', prev);
      console.log('Updated prompt variables:', updatedVariables);

      return updatedVariables;
    });

    // Update session configuration
    setSession(prevSession => ({
      ...prevSession,
      configuration: {
        ...prevSession.configuration,
        analyzedTranscript: result
      }
    }));

    console.log('Session updated with analysis result');
  };
  const handleAddCard = () => {
    setEditingCardIndex(null);
    setIsModalOpen(true);
  };

  const handleEditCard = (index) => {
    setEditingCardIndex(index);
    setIsModalOpen(true);
  };

  const handleDeleteCard = (index) => {
    setSession(prevSession => ({
      ...prevSession,
      cards: prevSession.cards.filter((_, i) => i !== index)
    }));
  };

  const handleSaveCard = (cardData) => {
    setSession(prevSession => {
      const newCards = [...prevSession.cards];
      if (editingCardIndex !== null) {
        newCards[editingCardIndex] = cardData;
      } else {
        newCards.push(cardData);
      }
      return { ...prevSession, cards: newCards };
    });
  };




  const handleChange = (e, field, nestedField = null) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setSession(prev => {
      if (nestedField) {
        return {
          ...prev,
          [field]: {
            ...prev[field],
            [nestedField]: value
          }
        };
      } else {
        return { ...prev, [field]: value };
      }
    });
  };

  const handleArrayChange = (field, index, value) => {
    setSession(prev => ({
      ...prev,
      [field]: prev[field].map((item, i) => i === index ? value : item)
    }));
  };

  const addArrayItem = (field, item = '') => {
    setSession(prev => ({
      ...prev,
      [field]: [...prev[field], item]
    }));
  };

  const removeArrayItem = (field, index) => {
    setSession(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleAvatarUpload = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploadingAvatar(true);
        const extension = file.name.split('.').pop();
        const fileUrl = await storageUtils.saveFile(file, extension, `image/${extension}`);

        setSession(prev => ({
          ...prev,
          cards: prev.cards.map((card, i) =>
            i === index
              ? { ...card, inputData: { ...card.inputData, avatar: fileUrl } }
              : card
          )
        }));
      } catch (err) {
        console.error('Failed to upload avatar:', err);
      } finally {
        setUploadingAvatar(false);
      }
    }
  };

  const handlePromptTemplateSave = (newTemplate, newVariables) => {
    setPromptTemplate(newTemplate);
    setSession(prev => ({
      ...prev,
      session_prompt: newTemplate,
      configuration: {
        ...prev.configuration,
        ...newVariables,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!session.user_id || !session.coach_id) {
      setError('Please select both user and coach.');
      return;
    }

    const payload = {
      ...session,
      user_id: session.user_id,
      coach_id: session.coach_id,
    };

    delete payload.user
    delete payload.coach
    delete payload.prompt

    try {
      if (sessionId === 'new') {
        await dbUtils.sessions.create(payload);
      } else {
        await dbUtils.sessions.update(sessionId, payload);
      }
      onSave();
    } catch (err) {
      setError('Failed to save session');
      console.error(err);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 'sessionDetails':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Date and Time</label>
              <input
                type="datetime-local"
                value={session.datetime}
                onChange={(e) => handleChange(e, 'datetime')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Status</label>
              <select
                value={session.status}
                onChange={(e) => handleChange(e, 'status')}
                className="w-full p-2 bg-gray-700 rounded"
              >
                <option value="scheduled">Scheduled</option>
                <option value="in-progress">In Progress</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
          </div>
        );
      case 'userCoachSelection':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Select User</label>
              <select
                value={session.user_id}
                onChange={(e) => handleChange(e, 'user_id')}
                className="w-full p-2 bg-gray-700 rounded"
              >
                <option value="">-- Select User --</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>{user.name}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Select Coach</label>
              <select
                value={session.coach_id}
                onChange={(e) => handleChange(e, 'coach_id')}
                className="w-full p-2 bg-gray-700 rounded"
              >
                <option value="">-- Select Coach --</option>
                {coaches.map(coach => (
                  <option key={coach.id} value={coach.id}>{coach.name}</option>
                ))}
              </select>
            </div>
          </div>
        );
      case 'sessionContent':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Session Topic</label>
              <input
                type="text"
                value={session.topic}
                onChange={(e) => handleChange(e, 'topic')}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Session Objectives</label>
              {session.objectives.map((objective, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="text"
                    value={objective}
                    onChange={(e) => handleArrayChange('objectives', index, e.target.value)}
                    className="flex-grow p-2 bg-gray-700 rounded"
                  />
                  <button type="button" onClick={() => removeArrayItem('objectives', index)} className="p-2 bg-red-500 rounded">
                    <Minus size={16} />
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => addArrayItem('objectives')} className="mt-2 px-4 py-2 bg-blue-500 rounded-lg">
                <Plus className="inline-block mr-1" /> Add Objective
              </button>
            </div>
          </div>
        );
      case 'promptTemplate':
        return (
          <div className="space-y-6">
            <PromptTemplateSelector selectedPromptId={selectedPromptId}
              onSelect={handlePromptSelect} />
            {selectedPromptId && (
              <>
                <TranscriptAnalyzerForPrompt
                  onAnalysisComplete={handleAnalysisComplete}
                  templateVariables={promptVariables}
                />
                <PromptTemplateEditor
                  key={analysisCompleted ? 'analyzed' : 'initial'} // This key change will force a re-render
                  template={promptTemplate}
                  variables={promptVariables}
                  onSave={handlePromptTemplateSave}

                />
              </>
            )}
          </div>
        );
      // case 'cards':
      //   return (
      //     <div className="space-y-4">
      //       <h3 className="text-lg font-medium">Session Cards</h3>
      //       {session.cards.map((card, index) => (
      //         <div key={index} className="space-y-2">
      //           <div>
      //             <label className="block text-sm font-medium mb-1">Card Type</label>
      //             <select
      //               value={card.type}
      //               onChange={(e) => handleArrayChange('cards', index, { ...card, type: e.target.value })}
      //               className="w-full p-2 bg-gray-700 rounded"
      //             >
      //               <option value="roleplay">Roleplay</option>
      //               <option value="teleprompter">Teleprompter</option>
      //             </select>
      //           </div>
      //           {card.type === 'roleplay' && (
      //             <>
      //               <div>
      //                 <label className="block text-sm font-medium mb-1">Roleplay Name</label>
      //                 <input
      //                   type="text"
      //                   value={card.inputData?.name || ''}
      //                   onChange={(e) => handleArrayChange('cards', index, { ...card, inputData: { ...card.inputData, name: e.target.value } })}
      //                   className="w-full p-2 bg-gray-700 rounded"
      //                 />
      //               </div>
      //               <div>
      //                 <label className="block text-sm font-medium mb-1">Avatar URL or Upload</label>
      //                 <div className="flex space-x-4 items-center">
      //                   <input
      //                     type="text"
      //                     value={card.inputData?.avatar || ''}
      //                     onChange={(e) => handleArrayChange('cards', index, { ...card, inputData: { ...card.inputData, avatar: e.target.value } })}
      //                     className="w-full p-2 bg-gray-700 rounded"
      //                   />
      //                   <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
      //                     <Upload className="mr-2" />
      //                     {uploadingAvatar ? 'Uploading...' : 'Upload Avatar'}
      //                     <input type="file" onChange={(e) => handleAvatarUpload(e, index)} className="hidden" accept="image/*" />
      //                   </label>
      //                 </div>
      //                 {card.inputData?.avatar && (
      //                   <img src={card.inputData.avatar} alt="Avatar" className="w-16 h-16 rounded-full mt-2" />
      //                 )}
      //               </div>
      //               <div>
      //                 <label className="block text-sm font-medium mb-1">Roleplay Voice ID</label>
      //                 <input
      //                   type="text"
      //                   value={card.inputData?.voice || ''}
      //                   onChange={(e) => handleArrayChange('cards', index, { ...card, inputData: { ...card.inputData, voice: e.target.value } })}
      //                   className="w-full p-2 bg-gray-700 rounded"
      //                 />
      //               </div>
      //               <div>
      //                 <label className="block text-sm font-medium mb-1">Roleplay Prompt</label>
      //                 <textarea
      //                   value={card.inputData?.prompt || ''}
      //                   onChange={(e) => handleArrayChange('cards', index, { ...card, inputData: { ...card.inputData, prompt: e.target.value } })}
      //                   className="w-full p-2 bg-gray-700 rounded"
      //                 />
      //               </div>
      //             </>
      //           )}
      //           <button
      //             type="button"
      //             onClick={() => removeArrayItem('cards', index)}
      //             className="p-2 bg-red-500 rounded"
      //           >
      //             <Minus size={16} /> Remove Card
      //           </button>
      //         </div>
      //       ))}
      //       <button
      //         type="button"
      //         onClick={() => addArrayItem('cards', { type: 'roleplay', status: 'not-started', history: [], inputData: { name: '', avatar: '', voice: '', prompt: '' } })}
      //         className="mt-2 px-4 py-2 bg-blue-500 rounded-lg"
      //       >
      //         <Plus className="inline-block mr-1" /> Add Roleplay Card
      //       </button>
      //     </div>
      //   );
      // In your renderStepContent function, add a new case for 'cardCreator'
      case 'cards':
        return (
          <>
            {/* Cards section */}
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-purple-300 mb-2">Session Cards</h3>
              {session.cards.map((card, index) => (
                <div key={index} className="bg-gray-700 rounded-lg p-4 flex justify-between items-center">
                  <div>
                    <h4 className="text-lg font-medium">{card.type}</h4>
                    <p className="text-sm text-gray-300">
                      {card ? Object.keys(card?.inputData || []).length : 0} input field(s)
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      onClick={() => handleEditCard(index)}
                      className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
                    >
                      <Edit size={16} />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeleteCard(index)}
                      className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddCard}
                className="w-full py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
              >
                <Plus size={20} className="mr-2" /> Add New Card
              </button>
            </div>



          </>

        );


      default:
        return null;
    }
  };

  if (loading) return <div className="flex justify-center items-center h-64"><Loader className="animate-spin" /></div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
  if (!session) return <div className="text-center py-4">Session not found</div>;

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">{sessionId === 'new' ? 'Create New Session' : 'Edit Session'}</h2>
          <div className="space-x-2">
            <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
              <X className="inline-block mr-1" /> Cancel
            </button>
            <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
              <Save className="inline-block mr-1" /> Save
            </button>
          </div>
        </div>

        <div className="mb-6">
          <div className="flex space-x-4">
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => setCurrentStep(index)}
                className={`p-2 cursor-pointer ${index === currentStep ? 'bg-blue-600' : 'bg-gray-600'} rounded-lg text-white`}
              >
                {step.label}
              </div>
            ))}
          </div>
        </div>

        <div>{renderStepContent(steps[currentStep].key)}</div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
            className="px-4 py-2 bg-gray-600 rounded-lg"
          >
            <ArrowLeft className="inline-block mr-1" /> Previous
          </button>
          <button
            type="button"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === steps.length - 1}
            className="px-4 py-2 bg-blue-500 rounded-lg"
          >
            Next <ArrowRight className="inline-block ml-1" />
          </button>
        </div>

      </form>
      <CardCreatorModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveCard}
        initialCard={editingCardIndex !== null ? session.cards[editingCardIndex] : null}
      />
    </>
  );
};

export default SessionEdit;