import React from 'react';
import { CheckCircle, Clock } from 'lucide-react';

const SessionInfoCard = ({ sessionData }) => {
  return (
    <div className="text-white">
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mb-6 lg:mb-8">
        <div className="mb-4 lg:mb-0">
          <h1 className="text-3xl sm:text-4xl font-bold mb-2">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
              Mentara
            </span>{' '}
            Coaching Session
          </h1>
          <p className="text-lg sm:text-xl text-gray-300">{sessionData?.topic}</p>
        </div>
        <div className="flex items-center">
          <Clock className="mr-2 text-purple-400" />
          <span>{sessionData?.duration} mins</span>
        </div>
      </div>
      <h2 className="text-xl font-semibold mb-4">Session Objectives</h2>
      <ul className="space-y-2 mb-6">
        {sessionData?.objectives.map((objective, index) => (
          <li key={index} className="flex items-start">
            <CheckCircle className="w-5 h-5 mr-2 text-green-400 flex-shrink-0" />
            <span className="text-sm">{objective}</span>
          </li>
        ))}
      </ul>

      {/* <div className="bg-gray-700 bg-opacity-40 rounded-lg p-4">
        <h3 className="text-lg font-semibold mb-2">Your Coach: {sessionData?.coach.name}</h3>
        <div className="flex items-center">
          <img
            src={sessionData?.coach.avatar}
            alt={sessionData?.coach.name}
            className="w-16 h-16 rounded-full mr-4"
          />

          <p className="text-sm text-gray-300">{sessionData?.coach.bio}</p>
        </div>
      </div> */}
    </div>
  );
};

export default SessionInfoCard;