import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff, Video, VideoOff, MessageSquare, Settings, Maximize2, ScreenShareIcon, ScreenShareOff } from 'lucide-react';
import { ConversationManager } from '../../ConversationManager';
import Card from './Card';



const CallCard = () => {


  const [isMicOn, setIsMicOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);

  const sessionConfig = {
    prompt: {
      base: "You are an AI coach named Mentara, designed to guide users through their personal growth journey.",
      objective: "Complete the onboarding process by gathering essential user information and introducing Mentara's features."
    },
    stateVariables: [
      { name: "userName", description: "User's full name", currentValue: null },
      { name: "userAge", description: "User's age", currentValue: null },
      { name: "userOccupation", description: "User's current occupation", currentValue: null },
      { name: "userGoal", description: "User's primary personal growth goal", currentValue: null }
    ],
    checklist: [
      { id: 1, text: "Introduce yourself", completed: false },
      { id: 2, text: "Gather user's name", completed: false },
      { id: 3, text: "Gather user's age", completed: false },
      { id: 4, text: "Gather user's occupation", completed: false },
      { id: 5, text: "Identify user's primary goal", completed: false },
      { id: 6, text: "Explain Mentara's key features", completed: false },
      { id: 7, text: "Set initial milestones", completed: false }
    ],
    tools: [
      {
        type: "function",
        function: {
          name: "update_state",
          description: "Update user state with new information",
          parameters: {
            type: "object",
            properties: {
              variable: { type: "string", enum: ["userName", "userAge", "userOccupation", "userGoal"] },
              value: { type: "string" }
            },
            required: ["variable", "value"]
          }
        }
      },
      {
        type: "function",
        function: {
          name: "update_checklist",
          description: "Update onboarding checklist item",
          parameters: {
            type: "object",
            properties: {
              itemId: { type: "number", enum: [1, 2, 3, 4, 5, 6, 7] },
              completed: { type: "boolean" }
            },
            required: ["itemId", "completed"]
          }
        }

      },

    ]
  }

  const updateState = (property, value) => {
    return `${property} updated successfully to ${value}. `

  }
  const updateChecklist = (property, value) => {
    return `Item ${property} checklisted status successfully to ${value}. `

  }


  const {
    currentContent,
    playerAudioLevel,
    micAudioLevel,
    startListening,
    stopListening,
    conversationState,
    videoRef,
    startCamera,
    stopCamera,
  } = ConversationManager({ updateState, updateChecklist }, sessionConfig);



  // Setting up video stream if the video is on
  useEffect(() => {
    if (isVideoOn) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [isVideoOn]);

  useEffect(() => {
    if (isMicOn) {
      startListening()
      console.log('startListening')

    }
    else
      stopListening()
  }, [isMicOn])


  const Avatar = ({ letter, audioLevel, isHost, photo }) => {
    const baseScale = 1 + audioLevel * 0.2;
    const rippleScale1 = 1 + audioLevel * 0.5;
    const rippleScale2 = 1 + audioLevel * 0.7;

    return (
      <div className="w-full h-full flex items-center justify-center relative overflow-hidden">
        {/* The main avatar circle */}
        <div
          className={`relative z-10 rounded-full flex items-center justify-center ${isHost ? 'bg-green-600' : 'bg-orange-600'}`}
          style={{
            width: '100%', // Adjust width based on screen size
            maxWidth: '120px', // max size to avoid being too large
            height: '100%',
            maxHeight: '120px',
            transform: `scale(${baseScale})`,
            transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
          }}
        >
          {photo ? (
            <img
              src={photo}
              alt="User"
              className="object-cover rounded-full"
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '120px', // same max as container
                maxHeight: '120px',
              }}
            />
          ) : (
            <span className="text-white text-6xl font-bold">{letter}</span>
          )}
        </div>

        {/* Ripple Effect 1 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-30"
          style={{
            transform: `scale(${rippleScale1})`,
            transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
            opacity: `${audioLevel * 0.3}`,
          }}
        ></div>

        {/* Ripple Effect 2 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-20"
          style={{
            transform: `scale(${rippleScale2})`,
            transition: 'transform 0.4s ease-out, opacity 0.4s ease-out',
            opacity: `${audioLevel * 0.2}`,
          }}
        ></div>
      </div>
    );
  };




  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow flex items-center justify-center relative">
        <div className="absolute inset-0 flex p-4 gap-4">
          <div className="flex-1 bg-gray-800 rounded-lg overflow-hidden relative">
            {isVideoOn ? (
              <video ref={videoRef} autoPlay muted className="w-full h-full object-cover" />
            ) : (
              <Avatar letter="You" audioLevel={micAudioLevel} isHost={false} />
            )}

          </div>
          <div className="flex-1 bg-gray-800 rounded-lg overflow-hidden relative">
            {currentContent?.type && currentContent.type != 'text' ? (

              <div className="w-full">
                <Card
                  content={currentContent}
                />
              </div>


            ) : (
              <Avatar letter="M" audioLevel={playerAudioLevel} isHost={true} />

            )}


            <div className="absolute top-4 left-4 bg-green-500 px-2 py-1 rounded-lg text-white text-xs font-medium">
              Coach
            </div>
            <div className="absolute bottom-4 left-4 bg-gray-900 bg-opacity-60 px-3 py-1 rounded-lg text-white text-sm">
              {conversationState == 'listening' || conversationState == 'processing' ? conversationState + '...' : '...'}
            </div>
          </div>
        </div>
      </div>

      {/* Control buttons */}
      <div className="flex justify-center space-x-2 mb-4">
        <button
          onClick={() => setIsMicOn(!isMicOn)}
          className={`p-3 text-white rounded-full ${isMicOn ? 'bg-gray-700 hover:bg-gray-600' : 'bg-red-500 hover:bg-red-600'
            } transition duration-300`}
        >
          {isMicOn ? <Mic size={20} /> : <MicOff size={20} />}
        </button>
        <button
          onClick={() => setIsVideoOn(!isVideoOn)}
          className={`p-3 text-white rounded-full ${isVideoOn ? 'bg-gray-700 hover:bg-gray-600' : 'bg-red-500 hover:bg-red-600'
            } transition duration-300`}
        >
          {isVideoOn ? <Video size={20} /> : <VideoOff size={20} />}
        </button>
      </div>
    </div>
  );

};

export default CallCard;
