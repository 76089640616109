import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from './services/core/supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAuthChange = (event, session) => {
    console.log("Auth state changed:", event, session);
    setUser(session?.user ?? null);
    setLoading(false);

    if (event === 'SIGNED_IN' && session?.user) {
      // Check onboarding status from user metadata
      const isOnboarded = session.user.user_metadata?.onboarded;

      // Only redirect if user is on the home page
      if (location.pathname === '/') {
        if (isOnboarded) {
          navigate('/user/home');
        } else {
          navigate('/user/onboarding');
        }
      }
    } else if (event === 'SIGNED_OUT') {
      navigate('/');
    }
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthChange);

    // Initial session check
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);

      if (session?.user && location.pathname === '/') {
        const isOnboarded = session.user.user_metadata?.onboarded;
        if (isOnboarded) {
          navigate('/user/home');
        } else {
          navigate('/user/onboarding');
        }
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate, location]);

  const updateUserMetadata = async (metadata) => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        data: {
          ...user?.user_metadata,
          ...metadata
        }
      });


      if (error) {
        throw error;
      }

      // Update local user state with new metadata
      setUser(data.user);

      // If onboarding status is being updated, handle navigation
      if ('onboarded' in metadata && metadata.onboarded === true) {
        navigate('/user/home');
      }

      return { data, error: null };
    } catch (error) {
      console.error('Error updating user metadata:', error.message);
      return { data: null, error };
    }
  };

  // Helper function specifically for onboarding status
  const setOnboardingStatus = async (status) => {
    return await updateUserMetadata({ onboarded: status });
  };

  const value = {
    signIn: (options) => supabase.auth.signInWithOAuth(options),
    signOut: () => supabase.auth.signOut(),
    updateUserMetadata,
    setOnboardingStatus,
    user,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);