import React, { useState, useEffect } from 'react';
import { Settings, Moon, Sun, Monitor, Volume2, VolumeX, Mail, User, LogOut, X, Check } from 'lucide-react';
import UserMemory from './UserMemory';
import StripeCheckoutButton from '../../components/StripeCheckoutButton';
import BundlePricingModal from '../BundlePricingModal';
import { dbUtils } from '../../../services/core/dbService';

const UserSettingsModal = ({ isOpen, onClose, user, signOut }) => {
  const [activeTab, setActiveTab] = useState('general');
  const [theme, setTheme] = useState('system');
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [purchasedBundles, setPurchasedBundles] = useState([]);

  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const fetchPurchasedBundles = async () => {
    try {
      const bundles = await dbUtils.bundles.getUserBundles(user?.id);
      setPurchasedBundles(bundles);
    } catch (error) {
      console.error('Error fetching purchased bundles:', error);
    }
  };

  // Call this function when the component mounts or when the billing tab is activated
  useEffect(() => {
    if (activeTab === 'billing') {
      fetchPurchasedBundles();
    }
  }, [activeTab]);

  if (!isOpen) return null;

  return (
    <div className="fixed mt-15 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-[#231639] rounded-xl w-full max-w-5xl max-h-[95vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-700">
          <h2 className="text-2xl font-bold text-[#E7C6EF]">Settings</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>

        {/* Content */}
        <div className="flex flex-col md:flex-row flex-grow overflow-hidden">
          {/* Sidebar */}
          <div className="w-full md:w-64 border-b md:border-b-0 md:border-r border-gray-700">
            <nav className="p-4">
              <button
                onClick={() => setActiveTab('general')}
                className={`w-full text-left px-3 py-2 rounded-lg mb-2 ${activeTab === 'general' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'text-gray-300 hover:bg-[#2A1F45]'
                  }`}
              >
                General
              </button>
              <button
                onClick={() => setActiveTab('billing')}
                className={`w-full text-left px-3 py-2 rounded-lg ${activeTab === 'billing' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'text-gray-300 hover:bg-[#2A1F45]'
                  }`}
              >
                Billing
              </button>
              <button
                onClick={() => setActiveTab('memory')}
                className={`w-full text-left px-3 py-2 rounded-lg mb-2 ${activeTab === 'memory' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'text-gray-300 hover:bg-[#2A1F45]'
                  }`}
              >
                Memory
              </button>
              {/* <button
                onClick={() => setActiveTab('appearance')}
                className={`w-full text-left px-3 py-2 rounded-lg mb-2 ${activeTab === 'appearance' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'text-gray-300 hover:bg-[#2A1F45]'
                  }`}
              >
                Appearance
              </button> */}
              <button
                onClick={() => setActiveTab('account')}
                className={`w-full text-left px-3 py-2 rounded-lg ${activeTab === 'account' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'text-gray-300 hover:bg-[#2A1F45]'
                  }`}
              >
                Account
              </button>
            </nav>
          </div>

          {/* Main Content */}
          <div className="flex-grow p-8 overflow-y-auto">
            {activeTab === 'general' && (
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold text-[#E7C6EF] mb-4">Sound</h3>
                  <button
                    onClick={() => setSoundEnabled(!soundEnabled)}
                    className="flex items-center justify-between w-full p-3 rounded-lg bg-[#2A1F45] hover:bg-[#3D2E5E] transition-colors"
                  >
                    <div className="flex items-center gap-3">
                      {soundEnabled ? <Volume2 size={20} /> : <VolumeX size={20} />}
                      <span>Sound Effects</span>
                    </div>
                    <div className={`w-10 h-6 rounded-full flex items-center transition-colors ${soundEnabled ? 'bg-purple-600' : 'bg-gray-600'
                      }`}>
                      <div className={`w-4 h-4 rounded-full bg-white transition-transform ${soundEnabled ? 'translate-x-5' : 'translate-x-1'
                        }`} />
                    </div>
                  </button>
                </div>

                <div>
                  <h3 className="text-lg font-semibold text-[#E7C6EF] mb-4">Email Notifications</h3>
                  <div className="space-y-3">
                    <button className="flex items-center justify-between w-full p-3 rounded-lg bg-[#2A1F45] hover:bg-[#3D2E5E] transition-colors">
                      <div className="flex items-center gap-3">
                        <Mail size={20} />
                        <span>Session Reminders</span>
                      </div>
                      <div className="w-10 h-6 rounded-full bg-purple-600 flex items-center">
                        <div className="w-4 h-4 rounded-full bg-white translate-x-5" />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'memory' && (
              <div>
                <h3 className="text-lg font-semibold text-[#E7C6EF] mb-4">Memory</h3>

                <UserMemory />

              </div>
            )}

            {activeTab === 'appearance' && (
              <div>
                <h3 className="text-lg font-semibold text-[#E7C6EF] mb-4">Theme</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  <button
                    onClick={() => setTheme('light')}
                    className={`p-3 rounded-lg flex items-center gap-3 ${theme === 'light' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'bg-[#2A1F45] hover:bg-[#3D2E5E]'
                      }`}
                  >
                    <Sun size={20} />
                    <span>Light</span>
                    {theme === 'light' && <Check size={16} className="ml-auto" />}
                  </button>
                  <button
                    onClick={() => setTheme('dark')}
                    className={`p-3 rounded-lg flex items-center gap-3 ${theme === 'dark' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'bg-[#2A1F45] hover:bg-[#3D2E5E]'
                      }`}
                  >
                    <Moon size={20} />
                    <span>Dark</span>
                    {theme === 'dark' && <Check size={16} className="ml-auto" />}
                  </button>
                  <button
                    onClick={() => setTheme('system')}
                    className={`p-3 rounded-lg flex items-center gap-3 ${theme === 'system' ? 'bg-[#3D2E5E] text-[#E7C6EF]' : 'bg-[#2A1F45] hover:bg-[#3D2E5E]'
                      }`}
                  >
                    <Monitor size={20} />
                    <span>System</span>
                    {theme === 'system' && <Check size={16} className="ml-auto" />}
                  </button>
                </div>
              </div>
            )}

            {activeTab === 'account' && (
              <div className="space-y-6">
                <div className="p-4 rounded-lg bg-[#2A1F45]">
                  <div className="flex items-center gap-4 mb-4">
                    <img
                      src={user?.user_metadata?.avatar_url}
                      alt="Profile"
                      className="w-16 h-16 rounded-full"
                    />
                    <div>
                      <h3 className="font-semibold text-lg">{user?.user_metadata?.full_name}</h3>
                      <p className="text-gray-400">{user?.email}</p>
                    </div>
                  </div>
                  <button className="w-full p-3 rounded-lg bg-[#3D2E5E] hover:bg-[#4D3E6E] transition-colors">
                    Edit Profile
                  </button>
                </div>

                <button
                  onClick={signOut}
                  className="w-full p-3 rounded-lg bg-red-500/10 hover:bg-red-500/20 text-red-400 hover:text-red-300 transition-colors flex items-center justify-center gap-2"
                >
                  <LogOut size={20} />
                  Sign Out
                </button>
              </div>
            )}

            {activeTab === 'billing' && (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold text-[#E7C6EF] mb-4">Purchased Bundles</h3>
                {/* Display purchased bundles here */}
                {purchasedBundles.map(bundle => (
                  <div key={bundle.id} className="p-4 rounded-lg bg-[#2A1F45] mb-4">
                    <h4 className="font-semibold text-lg">{bundle.bundle?.name}</h4>
                    <p className="text-gray-400">{bundle.sessions_remaining} sessions remaining</p>
                  </div>
                ))}
                <button
                  onClick={() => window.location = '/user/billing'}
                  className="w-full p-3 rounded-lg bg-[#3D2E5E] hover:bg-[#4D3E6E] transition-colors"
                >
                  Purchase More Bundles
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default UserSettingsModal;