import { supabase } from "./supabaseClient";

export const dbUtils = {
  // Sessions
  sessions: {
    async getById(id) {
      return await fetchAPI('sessions.getById', { id });
    },
    async delete(id) {
      return await fetchAPI('sessions.delete', { id }, 'DELETE');
    },
    async getLastSession(userId, currentSessionId = null) {
      return await fetchAPI('sessions.getLastSession', { userId, currentSessionId });
    },
    async getUpcoming(userId) {
      return await fetchAPI('sessions.getUpcoming', { userId });
    },
    async create(sessionData) {
      return await fetchAPI('sessions.create', sessionData, 'POST');
    },
    async getAIEvaluation(sessionId) {
      return await fetchAPI('sessions.getAIEvaluation', { sessionId });
    },
    async saveSessionReport(id, report) {
      return await fetchAPI('sessions.saveSessionReport', { id, report }, 'PUT');
    },
    async getSessionReport(id) {
      return await fetchAPI('sessions.getSessionReport', { id });
    },
    async setAIEvaluation(sessionId, evaluation) {
      return await fetchAPI('sessions.setAIEvaluation', { sessionId, evaluation }, 'PUT');
    },
    async getCompletedTopics(userId, templateId) {
      return await fetchAPI('sessions.getCompletedTopics', { userId, templateId });
    },
    async update(id, updates) {
      return await fetchAPI('sessions.update', { id, updates }, 'PUT');
    },
    async resetSession(id) {
      return await fetchAPI('sessions.resetSession', { id }, 'PUT');
    },
    async getAll() {
      return await fetchAPI('sessions.getAll');
    },
  },

  // Coaches
  coaches: {
    async getAll() {
      return await fetchAPI('coaches.getAll');
    },
    async getById(id) {
      return await fetchAPI('coaches.getById', { id });
    },
    async update(id, updates) {
      return await fetchAPI('coaches.update', { id, updates }, 'PUT');
    },
    async create(coachData) {
      return await fetchAPI('coaches.create', coachData, 'POST');
    },
    async delete(id) {
      return await fetchAPI('coaches.delete', { id }, 'DELETE');
    },
  },

  // Bundles
  bundles: {
    async getLatestPurchasedBundleForTemplate(userId, sessionTemplateId) {
      return await fetchAPI('bundles.getLatestPurchasedBundleForTemplate', { userId, sessionTemplateId });
    },
    async getAll() {
      return await fetchAPI('bundles.getAll');
    },
    async getByStripeSessionId(stripeSessionId) {
      return await fetchAPI('bundles.getByStripeSessionId', { stripeSessionId });
    },
    async recordPurchase(userId, bundleId, stripePaymentId, sessionsGranted, stripeSessionId, metadata) {
      return await fetchAPI('bundles.recordPurchase', {
        userId,
        bundleId,
        stripePaymentId,
        sessionsGranted,
        stripeSessionId,
        metadata,
      }, 'POST');
    },
    async getUserBundle(userId, bundleId) {
      return await fetchAPI('bundles.getUserBundle', { userId, bundleId });
    },
    async getUserBundles(userId) {
      return await fetchAPI('bundles.getUserBundles', { userId });
    },
    async updateSessionsRemaining(bundleId, userId, sessionsUsed) {
      return await fetchAPI('bundles.updateSessionsRemaining', { bundleId, userId, sessionsUsed }, 'PUT');
    },
    async markBundleAsExpired(bundleId) {
      return await fetchAPI('bundles.markBundleAsExpired', { bundleId }, 'PUT');
    },
  },

  // Session Templates
  sessionTemplates: {
    async getAll(publishedStatus = true) {
      return await fetchAPI('sessionTemplates.getAll', { publishedStatus });
    },
    async getById(id) {
      return await fetchAPI('sessionTemplates.getById', { id });
    },
    async delete(id) {
      return await fetchAPI('sessionTemplates.delete', { id }, 'DELETE');
    },
    async create(templateData) {
      return await fetchAPI('sessionTemplates.create', templateData, 'POST');
    },
    async update(id, updates) {
      return await fetchAPI('sessionTemplates.update', { id, updates }, 'PUT');
    },
  },

  // Card Templates
  cardTemplates: {
    async getAll() {
      return await fetchAPI('cardTemplates.getAll');
    },
    async getById(id) {
      return await fetchAPI('cardTemplates.getById', { id });
    },
    async create(cardData) {
      return await fetchAPI('cardTemplates.create', cardData, 'POST');
    },
    async update(id, updates) {
      return await fetchAPI('cardTemplates.update', { id, updates }, 'PUT');
    },
    async delete(id) {
      return await fetchAPI('cardTemplates.delete', { id }, 'DELETE');
    },
  },

  // Prompts
  prompts: {
    async getAll() {
      return await fetchAPI('prompts.getAll');
    },
    async getById(id) {
      return await fetchAPI('prompts.getById', { id });
    },
    async create(promptData) {
      return await fetchAPI('prompts.create', promptData, 'POST');
    },
    async update(id, updates) {
      return await fetchAPI('prompts.update', { id, updates }, 'PUT');
    },
    async delete(id) {
      return await fetchAPI('prompts.delete', { id }, 'DELETE');
    },
  },

  // Users Data
  usersData: {
    async get(userId, key) {
      return await fetchAPI('usersData.get', { userId, key });
    },
    async set(userId, key, newData) {
      return await fetchAPI('usersData.set', { userId, key, newData }, 'PUT');
    },
    async getAllFounderProfiles() {
      return await fetchAPI('usersData.getAllFounderProfiles', {});
    },
    async getAllKeys(userId) {
      return await fetchAPI('usersData.getAllKeys', { userId });
    },
  },

  // Users
  users: {
    async delete(id) {
      return await fetchAPI('users.delete', { id }, 'DELETE');
    },
    async getAll() {
      return await fetchAPI('users.getAll');
    },
    async create(userData) {
      return await fetchAPI('users.create', userData, 'POST');
    },
    async getProfile(userId) {
      return await fetchAPI('users.getProfile', { userId });
    },
    async updateProfile(userId, updates) {
      return await fetchAPI('users.updateProfile', { userId, updates }, 'PUT');
    },
  },
  // Reports
  reports: {
    async getInProgressRoleplaySessions() {
      return await fetchAPI('reports.getInProgressRoleplaySessions');
    },
    async getUserActivityMetrics() {
      return await fetchAPI('reports.getUserActivityMetrics');
    },
    async getRoleplayPerformance() {
      return await fetchAPI('reports.getRoleplayPerformance');
    },
    async getUserFeedbackMetrics() {
      return await fetchAPI('reports.getUserFeedbackMetrics');
    },
    async getFounderProfiles() {
      return await fetchAPI('reports.getFounderProfiles');
    }

  },


};


async function fetchAPI(fn, params = {}, method = 'GET') {
  const url = new URL(`/api/db`, window.location.origin);
  url.searchParams.append('fn', fn);

  // Add query params for GET requests
  if (method === 'GET' || method === 'DELETE') {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.append(key, value);
      }
    });
  }

  // Get the current session token from Supabase Auth
  const { data: session } = await supabase.auth.getSession();
  const token = session?.session?.access_token;


  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      // Include the Supabase token in the Authorization header
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(params);
  }

  const response = await fetch(url, options);
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'API request failed');
  }
  return await response.json();
}



