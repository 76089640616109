import React, { useEffect } from 'react';
import { useLinkedInDetector } from './useLinkedInDetector';
import { useNativeRedirect } from './useNativeRedirect';

export const withLinkedInRedirect = (WrappedComponent) => {
  return function WithLinkedInRedirectWrapper(props) {
    const { isLinkedIn, isLoading } = useLinkedInDetector();
    const { deviceType, attemptRedirect, attemptFallbackRedirect } = useNativeRedirect('https://www.mentara.io');

    useEffect(() => {
      if (!isLoading && isLinkedIn) {
        // Try primary redirect method
        attemptRedirect();

        // Try fallback after a short delay if primary fails
        // setTimeout(() => {
        //   attemptFallbackRedirect();
        // }, 500);
      }
    }, [isLoading, isLinkedIn, attemptRedirect, attemptFallbackRedirect]);

    // While detecting LinkedIn and attempting redirect, show loading
    if (isLoading) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      );
    }

    // If not in LinkedIn or redirect hasn't worked, render the original component
    return <WrappedComponent {...props} />;
  };
};