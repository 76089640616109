import './tailwind/tailwind.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppStateProvider } from './context/AppStateProvider';
import SessionRoomPage from './ui/pages/SessionRoomPage';
import OnboardingPage from './ui/pages/OnboardingPage';
import SessionLandingPage from './ui/pages/SessionLandingPage';
import LandingPage from './ui/pages/LandingPage';
import AdmingPage from './ui/pages/admin/AdminLayout';
import SessionReportPage from './ui/pages/SessionReportPage';
import { Analytics } from '@vercel/analytics/react';
import SessionList from './ui/pages/admin/sessions/SessionList';
import FeedbackResults from './ui/pages/admin/feedback/FeedbackResults';
import TranscriptAnalyzer from './ui/pages/admin/analyzer/TranscriptAnalyzer';
import SessionEvaluation from './ui/pages/admin/reports/SessionEvaluation';
import AdminLayout from './ui/pages/admin/AdminLayout';
import UserDashboard from './ui/pages/user/UserDashboard';
import UserLayout from './ui/pages/user/UserLayout';
import UserList from './ui/pages/admin/users/UserList';
import PromptList from './ui/pages/admin/prompts/PromptList';
import CardList from './ui/pages/admin/cards/CardList';
import SessionTemplateList from './ui/pages/admin/sessiontemplate/SessionTemplateList';
import CoachesList from './ui/pages/admin/coaches/CoachesList';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import UserOnboarding from './ui/pages/user/UserOnboarding';
import UserInsights from './ui/pages/user/UserInsights';
import UserSessionList from './ui/pages/user/UserSessionList';
import MiroBoard from './ui/components/cards/miroboard/MiroBoard';
import UserBilling from './ui/pages/user/UserBilling';
import CookieBanner from './ui/pages/CookieBanner';
import LinkedInEmbedDetector from './ui/LinkedInEmbedDetector';
import LinkedInRedirectTest from './ui/LinkedInRedirectTest';
import FaqPage from './ui/pages/FAQPage';
import DynamicReports from './ui/pages/admin/reports/Reports';

const App = () => {

    return (
        <AuthProvider>
            <AppStateProvider>
                <Analytics />
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/onboarding" element={<OnboardingPage />} />
                    <Route path="/landing" element={<OnboardingPage />} />
                    <Route path="/board" element={<MiroBoard />} />
                    <Route path="/faq" element={<FaqPage />} />

                    <Route path="/linkedin" element={<LinkedInEmbedDetector />} />
                    <Route path="/linkedin-redirect" element={<LinkedInRedirectTest />} />

                    <Route path="/session/intro/:id" element={<SessionLandingPage />} />
                    <Route path="/session/room/:id" element={<SessionRoomPage />} />
                    <Route path="/session/report/:id" element={<SessionReportPage />} />

                    <Route path="/admin" element={<AdminLayout />}>
                        <Route index element={<SessionList />} />
                        <Route path="users" element={<UserList />} />
                        <Route path="sessions" element={<SessionList />} />
                        <Route path="prompts" element={<PromptList />} />
                        <Route path="cards" element={<CardList />} />
                        <Route path="coaches" element={<CoachesList />} />

                        <Route path="session-templates" element={<SessionTemplateList />} />

                        <Route path="feedback" element={<FeedbackResults />} />
                        <Route path="analyzer" element={<TranscriptAnalyzer />} />
                        <Route path="reports" element={<DynamicReports />} />
                    </Route>

                    <Route element={<PrivateRoute />}>

                        <Route path="/user" element={<UserLayout />}>
                            <Route path="home" element={<UserDashboard />} />
                            <Route path="billing" element={<UserBilling />}>
                                <Route path="success" element={<UserBilling />} />
                                <Route path="error" element={<UserBilling />} />
                            </Route>
                            <Route path="insights" element={<UserInsights />} />

                            <Route path="onboarding" element={<UserOnboarding />} />
                            <Route path="sessions" element={<UserSessionList />} />

                            <Route path="dashboard" element={<UserDashboard />} />

                        </Route>
                    </Route>


                </Routes>
            </AppStateProvider>
        </AuthProvider>
    );
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
            <CookieBanner />
        </BrowserRouter>
    </React.StrictMode>
);