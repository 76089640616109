// src/CookieBanner.js
import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const COOKIE_EXPIRY = 365;

  useEffect(() => {
    const preferences = getCookie('mentara_cookie_preferences');
    if (preferences) {
      setShowBanner(false);
    } else {
      detectRegion();
    }
  }, []);

  const EU_COUNTRIES = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR',
    'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL',
    'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'IS', 'LI',
    'NO', 'CH'
  ];

  const detectRegion = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      if (EU_COUNTRIES.includes(data.country_code)) {
        document.getElementById('cookieConsent').classList.add('eu-version');
      }
    } catch (error) {
      console.error('Region detection failed:', error);
    }
  };

  const acceptAll = () => {
    setConsent({ essential: true, analytics: true, functional: true, marketing: true });
    setShowBanner(false);
  };

  const rejectAll = () => {
    setConsent({ essential: true, analytics: false, functional: false, marketing: false });
    setShowBanner(false);
  };

  const savePreferences = () => {
    const preferences = {
      essential: true,
      analytics: document.getElementById('analyticsConsent').checked,
      functional: document.getElementById('functionalConsent').checked,
      marketing: document.getElementById('marketingConsent').checked,
    };
    setConsent(preferences);
    setShowBanner(false);
    setShowPreferences(false);
  };

  const setConsent = (preferences) => {
    setCookie('mentara_cookie_preferences', JSON.stringify(preferences), COOKIE_EXPIRY);
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  if (!showBanner) return null;

  return (
    <>
      <style>{`
  :root {
    --mentara-purple: #261731;
    --mentara-purple-light: #372645;
    --mentara-white: #ffffff;
    --mentara-gray: #e5e7eb;
    --mentara-text: #f3f4f6;
    --primary-purple: #9333ea;
    --hover-purple: #7e22ce;
  }

  .cookie-consent {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    max-width: 400px;
    background: var(--mentara-purple);
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    color: var(--mentara-text);
  }

  .cookie-content {
    margin-bottom: 1rem;
  }

  .cookie-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .cookie-text {
    font-size: 0.9rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .cookie-buttons {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .cookie-btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }

  .accept-all {
    background: var(--primary-purple);
    color: white;
  }

  .accept-all:hover {
    background: var(--hover-purple);
  }

  .reject-all {
    background: transparent;
    border: 1px solid var(--mentara-gray);
    color: var(--mentara-text);
  }

  .reject-all:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .customize {
    background: transparent;
    color: var(--mentara-text);
    text-decoration: underline;
  }

  .customize:hover {
    text-decoration: none;
  }

  /* Preferences Modal Styles */
  .preferences-modal {
    display: ${showPreferences ? 'block' : 'none'};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--mentara-purple);
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    z-index: 10000;
    color: var(--mentara-text);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .cookie-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .cookie-option:last-child {
    border-bottom: none;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--primary-purple);
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .overlay {
    display: ${showPreferences ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .preferences-modal .cookie-btn {
    width: 100%;
    background: var(--primary-purple);
    color: white;
    margin-top: 1rem;
  }

  .preferences-modal .cookie-btn:hover {
    background: var(--hover-purple);
  }

  @media (max-width: 768px) {
    .cookie-consent {
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      border-radius: 0;
    }

    .preferences-modal {
      width: 100%;
      max-width: 90%;
      padding: 1.5rem;
    }

    .cookie-buttons {
      flex-direction: column;
    }
  }
`}</style>

      <div className="cookie-consent" id="cookieConsent">
        <div className="cookie-content">
          <div className="cookie-title">Cookie Settings</div>
          <p className="cookie-text">
            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By
            clicking "Accept All", you consent to our use of cookies.
          </p>
        </div>
        <div className="cookie-buttons">
          <button className="cookie-btn accept-all" onClick={acceptAll}>Accept All</button>
          <button className="cookie-btn reject-all" onClick={rejectAll}>Reject All</button>
          <button className="cookie-btn customize" onClick={() => setShowPreferences(true)}>Customize</button>
        </div>
      </div>

      {showPreferences && <div className="overlay" onClick={() => setShowPreferences(false)} />}

      <div className="preferences-modal" id="preferencesModal">
        <div className="cookie-title">Cookie Preferences</div>
        <div className="cookie-option">
          <strong>Essential Cookies</strong>
          <p>Required for the website to function properly.</p>
          <label className="switch">
            <input type="checkbox" checked disabled />
            <span className="slider"></span>
          </label>
        </div>
        <div className="cookie-option">
          <strong>Analytics Cookies</strong>
          <label className="switch">
            <input type="checkbox" id="analyticsConsent" />
            <span className="slider"></span>
          </label>
        </div>
        <div className="cookie-option">
          <strong>Functional Cookies</strong>
          <label className="switch">
            <input type="checkbox" id="functionalConsent" />
            <span className="slider"></span>
          </label>
        </div>
        <div className="cookie-option">
          <strong>Marketing Cookies</strong>
          <label className="switch">
            <input type="checkbox" id="marketingConsent" />
            <span className="slider"></span>
          </label>
        </div>
        <button className="cookie-btn accept-all" onClick={savePreferences}>Save Preferences</button>
      </div>
    </>
  );
};

export default CookieBanner;
