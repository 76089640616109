import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Edit, Trash2, Plus, Loader } from 'lucide-react';
import PromptEdit from './PromptEdit';
import { useNavigate } from 'react-router-dom';

const PromptList = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPromptId, setEditingPromptId] = useState(null);

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.prompts.getAll();
      setPrompts(data);
    } catch (err) {
      setError('Failed to load prompts');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (promptId) => {
    setEditingPromptId(promptId);
  };

  const handleEditSave = () => {
    setEditingPromptId(null);
    fetchPrompts();
  };

  const handleEditCancel = () => {
    setEditingPromptId(null);
  };

  const handleDeleteClick = async (promptId) => {
    if (window.confirm('Are you sure you want to delete this prompt?')) {
      try {
        await dbUtils.prompts.delete(promptId);
        fetchPrompts();
      } catch (err) {
        setError('Failed to delete prompt');
        console.error(err);
      }
    }
  };

  const renderPromptCard = (prompt) => (
    <div key={prompt.id} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex justify-between items-center">
      <div className="p-4 flex-grow">
        <h3 className="text-lg font-semibold">{prompt.name}</h3>
        <p className="text-sm text-gray-400">Version: {prompt.version}</p>
      </div>
      <div className="bg-gray-700 p-4 flex space-x-2">
        <button
          onClick={() => handleEditClick(prompt.id)}
          className="p-1 bg-blue-500 rounded hover:bg-blue-600 transition-colors"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => handleDeleteClick(prompt.id)}
          className="p-1 bg-red-500 rounded hover:bg-red-600 transition-colors"
        >
          <Trash2 size={16} />
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading prompts...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-10 bg-red-100 border border-red-300 rounded-lg">
        <p className="text-lg font-semibold">{error}</p>
      </div>
    );
  }

  if (editingPromptId) {
    return (
      <PromptEdit
        promptId={editingPromptId}
        onSave={handleEditSave}
        onCancel={handleEditCancel}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Prompt Management</h2>
        <button
          onClick={() => handleEditClick('new')}
          className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center hover:bg-green-600 transition-colors"
        >
          <Plus className="mr-2" /> New Prompt
        </button>
      </div>

      <div className="space-y-4">
        {prompts.map(renderPromptCard)}
      </div>
    </div>
  );
};

export default PromptList;