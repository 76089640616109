import { useRef, useState, useEffect } from "react";

export function useAudioPlayer(audioContext, source) {
  const [audioLevel, setAudioLevel] = useState(0);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const currentStreamRef = useRef(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      stop();
    };
  }, []);

  function updateAudioLevel() {
    if (analyserRef.current && audioContext.current) {
      const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      analyserRef.current.getByteFrequencyData(dataArray);
      const average = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

      setAudioLevel(average / 255); // Normalize to 0-1 range
      animationFrameRef.current = requestAnimationFrame(updateAudioLevel);
    } else {
      setAudioLevel(0);
    }
  }

  async function play(stream, callback) {
    // Stop any previous playback and abort previous stream processing
    stop();

    // Create new AbortController for this stream
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    // Store current stream reference
    currentStreamRef.current = stream;

    // Check if audioContext is null or closed, and reinitialize it
    if (!audioContext.current || audioContext.current.state === 'closed') {
      audioContext.current = new AudioContext({ sampleRate: 24000 });
    } else if (audioContext.current.state === 'suspended') {
      await audioContext.current.resume();
    }

    analyserRef.current = audioContext.current.createAnalyser();
    analyserRef.current.fftSize = 256;

    let nextStartTime = audioContext.current.currentTime;
    const reader = stream.getReader();
    let leftover = new Uint8Array();

    updateAudioLevel(); // Start updating audio level

    try {
      while (!signal.aborted) {
        const { value, done } = await reader.read();

        // Check if this is still the current stream
        if (stream !== currentStreamRef.current) {
          break;
        }

        if (done) break;

        const data = new Uint8Array(leftover.length + value.length);
        data.set(leftover);
        data.set(value, leftover.length);
        const length = Math.floor(data.length / 4) * 4;
        const remainder = data.length % 4;
        const buffer = new Float32Array(data.buffer, 0, length / 4);
        leftover = new Uint8Array(data.buffer, length, remainder);

        // Create the audio buffer
        const audioBuffer = audioContext.current.createBuffer(
          1,
          buffer.length,
          audioContext.current.sampleRate
        );
        audioBuffer.copyToChannel(buffer, 0);

        // Create buffer source and connect it to analyser and destination
        source.current = audioContext.current.createBufferSource();
        source.current.buffer = audioBuffer;
        source.current.connect(analyserRef.current);
        analyserRef.current.connect(audioContext.current.destination);

        source.current.start(nextStartTime);
        nextStartTime += audioBuffer.duration;
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Stream processing aborted');
      } else {
        console.error("Error during audio playback:", error);
      }
    } finally {
      reader.releaseLock();
    }

    // Handle the end of the stream
    if (source.current && stream === currentStreamRef.current) {
      source.current.onended = () => {
        stop();
        if (callback) callback();
      };
    }
  }

  function stop() {
    // Abort any ongoing stream processing
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    // Clear current stream reference
    currentStreamRef.current = null;

    if (audioContext.current) {
      audioContext.current.close();
      audioContext.current = null;
    }

    if (source.current) {
      source.current.stop();
      source.current = null;
    }

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }

    setAudioLevel(0);
  }

  return {
    play,
    stop,
    audioLevel,
  };
}