import React, { useState, useEffect } from 'react';
import { X, Check, Zap, PlayCircle } from 'lucide-react';
import { dbUtils } from '../../services/core/dbService';
import { loadStripe } from '@stripe/stripe-js';
import LoginButton from '../components/LoginButton';

// Initialize Stripe outside of the component for reusability
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const BundleCard = ({ bundle, isSelected, onSelect, variant = 'pricing' }) => {
  const progressPercentage = variant === 'purchased' ?
    (bundle.sessions_remaining / bundle.sessions_included) * 100 : null;

  return (
    <div
      className={`relative bg-[#2A1F45] rounded-xl p-6 border-2 transition-all duration-300 overflow-hidden
        ${isSelected ? 'border-purple-500 transform scale-105' :
          variant === 'purchased' ? 'border-gray-700' : 'border-transparent hover:border-purple-500/50'}`}
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bundle.background_image})`,
        }}
      />

      {/* Gradient Overlay for better text readability */}
      <div className={`absolute inset-0 ${bundle.is_popular
        ? 'bg-gradient-to-br from-purple-900/95 via-purple-800/95 to-pink-900/95'
        : 'bg-gradient-to-br from-gray-900/95 via-[#2A1F45]/95 to-purple-900/95'
        }`} />

      {/* Content */}
      <div className="relative z-10">
        {/* Popular Tag */}
        {bundle.is_popular && variant === 'pricing' && (
          <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
            <span className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-6 py-1.5 rounded-full text-sm font-medium shadow-lg">
              Most Popular
            </span>
          </div>
        )}

        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold text-white mb-3">{bundle.name}</h3>
          <p className="text-gray-300 text-sm mb-4">{bundle.description}</p>

          {variant === 'pricing' ? (
            <>
              <div className="flex items-baseline justify-center mb-3">
                <span className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-300 to-pink-300">
                  ${bundle.price}
                </span>
              </div>
              <div className="flex flex-col items-center text-sm text-gray-300 space-y-2">
                <div className="flex items-center bg-black/20 backdrop-blur-sm px-4 py-2 rounded-full">
                  <PlayCircle size={16} className="mr-2 text-purple-400" />
                  <span>{bundle.sessions_included} sessions</span>
                </div>
                {/* <div className="bg-black/20 backdrop-blur-sm px-4 py-2 rounded-full">
                  ${(bundle.price / bundle.sessions_included).toFixed(2)}/session
                </div> */}
                {bundle.save_percent && (
                  <div className="text-green-400 mt-1 flex items-center bg-green-400/10 backdrop-blur-sm px-4 py-2 rounded-full">
                    <Zap size={14} className="mr-2" />
                    Save {bundle.save_percent}%
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="space-y-3">
              <div className="w-full bg-black/30 backdrop-blur-sm rounded-full h-3 relative overflow-hidden">
                <div
                  className="h-full bg-gradient-to-r from-purple-500 to-pink-500 rounded-full transition-all duration-500"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-300">{bundle.sessions_remaining} sessions left</span>
                <span className="text-gray-300">
                  {bundle.sessions_remaining}/{bundle.sessions_included} sessions
                </span>
              </div>
            </div>
          )}
        </div>

        {variant === 'pricing' && (
          <>
            <ul className="space-y-3 mb-6">
              {bundle.features?.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-200 bg-black/20 backdrop-blur-sm px-4 py-2 rounded-lg">
                  <Check size={16} className="text-green-400 mr-3 flex-shrink-0" />
                  <span className="text-sm">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => onSelect?.(bundle)}
              className={`w-full py-3.5 rounded-lg transition-all duration-300 transform hover:scale-105 ${bundle.is_popular
                ? 'bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white shadow-lg'
                : 'bg-white/10 hover:bg-white/20 text-white backdrop-blur-sm'
                }`}
            >
              Choose Bundle
            </button>
          </>
        )}
      </div>
    </div>
  );
};
const BundlePricingModal = ({
  mode = 'modal',
  isOpen = true,
  onClose,
  onSelectBundle,
  className = '',
  variant = 'pricing',
  selectedSession = {},
  user
}) => {
  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleCheckout = async (bundle) => {
    try {
      setLoading(true)
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bundle_id: bundle.id,
          user_id: user.id, // Replace with dynamic user ID if needed
          success_url: `${window.location.origin}/user/billing/success`,
          cancel_url: `${window.location.origin}/user/billing/error`,
          selected_session: selectedSession
        }),
      });

      const session = await response.json();

      if (session.id) {
        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (error) {
          console.error('Stripe Checkout Error:', error);
          alert(`Checkout error: ${error.message}`);
        }
      } else {
        console.error('Session creation failed');
        alert('Failed to create a checkout session');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      setLoading(false)

      alert('Error initiating checkout. Check console for details.');
    }
  };
  useEffect(() => {
    fetchBundles();
  }, []);

  const fetchBundles = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.bundles.getAll();
      setBundles(data);
    } catch (err) {
      console.error('Error fetching bundles:', err);
      setError('Failed to load pricing bundles');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;
  if (loading) return (
    <div className="flex justify-center items-center p-8">
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );
  if (error) return (
    <div className="text-red-500 text-center p-8">{error}</div>
  );

  const renderContent = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {!user && <div className=" bg-indigo-900 rounded-xl p-6 border-2 border-indigo-400 transition-all duration-300 overflow-hidden">


        {/* Content */}
        <div className="relative z-10 text-center">
          <h3 className="text-2xl font-bold text-white mb-3">2x Free Sessions</h3>
          <p className="text-gray-300 text-sm mb-4">Start your journey with 2 FREE practice sessions</p>

          <div className="flex flex-col items-center text-sm text-gray-300 space-y-2 mb-6">
            <div className="flex items-center bg-black/20 backdrop-blur-sm px-4 py-2 rounded-full">
              <PlayCircle size={16} className="mr-2 text-indigo-300" />
              <span>2 sessions</span>
            </div>
            <div className="text-indigo-300 flex items-center bg-indigo-500/10 backdrop-blur-sm px-4 py-2 rounded-full">
              <Zap size={14} className="mr-2" />
              No credit card required.
            </div>
          </div>

          <button
            onClick={() => document.getElementById('login-button').click()}
            className="w-full py-3.5 rounded-lg transition-all duration-300 transform hover:scale-105 bg-indigo-500 hover:bg-indigo-600 text-white shadow-lg"
          >
            Start Free Sessions
          </button>
        </div>
      </div>}

      {bundles.map((bundle) => (
        <BundleCard
          key={bundle.id}
          bundle={bundle}
          isSelected={selectedBundle === bundle.id}
          onSelect={(bundle) => {
            setSelectedBundle(bundle.id);
            handleCheckout(bundle)
            onSelectBundle?.(bundle);
          }}
          variant={variant}
        />
      ))}
    </div>
  );

  if (mode === 'modal') {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex justify-center items-center z-50 p-4">
        <div className="bg-[#231639] rounded-xl w-full max-w-4xl max-h-[95vh] overflow-hidden flex flex-col">
          <div className="flex justify-between items-center p-6 border-b border-gray-700">
            <div>
              <h2 className="text-3xl font-bold text-[#E7C6EF]">Practice Bundles</h2>
              <p className="text-gray-400 mt-1">Choose your practice bundle</p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X size={24} />
            </button>
          </div>

          <div className="p-6 overflow-auto">
            {renderContent()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-[#231639] rounded-xl p-6 ${className}`}>
      <div className="mb-6">

        <h2 className="text-3xl font-bold text-[#E7C6EF]">Practice Bundles</h2>
        <p className="text-gray-400 mt-1">Choose your practice bundle</p>
      </div>
      {renderContent()}
      <LoginButton className='hidden' />

    </div>
  );
};

export default BundlePricingModal;