import React, { useState } from 'react';
import { Upload, Loader } from 'lucide-react';
import { dynamicTranscriptAnalyzer } from '../../utils/llm';

const TranscriptAnalyzerForPrompt = ({ onAnalysisComplete, templateVariables }) => {
  const [transcript, setTranscript] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setTranscript(e.target.result);
      reader.readAsText(file);
    }
  };

  const analyzeTranscript = async () => {
    setIsAnalyzing(true);
    setError(null);
    try {
      const config = {
        maxLength: 150,
        detailLevel: 'moderate'
      };
      const result = await dynamicTranscriptAnalyzer(transcript, templateVariables, config);
      alert('Transcript Analyzed')
      onAnalysisComplete(result);
    } catch (error) {
      console.error('Error analyzing transcript:', error);
      setError('Failed to analyze transcript. Please try again.');
    }
    setIsAnalyzing(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
          <Upload className="mr-2" />
          Upload Transcript
          <input type="file" onChange={handleFileUpload} className="hidden" accept=".txt" />
        </label>
        <span className="text-gray-300">{transcript ? 'File selected' : 'No file chosen'}</span>
      </div>

      <button
        onClick={analyzeTranscript}
        disabled={!transcript || isAnalyzing || Object.keys(templateVariables).length === 0}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-gray-500 disabled:cursor-not-allowed hover:bg-blue-600 transition flex items-center"
      >
        {isAnalyzing ? <><Loader className="animate-spin mr-2" /> Analyzing...</> : 'Analyze Transcript'}
      </button>

      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default TranscriptAnalyzerForPrompt;