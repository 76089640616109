import React from 'react';
import { useAuth } from '../../AuthContext';

const LoginButton = ({ className }) => {
  const { signIn } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      const sign = await signIn({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/user/onboarding`
        }
      });
    } catch (error) {
      console.error('Error logging in with Google:', error.message);
    }
  };

  return (
    <button id='login-button' onClick={handleGoogleLogin} className={`${className} bg-blue-500 text-white px-4 py-2 rounded`}>
      Sign in with Google
    </button>
  );
};

export default LoginButton;