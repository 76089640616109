import React, { useState, useCallback, useEffect, useRef } from 'react';
import { X, MessageCircle, FileText, Settings } from 'lucide-react';

const VoiceSelector = ({ selectedVoice, availableVoices, onVoiceChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-2">Select Voice:</label>
      <select
        className="w-full p-2 border rounded"
        value={selectedVoice.id}
        onChange={(e) => {
          const newVoice = availableVoices.find(voice => voice.id === e.target.value);
          onVoiceChange(newVoice);
        }}
      >
        {availableVoices.map((voice) => (
          <option key={voice.id} value={voice.id}>
            {voice.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const ChatSidebar = ({ sessionData, updateSessionData, currentContent, sendText, isOpen, onClose }) => {
  const [userInput, setUserInput] = useState('');
  const [activeTab, setActiveTab] = useState('chat');
  const availableVoices = [
    { id: '79a125e8-cd45-4c13-8a67-188112f4dd22', name: 'Default Voice' },
    { id: '0faa49fe-f301-4628-bbe6-23b4574eaeba', name: 'Steve Jobs' },
    { id: 'd21bac18-fa21-48eb-a347-0f7dd76d0bf4', name: 'Steve Wozniak' },
    { id: 'a5a1089f-55db-497e-84aa-b2788f91dda0', name: 'Joe Rogan' },
    { id: 'db5941ab-7e97-44ce-9a92-1dd5633ca8ec', name: 'Simon Sinek' },
    { id: '0f970d65-17fd-4585-b5ab-c7cdb5e186e8', name: 'Tony Robbins' },

  ]

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    sendText(userInput, "text");
    setUserInput('');
  }, [userInput, sendText]);

  const handleVoiceChange = (newVoice) => {
    updateSessionData('coach.voice', newVoice);
  };

  const handlePersonaChange = (newPersona) => {
    updateSessionData('coach.name', newPersona);
  };

  const handleBioChange = (newBio) => {
    updateSessionData('coach.bio', newBio);
  };

  const renderTabContent = () => {
    const isRoleplaying = currentContent?.type == 'roleplay'
    let history = isRoleplaying ? sessionData.cards[0].history : sessionData.history

    if (!sessionData.history)
      return null;

    switch (activeTab) {
      case 'chat':
        return (
          <div className="flex-grow overflow-y-auto p-4">
            {history.map((message, index) => (
              <div
                key={index}
                className={`mb-2 p-3 rounded-lg ${message.role === 'user' ? 'bg-pink-50' : 'bg-purple-50'}`}
              >
                <strong>{message.role === 'user' ? 'You' : 'AI'}:</strong>{' '}
                {message.content}
              </div>
            ))}
          </div>
        );
      case 'summary':
        return (
          <div className="flex-grow overflow-y-auto p-4 text-white">
            <h3 className="font-semibold mb-2 ">Conversation Summary</h3>
            <p>Summary content goes here...</p>
          </div>
        );
      case 'settings':
        return (
          <div className="flex-grow overflow-y-auto p-4">
            <h3 className="font-semibold text-white mb-4">Settings</h3>
            <VoiceSelector
              selectedVoice={sessionData.coach.voice}
              availableVoices={availableVoices}
              onVoiceChange={handleVoiceChange}
            />
            <div className="mb-4">
              <label className="block text-white text-sm font-medium mb-2">AI Persona:</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={sessionData.coach.name}
                onChange={(e) => handlePersonaChange(e.target.value)}
                placeholder="Enter AI persona..."
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm text-white font-medium mb-2">AI Bio:</label>
              <textarea
                className="w-full p-2 border rounded"
                value={sessionData.coach.bio}
                onChange={(e) => handleBioChange(e.target.value)}
                rows={4}
                placeholder="Enter AI bio..."
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div
      className={`fixed z-50 top-0 right-0 h-full w-80 bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg shadow-lg transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      style={{ maxHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <div className="flex justify-between items-center p-4 text-white bg-white bg-opacity-10">
        <h3 className="font-bold text-xl text-white opacity-90">Sidebar</h3>
        <button onClick={onClose} className="text-white opacity-80 hover:opacity-100">
          <X size={24} />
        </button>
      </div>

      <div className="flex border-b border-white border-opacity-20">
        {['chat'].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`flex-1 py-2 px-4 ${activeTab === tab
              ? 'bg-white bg-opacity-10 text-white font-semibold'
              : 'text-white text-opacity-60 hover:bg-white hover:bg-opacity-5'
              }`}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>


      {renderTabContent()}

      <form
        onSubmit={handleSubmit}
        className="flex p-4 bg-white bg-opacity-5"
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      >
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message here..."
          className="flex-grow p-2 rounded-l bg-black bg-opacity-30 text-white placeholder-gray-400 border border-white border-opacity-20 focus:ring-2 focus:ring-purple-500 focus:border-transparent outline-none"
        />
        <button
          type="submit"
          className="p-2 bg-purple-500 bg-opacity-80 text-white rounded-r hover:bg-opacity-100 transition-colors duration-300"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default ChatSidebar;