// src/components/admin/AdminLayout.js
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FileText, Users, BarChart2, NotepadText, ChartBarIcon, Settings, Sliders, User, Layers, NotebookTabs, ChefHatIcon } from 'lucide-react';

const AdminLayout = () => {
  const tabs = [
    { id: 'sessions', icon: FileText, label: 'Sessions', path: '/admin/sessions' },
    { id: 'users', icon: User, label: 'Users', path: '/admin/users' },
    { id: 'coaches', icon: ChefHatIcon, label: 'Coaches', path: '/admin/coaches' },

    { id: 'prompts', icon: NotepadText, label: 'Prompts', path: '/admin/prompts' },
    { id: 'cards', icon: Layers, label: 'Cards', path: '/admin/cards' },
    { id: 'templates', icon: NotebookTabs, label: 'Templates', path: '/admin/session-templates' },

    { id: 'feedback', icon: BarChart2, label: 'Feedback Results', path: '/admin/feedback' },
    // { id: 'analyzer', icon: Sliders, label: 'Transcript Analyzer', path: '/admin/analyzer' },
    { id: 'reports', icon: BarChart2, label: 'Reports', path: '/admin/reports' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">Admin Dashboard</h1>

        <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-6">
          <div className="flex mb-6 border-b border-gray-700">
            {tabs.map((tab) => (
              <NavLink
                key={tab.id}
                to={tab.path}
                className={({ isActive }) =>
                  `mr-4 pb-2 text-lg font-medium cursor-pointer focus:outline-none ${isActive ? 'border-b-2 border-purple-500' : ''
                  }`
                }
              >
                <tab.icon className="inline-block mr-2" />
                {tab.label}
              </NavLink>
            ))}
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;