import React, { useState } from 'react';
import { Upload, Sliders, ChevronDown, ChevronRight } from 'lucide-react'; // Import Chevron Icons for collapse/expand
import { llmAnalyzeTranscriptWithGemini } from '../../../../utils/llm';

const TranscriptAnalyzer = ({ onAnalyze }) => {
  const [transcript, setTranscript] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isConfigExpanded, setIsConfigExpanded] = useState(false); // State for collapsing/expanding config
  const [config, setConfig] = useState({
    maxLength: 150,
    focusAreas: ['name', 'age', 'gender', 'position', 'industry', 'currentCompany', 'maritalStatus', 'professionalBackground', 'keyChallenges', 'leadershipGoals', 'motivationFactors'],
    detailLevel: 'moderate'
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setTranscript(e.target.result);
      reader.readAsText(file);
    }
  };

  const analyzeTranscript = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await llmAnalyzeTranscriptWithGemini(transcript, config);
      setAnalysis(result);
      if (onAnalyze)
        onAnalyze(result); // Pass analysis result to parent component

    } catch (error) {
      console.error('Error analyzing transcript:', error);
      setError('An error occurred while analyzing the transcript. Please try again.');
    }
    setIsLoading(false);
  };

  const handleConfigChange = (key, value) => {
    setConfig(prev => ({ ...prev, [key]: value }));
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold mb-4">Transcript Analyzer</h2>

      <div className="flex items-center space-x-4">
        <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
          <Upload className="mr-2" />
          Choose File
          <input type="file" onChange={handleFileUpload} className="hidden" />
        </label>
        <span className="text-gray-300">{transcript ? 'File selected' : 'No file chosen'}</span>
      </div>

      {/* Collapsible Configuration Section */}
      <div className="bg-gray-700 bg-opacity-50 rounded-lg p-6">
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setIsConfigExpanded(!isConfigExpanded)} // Toggle expanded state
        >
          <h3 className="text-xl font-semibold flex items-center">
            <Sliders className="mr-2" /> Analysis Configuration
          </h3>
          {isConfigExpanded ? <ChevronDown /> : <ChevronRight />} {/* Icon for collapse/expand */}
        </div>

        {isConfigExpanded && ( // Only show config if expanded
          <div className="space-y-4 mt-4">
            <div>
              <label className="block text-sm font-medium mb-1">Max Length per Property</label>
              <input
                type="range"
                min="50"
                max="300"
                value={config.maxLength}
                onChange={(e) => handleConfigChange('maxLength', parseInt(e.target.value))}
                className="w-full"
              />
              <span className="text-sm">{config.maxLength} characters</span>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Focus Areas</label>
              <div className="flex flex-wrap gap-2">
                {['name', 'age', 'gender', 'position', 'industry', 'currentCompany', 'maritalStatus', 'professionalBackground', 'keyChallenges', 'leadershipGoals', 'motivationFactors', 'skillDevelopment', 'communicationStyle'].map(area => (
                  <button
                    key={area}
                    onClick={() => handleConfigChange('focusAreas',
                      config.focusAreas.includes(area)
                        ? config.focusAreas.filter(a => a !== area)
                        : [...config.focusAreas, area]
                    )}
                    className={`px-3 py-1 rounded-full text-sm ${config.focusAreas.includes(area)
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-600 text-gray-300'
                      }`}
                  >
                    {area}
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Detail Level</label>
              <select
                value={config.detailLevel}
                onChange={(e) => handleConfigChange('detailLevel', e.target.value)}
                className="bg-gray-700 text-white rounded px-3 py-2"
              >
                <option value="brief">Brief</option>
                <option value="moderate">Moderate</option>
                <option value="detailed">Detailed</option>
              </select>
            </div>
          </div>
        )}
      </div>

      <button
        onClick={analyzeTranscript}
        disabled={!transcript || isLoading}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg disabled:bg-gray-500 disabled:cursor-not-allowed hover:bg-blue-600 transition"
      >
        {isLoading ? 'Analyzing...' : 'Analyze Transcript'}
      </button>

      {error && (
        <p className="text-red-400 mt-4">{error}</p>
      )}

      {analysis && (
        <div className="mt-8 bg-gray-700 bg-opacity-50 rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Analysis Results</h2>
          {Object.entries(analysis).map(([key, value]) => (
            <div key={key} className="mb-4">
              <h3 className="font-medium capitalize text-purple-300">{key.replace(/([A-Z])/g, ' $1').trim()}</h3>
              <p className="text-gray-300">{value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TranscriptAnalyzer;
