import { Save, X, Loader, Plus, Minus, Upload, ArrowRight, ArrowLeft } from 'lucide-react';
import CardCreator from './CardCreator';

const CardCreatorModal = ({ isOpen, onClose, onSave, initialCard = null }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed mt-15 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg w-full max-w-6xl max-h-[95vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center p-6 border-b border-gray-700">
          <h2 className="text-2xl font-bold text-purple-400">
            {initialCard ? 'Edit Card' : 'Create New Card'}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X size={24} />
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-6">
          <CardCreator
            onSave={(cardData) => {
              onSave(cardData);
              onClose();
            }}
            initialCard={initialCard}
          />
        </div>
      </div>
    </div>
  );
};

export default CardCreatorModal