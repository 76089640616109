import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff, Video, Settings, VideoOff, MessageSquare, ScreenShareIcon, ScreenShareOff, LogOut, Layers } from 'lucide-react';
import { motion } from 'framer-motion';
import Avatar from '../components/Avatar';
import ChatSidebar from '../components/ChatSidebar';
import DeveloperConsole from '../components/DeveloperConsole';
// import SessionConsole from '../components/SessionConsole';
import ConfirmationPopup from '../components/ConfirmationPopup';
import { ConversationManager } from '../../ConversationManager';
import Card from '../components/Card';
import CardsSidebar from '../components/CardsSidebar';
import Logo from '../components/Logo';
import PreSessionView from './PreSessionView';
import GrowingTextboxComponent from '../components/TextboxComponent';
import TypeWriter from '../components/TypeWriter';


const ControlButton = ({ demoMode, icon, activeIcon, isActive, onClick, disabled, hidden = false, showPulse = false }) => {
  if (hidden) {
    return null;
  }

  return (
    <div className="relative">
      {/* Pulsing ring animation - only shows when showPulse is true and button is muted (not active) */}
      {showPulse && !isActive && !disabled && (
        <div className="absolute inset-0 rounded-full animate-ping bg-red-500 opacity-75" />
      )}

      {/* The actual button */}
      <motion.button
        whileHover={disabled ? {} : { scale: 1.05 }}
        whileTap={disabled ? {} : { scale: 0.95 }}
        onClick={onClick}
        disabled={disabled}
        className={`p-3 rounded-full transition duration-300 relative z-10 ${isActive
          ? 'bg-gray-700 text-white'
          : disabled
            ? 'bg-gray-700 text-gray-400 cursor-not-allowed'
            : 'bg-red-500 text-white hover:bg-[#3D2E5E]'
          }`}
      >
        {isActive ? icon : activeIcon}
      </motion.button>

      {/* Additional subtle glow effect */}
      {showPulse && !isActive && !disabled && (
        <div className="absolute inset-0 rounded-full bg-red-500 opacity-20 animate-pulse" />
      )}
    </div>
  );
};


const SessionRoom = ({ roomConfig, sessionData, setSessionData }) => {
  const [showConfirmEndCall, setShowConfirmEndCall] = useState(false);
  const [showChatSidebar, setShowChatSidebar] = useState(false);
  const [showCardsSidebar, setShowCardsSidebar] = useState(false);
  const [isPreSession, setIsPreSession] = useState(true);
  const [textMode, setTextMode] = useState(false);
  const [message, setMessage] = useState('');
  const [showMicPulse, setShowMicPulse] = useState(true);

  // Add useEffect to handle the pulse animation timing
  useEffect(() => {
    if (!isPreSession) {
      // Hide pulse after 30 seconds or when mic is unmuted
      const timer = setTimeout(() => {
        setShowMicPulse(false);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [isPreSession]);


  const isSessionCompleted = sessionData.status === 'completed';

  const updateSessionData = (path, valueOrUpdater) => {
    setSessionData(prevData => {
      const pathArray = path.split('.');
      const lastKey = pathArray.pop();
      const nestedObj = pathArray.reduce((acc, key) => {
        if (!acc[key]) acc[key] = {};
        return acc[key];
      }, prevData);

      const newValue = typeof valueOrUpdater === 'function'
        ? valueOrUpdater(nestedObj[lastKey])
        : valueOrUpdater;

      nestedObj[lastKey] = newValue;
      return { ...prevData };
    });
  };

  const updateParticipant = (participantId, key, value) => {
    setParticipants((prevParticipants) =>
      prevParticipants.map((p) =>
        p.id === participantId ? { ...p, [key]: value } : p
      )
    );
  };

  const getVisibleParticpants = () => {
    const isMobileView = window.innerWidth < 768;

    return isMobileView ? participants.filter(participant => participant.type === 'ai')
      : participants;

  }


  const {
    currentAIResponse,
    updateUserData,
    startSession,
    startRoleplay,
    stopRoleplay,
    currentContent,
    playerAudioLevel,
    micAudioLevel,
    startListening,
    stopListening,
    sendText,
    endCall,
    updateRoomConfiguration,
    updateFeedbackResponse,
    conversationState,
    setCurrentCard,
    videoRef,
    startCamera,
    stopCamera,
    isScreenSharing,
    screenRef,
    startScreenShare,
    stopScreenShare,
    stopSpeaking,
  } = ConversationManager(roomConfig, sessionData, updateSessionData, updateParticipant);


  // Local state for participants, not stored in sessionData
  const [participants, setParticipants] = useState(roomConfig.participants);

  const [selectedParticipantId, setSelectedParticipantId] = useState(participants[0]?.id || null);


  // Toggle states for the selected participant
  const toggleMic = (participantId) => {
    const particpant = participants.find(p => p.id === participantId)
    if (particpant.isMicOn)
      stopListening()
    else {
      startListening()
      setShowMicPulse(false); // Hide pulse when mic is enabled

    }


    setParticipants((prevParticipants) =>
      prevParticipants.map((p) =>
        p.id === participantId ? { ...p, isMicOn: !p.isMicOn } : p
      )
    );
  };

  const toggleVideo = (participantId) => {
    const particpant = participants.find(p => p.id === participantId)
    if (particpant.isVideoOn)
      stopCamera()
    else
      startCamera()

    setParticipants((prevParticipants) =>
      prevParticipants.map((p) =>
        p.id === participantId ? { ...p, isVideoOn: !p.isVideoOn } : p
      )
    );

  };

  const toggleScreenSharing = (participantId) => {
    const particpant = participants.find(p => p.id === participantId)
    if (particpant.isScreenSharingOn)
      stopScreenShare()
    else
      startScreenShare()
    setParticipants((prevParticipants) =>
      prevParticipants.map((p) =>
        p.id === participantId ? { ...p, isScreenSharingOn: !p.isScreenSharingOn } : p
      )
    );
  };




  // Responsive Layout using CSS Grid
  const generateGridStyles = (participantsCount) => {
    if (participantsCount === 1) {
      return 'grid-cols-1'; // 1 column taking full width
    }
    else if (participantsCount <= 2) {
      return 'grid-cols-1 md:grid-cols-2'; // 1 column or 2 side by side
    } else if (participantsCount === 3) {
      return 'grid-cols-2 grid-rows-2'; // 2x2 grid for 3 participants
    } else if (participantsCount <= 4) {
      return 'grid-cols-2'; // 2x2 layout for 4 users
    } else {
      return 'grid-cols-3'; // 3x3 for more participants
    }
  };


  const handleEndCall = async () => {
    // Handle call end logic, generate session report, etc.
    setShowConfirmEndCall(false);
    const card = await endCall();
    setCurrentCard(card)
  };

  return (
    <div className={`${roomConfig.fullScreen ? 'h-screen w-screen' : 'w-full h-full'}  bg-[#1B1130]  flex flex-col`} >

      {isPreSession && sessionData.is_demo === false ? (
        <PreSessionView
          sessionData={sessionData}
          coach={sessionData.coach}
          toggleMic={() => toggleMic(selectedParticipantId)}
          onJoin={() => { setIsPreSession(false); startSession() }}
        />
      ) : (
        <>
          {!roomConfig?.hideHeader && <header className=" bg-opacity-50 backdrop-filter backdrop-blur-lg p-4 flex justify-between items-center">
            <Logo size={'text-2xl'} />
            <div className="flex-1 px-4 text-center">
              <h2 className="text-white font-medium truncate max-w-[200px] sm:max-w-[300px] md:max-w-[400px] mx-auto">
                {sessionData.topic}
              </h2>

              {/* <p className="text-gray-300 text-sm">20</p> */}
            </div>
            <div className="flex items-center space-x-2">
              <button className="text-gray-300 hover:bg-gray-700 p-2 rounded-full">
                <Settings size={20} onClick={() => { setShowChatSidebar(!showChatSidebar) }} />
              </button>
              <button
                onClick={() => window.open('https://calendly.com/talktoteammentara', '_blank')}
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-2 sm:px-4 py-2 rounded-full text-xs sm:text-sm font-medium hover:opacity-90 transition duration-300"
              >
                Support
              </button>
            </div>
          </header>
          }


          {/* Main Content */}
          <main className="flex-grow flex overflow-hidden relative">
            {/* Background similar to PreSessionView */}
            <div className="absolute inset-0 z-0">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-900/20 to-pink-900/20"></div>
              <div className={`absolute -top-1/4 -left-1/4 w-1/2 h-1/2 bg-purple-600/20 rounded-full ${['idle', 'listening', 'responding'].includes(conversationState) ? '' : 'animate-pulse'}`}></div>
              <div className={`absolute top-3/4 -right-1/4 w-1/2 h-1/2 bg-purple-800/20 rounded-full ${['idle', 'listening', 'responding'].includes(conversationState) ? '' : 'animate-pulse'} `} style={{ animationDelay: '1s' }}></div>
            </div>
            <div className={`w-full h-full p-4 grid gap-4 ${generateGridStyles(participants.length)}`}>
              {getVisibleParticpants().map((participant, index) => (

                <div
                  key={participant.id}
                  className={`relative bg-[#2A1F45] bg-opacity-50 border border-gray-700 shadow-xl rounded-lg overflow-hidden ${participants.length === 3 && index === 2 ? 'col-span-2 justify-self-center w-1/2' : ''
                    }`}
                >

                  {participant.isScreenSharingOn ? (
                    <>
                      {/* Screen sharing video */}
                      {
                        participant.type == 'user' ?
                          (
                            <video ref={participant.type === 'user' ? screenRef : null} autoPlay className="w-full h-full object-contain" />)
                          : (
                            <div className="w-full h-full object-contain overflow-y-auto">
                              <Card
                                updateRoomConfiguration={updateRoomConfiguration}
                                updateFeedbackResponse={updateFeedbackResponse}
                                updateUserData={updateUserData}
                                sessionData={sessionData}
                                sendResponse={sendText}
                                content={currentContent}
                                setCurrentCard={setCurrentCard}
                                conversationState={conversationState}
                                audioLevel={playerAudioLevel}
                                startRoleplay={startRoleplay}
                                endRoleplay={stopRoleplay}
                                currentAIResponse={currentAIResponse}
                              />
                            </div>
                          )
                      }
                      {/* Avatar or video as a small overlay in the bottom-right corner */}
                      <div className="z-20 absolute bottom-2 right-2 w-64 h-auto max-w-[20%] max-h-[20%] bg-gray-900 bg-opacity-75 rounded-md ">
                        {participant.isVideoOn ? (
                          <video ref={participant.type === 'user' ? videoRef : null} autoPlay muted className="w-full h-full object-cover rounded-md" />
                        ) : (
                          <>

                            <Avatar
                              conversationState={conversationState}
                              photo={participant.userImage}
                              isHost={participant.type === 'ai'}
                              letter={participant.name.charAt(0)}
                              audioLevel={participant.type === 'user' ? micAudioLevel : (currentContent.type == 'roleplay' ? 0 : playerAudioLevel)}
                              className="w-full h-full rounded-md"
                              captions={currentAIResponse}
                            />

                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Video or Avatar based on whether video is enabled */}
                      {participant.isVideoOn ? (
                        <video ref={participant.type === 'user' ? videoRef : null} autoPlay muted className="w-full h-full object-cover rounded-md" />
                      ) : (
                        <>

                          <Avatar
                            conversationState={conversationState}
                            photo={participant.userImage}
                            isHost={participant.type === 'ai'}
                            letter={participant.name.charAt(0)}
                            audioLevel={participant.type === 'user' ? micAudioLevel : playerAudioLevel}
                            className="w-full h-full rounded-md"
                            backgroundImage={participant.backgroundImage}
                            captions={currentAIResponse}

                          />

                        </>
                      )}
                    </>
                  )}

                  {/* Participant Info */}
                  <div className="absolute bottom-4 left-4 bg-gray-900 bg-opacity-60 px-3 py-1 rounded-lg text-white text-sm">
                    {!participant.status ? <span>{participant.name} {participant.isScreenSharingOn ? (conversationState || '(Screen Sharing)') : ''}</span> : <span>{participant.status}</span>}
                  </div>
                </div>
              ))}
            </div>

          </main>

          {/* Footer */}
          <footer className="relative z-10 py-4 bg-opacity-30 backdrop-filter backdrop-blur-lg">
            <div className="max-w-4xl mx-auto px-4">
              {textMode && !sessionData.room_configuration?.disableTextModeControl ? (
                <GrowingTextboxComponent sendText={sendText} onClose={() => setTextMode(false)} />
              ) : (
                <div className="bg-[#2A1F45] rounded-full p-2 flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <ControlButton
                      icon={<Mic size={20} />}
                      activeIcon={<MicOff size={20} />}
                      isActive={participants.find(p => p.id === selectedParticipantId)?.isMicOn}
                      onClick={() => toggleMic(selectedParticipantId)}
                      disabled={isSessionCompleted || sessionData.room_configuration?.disableMicControl}
                      hidden={sessionData.room_configuration?.hideMicControl}
                      showPulse={showMicPulse} // Add this prop

                    />
                    <ControlButton
                      icon={<Video size={20} />}
                      activeIcon={<VideoOff size={20} />}
                      isActive={participants.find(p => p.id === selectedParticipantId)?.isVideoOn}
                      onClick={() => toggleVideo(selectedParticipantId)}
                      disabled={isSessionCompleted || sessionData.room_configuration?.disableVideoControl}
                      hidden={sessionData.room_configuration?.hideVideoControl}

                    />
                    <ControlButton
                      icon={<ScreenShareIcon size={20} />}
                      activeIcon={<ScreenShareOff size={20} />}
                      isActive={participants.find(p => p.id === selectedParticipantId)?.isScreenSharingOn}
                      onClick={() => toggleScreenSharing(selectedParticipantId)}
                      disabled={isSessionCompleted || sessionData.room_configuration?.disableScreenshareControl}
                      hidden={sessionData.room_configuration?.hideScreenshareControl}

                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <ControlButton
                      icon={<MessageSquare size={20} />}
                      activeIcon={<MessageSquare size={20} />}
                      isActive={textMode}
                      onClick={() => setTextMode(!textMode)}
                      disabled={isSessionCompleted || sessionData.room_configuration?.disableTextModeControl}
                      hidden={sessionData.room_configuration?.hideTextModeControl}

                    />
                    <ControlButton
                      icon={<Layers size={20} />}
                      activeIcon={<Layers size={20} />}
                      isActive={showCardsSidebar}
                      onClick={() => setShowCardsSidebar(!showCardsSidebar)}
                      disabled={isSessionCompleted || sessionData.room_configuration?.disableCardControl}
                      hidden={sessionData.room_configuration?.hideCardControl}

                    />
                    {!sessionData.room_configuration?.hideEndControl && (
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setShowConfirmEndCall(true)}
                        className="bg-gradient-to-r from-red-500 to-pink-500 text-white px-4 py-2 rounded-full text-sm font-medium hover:shadow-lg transition duration-300"
                      >
                        <LogOut size={20} />
                      </motion.button>

                    )}

                  </div>
                </div>)}
            </div>
          </footer>
          <CardsSidebar cards={sessionData?.cards} isOpen={showCardsSidebar} onClose={() => setShowCardsSidebar(false)} onCardSelect={(card) => { setShowCardsSidebar(false); setCurrentCard(card) }} />
          <ChatSidebar sessionData={sessionData} updateSessionData={updateSessionData} currentContent={currentContent} sendText={sendText} isOpen={showChatSidebar} onClose={() => { setShowChatSidebar(!showChatSidebar) }} />
          <DeveloperConsole sessionData={sessionData} updateSessionData={updateSessionData} />
          {/* <SessionConsole sessionData={sessionData} updateSessionData={updateSessionData} /> */}
          <ConfirmationPopup isOpen={showConfirmEndCall} onClose={() => setShowConfirmEndCall(false)} onConfirm={handleEndCall} message="Are you sure you want to end the call?" />
        </>
      )}
    </div >

  );
};

export default SessionRoom;