import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff, Video, VideoOff, MessageSquare, Settings, Maximize2, ScreenShareIcon, ScreenShareOff } from 'lucide-react';

import { useVideoStream } from '../../hooks/useVideoStream';


const FaceLogin = ({ onLogin, userImage, user }) => {


  const [isVideoOn, setIsVideoOn] = useState(false);
  const [detectionStatus, setDetectionStatus] = useState(null);


  const { videoRef, detectedUser, startCamera,
    stopCamera, setUserProvidedReferenceImageFromUrl, startFaceRecognition } = useVideoStream(true)

  useEffect(() => {
    const runAsyncEffect = async () => {
      if (userImage && isVideoOn && videoRef) {
        try {
          setDetectionStatus({ status: 'detecting', text: 'Hold still detecting..' })

          // Handle URL image
          const image = await setUserProvidedReferenceImageFromUrl(userImage);

          // Start face recognition
          const detected = await startFaceRecognition();

          if (detected.label != 'unknown')
            setDetectionStatus({ status: 'detected', text: 'Nice to meet you..' + user?.name })
          else
            setDetectionStatus({ status: 'unknown', text: 'Cant seem to find you..' })

          onLogin(detected);

        } catch (error) {
          console.error('Error in async useEffect:', error);
        }
      }
    };

    setTimeout(() => {
      runAsyncEffect(); // Call the async function

    }, 3000);
  }, [isVideoOn, userImage]);



  // useEffect(() => {
  //   if (detectedUser) {
  //     setDetectionStatus('Nice to meet you..' + JSON.stringify(detectedUser))
  //     onLogin(detectedUser);
  //   }
  // }, [detectedUser, onLogin]);



  useEffect(() => {
    if (isVideoOn) {
      startCamera();
      setDetectionStatus({ text: 'Look into the Camera..', status: 'detecting' })

    } else {
      stopCamera();
    }
  }, [isVideoOn]);


  const Avatar = ({ letter, audioLevel, isHost, photo }) => {
    const baseScale = 1 + audioLevel * 0.2;
    const rippleScale1 = 1 + audioLevel * 0.5;
    const rippleScale2 = 1 + audioLevel * 0.7;

    return (
      <div className="w-full h-full flex items-center justify-center relative overflow-hidden">
        {/* The main avatar circle */}
        <div
          className={`relative z-10 rounded-full flex items-center justify-center ${isHost ? 'bg-green-600' : 'bg-orange-600'}`}
          style={{
            width: '100%', // Adjust width based on screen size
            maxWidth: '120px', // max size to avoid being too large
            height: '100%',
            maxHeight: '120px',
            transform: `scale(${baseScale})`,
            transition: 'transform 0.2s ease-out, opacity 0.2s ease-out',
          }}
        >
          {photo ? (
            <img
              src={photo}
              alt="User"
              className="object-cover rounded-full"
              style={{
                width: '100%',
                height: '100%',
                maxWidth: '120px', // same max as container
                maxHeight: '120px',
              }}
            />
          ) : (
            <span className="text-white text-6xl font-bold">{letter}</span>
          )}
        </div>

        {/* Ripple Effect 1 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-30"
          style={{
            transform: `scale(${rippleScale1})`,
            transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
            opacity: `${audioLevel * 0.3}`,
          }}
        ></div>

        {/* Ripple Effect 2 */}
        <div
          className="absolute inset-0 rounded-full bg-white opacity-20"
          style={{
            transform: `scale(${rippleScale2})`,
            transition: 'transform 0.4s ease-out, opacity 0.4s ease-out',
            opacity: `${audioLevel * 0.2}`,
          }}
        ></div>
      </div>
    );
  };




  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden">
      <div className="relative aspect-video">
        {isVideoOn ? (
          <video ref={videoRef} autoPlay muted className="w-full h-full object-cover" />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-700">
            <span className="text-white text-xl">Camera Off</span>
          </div>
        )}

        {detectionStatus && (
          <div className={`absolute top-2 left-2 px-4 py-2 rounded-lg text-white text-sm backdrop-blur-sm shadow-md border
        ${detectionStatus.status === 'detecting' ? 'bg-blue-500/70 border-blue-400' :
              detectionStatus.status === 'detected' ? 'bg-green-500/70 border-green-400' :
                detectionStatus.status === 'unknown' ? 'bg-red-500/70 border-red-400' : ''
            }`}>
            {detectionStatus?.text || 'Start..'}
          </div>
        )}


      </div>
      <div className="p-4 flex justify-center space-x-4">
        <button onClick={() => setIsVideoOn(!isVideoOn)} className={`p-2 rounded-full ${isVideoOn ? 'bg-red-500' : 'bg-gray-600'}`}>
          {isVideoOn ? <Video size={20} /> :
            <VideoOff size={20} />}
        </button>
      </div>
    </div>
  );

};

export default FaceLogin;
