import React, { useState, useEffect } from 'react';
import { getUserMemory } from '../../../services/ai/MemoryRAG'
import { Book, Loader, AlertCircle } from 'lucide-react';
import { useAuth } from '../../../AuthContext';

const UserMemory = () => {
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoading(true);
        const allInsights = await getUserMemory(user.id);
        setInsights(allInsights);
      } catch (err) {
        setError('Failed to fetch memory');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInsights();
  }, [user.id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin mr-2" size={24} />
        <span className="text-lg font-semibold">Loading Memory...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-10">
        <AlertCircle className="mx-auto mb-4" size={48} />
        <p className="text-lg font-semibold">{error}</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto w-full p-6">
      {insights.length === 0 ? (
        <p className="text-gray-600 text-center">No insights available yet.</p>
      ) : (
        <div className="space-y-6">
          {insights.map((insight, index) => (
            <div key={insight.id || index} className="bg-white rounded-lg shadow-md p-6 transition duration-300 ease-in-out hover:shadow-lg">
              <div className="flex items-start w-full">
                <Book className="text-purple-500 mr-4 flex-shrink-0" size={24} />
                <div>
                  <p className="text-gray-800 mb-2">{insight.text}</p>
                  <small className="text-gray-500">
                    {new Date(insight.timestamp).toLocaleString()}
                  </small>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserMemory;