import React from 'react';
import { useAuth } from '../../AuthContext';
import { LogOut } from 'lucide-react';

const LogoutButton = () => {
  const { signOut } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}`
        }
      });
    } catch (error) {
      console.error('Error logging out', error.message);
    }
  };

  return (
    <button onClick={handleLogout} className="mt-4 flex items-center w-full p-3 rounded-lg text-gray-400 hover:bg-[#2A2A3B] hover:text-white transition-colors">
      <LogOut className="h-6 w-6 md:mr-4" />
      <span className="hidden md:inline text-lg">Logout</span>
    </button>

  );
};

export default LogoutButton;