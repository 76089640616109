import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { ArrowRight, Plus, X, HelpCircle, DollarSign } from 'lucide-react';

const CapTableSimulator = () => {
  const [rounds, setRounds] = useState([
    {
      id: 'founders',
      name: 'Founders',
      shares: 1000000,
      valuation: 0,
      percentageOwned: 100,
      color: '#8B5CF6'
    }
  ]);

  const [showNewRoundModal, setShowNewRoundModal] = useState(false);
  const [newRound, setNewRound] = useState({
    name: '',
    valuation: '',
    investment: ''
  });

  const [showTooltip, setShowTooltip] = useState('');

  const calculateNewShares = (investment, valuation, existingShares) => {
    return (investment / valuation) * existingShares;
  };

  const calculatePercentages = (rounds) => {
    const totalShares = rounds.reduce((sum, round) => sum + round.shares, 0);
    return rounds.map(round => ({
      ...round,
      percentageOwned: ((round.shares / totalShares) * 100).toFixed(2)
    }));
  };

  const addNewRound = () => {
    const totalExistingShares = rounds.reduce((sum, round) => sum + round.shares, 0);
    const postMoneyValuation = parseFloat(newRound.valuation);
    const investment = parseFloat(newRound.investment);

    const newShares = calculateNewShares(
      investment,
      postMoneyValuation,
      totalExistingShares
    );

    const colors = [
      '#8B5CF6', '#EC4899', '#10B981', '#3B82F6',
      '#F59E0B', '#6366F1', '#14B8A6', '#8B5CF6'
    ];

    const updatedRounds = [
      ...rounds,
      {
        id: `round-${rounds.length}`,
        name: newRound.name,
        shares: newShares,
        valuation: postMoneyValuation,
        investment: investment,
        color: colors[rounds.length % colors.length]
      }
    ];

    setRounds(calculatePercentages(updatedRounds));
    setShowNewRoundModal(false);
    setNewRound({ name: '', valuation: '', investment: '' });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  const renderTooltipContent = (props) => {
    if (props.payload && props.payload.length) {
      const data = props.payload[0].payload;
      return (
        <div className="bg-gray-800 p-3 rounded-lg shadow-lg">
          <p className="text-white font-semibold">{data.name}</p>
          <p className="text-gray-300">Shares: {data.shares.toLocaleString()}</p>
          <p className="text-gray-300">Ownership: {data.percentageOwned}%</p>
          {data.investment && (
            <p className="text-gray-300">Investment: {formatCurrency(data.investment)}</p>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-gray-900 text-white p-6 rounded-xl shadow-2xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
          Cap Table Simulator
        </h2>
        <button
          onClick={() => setShowNewRoundModal(true)}
          className="bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-2 rounded-lg text-white font-semibold flex items-center hover:opacity-90 transition-opacity"
        >
          <Plus size={20} className="mr-2" />
          Add Funding Round
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Chart */}
        <div className="bg-gray-800 p-4 rounded-xl">
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={rounds}
                dataKey="shares"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={100}
                labelLine={false}
              >
                {rounds.map((round) => (
                  <Cell key={round.id} fill={round.color} />
                ))}
              </Pie>
              <Tooltip content={renderTooltipContent} />
            </PieChart>
          </ResponsiveContainer>
        </div>

        {/* Cap Table */}
        <div className="bg-gray-800 p-4 rounded-xl">
          <table className="w-full">
            <thead>
              <tr className="text-gray-400">
                <th className="text-left py-2">Round</th>
                <th className="text-right py-2">Shares</th>
                <th className="text-right py-2">Ownership</th>
              </tr>
            </thead>
            <tbody>
              {rounds.map((round) => (
                <tr key={round.id} className="border-t border-gray-700">
                  <td className="py-3 flex items-center">
                    <div
                      className="w-3 h-3 rounded-full mr-2"
                      style={{ backgroundColor: round.color }}
                    ></div>
                    {round.name}
                  </td>
                  <td className="text-right">{round.shares.toLocaleString()}</td>
                  <td className="text-right">{round.percentageOwned}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* New Round Modal */}
      {showNewRoundModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">New Funding Round</h3>
              <button
                onClick={() => setShowNewRoundModal(false)}
                className="text-gray-400 hover:text-white"
              >
                <X size={24} />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Round Name</label>
                <input
                  type="text"
                  value={newRound.name}
                  onChange={(e) => setNewRound({ ...newRound, name: e.target.value })}
                  className="w-full bg-gray-700 border border-gray-600 rounded-lg px-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="e.g., Seed Round"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Post-Money Valuation
                </label>
                <div className="relative">
                  <DollarSign size={16} className="absolute left-3 top-3 text-gray-400" />
                  <input
                    type="number"
                    value={newRound.valuation}
                    onChange={(e) => setNewRound({ ...newRound, valuation: e.target.value })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg pl-8 pr-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                    placeholder="10000000"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Investment Amount
                </label>
                <div className="relative">
                  <DollarSign size={16} className="absolute left-3 top-3 text-gray-400" />
                  <input
                    type="number"
                    value={newRound.investment}
                    onChange={(e) => setNewRound({ ...newRound, investment: e.target.value })}
                    className="w-full bg-gray-700 border border-gray-600 rounded-lg pl-8 pr-3 py-2 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                    placeholder="2000000"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                onClick={() => setShowNewRoundModal(false)}
                className="px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={addNewRound}
                disabled={!newRound.name || !newRound.valuation || !newRound.investment}
                className="px-4 py-2 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Add Round
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Help Text */}
      {/* <div className="mt-6 bg-gray-800 p-4 rounded-xl flex items-start space-x-3">
        <HelpCircle size={20} className="text-purple-400 flex-shrink-0 mt-1" />
        <p className="text-gray-400 text-sm">
          Add funding rounds to see how your cap table evolves. Enter the post-money valuation
          and investment amount for each round to calculate share allocation and dilution.
          The visualization helps you understand ownership percentages at each stage.
        </p>
      </div> */}
    </div>
  );
};

export default CapTableSimulator;