import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Save, X, Loader } from 'lucide-react';

const PromptEdit = ({ promptId, onSave, onCancel }) => {
  const [prompt, setPrompt] = useState({
    name: '',
    version: '',
    content: '',
    description: '',
    is_active: true
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrompt = async () => {
      if (promptId === 'new') return;
      try {
        setLoading(true);
        const data = await dbUtils.prompts.getById(promptId);
        setPrompt(data);
      } catch (err) {
        setError('Failed to load prompt');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPrompt();
  }, [promptId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPrompt(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (promptId === 'new') {
        await dbUtils.prompts.create(prompt);
      } else {
        await dbUtils.prompts.update(promptId, prompt);
      }
      onSave();
    } catch (err) {
      setError('Failed to save prompt');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading prompt...</span>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">
          {promptId === 'new' ? 'Create New Prompt' : 'Edit Prompt'}
        </h2>
        <div className="space-x-2">
          <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
            <X className="inline-block mr-1" /> Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
            <Save className="inline-block mr-1" /> Save
          </button>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            name="name"
            value={prompt.name}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Version</label>
          <input
            type="text"
            name="version"
            value={prompt.version}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Content</label>
          <textarea
            name="content"
            value={prompt.content}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded h-64"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Description</label>
          <textarea
            name="description"
            value={prompt.description}
            onChange={handleChange}
            className="w-full p-2 bg-gray-700 rounded h-32"
          />
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="is_active"
            checked={prompt.is_active}
            onChange={handleChange}
            className="mr-2"
          />
          <label className="text-sm font-medium">Active</label>
        </div>
      </div>
    </form>
  );
};

export default PromptEdit;