import React from 'react';
import { Star, Award, BookOpen, CheckCircle } from 'lucide-react';

const CoachCard = ({ coach, isSelected, onClick }) => {
  return (
    <div
      className={`p-6 rounded-xl text-left transition-all duration-300 cursor-pointer relative ${isSelected
        ? 'bg-[#3D2E5E] text-[#E7C6EF] shadow-lg transform scale-105'
        : 'bg-[#2A1F45] text-white hover:bg-[#352A50]'
        }`}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute top-4 right-4 flex items-center bg-[#E7C6EF] text-[#231639] px-3 py-1 rounded-full">
          <CheckCircle className="w-4 h-4 mr-1" />
          <span className="text-sm font-medium">Selected</span>
        </div>
      )}
      <div className="flex items-start space-x-4">
        <img
          src={coach.avatar || 'https://via.placeholder.com/100'}
          alt={coach.name}
          className="w-20 h-20 rounded-full object-cover border-2 border-[#E7C6EF]"
        />
        <div className="flex-grow">
          <h4 className="text-xl font-semibold mb-1">Coach {coach.name} (AI)</h4>
          <p className="text-sm opacity-80 mb-2">{coach.specialization}</p>
          <div className="flex items-center space-x-2 mb-2">
            <Star className="w-4 h-4 text-yellow-400" />
            <span className="text-sm">{coach.rating} ({coach.reviewCount || '247'} reviews)</span>
          </div>
          <div className="flex items-center space-x-2 text-sm">
            <Award className="w-4 h-4" />
            <span>{coach.experience || '15'} years experience</span>
          </div>
          <div className="flex items-center space-x-2 text-sm mt-1">
            <BookOpen className="w-4 h-4" />
            <span>{coach.sessionCount || '500+'} sessions completed</span>
          </div>
        </div>
      </div>
      <p className="text-sm mt-4 line-clamp-2">{coach.bio}</p>
    </div>
  );
};

export default CoachCard;