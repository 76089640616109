import React, { useState } from 'react';
import { Users, Check, X, AlertCircle } from 'lucide-react';

const CofounderDecisionTool = () => {
  const [selectedDomain, setSelectedDomain] = useState('product');
  const [showExample, setShowExample] = useState(false);

  const domains = [
    {
      id: 'product',
      name: 'Product Decisions',
      examples: [
        'Major feature prioritization',
        'Tech stack changes',
        'Design philosophy'
      ]
    },
    {
      id: 'hiring',
      name: 'Team & Hiring',
      examples: [
        'Leadership hires',
        'Compensation structure',
        'Performance reviews'
      ]
    },
    {
      id: 'finance',
      name: 'Financial',
      examples: [
        'Fundraising terms',
        'Major expenses',
        'Budget allocation'
      ]
    },
    {
      id: 'strategy',
      name: 'Strategic',
      examples: [
        'Market positioning',
        'Business model changes',
        'Partnership deals'
      ]
    }
  ];

  const framework = {
    product: {
      veto: 'CTO has final say on technical feasibility',
      consensus: 'Required for major UX changes',
      lead: 'CPO drives product roadmap',
      timeline: '48hr decision window'
    },
    hiring: {
      veto: 'Both founders can veto any hire',
      consensus: 'Required for leadership roles',
      lead: 'Hiring manager drives process',
      timeline: '24hr feedback window'
    },
    finance: {
      veto: 'CFO has final say on budget impact',
      consensus: 'Required over $10K spend',
      lead: 'CEO drives fundraising',
      timeline: '72hr review period'
    },
    strategy: {
      veto: 'CEO has final say on strategy',
      consensus: 'Required for pivot/model change',
      lead: 'CEO sets direction',
      timeline: 'Weekly strategy review'
    }
  };

  return (
    <div className="bg-gray-900 rounded-xl p-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-2xl font-bold text-white">Decision Framework</h2>
          <p className="text-purple-400">Clear protocols prevent conflicts</p>
        </div>
        <Users className="w-8 h-8 text-purple-400" />
      </div>

      {/* Domain Selection */}
      <div className="grid grid-cols-2 gap-3 mb-6">
        {domains.map(domain => (
          <button
            key={domain.id}
            onClick={() => setSelectedDomain(domain.id)}
            className={`p-3 rounded-lg text-left transition-colors ${selectedDomain === domain.id
              ? 'bg-purple-600 text-white'
              : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
          >
            {domain.name}
          </button>
        ))}
      </div>

      {/* Framework Display */}
      <div className="bg-gray-800 rounded-lg p-4 mb-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-1">
            <label className="text-sm text-purple-400">Power of Veto</label>
            <p className="text-white">{framework[selectedDomain].veto}</p>
          </div>
          <div className="space-y-1">
            <label className="text-sm text-purple-400">Consensus Required</label>
            <p className="text-white">{framework[selectedDomain].consensus}</p>
          </div>
          <div className="space-y-1">
            <label className="text-sm text-purple-400">Decision Lead</label>
            <p className="text-white">{framework[selectedDomain].lead}</p>
          </div>
          <div className="space-y-1">
            <label className="text-sm text-purple-400">Timeline</label>
            <p className="text-white">{framework[selectedDomain].timeline}</p>
          </div>
        </div>
      </div>

      {/* Example Toggle */}
      {/* <button
        onClick={() => setShowExample(!showExample)}
        className="w-full mb-4 p-3 bg-gray-800 rounded-lg text-left text-gray-300 hover:bg-gray-700"
      >
        👉 See example decision flow
      </button> */}

      {/* Example Flow */}
      {showExample && (
        <div className="bg-gray-800 rounded-lg p-4 space-y-3">
          {domains.find(d => d.id === selectedDomain).examples.map((example, index) => (
            <div key={index} className="flex items-center justify-between">
              <span className="text-gray-300">{example}</span>
              <div className="flex space-x-2">
                <button className="p-1 bg-green-600/20 text-green-400 rounded">
                  <Check size={16} />
                </button>
                <button className="p-1 bg-red-600/20 text-red-400 rounded">
                  <X size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Quick Tip */}
      {/* <div className="mt-4 bg-purple-900/30 rounded-lg p-4 flex items-start space-x-3">
        <AlertCircle className="w-5 h-5 text-purple-400 flex-shrink-0 mt-1" />
        <p className="text-sm text-purple-200">
          Set these frameworks early and review quarterly. Having clear protocols prevents
          deadlocks and reduces friction in day-to-day operations.
        </p>
      </div> */}
    </div>
  );
};

export default CofounderDecisionTool;