import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Edit, Trash2, Plus, Loader } from 'lucide-react';
import UserEdit from './UserEdit';
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.users.getAll();
      setUsers(data);
    } catch (err) {
      setError('Failed to load users');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (userId) => {
    setEditingUserId(userId);
  };

  const handleEditSave = () => {
    setEditingUserId(null);
    fetchUsers();
  };

  const handleEditCancel = () => {
    setEditingUserId(null);
  };

  const handleDeleteClick = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await dbUtils.users.delete(userId);
        fetchUsers();
      } catch (err) {
        setError('Failed to delete user');
        console.error(err);
      }
    }
  };

  const renderUserCard = (user) => (
    <div key={user.id} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex justify-between items-center">
      <div className="p-4 flex-grow flex items-center">
        <img
          src={user.photo || 'https://via.placeholder.com/40'}
          alt={user.name}
          className="w-10 h-10 rounded-full object-cover mr-4"
        />
        <div>
          <h3 className="text-lg font-semibold">{user.name}</h3>
          <p className="text-sm text-gray-400">{user.email}</p>
        </div>
      </div>
      <div className="bg-gray-700 p-4 flex space-x-2">
        <button
          onClick={() => handleEditClick(user.id)}
          className="p-1 bg-blue-500 rounded hover:bg-blue-600 transition-colors"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => handleDeleteClick(user.id)}
          className="p-1 bg-red-500 rounded hover:bg-red-600 transition-colors"
        >
          <Trash2 size={16} />
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading users...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-10 bg-red-100 border border-red-300 rounded-lg">
        <p className="text-lg font-semibold">{error}</p>
      </div>
    );
  }

  if (editingUserId) {
    return (
      <UserEdit
        userId={editingUserId}
        onSave={handleEditSave}
        onCancel={handleEditCancel}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">User Management</h2>
        <button
          onClick={() => handleEditClick('new')}
          className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center hover:bg-green-600 transition-colors"
        >
          <Plus className="mr-2" /> New User
        </button>
      </div>

      <div className="space-y-4">
        {users.map(renderUserCard)}
      </div>
    </div>
  );
};

export default UsersList;
