import React, { useState, useEffect } from 'react';

const ElegantSpinner = () => (
  <div className="relative w-20 h-20">
    <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-purple-300 opacity-20 rounded-full"></div>
    <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-t-purple-500 animate-spin rounded-full"></div>
  </div>
);

const IFrameWithLoading = ({ content }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [content.inputData]);

  const handleIFrameLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="w-full h-screen flex flex-col overflow-y-auto  relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-blur-sm z-10">
          <div className="text-white text-center">
            <ElegantSpinner />
            <p className="mt-4 text-lg font-light tracking-wider animate-pulse">
              Loading Card...
            </p>
          </div>
        </div>
      )}
      {content.inputData?.url && (
        <iframe
          className="w-full flex-grow border-0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          src={content.inputData.url}
          onLoad={handleIFrameLoad}
        />
      )}
      {content.inputData?.html && (
        <iframe
          className="w-full flex-grow border-0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          srcDoc={content.inputData.html}
          onLoad={handleIFrameLoad}
        />
      )}
    </div>
  );
};

export default IFrameWithLoading;