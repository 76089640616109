import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const PromptTemplateEditor = ({ template, variables, onSave }) => {
  const [editedTemplate, setEditedTemplate] = useState(template);
  const [editableVariables, setEditableVariables] = useState(variables);

  useEffect(() => {
    let newTemplate = template;
    Object.entries(editableVariables).forEach(([key, value]) => {
      const regex = new RegExp(`\\{\\{${key}\\}\\}`, 'g');
      newTemplate = newTemplate.replace(regex, value || `{{${key}}}`);
    });
    setEditedTemplate(newTemplate);
  }, [template, editableVariables]);

  const handleVariableChange = (key, value) => {
    setEditableVariables(prev => ({ ...prev, [key]: value }));
  };
  useEffect(() => {
    setEditableVariables(variables);
    console.log('PromptTemplateEditor variables updated:', variables);
  }, [variables]);


  const handleSave = () => {
    onSave(editedTemplate, editableVariables);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Session Prompt Template</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="text-md font-medium mb-2">Template (Markdown):</h4>
          <textarea
            value={editedTemplate}
            onChange={(e) => setEditedTemplate(e.target.value)}
            className="w-full p-2 bg-gray-700 rounded h-96"
          />
        </div>
        <div>
          <h4 className="text-md font-medium mb-2">Preview:</h4>
          <div className="w-full p-2 bg-gray-700 rounded h-96 overflow-auto">
            <ReactMarkdown>{editedTemplate}</ReactMarkdown>
          </div>
        </div>
      </div>
      <div>
        <h4 className="text-md font-medium mb-2">Variables:</h4>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(editableVariables).map(([key, value]) => (
            <div key={key} className="mb-2">
              <label className="block text-sm font-medium mb-1">{key}</label>
              <input
                type="text"
                value={value}
                onChange={(e) => handleVariableChange(key, e.target.value)}
                className="w-full p-2 bg-gray-700 rounded"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        type='button'
        onClick={handleSave}
        className="px-4 py-2 bg-blue-500 rounded-lg"
      >
        Confirm Prompt
      </button>
    </div>
  );
};

export default PromptTemplateEditor;