import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Plus, Minus, Move, StickyNote, Type, Image, Pencil, Grid, LayoutTemplate, Square, X, FileText } from 'lucide-react';

const TemplatePanel = ({ templates, onSelectTemplate, onClose }) => {
  return (
    <div className="absolute top-20 left-20 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-50">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Select a Template</h3>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={20} />
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {Object.entries(templates).map(([key, template]) => (
          <button
            key={key}
            onClick={() => onSelectTemplate(key)}
            className="bg-gray-100 hover:bg-gray-200 rounded-lg p-4 text-left"
          >
            <h4 className="font-bold mb-2">{key}</h4>
            <p className="text-sm text-gray-600">{template.length} elements</p>
          </button>
        ))}
      </div>
    </div>
  );
};
const EnhancedMiroBoard = ({
  title = "Enhanced MiroBoard",
  description = "Your canvas for ideas, collaboration, and organization",
  initialZoom = 1,
  initialPan = { x: 0, y: 0 },
  tools = [
    { name: 'sticky', icon: StickyNote, draggable: true, deletable: true },
    { name: 'text', icon: Type, draggable: false },
    { name: 'image', icon: Image, draggable: false },
    { name: 'draw', icon: Pencil, draggable: false },
    { name: 'column', icon: Grid, draggable: false },
    { name: 'frame', icon: Square, draggable: false },
    { name: 'template', icon: LayoutTemplate, draggable: false },
    { name: 'loadTemplate', icon: FileText, draggable: false }

  ],
  headerClassName = "bg-purple-600 text-white p-4",
  toolboxClassName = "w-16 bg-gray-200 p-2 flex flex-col items-center",
  canvasClassName = "w-full h-[calc(100vh-200px)] overflow-hidden bg-white relative cursor-move",
  templates = {}, // Object containing different template configurations
  loadedTemplateName = null
}) => {
  // const templates = {
  //   businessCanvas: [
  //     { id: 'keyPartners', title: 'Key Partners', x: 10, y: 10, width: 300, height: 300 },
  //     { id: 'keyActivities', title: 'Key Activities', x: 320, y: 10, width: 300, height: 150 },
  //     { id: 'keyResources', title: 'Key Resources', x: 320, y: 170, width: 300, height: 140 },
  //     { id: 'valuePropositions', title: 'Value Propositions', x: 630, y: 10, width: 300, height: 300 },
  //     { id: 'customerRelationships', title: 'Customer Relationships', x: 940, y: 10, width: 300, height: 150 },
  //     { id: 'channels', title: 'Channels', x: 940, y: 170, width: 300, height: 140 },
  //     { id: 'customerSegments', title: 'Customer Segments', x: 1250, y: 10, width: 300, height: 300 },
  //     { id: 'costStructure', title: 'Cost Structure', x: 10, y: 320, width: 770, height: 150 },
  //     { id: 'revenueStreams', title: 'Revenue Streams', x: 790, y: 320, width: 760, height: 150 }
  //   ],
  //   swotAnalysis: [
  //     { id: 'strengths', title: 'Strengths', x: 10, y: 10, width: 400, height: 300 },
  //     { id: 'weaknesses', title: 'Weaknesses', x: 420, y: 10, width: 400, height: 300 },
  //     { id: 'opportunities', title: 'Opportunities', x: 10, y: 320, width: 400, height: 300 },
  //     { id: 'threats', title: 'Threats', x: 420, y: 320, width: 400, height: 300 }
  //   ],
  //   // Add more templates as needed
  // };


  const [zoom, setZoom] = useState(initialZoom);
  const [pan, setPan] = useState(initialPan);
  const [selectedTool, setSelectedTool] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [items, setItems] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [loadedTemplate, setLoadedTemplate] = useState(null);
  const [showTemplatePanel, setShowTemplatePanel] = useState(false);
  const boardRef = useRef(null);

  const handleZoom = useCallback((delta) => {
    setZoom(prevZoom => {
      const newZoom = prevZoom + delta;
      return Math.max(0.1, Math.min(newZoom, 5)); // Limit zoom between 0.1 and 5
    });
  }, []);
  const deleteItem = useCallback((id) => {
    setItems(prevItems => prevItems.filter(item => item.id !== id));
    setEditingItemId(null);
  }, []);
  const handleItemDrag = useCallback((e, itemId) => {
    const dx = (e.clientX - dragStart.x) / zoom;
    const dy = (e.clientY - dragStart.y) / zoom;
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === itemId
          ? { ...item, x: item.x + dx, y: item.y + dy }
          : item
      )
    );
    setDragStart({ x: e.clientX, y: e.clientY });
  }, [dragStart, zoom]);


  const handlePan = useCallback((dx, dy) => {
    setPan(prevPan => ({
      x: prevPan.x + dx,
      y: prevPan.y + dy
    }));
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button === 1 || (e.button === 0 && e.altKey)) {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      const dx = e.clientX - dragStart.x;
      const dy = e.clientY - dragStart.y;
      handlePan(dx / zoom, dy / zoom);
      setDragStart({ x: e.clientX, y: e.clientY });
    } else if (draggingItemId !== null) {
      const dx = (e.clientX - dragStart.x) / zoom;
      const dy = (e.clientY - dragStart.y) / zoom;
      setItems(prevItems =>
        prevItems.map(item =>
          item.id === draggingItemId
            ? { ...item, x: item.x + dx, y: item.y + dy }
            : item
        )
      );
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  }, [isDragging, dragStart, draggingItemId, zoom, handlePan]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDraggingItemId(null);
  }, []);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      // Zoom
      const delta = e.deltaY > 0 ? -0.1 : 0.1;
      handleZoom(delta);
    } else {
      // Pan
      handlePan(-e.deltaX / zoom, -e.deltaY / zoom);
    }
  }, [zoom, handleZoom, handlePan]);

  const loadTemplate = useCallback((templateName) => {
    if (templates[templateName] && !loadedTemplate) {
      const newItems = templates[templateName].map(frame => ({
        id: Date.now() + Math.random(),
        type: 'templateFrame',
        ...frame,
        color: 'rgba(200, 200, 200, 0.5)',
        draggable: false,
        deletable: false
      }));
      setItems(prevItems => [...prevItems, ...newItems]);
      setLoadedTemplate(templateName);
      setShowTemplatePanel(false);
    }
  }, [templates, loadedTemplate]);

  const handleToolClick = (toolName) => {
    if (toolName === 'loadTemplate') {
      setShowTemplatePanel(true);
    } else {
      setSelectedTool(selectedTool === toolName ? null : toolName);
    }
  };

  const handleCloseTemplatePanel = () => {
    setShowTemplatePanel(false);
  };


  useEffect(() => {
    if (loadedTemplateName)
      loadTemplate(loadedTemplateName)
  }, [])
  const handleCanvasClick = useCallback((e) => {
    if (selectedTool) {
      const rect = boardRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left - pan.x) / zoom;
      const y = (e.clientY - rect.top - pan.y) / zoom;

      const selectedToolConfig = tools.find(tool => tool.name === selectedTool);
      const newItem = {
        id: Date.now(),
        type: selectedTool,
        x,
        y,
        width: 150,
        height: 100,
        content: '',
        color: getRandomColor(),
        draggable: selectedToolConfig ? selectedToolConfig.draggable : false,
        deletable: selectedToolConfig ? selectedToolConfig.deletable : false,
      };

      if (selectedTool === 'sticky') {
        newItem.width = 100;
        newItem.height = 100;
      } else if (selectedTool === 'column') {
        newItem.width = 200;
        newItem.height = 400;
      } else if (selectedTool === 'frame') {
        newItem.width = 800;
        newItem.height = 400;
      }

      setItems(prevItems => [...prevItems, newItem]);
      setSelectedTool(null);
      setEditingItemId(newItem.id);
    } else {
      setEditingItemId(null);
    }
  }, [selectedTool, pan, zoom]);

  const getRandomColor = () => {
    const colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const BoardItem = ({ item }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localContent, setLocalContent] = useState(item.content);
    const itemRef = useRef(null);
    const clickTimeoutRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
      if (isEditing && itemRef.current) {
        itemRef.current.focus();
      }
    }, [isEditing]);

    useEffect(() => {
      setLocalContent(item.content);
    }, [item.content]);

    const handleMouseDown = (e) => {
      e.stopPropagation();
      setIsClicked(true);

      clickTimeoutRef.current = setTimeout(() => {
        setIsClicked(false);
        setDraggingItemId(item.id);
        setDragStart({ x: e.clientX, y: e.clientY });
      }, 150);
    };

    const handleMouseUp = (e) => {
      e.stopPropagation();
      clearTimeout(clickTimeoutRef.current);

      if (isClicked) {
        setIsEditing(true);
      }

      setIsClicked(false);
      setDraggingItemId(null);
    };
    const updateItemContent = useCallback((id, newContent) => {
      setItems(prevItems =>
        prevItems.map(item =>
          item.id === id ? { ...item, content: newContent } : item
        )
      );
    }, []);


    const handleMouseMove = (e) => {
      if (draggingItemId === item.id) {
        e.stopPropagation();
        handleItemDrag(e, item.id);
      }
    };

    const handleDeleteClick = (e) => {
      e.stopPropagation();
      deleteItem(item.id);
    };

    const handleContentChange = (e) => {
      setLocalContent(e.target.value);
    };

    const handleBlur = () => {
      setIsEditing(false);
      updateItemContent(item.id, localContent);
    };

    const renderItemContent = () => {
      switch (item.type) {
        case 'sticky':
        case 'text':
          return (
            isEditing ? (
              <textarea
                ref={itemRef}
                value={localContent}
                onChange={handleContentChange}
                onBlur={handleBlur}
                className="w-full h-full bg-transparent resize-none focus:outline-none text-sm"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <div className="w-full h-full overflow-auto text-sm" onClick={() => setIsEditing(true)}>
                {item.content || 'Click to edit'}
              </div>
            )
          );
        case 'column':
        case 'frame':
        case 'templateFrame':
          return (
            <div className="w-full h-full flex flex-col">
              <div className="bg-gray-200 p-1 text-xs font-bold">{item.title || 'Untitled'}</div>
              <div className="flex-grow p-2 overflow-auto text-xs">
                {item.content || 'Add content here'}
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div
        style={{
          position: 'absolute',
          left: item.x,
          top: item.y,
          width: item.width,
          height: item.height,
          backgroundColor: item.type === 'sticky' ? item.color : 'rgba(255, 255, 255, 0.8)',
          padding: '5px',
          borderRadius: '3px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          cursor: item.draggable ? (draggingItemId === item.id ? 'grabbing' : 'grab') : 'default',
          zIndex: 10,
          fontSize: `${12 / zoom}px`,
          userSelect: 'none',
          WebkitUserSelect: 'none',
          MozUserSelect: 'none',
          msUserSelect: 'none',
        }}
        onMouseDown={item.draggable ? handleMouseDown : undefined}
        onMouseUp={item.draggable ? handleMouseUp : undefined}
        onMouseMove={item.draggable ? handleMouseMove : undefined}
        onMouseLeave={() => {
          clearTimeout(clickTimeoutRef.current);
          setIsClicked(false);
          setDraggingItemId(null);
        }}
      >
        {item.deletable && <button
          onClick={handleDeleteClick}
          className="absolute top-1 right-1 text-gray-600 hover:text-red-500"
          style={{ fontSize: `${10 / zoom}px` }} // Adjust delete button size
        >
          <X size={10 / zoom} />
        </button>}

        {renderItemContent()}
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-gray-100 text-black rounded-lg overflow-hidden flex flex-col">
      <div className={headerClassName}>
        <h2 className="text-xl font-bold">{title}</h2>
        <p className="text-sm">{description}</p>
      </div>
      <div className="flex-grow flex">
        <div className={toolboxClassName}>
          {tools.map(({ name, icon: Icon }) => (
            <button
              key={name}
              onClick={() => handleToolClick(name)}
              className={`p-2 mb-2 rounded ${selectedTool === name ? 'bg-purple-600 text-white' : 'bg-gray-300'}`}
            >
              <Icon size={20} />
            </button>
          ))}
        </div>
        <div className="flex-grow p-4 relative">
          <div className="flex justify-end mb-4">
            <button onClick={() => handleZoom(0.1)} className="bg-gray-200 p-2 rounded mr-2">
              <Plus size={20} />
            </button>
            <button onClick={() => handleZoom(-0.1)} className="bg-gray-200 p-2 rounded mr-2">
              <Minus size={20} />
            </button>
            <button onClick={() => { setZoom(initialZoom); setPan(initialPan); }} className="bg-gray-200 p-2 rounded">
              <Move size={20} />
            </button>
          </div>
          {showTemplatePanel && (
            <TemplatePanel
              templates={templates}
              onSelectTemplate={loadTemplate}
              onClose={handleCloseTemplatePanel}
            />
          )}
          <div
            ref={boardRef}
            className={canvasClassName}
            style={{ cursor: selectedTool ? 'crosshair' : 'move' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onClick={handleCanvasClick}
            onWheel={handleWheel}
          >
            <div style={{
              transform: `scale(${zoom})`,
              transformOrigin: '0 0',
              width: '5000px',
              height: '5000px',
              position: 'absolute',
              left: `${pan.x}px`,
              top: `${pan.y}px`,
              backgroundColor: '#f0f0f0',
              backgroundImage: 'linear-gradient(#e5e5e5 1px, transparent 1px), linear-gradient(90deg, #e5e5e5 1px, transparent 1px)',
              backgroundSize: '20px 20px'
            }}>
              {items.map(item => (
                <BoardItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default EnhancedMiroBoard;