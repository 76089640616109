import React, { useState } from 'react';
import Avatar from '../components/Avatar';
import { motion } from 'framer-motion';
import Card from '../components/Card';
import LoginButton from '../components/LoginButton';
import SessionRoomPage from './SessionRoomPage';
import Logo from '../components/Logo';
import { withLinkedInRedirect } from '../../hooks/withLinkedInRedirect';

const LandingPage = () => {

  const [selectedUseCase, setSelectedUseCase] = useState({
    title: 'Investor Pitch Practice',
    id: "7f3da77d-4968-47be-8833-623dc15013b2"
  });

  const useCases = [
    { title: 'Investor Pitch Practice', id: "7f3da77d-4968-47be-8833-623dc15013b2" },
    { title: 'Co-Founder Foundations', id: "528811c1-e18a-4ec0-a685-3357dfd3bfe1" },
    { title: 'Business Model Canvas', id: "68ac211c-ecce-4236-ad91-61f5543400ba" },
    { title: 'Cap Table Simulations', id: "e2c0bf3c-002f-452b-b6f5-a99c4de201a3" },
    { title: 'Goal Setting & Tracking', id: "f7a934da-2dd7-4902-9f62-7489c54d7060" },
    // { title: 'Navigating Investor Relations', id: "7f3da77d-4968-47be-8833-623dc15013b2" }
  ];

  return (
    <div className="min-h-screen w-full bg-[#0F0F1A] text-white font-sans overflow-hidden relative">
      {/* Dynamic background */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-900/20 to-pink-900/20"></div>
        {[...Array(5)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full mix-blend-screen filter blur-xl opacity-70"
            animate={{
              scale: [1, 2, 2, 1, 1],
              opacity: [0.5, 0.8, 0.1, 0.7, 0.5],
              x: [0, 100, 200, 100, 0],
              y: [0, 100, 50, 200, 0],
            }}
            transition={{
              duration: 20 + i * 2,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            style={{
              background: `radial-gradient(circle, rgba(233,30,99,0.8) 0%, rgba(156,39,176,0.3) 70%)`,
              width: `${100 + i * 50}px`,
              height: `${100 + i * 50}px`,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
          />
        ))}
      </div>

      {/* Header */}
      <nav className="relative z-10 flex justify-between items-center p-8">
        <Logo className="mr-4" /> {/* Added margin to the right for spacing */}

        <div className="flex space-x-8 text-lg">
          {/* <a href="#features" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">Features</a> */}
          <a href="/faq" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">Pricing</a>
          <a href="/faq" className="text-gray-300 hover:text-white transition duration-300 ease-in-out">Contact</a>
        </div>
      </nav>

      {/* Main Content - Session Experience Preview */}
      <main className="relative z-10 flex flex-col items-center justify-center text-center mt-16 px-4">
        <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">
          Experience Founder Coaching
        </h1>
        <p className="text-base text-xl text-gray-300 leading-relaxed mb-8">
          Practice investor pitches, roleplay tough conversations, and set clear goals <br />— all with the help of your <span className='font-bold'>AI coach</span>.
        </p>
        <a href="https://www.producthunt.com/posts/mentara?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-mentara" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=583928&theme=light" alt="Mentara - (YC) Interview Simulator  | Product Hunt" width="250" height="54" /></a>
        {/* Session Layout Preview */}
        <section className="w-full max-w-6xl bg-[#1F1F2E] p-4 md:p-8 rounded-2xl shadow-lg border border-gray-700 mt-8 grid grid-cols-1 lg:grid-cols-4 gap-4 md:gap-8">
          {/* Use Case Cards */}
          <div className="col-span-1 flex lg:flex-col overflow-x-auto space-x-4 lg:space-x-0 lg:space-y-4 scrollbar-hide">
            {useCases.map((useCase) => (
              <button
                key={useCase.title}
                onClick={() => setSelectedUseCase(useCase)}
                className={`min-w-[200px] md:min-w-[150px] p-2 md:p-4 rounded-lg whitespace-nowrap overflow-hidden text-ellipsis transition duration-300 ${selectedUseCase.title === useCase.title ? 'bg-purple-600 text-white' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
              >
                {useCase.title}
              </button>
            ))}
          </div>


          {/* Session Simulator */}
          <div className="col-span-3 flex flex-col items-center bg-[#2A2A3B] rounded-lg h-[500px] md:h-[600px] lg:h-[700px] overflow-hidden">
            <SessionRoomPage demoMode={true} sessionId={selectedUseCase.id} />
          </div>
        </section>

        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-8 md:mt-12">
          {/* <button onClick={() => document.getElementById('login-button').click()} className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-6 py-3 md:px-8 md:py-4 rounded-full font-bold hover:opacity-90 transition duration-300 text-md md:text-base">
            Schedule Your First Session
          </button> */}
          <button
            onClick={() => document.getElementById('login-button').click()}
            class="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-6 py-3 md:px-8 md:py-4 rounded-full font-bold hover:opacity-90 transition duration-300 text-md md:text-base">Start your Interview here</button>
          {/* <button className="border border-gray-600 text-white px-6 py-3 md:px-8 md:py-4 rounded-full font-bold hover:bg-gray-700 transition duration-300 text-sm md:text-base">
            Learn More
          </button>
          */}
          <LoginButton className='hidden' />
        </div>
      </main>

      {/* Footer */}
      <footer className="relative z-10 flex flex-col items-center justify-center mt-24 py-8 border-t border-gray-700 bg-[#1F1F2E]">
        <div className="flex flex-col items-center space-y-6 mb-6">
          {/* Social Media Icons */}
          <div className="flex space-x-6">
            <a
              href="https://x.com/MentaraCoach"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors duration-300"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/mentaracoach"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors duration-300"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
            </a>
            <a
              href="https://discord.gg/XypbU6XP"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors duration-300"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z" />
              </svg>
            </a>
          </div>
          {/* Legal Links */}
          <div className="flex flex-col md:flex-row  text-sm items-center md:space-x-6 space-y-4 md:space-y-0 mt-4">
            <a href="/legal/privacy-policy.html" className="text-gray-300 hover:text-white transition-colors duration-300">Privacy Policy</a>
            <a href="/legal/cookie-policy.html" className="text-gray-300 hover:text-white transition-colors duration-300">Cookie Policy</a>
            <a href="/legal/terms-and-conditions.html" className="text-gray-300 hover:text-white transition-colors duration-300">Terms and Conditions</a>
            <a href="/legal/data-processing-addendum.html" className="text-gray-300 hover:text-white transition-colors duration-300">Data Processing Addendum</a>

          </div>
        </div>

        <p className="text-sm text-gray-500 mt-4">© 2024 Mentara. All rights reserved.</p>
      </footer>


    </div>
  );
};

export default withLinkedInRedirect(LandingPage);
