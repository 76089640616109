import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Edit, Trash2, LogIn, Plus, Search, Calendar as CalendarIcon, List as ListIcon, ChevronLeft, ChevronRight, Loader, Copy, Check, NotepadTextIcon } from 'lucide-react';
import SessionEdit from './SessionEdit';
import { useNavigate } from 'react-router-dom';

const SessionList = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [statusFilter, setStatusFilter] = useState('all');
  const [copiedId, setCopiedId] = useState(null);

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      setLoading(true);
      const data = await dbUtils.sessions.getAll();
      setSessions(data);
    } catch (err) {
      setError('Failed to load sessions');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const navigate = useNavigate();

  const handleJoinRoom = (sessionId) => {
    navigate(`/session/room/${sessionId}`);
  };

  const handleEditClick = (sessionId) => {
    setEditingSessionId(sessionId);
  };

  const handleEditSave = () => {
    setEditingSessionId(null);
    fetchSessions();
  };

  const handleEditCancel = () => {
    setEditingSessionId(null);
  };

  const handleDeleteClick = async (sessionId) => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        await dbUtils.sessions.delete(sessionId);
        fetchSessions();
      } catch (err) {
        setError('Failed to delete session');
        console.error(err);
      }
    }
  };

  const filteredSessions = sessions.filter(session =>
    (session.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      session.topic.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (statusFilter === 'all' || session.status === statusFilter)
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'scheduled': return 'bg-blue-500';
      case 'in-progress': return 'bg-green-500';
      case 'completed': return 'bg-purple-500';
      case 'cancelled': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };



  const generateInvitationText = (session) => {
    return `You're invited to join a Mentara Coaching Session!
Topic: ${session.topic}
Location: Online via Mentara Meetings

Join the meeting by clicking the link below:
https://mentara-ai.vercel.app/session/intro/${session.id}

Meeting ID: ${session.id}
Passcode: 67890

Dial in from your phone:
+1 123-456-7890 (US)
+44 20 1234 5678 (UK)

Find your local number: https://mentara.com/dial-in`;
  };

  const copyInvitationToClipboard = async (session) => {
    const invitationText = generateInvitationText(session);
    try {
      await navigator.clipboard.writeText(invitationText);
      setCopiedId(session.id);
      setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const renderSessionCard = (session) => (
    <div key={session.id} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex justify-between items-center">
      <div className="p-4 flex-grow flex items-center">
        <img
          src={session.user?.photo || 'https://via.placeholder.com/40'}
          alt={session.user?.name}
          className="w-10 h-10 rounded-full object-cover mr-4"
        />
        <div>
          <div className="flex justify-between items-start mb-2">
            <h3 className="text-lg font-semibold">{session.user?.name}</h3>
            <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(session.status)}`}>
              {session.status}
            </span>
          </div>
          <p className="text-sm text-gray-400 mb-2">{session.topic}</p>
          <p className="text-xs text-gray-300 mb-2 flex items-center space-x-1">
            <NotepadTextIcon className="w-3 h-3" /> {/* Adjust the size here */}
            <span>{session.prompt?.name} (v{session.prompt?.version})</span>
          </p>

          <p className="text-xs text-gray-500">
            {new Date(session.datetime).toLocaleString()}
          </p>
        </div>
      </div>
      <div className="bg-gray-700 p-4 flex space-x-2">
        <button
          onClick={() => handleJoinRoom(session.id)}
          className="p-1 bg-green-500 rounded hover:bg-green-600 transition-colors"
          title="Join Room"
        >
          <LogIn size={16} />
        </button>
        <button
          onClick={() => copyInvitationToClipboard(session)}
          className="p-1 bg-yellow-500 rounded hover:bg-yellow-600 transition-colors"
          title="Copy invitation"
        >
          {copiedId === session.id ? <Check size={16} /> : <Copy size={16} />}
        </button>
        <button
          onClick={() => handleEditClick(session.id)}
          className="p-1 bg-blue-500 rounded hover:bg-blue-600 transition-colors"
        >
          <Edit size={16} />
        </button>
        <button
          onClick={() => handleDeleteClick(session.id)}
          className="p-1 bg-red-500 rounded hover:bg-red-600 transition-colors"
        >
          <Trash2 size={16} />
        </button>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
        <span className="ml-2 text-lg">Loading sessions...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-10 bg-red-100 border border-red-300 rounded-lg">
        <p className="text-lg font-semibold">{error}</p>
        <button
          onClick={fetchSessions}
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
        >
          Retry
        </button>
      </div>
    );
  }

  if (editingSessionId) {
    return (
      <SessionEdit
        sessionId={editingSessionId}
        onSave={handleEditSave}
        onCancel={handleEditCancel}
      />
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold">Session Management</h2>
        <button
          onClick={() => handleEditClick('new')}
          className="px-4 py-2 bg-green-500 text-white rounded-lg flex items-center hover:bg-green-600 transition-colors"
        >
          <Plus className="mr-2" /> New Session
        </button>
      </div>

      <div className="flex justify-between items-center space-x-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search sessions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 pl-10 bg-gray-700 rounded-lg"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" />
        </div>
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="p-2 bg-gray-700 rounded-lg"
        >
          <option value="all">All Statuses</option>
          <option value="scheduled">Scheduled</option>
          <option value="in-progress">In Progress</option>
          <option value="completed">Completed</option>
          <option value="cancelled">Cancelled</option>
        </select>
        <div className="flex space-x-2">
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded-lg ${viewMode === 'list' ? 'bg-blue-500' : 'bg-gray-700'}`}
          >
            <ListIcon />
          </button>
          <button
            onClick={() => setViewMode('calendar')}
            className={`p-2 rounded-lg ${viewMode === 'calendar' ? 'bg-blue-500' : 'bg-gray-700'}`}
          >
            <CalendarIcon />
          </button>
        </div>
      </div>

      {viewMode === 'list' ? (
        <div className="space-y-4">
          {filteredSessions.map(renderSessionCard)}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SessionList;