import React, { useState, useEffect } from 'react';
import { Video, VideoOff, Mic, MicOff, Settings, ArrowRight } from 'lucide-react';
import Avatar from '../components/Avatar';
import Logo from '../components/Logo';
// import VADPreloader from '../../VadPreloader';

const PreSessionView = ({ sessionData, coach, onJoin, startCamera, stopCamera, startMic, stopMic, videoRef }) => {
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [vadReady, setVadReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsReady(true), 1000); // Increased to 3 seconds for demo
    return () => clearTimeout(timer);
  }, []);

  const toggleVideo = () => {
    if (isVideoOn) {
      stopCamera();
    } else {
      startCamera();
    }
    setIsVideoOn(!isVideoOn);
  };

  const toggleMic = () => {
    if (isMicOn) {
      stopMic();
    } else {
      startMic();
    }
    setIsMicOn(!isMicOn);
  };

  return (
    // <VADPreloader onVADReady={() => setVadReady(true)}>

    <div className="relative h-screen w-full bg-[#1B1130] overflow-hidden">
      {/* Background similar to landing page and user layout */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-900/20 to-pink-900/20"></div>
        <div className="absolute -top-1/4 -left-1/4 w-1/2 h-1/2 bg-purple-600/20 rounded-full animate-pulse"></div>
        <div className="absolute top-3/4 -right-1/4 w-1/2 h-1/2 bg-purple-800/20 rounded-full animate-pulse" style={{ animationDelay: '1s' }}></div>
      </div>

      <div className="relative z-10 h-full flex flex-col items-center justify-center p-8">
        <div className="absolute top-8 left-8">
          <Logo text='Mentara' textColor='text-[#E7C6EF]' />
        </div>

        {!isReady ? (
          <div className="text-center">
            <h2 className="text-2xl font-bold text-[#E7C6EF] mb-4">Preparing Your Session</h2>
            <div className="inline-block">
              <svg className="animate-spin h-8 w-8 text-[#E7C6EF]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </div>
        ) : (
          <div className={`transition-opacity duration-1000 ${isReady ? 'opacity-100' : 'opacity-0'}`}>
            <div className="mb-8 text-center">
              <h1 className="text-4xl font-bold text-[#E7C6EF] mb-2">{sessionData.topic}</h1>
              <p className="text-xl text-[#A78BFA]">Your AI Coach {coach.name} is ready for you</p>
            </div>

            <div className="flex space-x-8 mb-12">
              <div className="w-64 h-64 bg-[#2A1F45] rounded-lg overflow-hidden shadow-lg">
                {isVideoOn ? (
                  <video ref={videoRef} autoPlay muted className="w-full h-full object-cover" />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-[#3D2E5E] to-[#2A1F45]">
                    <Avatar
                      photo={sessionData.user?.photo?.replace(/s\d+-c/, 's400')}
                      audioLevel={0}
                      isHost={false}
                      letter={sessionData.user?.name?.charAt(0)}
                      className="w-32 h-32"
                    />
                  </div>
                )}
              </div>
              <div className="w-64 h-64 bg-[#2A1F45] rounded-lg overflow-hidden shadow-lg flex items-center justify-center">
                <Avatar
                  photo={coach.avatar}
                  audioLevel={0}

                  isHost={true}
                  letter={coach.name.charAt(0)}
                  className="w-48 h-48"
                />
              </div>
            </div>

            <div className="flex justify-center space-x-4 mb-8">
              <button
                onClick={() => { }}
                disabled={true}
                className={`p-4 rounded-full transition-colors duration-300 ${isVideoOn ? 'bg-[#3D2E5E] hover:bg-[#4D3E6E]' : 'bg-[#2A1F45] hover:bg-[#3A2F55]'}`}
              >
                {isVideoOn ? <Video size={24} className="text-[#E7C6EF]" /> : <VideoOff size={24} className="text-[#E7C6EF]" />}
              </button>
              <button
                onClick={() => { }}
                disabled={true}

                className={`p-4 rounded-full transition-colors duration-300 ${isMicOn ? 'bg-[#3D2E5E] hover:bg-[#4D3E6E]' : 'bg-[#2A1F45] hover:bg-[#3A2F55]'}`}
              >
                {isMicOn ? <Mic size={24} className="text-[#E7C6EF]" /> : <MicOff size={24} className="text-[#E7C6EF]" />}
              </button>
              <button className="p-4 rounded-full bg-[#2A1F45] hover:bg-[#3A2F55] transition-colors duration-300"
                disabled={true}
              >
                <Settings size={24} className="text-[#E7C6EF]" />
              </button>
            </div>

            <button
              onClick={onJoin}
              className="flex items-center justify-center w-64 mx-auto bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white font-bold py-3 px-6 rounded-full text-lg transition duration-300 group shadow-lg hover:shadow-xl"
            >
              Join Session
              <ArrowRight size={20} className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
            </button>
          </div>
        )}
      </div>
    </div>
    /* </VADPreloader> */
  );
};

export default PreSessionView;