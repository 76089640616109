import React, { useState, useEffect } from 'react';
import { dbUtils } from '../../../../services/core/adminService';
import { Save, X, Loader, Upload, Plus, Minus } from 'lucide-react';
import { storageUtils } from '../../../../services/core/fileStorageService'; // For image upload

const UserEdit = ({ userId, onSave, onCancel }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        if (userId === 'new') {
          setUser({
            name: '',
            email: '',
            photo: '',
            data: {
              age: '',
              gender: '',
              role: '',
              currentCompany: '',
              goalOrientation: '',
              background: '',
              currentChallenge: '',
              position: '',
              industry: '',
              professionalBackground: '',
              maritalStatus: '',
            },
          });
        } else {
          const data = await dbUtils.users.getProfile(userId);
          setUser(data);
        }
      } catch (err) {
        setError('Failed to load user');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploadingAvatar(true);
        const extension = file.name.split('.').pop();
        const fileUrl = await storageUtils.saveFile(file, extension, `image/${extension}`);
        setUser({ ...user, photo: fileUrl });
      } catch (err) {
        console.error('Failed to upload avatar:', err);
      } finally {
        setUploadingAvatar(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (userId === 'new') {
        await dbUtils.users.create(user);
      } else {
        await dbUtils.users.updateProfile(userId, user);
      }
      onSave();
    } catch (err) {
      setError('Failed to save user');
      console.error(err);
    }
  };

  if (loading) return <div className="flex justify-center items-center h-64"><Loader className="animate-spin" /></div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;
  if (!user) return <div className="text-center py-4">User not found</div>;

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-gray-800 p-6 rounded-lg">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">{userId === 'new' ? 'Create New User' : 'Edit User'}</h2>
        <div className="space-x-2">
          <button type="button" onClick={onCancel} className="px-4 py-2 bg-gray-600 rounded-lg">
            <X className="inline-block mr-1" /> Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-green-500 rounded-lg">
            <Save className="inline-block mr-1" /> Save
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Name</label>
          <input
            type="text"
            value={user.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Email</label>
          <input
            type="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            className="w-full p-2 bg-gray-700 rounded"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Avatar</label>
          <div className="flex items-center space-x-4">
            <input
              type="text"
              value={user.photo}
              onChange={(e) => setUser({ ...user, photo: e.target.value })}
              className="w-full p-2 bg-gray-700 rounded"
            />
            <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
              <Upload className="mr-2" />
              {uploadingAvatar ? 'Uploading...' : 'Upload Image'}
              <input type="file" onChange={handleAvatarUpload} className="hidden" accept="image/*" />
            </label>
          </div>
          {user.photo && <img src={user.photo} alt="Avatar" className="w-16 h-16 rounded-full mt-2" />}
        </div>
      </div>

      <div className="space-y-4 mt-6">
        <h3 className="text-lg font-semibold">Personalization Data</h3>
        {Object.entries(user.data).map(([key, value]) => (
          <div key={key}>
            <label className="block text-sm font-medium mb-1">{key}</label>
            <input
              type="text"
              value={value}
              onChange={(e) => setUser({ ...user, data: { ...user.data, [key]: e.target.value } })}
              className="w-full p-2 bg-gray-700 rounded"
            />
          </div>
        ))}
      </div>
    </form>
  );
};

export default UserEdit;
