import { createContext, useEffect, useCallback, useState, useRef } from 'react';

export const AppStateContext = createContext();
const LOCAL_STORAGE_KEY = 'mentara_localdb';

const saveToLocalStorage = (data) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};

const loadFromLocalStorage = () => {
  try {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : null;
  } catch (error) {
    console.error('Error loading from local storage:', error);
    return null;
  }
};


export const AppStateProvider = ({ children }) => {

  // const [context, setContext] = useState(() => {

  //   const savedState = loadFromLocalStorage();
  //   return savedState || {
  //     user: {
  //       name: '',
  //       demographics: {
  //         age: null, gender: '', ethnicity: '', nationality: '',
  //         languages: [], education: '', occupation: '', income_bracket: '',
  //       },
  //       personality: {
  //         mbti_type: '',
  //         big_five_traits: {
  //           openness: null, conscientiousness: null, extraversion: null,
  //           agreeableness: null, neuroticism: null,
  //         },
  //         learning_style: '', communication_preference: '',
  //         decision_making_style: '', risk_tolerance: '', adaptability: '',
  //       },
  //       health: {
  //         physical_health_status: '', mental_health_status: '', stress_level: null,
  //         sleep_quality: '', diet_type: '', exercise_frequency: '',
  //         chronic_conditions: [],
  //       },
  //       goals: {
  //         short_term: [], long_term: [], life_aspirations: [],
  //         current_focus_areas: [],
  //       },
  //       interests: {
  //         hobbies: [], passions: [], skills: [], areas_of_expertise: [],
  //         preferred_learning_topics: [],
  //       },
  //       values: {
  //         core_values: [], belief_system: '', cultural_background: '',
  //         important_life_events: [],
  //       },
  //       relationships: {
  //         relationship_status: '', family_structure: '', social_circle_size: '',
  //         support_system_quality: '', work_relationship_quality: '',
  //       },
  //       digital_behavior: {
  //         preferred_platforms: [], online_activity_level: '',
  //         privacy_concerns: '', technology_adoption_speed: '',
  //       },
  //       physiological: {
  //         heart_rate: null, blood_pressure: '', body_temperature: null,
  //         sleep_data: { hours_slept: null, sleep_quality: '' },
  //         activity_level: '', hydration_level: '', hunger_level: '',
  //         pain_level: '', fatigue_level: '',
  //       },
  //       emotional: {
  //         current_mood: '', emotional_valence: '', emotional_arousal: '',
  //         recent_emotional_triggers: [], emotional_regulation_state: '',
  //         confidence_level: '', motivation_level: '',
  //       },
  //       cognitive: {
  //         attention_level: '', cognitive_load: '', current_focus: '',
  //         distractions: [], recent_learnings: [], problem_solving_state: '',
  //         creativity_level: '', memory_recall_quality: '',
  //       },
  //       social: {
  //         recent_social_interactions: [], upcoming_social_events: [],
  //         social_media_activity: '', group_dynamics: '', social_roles: [],
  //         cultural_events: [],
  //       },
  //       professional: {
  //         career_stage: '', job_satisfaction: '', work_life_balance: '',
  //         recent_work_challenges: [], professional_goals: [],
  //         skill_development_areas: [], networking_opportunities: [],
  //       },
  //       financial: {
  //         financial_health: '', recent_financial_decisions: [], financial_goals: [],
  //         financial_stressors: [], investment_strategy: '', risk_profile: '',
  //       },
  //       technological: {
  //         devices_in_use: [], app_usage_patterns: [], digital_literacy_level: '',
  //         recent_tech_issues: [], tech_preferences: '',
  //       },
  //       content_consumption: {
  //         recently_read_books: [], watched_movies: [], listened_podcasts: [],
  //         news_sources: [], learning_resources: [],
  //       },
  //       external_events: {
  //         global_news_impact: [], local_community_events: [], industry_trends: [],
  //         economic_factors: [], political_climate: '',
  //       },
  //       coaching_specific: {
  //         coaching_history: [], preferred_coaching_methods: [],
  //         resistance_areas: [], previous_breakthroughs: [],
  //         habit_formation_progress: [], accountability_measures: [],
  //       },
  //     },
  //     conversation: {
  //       history: [], current_stage: '', overall_tone: '',
  //       user_engagement_level: '', ai_persona: '', coaching_style: '',
  //       session_goals: [], unresolved_topics: [], breakthroughs: [], setbacks: [],
  //     },
  //     temporal: {
  //       timestamp: '', user_local_time: '', day_of_week: '', season: '',
  //       user_calendar_events: [],
  //       significant_dates: { personal: [], cultural: [], professional: [] },
  //       user_chronotype: '', time_since_last_interaction: '',
  //     },
  //     environmental: {
  //       location: { type: '', specifics: '', cultural_context: '' },
  //       weather: { condition: '', temperature: null, humidity: null },
  //       noise_level: '', light_condition: '', air_quality: '',
  //       surrounding_activity_level: '',
  //     },
  //     ui: {
  //       mood: '',
  //       theme: 'light',
  //       fontSize: 'medium',
  //       current_uploaded_image: null,
  //       demo_mode: false,
  //       // Add more UI-related state as needed
  //     },
  //     ai: {
  //       //model: 'llama-3.1-70b-versatile',

  //       model: 'gpt-4o',
  //       persona: {
  //         name: 'steve jobs',
  //         bio: 'the late ceo and co-founder of Apple'
  //       },
  //     }
  //   }
  // })

  const availableVoices = [
    { id: '79a125e8-cd45-4c13-8a67-188112f4dd22', name: 'Default Voice' },
    { id: '6ef8d14c-3c90-472e-ab88-f0cfa3acdc17', name: 'Michael Siebel' },
    { id: '0faa49fe-f301-4628-bbe6-23b4574eaeba', name: 'Steve Jobs' },
    { id: 'd21bac18-fa21-48eb-a347-0f7dd76d0bf4', name: 'Steve Wozniak' },
    { id: 'a5a1089f-55db-497e-84aa-b2788f91dda0', name: 'Joe Rogan' },
    { id: 'db5941ab-7e97-44ce-9a92-1dd5633ca8ec', name: 'Simon Sinek' },
    { id: '0f970d65-17fd-4585-b5ab-c7cdb5e186e8', name: 'Tony Robbins' },

  ]

  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());
  const audioSourceRef = useRef(null);


  // useEffect(() => {
  //   saveToLocalStorage(context);
  // }, [context]);


  // useEffect(() => {
  //   updateDateTime();
  //   fetchLocationAndWeather();

  //   // Update date and time every minute
  //   const dateTimeInterval = setInterval(updateDateTime, 60000);

  //   // Update location and weather every hour
  //   const weatherInterval = setInterval(fetchLocationAndWeather, 3600000);

  //   return () => {
  //     clearInterval(dateTimeInterval);
  //     clearInterval(weatherInterval);
  //   };
  // }, []);

  const updateDateTime = () => {
    const now = new Date();
    setContext(prevState => ({
      ...prevState,
      environmental: {
        ...prevState.environmental,
        dateTime: now.toISOString(),
      },
    }));
  };

  const fetchLocationAndWeather = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();

      setContext(prevState => ({
        ...prevState,
        environmental: {
          ...prevState.environmental,
          weather: {
            temperature: data.latitude ? (data.latitude > 0 ? 25 : 10) : null, // Rough estimate based on hemisphere
            description: "Data not available",
          },
          city: data.city,
          country: data.country_name,
          timezone: data.timezone,
        },
      }));
    } catch (error) {
      console.error("Error fetching location and weather data:", error);
    }
  };




  return (
    <AppStateContext.Provider value={{ audioContextRef, audioSourceRef, availableVoices }}>
      {children}
    </AppStateContext.Provider>
  );
};

