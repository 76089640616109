import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { dbUtils } from '../../../services/core/dbService';
import CreateSessionModal from './CreateSessionModal';
import { Calendar, Clock, TrendingUp, Target, Play, Plus, ChevronRight } from 'lucide-react';

const UserHomePage = () => {
  const { user } = useAuth();
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUpcomingSessions();
  }, []);

  const fetchUpcomingSessions = async () => {
    try {
      setLoading(true);
      const sessions = await dbUtils.sessions.getUpcoming(user.id);
      setUpcomingSessions(sessions.slice(0, 3)); // Get only the next 3 upcoming sessions
    } catch (error) {
      console.error('Error fetching upcoming sessions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateSession = (newSession) => {
    setUpcomingSessions([...upcomingSessions, newSession]);
    navigate(`/session/room/${newSession?.id}`);

  };

  return (
    <div className="min-h-screen bg-[#231639] text-white p-8">
      <div className="max-w-6xl mx-auto">
        {/* Hero Section */}
        <div className="relative h-[400px] mb-12">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover"
            autoPlay
            loop
            muted
            src="https://fdlzsjkpvowbwuxwhnvn.supabase.co/storage/v1/object/public/user_media/hyper-journey.mp4?t=2024-11-02T13%3A00%3A50.565Z"

          />
          <div className="absolute top-0 left-0 w-full h-full bg-[#1C1232] bg-opacity-70"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-center">
            <h1 className="text-5xl font-bold text-[#E7C6EF] mb-4">Welcome back, {user.name}!</h1>
            <p className="text-xl text-gray-300 mb-6">Ready to continue your growth journey?</p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="py-3 px-6 bg-[#E7C6EF] text-[#1C1232] font-semibold rounded-lg hover:bg-[#d1aad9] transition-all duration-200"
            >
              Start a New Session
            </button>
          </div>
        </div>

        {/* Upcoming Sessions Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-6">Upcoming Sessions</h2>
          {loading ? (
            <p>Loading upcoming sessions...</p>
          ) : upcomingSessions.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {upcomingSessions.map((session) => (
                <div key={session.id} className="bg-[#2A1F45] p-6 rounded-xl shadow-lg">
                  <h3 className="text-xl font-semibold mb-2">{session.topic}</h3>
                  <p className="text-gray-300 mb-4">{session.description}</p>
                  <div className="flex items-center text-sm text-gray-300 mb-4">
                    <Calendar className="w-4 h-4 mr-2" />
                    <span>{new Date(session.datetime).toLocaleDateString()}</span>
                    <Clock className="w-4 h-4 ml-4 mr-2" />
                    <span>{new Date(session.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                  </div>
                  <button
                    onClick={() => navigate(`/session/room/${session.id}`)}
                    className="w-full py-2 bg-[#3D2E5E] text-white rounded-lg hover:bg-[#4D3E6E] transition-all duration-200 flex items-center justify-center"
                  >
                    <Play size={16} className="mr-2" />
                    Join Session
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p>No upcoming sessions. Why not schedule one?</p>
          )}
          {upcomingSessions.length > 0 && (
            <button
              onClick={() => navigate('/user/sessions')}
              className="mt-6 text-[#E7C6EF] hover:text-white transition-colors duration-200 flex items-center"
            >
              View all sessions
              <ChevronRight size={20} className="ml-1" />
            </button>
          )}
        </div>

        <CreateSessionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSessionCreated={handleCreateSession}
        />
      </div>
    </div>
  );
};

export default UserHomePage;