import React, { useState } from 'react';
import Avatar from '../components/Avatar';
import { motion } from 'framer-motion';
import Card from '../components/Card';
import LoginButton from '../components/LoginButton';
import SessionRoomPage from './SessionRoomPage';
import Logo from '../components/Logo';
import { withLinkedInRedirect } from '../../hooks/withLinkedInRedirect';
import BundlePricingModal from './BundlePricingModal';

const faqs = [
  {
    question: "What is Mentara?",
    answer: "We're building an AI founder support system that combines coaching, training, and mentoring. Think of it as your always-available companion for founder challenges, both professional and personal.",
  },
  {
    question: "Why do founders need coaching?",
    answer: "Just like elite athletes, founders perform under intense pressure and need consistent, high-quality coaching to excel. Traditional coaching is often expensive and limited by individual perspectives. We're changing that by making premium coaching accessible 24/7 with diverse expertise.",
  },
  {
    question: "What topics do you cover?",
    answer: "We cover crucial founder challenges, including Investor Pitch Practice, Co-Founder Foundations, Business Model Canvas, Cap Table Simulations, and Goal Setting & Tracking.",
  },
  {
    question: "How are you rolling this out?",
    answer: "We're starting with the YC Interview Simulator as our first tool. Based on founder feedback, we will release more features like co-founder dynamics and customer conversation training.",
  },
  {
    question: "How can I get support?",
    answer: "Drop us a line at founders@mentara.io or join our Discord community for direct interactions with us and other founders.",
  },
  {
    question: "What's the YC Interview Simulator?",
    answer: "It's a conversational AI tool that runs realistic 10-minute interview sessions, pushing you on metrics, challenging vague answers, and helping you sharpen your pitch with instant feedback.",
  },
];

const FAQSection = () => {
  return (
    <div className="max-w-5xl mx-auto mt-16 px-6 md:px-12">
      <h2 className="text-3xl md:text-4xl font-bold text-[#E7C6EF] mb-8 text-center">
        Frequently Asked Questions
      </h2>
      <div className="space-y-6">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="p-6 bg-[#2A1F45] rounded-lg shadow-md hover:bg-[#3D2E5E] transition-colors duration-300"
          >
            <h3 className="text-xl font-semibold text-[#E7C6EF] mb-2">{faq.question}</h3>
            <p className="text-base text-gray-300">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const LandingPage = () => {
  return (
    <div className="min-h-screen w-full bg-[#0F0F1A] text-white font-sans overflow-hidden relative">
      {/* Dynamic Background */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-900/20 to-pink-900/20"></div>
      </div>


      {/* Header */}
      <nav className="relative z-10 flex justify-between items-center p-8">
        <Logo className="mr-4" />
        <div className="flex space-x-8 text-lg">
          <a href="#pricing" className="text-gray-300 hover:text-white transition duration-300">Pricing</a>
          <a href="#contact" className="text-gray-300 hover:text-white transition duration-300">Contact</a>
        </div>
      </nav>

      {/* Main Content */}
      <main className="relative z-10 flex flex-col items-center justify-center text-center mt-16 px-4">
        <BundlePricingModal mode='page' />

        {/* <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">
          Experience Founder Coaching
        </h1>
        <p className="text-xl text-gray-300 mb-8">
          Practice investor pitches, roleplay tough conversations, and set clear goals with your <span className="font-bold">AI coach</span>.
        </p> */}

        {/* FAQ Section */}
        <FAQSection />

        <div className="mt-12">
          <button
            onClick={() => document.getElementById('login-button').click()}
            className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-6 py-3 rounded-full font-bold hover:opacity-90 transition duration-300"
          >
            Start Your Interview Here
          </button>
          <a
            href="mailto:founders@mentara.io"
            className="ml-4 inline-block broder border-white text-white px-6 py-3 rounded-full font-bold hover:opacity-90 transition duration-300 text-center"
          >
            Drop us a message
          </a>


          <LoginButton className='hidden' />

        </div>
      </main>

      {/* Footer */}
      <footer className="relative z-10 flex flex-col items-center mt-24 py-8 border-t border-gray-700 bg-[#1F1F2E]">
        <div className="flex space-x-6 mb-6">
          <a href="https://x.com/MentaraCoach" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">Twitter</a>
          <a href="https://www.linkedin.com/company/mentaracoach" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">LinkedIn</a>
          <a href="https://discord.gg/XypbU6XP" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">Discord</a>
        </div>
        <div className="flex flex-col md:flex-row text-sm items-center space-y-4 md:space-y-0 md:space-x-6">
          <a href="/legal/privacy-policy.html" className="text-gray-300 hover:text-white">Privacy Policy</a>
          <a href="/legal/terms-and-conditions.html" className="text-gray-300 hover:text-white">Terms and Conditions</a>
          <a href="mailto:founders@mentara.io" className="text-gray-300 hover:text-white">Contact Us</a>
        </div>
        <p className="text-sm text-gray-500 mt-4">© 2024 Mentara. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default withLinkedInRedirect(LandingPage);
