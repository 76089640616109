import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Clock, CheckCircle, ArrowRight, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import FaceLogin from '../components/FaceLogin';
import { dbUtils } from '../../services/core/dbService';

const SessionLandingPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        setLoading(true);
        const data = await dbUtils.sessions.getById(id);
        setSessionData(data);
      } catch (error) {
        console.error(error);
        setError('Error fetching session data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
  }, [id]);

  const handleLogin = (user) => {
    if (user.label !== 'unknown') {
      setIsLoggedIn(true);
      // setTimeout(() => {
      //   navigate('/call-screen');
      // }, 2000);
    }
  };

  const startSession = () => {
    if (isLoggedIn) {
      navigate(`/session/room/${id}`, {
        state: { sessionData }

      });
    } else {
      alert('Please log in with face recognition to start the session.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center bg-gray-900 text-white">
        <p>Loading session data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center bg-gray-900 text-white">
        <div className="text-center">
          <AlertCircle className="mx-auto text-red-500 mb-4" size={48} />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-gray-900 via-purple-900 to-black flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-xl shadow-2xl p-4 sm:p-6 md:p-8 text-white">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mb-6 lg:mb-8">
          <div className="mb-4 lg:mb-0">
            <h1 className="text-3xl sm:text-4xl font-bold mb-2">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                Mentara
              </span>{' '}
              Coaching Session
            </h1>
            <p className="text-lg sm:text-xl text-gray-300">{sessionData?.topic}</p>
          </div>
          <div className="flex items-center">
            <Clock className="mr-2 text-purple-400" />
            <span>{sessionData?.duration}</span>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-6 mb-8">
          <div>
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">Session Objectives</h2>
            <ul className="space-y-2">
              {sessionData?.objectives.map((objective, index) => (
                <li key={index} className="flex items-start">
                  <CheckCircle className="mr-2 text-green-400 flex-shrink-0 mt-1" />
                  <span className="text-sm sm:text-base">{objective}</span>
                </li>
              ))}
            </ul>

            <div className="mb-8 mt-12 flex items-center bg-gray-700 bg-opacity-50 rounded-lg p-4 sm:p-6">
              <img
                src={sessionData?.coach.avatar}
                alt={sessionData?.coach.name}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div>
                <h3 className="text-lg sm:text-xl font-semibold mb-2">
                  Your AI Coach: {sessionData?.coach.name}
                </h3>
                <p className="text-gray-300 text-sm sm:text-base">{sessionData?.coach.bio}</p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">Face Login</h2>
            <FaceLogin onLogin={handleLogin} user={sessionData?.user} userImage={sessionData?.userImage} />
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={startSession}
            className={`bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-full text-lg sm:text-xl transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg flex items-center ${!isLoggedIn && 'opacity-50 cursor-not-allowed'
              }`}
            disabled={!isLoggedIn}
          >
            Start Your Session <ArrowRight className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionLandingPage;
