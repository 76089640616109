import Groq from "groq-sdk";
import OpenAI from 'openai';
import { GoogleGenerativeAI, SchemaType } from "@google/generative-ai";
import { CoachAgent, coachingSchema } from "../services/ai/CoachAgent";
import { generateEmbedding, retrieveContext } from "../services/ai/MemoryRAG";
import instructions from "./prompt";

const groq = new Groq({ apiKey: process.env.REACT_APP_GROQ_API_KEY, dangerouslyAllowBrowser: true });
const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

const generatePrompt = (config) => {
  const { userInput, context, uiFunctions, session } = config;
  const { text: currentTextMessage, images, isVideoOn, isScreenSharing } = userInput;
  const { history } = context;
  const { setConversationState, speakWords } = uiFunctions;

  const cards = session.cards
  const topics = session.room_configuration?.topics || []
  console.log(topics)
  console.log(session)

  let promptParts = [session.session_prompt];



  // Check if recall_memories tool is available
  if (config.tools.includes('recall_memories') &&
    history.length != 0) {
    promptParts.push(instructions.recall_memories);
  }

  // Check if load_card tool is available and cards exist
  if (config.tools.includes('load_card') && cards && cards.length > 0) {
    promptParts.push(instructions.load_cards(cards));
  }

  // Include camera vision instructions if video is on
  if (isVideoOn) {
    promptParts.push(instructions.camera_vision(isVideoOn));
  }

  // Include screenshare vision instructions if screen is being shared
  if (isScreenSharing) {
    promptParts.push(instructions.screenshare_vision(isScreenSharing));
  }

  if (session.room_configuration?.preSelectedTool && history.length == 0) {
    promptParts.push(instructions.preselected_tool(session.room_configuration?.preSelectedTool));

  }

  //TODO-TOPICS: ADD SELECTED TOPICS


  promptParts.push(instructions.default)

  // Join all prompt parts
  let prompt2 = promptParts.join('\n\n');

  // Create the topics string
  let topicsString = '';
  if (topics.length > 0) {
    topicsString = topics?.map(topic => {
      const promptsString = topic.prompt?.join('\n');
      return `Topic:${topic.title}\nQuestions:\n${promptsString}`;
    }).join('\n\n');
  }

  // Replace [TOPICS] in the session prompt with the topics string
  prompt2 = prompt2.replace('[TOPICS]', topicsString);



  return prompt2;
}

export async function llmCall(config) {
  const { userInput, context, uiFunctions, session } = config;
  const { text: currentTextMessage, images, isVideoOn, isScreenSharing } = userInput;
  const { history } = context;
  const { setConversationState, speakWords } = uiFunctions;
  const { user, cards, configuration } = session;

  const tools = [

    {
      type: "function",
      function: {
        name: "recall_memories",
        parameters: {
          type: "object",
          required: ["query"],
          properties: {
            query: {
              type: "string",
              description: "The topic or question to recall memories about"
            }
          }
        },
        description: "Recalls relevant memories or information from past interactions"
      }
    },
    {
      type: "function",
      function: {
        name: "query_knowledge",
        parameters: {
          type: "object",
          required: ["query"],
          properties: {
            query: {
              type: "string",
              description: "The topic or question you need to get more info and nuggets on coaching"
            }
          }
        },
        description: "Query your knoweledege databas of coaching"
      }
    },
    {
      type: "function",
      function: {
        name: "recall_lastsession",
        parameters: {
        },
        description: "Recalls the summary of the last session to personalize and contextualize responses"
      }
    },
    {
      type: "function",
      function: {
        name: "end_call",
        parameters: {
          type: "object",
          required: ["conclusion"],
          properties: {
            conclusion: {
              type: "string",
              description: "The conclusion and action items for next session"
            }
          }
        },
        description: "Ends the call"
      }
    },
    {
      type: "function",
      function: {
        name: "generate_card",
        parameters: {
          type: "object",
          required: ["brief"],
          properties: {
            brief: {
              type: "string",
              description: "The brief about the card to be generated"
            }
          }
        },
        description: "Generate an interactive card for the user to help with their goal or state"
      }
    },
    {
      type: "function",
      function: {
        name: "start_roleplay",
        parameters: {
        },
        description: "Start the interactive roleplay"
      }
    },
    {
      type: "function",
      function: {
        name: "end_roleplay",
        parameters: {
        },
        description: "Ends the interactive roleplay"
      }
    },
    {
      type: "function",
      function: {
        name: "end_roleplay",
        parameters: {
        },
        description: "Ends the interactive roleplay"
      }
    },
    {
      type: "function",
      function: {
        name: "load_card",
        parameters: {
          type: "object",
          required: ["card_id"],
          properties: {
            card_id: {
              type: "string",
              description: "The card id to load"
            }
          }
        },
        description: "loads an existing card into the screen for the user"
      }
    },
    {
      type: "function",
      function: {
        name: "update_card",
        parameters: {
          type: "object",
          required: ["key", "value"],
          properties: {
            key: {
              type: "string",
              description: "The key of the card data to update"
            },
            value: {
              type: "string",
              description: "The new value for the specified key"
            }
          }
        },
        description: "Updates the current card data with the given key and value"
      }
    },
    {
      type: "function",
      function: {
        name: "web_search",
        parameters: {
          type: "object",
          required: ["query"],
          properties: {
            query: {
              type: "string",
              description: "The search query"
            }
          }
        },
        description: "Search the web for relevant information"
      }
    }
  ];

  const selectedTools = tools.filter(tool =>
    config.tools?.includes(tool.function.name)
  );

  const preselected_tool = session.room_configuration?.preSelectedTool

  console.log(selectedTools)


  const imageMessages = images.map(image => ({
    type: "image_url",
    image_url: { url: `${image}` }
  }))

  const filteredMessages = history.filter(message => message.content !== null);

  let llm = images.length === 0 ? groq : openai;
  let llm_model = images.length === 0 ? 'llama-3.2-11b-vision-preview' : 'gpt-4o';
  // override
  llm = openai
  llm_model = 'gpt-4o'

  const prompt2 = generatePrompt(config)
  //console.log(prompt2)

  const completion = await llm.chat.completions.create({
    model: llm_model,
    messages: [
      { role: "system", content: prompt2 },
      ...filteredMessages,
      {
        role: "user",
        content: imageMessages.length === 0 ? currentTextMessage : [
          { type: "text", text: currentTextMessage },
          ...imageMessages
        ],
      },
    ],
    tools: selectedTools,
    tool_choice: preselected_tool && history.length == 0 ? 'required' : "auto",
  });

  const responseMessage = completion.choices[0].message;
  const toolCalls = responseMessage.tool_calls || [];
  let outputResponseType = 'text'
  let finalResponse = responseMessage.content;
  let generatedContent = null;


  if (toolCalls.length > 0) {

    // speak filler words for processing
    let currentTool = toolCalls[0]
    let currentAction = currentTool.function?.functionName
    const fillerWords = await generateFillerWords(history, user, currentAction)

    if (fillerWords)
      speakWords(fillerWords);


    let toolMessages = filteredMessages;

    for (const toolCall of toolCalls) {
      const { name: functionName, arguments: functionArgs } = toolCall.function;
      const parsedArgs = JSON.parse(functionArgs);

      let functionResponse;
      let functionPrompt;

      if (functionName === 'generate_card') {
        setConversationState('generating');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `Incorporate the generated content into your response, maintaining the conversation flow . 
            If it's about generated content or interactive card, mention it's visible on the screen without discussing hyperlinks.
            if no speical response needed, just carry on with the flow ofthe conversation normally .`
      }
      if (functionName === 'recall_memories') {
        setConversationState('recalling memories');
        outputResponseType = 'text'
        functionPrompt = `Incorporate the retrieved memories from the user memory into your response, maintaining the conversation flow. 
        If you don't find the memory related do not incorporate it. Address the user directly.
        `;
      }
      if (functionName === 'query_knowledge') {
        setConversationState('thinking');
        outputResponseType = 'text'
        functionPrompt = `Incorporate the retrieved knoweledge nuggets and facts into your response, maintaining the conversation flow. 
        Make sure your response is coheret with previous messages and doesn't deter from the conversation.
        `;
      }
      if (functionName === 'recall_lastsession') {
        setConversationState('recalling last session');
        outputResponseType = 'text'
        functionPrompt = `Incorporate the retrieved last session summary from the user memory into your response, maintaining the conversation flow.
        and make sure to respond relevant to the current's session topic ${session.topic} 
        If you don't find the memory related do not incorporate it. Address the user directly.
        `;
      }
      if (functionName === 'web_search') {
        setConversationState('searching');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `Incorporate the generated content into your response, maintaining the conversation flow . 
            If it's about generated content or interactive card, mention it's visible on the screen without discussing hyperlinks.
            if no speical response needed, just carry on with the flow ofthe conversation normally .`
      }
      if (functionName === 'end_call') {
        setConversationState('closing');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `Close the session in a nice way and ask them to fill the survey and tell them what to prepare for next session. `
      }
      if (functionName === 'start_roleplay') {
        setConversationState('starting roleplay');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `Inform the user that you have invited the ${session.roleplay?.name || 'character'} to start the roleplay and 
        motivate them and tell them you will be back after the roleplay ends. Till them once they are ready they can click on start roleplay on the screen`
      }
      if (functionName === 'load_card') {
        setConversationState('loading card');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `
        Incorporate the loaded content into your response, maintaining the conversation flow .
            If it's about loadaed content or interactive card, mention it's visible on the screen without discussing hyperlinks.
            if no speical response needed, just carry on with the flow ofthe conversation normally .
        `
      }
      if (functionName === 'update_card') {
        setConversationState('updating info');         //CHANGE OF STATE: 
        outputResponseType = 'text'
        functionPrompt = `${prompt2}
        Incorporate the updated data into your response, maintaining the conversation flow .
            if no special response needed, just carry on with the flow ofthe conversation normally .
        `
      }

      if (functionName === 'end_roleplay') {
        setConversationState('ending roleplay');         //CHANGE OF STATE: 
        outputResponseType = 'card'
        functionPrompt = `Say goodbye the the user and inform them that ${session.coach?.name} will be here back to give them feedback`
      }

      switch (functionName) {
        case 'recall_memories':
          const embedding = await generateEmbedding(parsedArgs.query);
          functionResponse = await retrieveContext(embedding, user.id);
          break;
        case 'query_knowledge':
          const kbEmbedding = await generateEmbedding(parsedArgs.query);
          functionResponse = await retrieveContext(kbEmbedding, user.id);
          break;

        case 'recall_lastsession':
          functionResponse = await config.uiFunctions.getLastSessionSummary();
          break;
        // case 'web_search':
        //   functionResponse = await webSearch(parsedArgs.query);
        //   break;
        case 'end_call':
          functionResponse = await config.uiFunctions.endCall(parsedArgs.brief);
          break;
        case 'start_roleplay':
          functionResponse = await config.uiFunctions.startRoleplay();
          break;
        case 'load_card':
          functionResponse = await config.uiFunctions.loadCard(parsedArgs.card_id);
          break;
        case 'update_card':
          functionResponse = await config.uiFunctions.updateCardData(parsedArgs.key, parsedArgs.value);
          break;
        case 'end_roleplay':
          functionResponse = await config.uiFunctions.endRoleplay();
          break;
        case 'generate_card':
          functionResponse = await config.uiFunctions.generateCard(parsedArgs.brief);
          break;
        default:
          console.warn(`Unknown function: ${functionName}`);
          continue;
      }

      generatedContent = functionResponse;

      if (functionResponse == null) {
        outputResponseType = 'none'
        return { generatedContent, finalResponse, outputResponseType };
      }

      toolMessages.push({
        role: "function",
        name: functionName,
        content: JSON.stringify(functionResponse),
      });

      const secondResponse = await llm.chat.completions.create({
        model: llm_model,
        messages: [
          ...toolMessages,
          {
            role: "system",
            content: functionPrompt
          }
        ]
      });

      finalResponse = secondResponse.choices[0].message.content;
    }
  }


  return { generatedContent, finalResponse, outputResponseType };
}

export async function generateFillerWords(history, user, action) {
  try {
    const filteredMessages = history.filter(message => message.content !== null);
    if (filteredMessages.length == 0)
      return 'give me a moment please'
    // override
    let llm = groq
    let llm_model = 'llama-3.1-70b-versatile'

    const systemPrompt = `
You are a conversationalist you understand the nuance of human
conversations your objective is to generate a human-like
filler sentence for the AI in the exchange below so the AI can use
while their processing their next output or action.

Current Action AI will do: ${action}

Objective: Return a filler sentence or word that flows with the current conversation and is hyper personalized

If no history of conversation just return "wait for a moment please"

User Context:
${JSON.stringify(user)}
`;

    const completion = await llm.chat.completions.create({
      model: llm_model,
      messages: [
        { role: "system", content: systemPrompt },
        ...filteredMessages,
        {
          role: "user",
          content: 'generate filler word for AI to utter based on the conversation history. Only return the words and no explainations.'

        },
      ],

    });

    const responseMessage = completion.choices[0].message;
    let finalResponse = responseMessage.content;



    return finalResponse
  } catch (e) {
    return 'give me a moment please'

  }
}

export const webSearch = async (query) => {
  const apiKey = 'a9b1142a8476e8c7c3c3837a15a390d308d279f0'; // Your Serper API key
  const url = 'https://google.serper.dev/search';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-API-KEY': apiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ q: query })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    ///return data;
    return { 'type': 'text', 'text': data?.answerBox?.answer || JSON.stringify(data) }
  } catch (error) {
    return { 'type': 'text', 'text': 'an error' }

    console.error('Error:', error);
    return null;
  }
};

export async function llmAnalyze(sessionData) {
  const { user, history, coachingParameters, topic, objectives } = sessionData
  const lastTwoMessages = history.slice(-2); // Take the last two elements of the array

  let messages = [
    {
      role: "system",
      content: `
         You are an AI assistant acting as an expert coach evaluator. Your task is to analyze the last two messages in a conversation between an AI coach and a user.
         Based on the conversation, suggest specific updates to the coaching parameters that will optimize the AI coach's performance in real time during the sesssion.
        

        The available coaching parameters properties and their valid values are:
        - phase: ${coachingSchema.phase.join(', ')}
        - mode: ${coachingSchema.mode.join(', ')}
        - formality: ${coachingSchema.formality.join(', ')}
        - pace: ${coachingSchema.pace.join(', ')}
        - languageComplexity: ${coachingSchema.languageComplexity.join(', ')}
        - primaryTechnique: ${coachingSchema.primaryTechnique.join(', ')}
        - empathyLevel: ${coachingSchema.empathyLevel.join(', ')}
        - validationFrequency: ${coachingSchema.validationFrequency.join(', ')}
        - goalSettingFramework: ${coachingSchema.goalSettingFramework.join(', ')}
        - learningStyle: ${coachingSchema.learningStyle.join(', ')}
        - feedbackStyle: ${coachingSchema.feedbackStyle.join(', ')}

        Current user:
        ${JSON.stringify(user, null, 2)}

        Current coaching parameters:
        ${JSON.stringify(coachingParameters, null, 2)}

        Based on the last two user messages, suggest updates to the following coaching parameters, specifying the reasoning behind each update:
        1. Phase
        2. Mode
        3. Formality
        4. Pace
        5. Language Complexity
        6. Primary Technique
        7. Empathy Level
        8. Validation Frequency
        9. Goal-Setting Framework
        10. Learning Style
        11. Feedback Style

        Only update the properties that must be updated to better guide the AI coach in the conversation.
        Avoid uncessary updates.

        Ensure the suggested updates match the conversation's tone, user behavior, and objectives.
      `,
    },
    {
      role: "user",
      content: `Analyze these last two messages and suggest context updates: ${JSON.stringify(lastTwoMessages)}`,
    },
  ];

  try {
    const completion = await groq.chat.completions.create({
      model: "llama3-groq-8b-8192-tool-use-preview",
      messages: messages,
      tools: [
        {
          type: "function",
          function: {
            name: "suggest_coaching_updates",
            description: "Suggest coaching parameters updates based on the conversation",
            parameters: {
              type: "object",
              properties: {
                updates: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      path: {
                        type: "string",
                        description: "The dot-notation path in the context to update",
                      },
                      value: {
                        type: "string",
                        description: "The new value to set at this path. For schema-constrained fields, use only allowed values.",
                      },
                      reasoning: {
                        type: "string",
                        description: "Brief explanation for this update",
                      },
                    },
                    required: ["path", "value", "reasoning"],
                  },
                },
              },
              required: ["updates"],
            },
          },
        },
      ],
      tool_choice: "auto",
    });

    const responseMessage = completion.choices[0].message;
    console.log('Analysis response:', responseMessage);

    if (responseMessage.tool_calls && responseMessage.tool_calls[0].function.name === "suggest_coaching_updates") {
      const functionArgs = JSON.parse(responseMessage.tool_calls[0].function.arguments);
      return functionArgs.updates;
    }

    return null; // No updates were suggested
  } catch (error) {
    console.error('Error in llmAnalyze:', error);
    return null;
  }
}

export async function llmCheckForCards(sessionData) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      recommendations: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.OBJECT,
          properties: {
            type: {
              type: SchemaType.STRING,
              enum: ["generate_card"],
              description: "Type of recommendation",
            },
            description: {
              type: SchemaType.STRING,
              description: "Description of the card to generate",
            },
          },
          required: ["type", "description"],
        },
      },
      attentionItems: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.STRING,
          description: "Items needing immediate attention, including topic diversion",
        },
      },
    },
    required: ["recommendations", "attentionItems"],
  };

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const { history, topic } = sessionData;
  const lastTwoMessages = history.slice(-2);

  const prompt = `
    Analyze the following last two messages in a conversation between an AI coach and a user:

    ${JSON.stringify(lastTwoMessages)}


    Based on these messages and considering the immediate next steps for the AI coach:
    1. ONLY suggest interactive cards or visualizations if they are critically needed to enhance the discussion for the next response. Each suggestion should be a card generation recommendation.
    2. ONLY identify if the AI coach has significantly diverted from the main topic: "${topic}"
    3. ONLY note items that need immediate attention for the next response.

    If the conversation is flowing smoothly and no significant issues or needs are present, return empty arrays for both recommendations and attentionItems.

    Provide your analysis in the specified JSON format, where each recommendation (if any) is an object with a 'type' of 'generate_card' and a 'description' of what the card should contain or visualize.
    Only include recommendations and attention items if they are truly necessary for the immediate next steps in the conversation.
  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();
    const parsedResult = JSON.parse(generatedText);

    // Ensure we only return non-empty arrays if there are actual items
    return {
      recommendations: parsedResult.recommendations.length > 0 ? parsedResult.recommendations : [],
      attentionItems: parsedResult.attentionItems.length > 0 ? parsedResult.attentionItems : [],
    };
  } catch (error) {
    console.error("Error in llmCheckForCards:", error);
    return { recommendations: [], attentionItems: [] };
  }
}

export async function llmAnalyzeTranscriptWithGemini(transcript, config) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      name: { type: SchemaType.STRING },
      gender: { type: SchemaType.STRING },
      maritalStatus: { type: SchemaType.STRING },
      country: { type: SchemaType.STRING },
      age: { type: SchemaType.STRING },
      position: { type: SchemaType.STRING },
      currentCompany: { type: SchemaType.STRING },
      industry: { type: SchemaType.STRING },
      professionalBackground: { type: SchemaType.STRING },
      technicalLeadershipBalance: { type: SchemaType.STRING },
      keyChallenges: { type: SchemaType.STRING },
      skillDevelopment: { type: SchemaType.STRING },
      leadershipGoals: { type: SchemaType.STRING },
      successMetrics: { type: SchemaType.STRING },
      previousCoaching: { type: SchemaType.STRING },
      communicationStyle: { type: SchemaType.STRING },
      motivationFactors: { type: SchemaType.STRING },
      potentialRoadblocks: { type: SchemaType.STRING },
    },
    required: [
      "professionalBackground",
      "technicalLeadershipBalance",
      "keyChallenges",
      "skillDevelopment",
      "leadershipGoals",
      "successMetrics",
      "previousCoaching",
      "communicationStyle",
      "motivationFactors",
      "potentialRoadblocks"
    ],
  };

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });



  const prompt = `
    Analyze this coaching session transcript and generate a JSON object with these properties:
    ${config.focusAreas.join(', ')}
    

    Each property should be summarized in a concise paragraph of no more than ${config.maxLength} characters.
    Provide a ${config.detailLevel} level of detail in your analysis.

    Prioritize depth and specificity, using impactful quotes. Aim for a rich, contextual understanding to enable personalized coaching.

    Transcript:
    ${transcript}
  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();

    return JSON.parse(generatedText);
  } catch (error) {
    console.error("Error in llmAnalyzeTranscriptWithGemini:", error);
    throw error;
  }
}

export async function dynamicTranscriptAnalyzer(transcript, templateVariables, config) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  // Dynamically create the schema based on the templateVariables
  const schemaProperties = {};
  Object.keys(templateVariables).forEach(key => {
    schemaProperties[key] = { type: SchemaType.STRING };
  });

  const schema = {
    type: SchemaType.OBJECT,
    properties: schemaProperties,
    required: Object.keys(templateVariables)
  };

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const prompt = `
    Analyze this coaching session transcript and generate a JSON object with the following properties:
    ${Object.keys(templateVariables).join(', ')}
    
    For each property:
    1. Provide a concise summary of no more than ${config.maxLength} characters.
    2. Aim for a ${config.detailLevel} level of detail in your analysis.
    3. Prioritize depth and specificity, using impactful quotes where relevant.
    4. Focus on information that would be useful for personalized coaching.

    Here's a brief description of what to look for in each property:
    ${Object.entries(templateVariables).map(([key, value]) => `${key}: ${value || 'Extract relevant information from the transcript.'}`).join('\n')}

    Transcript:
    ${transcript}
  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();
    return JSON.parse(generatedText);
  } catch (error) {
    console.error("Error in dynamicTranscriptAnalyzer:", error);
    throw error;
  }
}

export async function generateRoleplayScenario(prompt) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      name: { type: SchemaType.STRING },
      avatar: { type: SchemaType.STRING },
      voice: { type: SchemaType.STRING },
      prompt: { type: SchemaType.STRING },
      background: { type: SchemaType.STRING }
    },
    required: ["name", "avatar", "voice", "prompt", "background"]
  };

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const aiPrompt = `
    Generate a roleplay scenario based on the following prompt:
    ${prompt}

    Provide the following details:
    1. name: A suitable name for the character in this scenario.
    2. avatar: A URL to a suitable avatar image from randomuser.me (e.g., https://randomuser.me/api/portraits/men/1.jpg).
    3. voice: A voice ID suitable for this character (choose from: 79a125e8-cd45-4c13-8a67-188112f4dd22, 0faa49fe-f301-4628-bbe6-23b4574eaeba, d21bac18-fa21-48eb-a347-0f7dd76d0bf4, a5a1089f-55db-497e-84aa-b2788f91dda0, db5941ab-7e97-44ce-9a92-1dd5633ca8ec, 0f970d65-17fd-4585-b5ab-c7cdb5e186e8).
    4. prompt: A detailed prompt for the AI to act as this character, including their background, personality, and the specific situation they're in.
    5. background: A brief background description of the character.

    Ensure all details are consistent with the given scenario.
  `;

  try {
    const result = await model.generateContent(aiPrompt);
    const generatedText = result.response.text();
    return JSON.parse(generatedText);
  } catch (error) {
    console.error("Error in generateRoleplayScenario:", error);
    throw error;
  }
}

export async function llmGenerateMemory(sessionData) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  const { history, user, topic, coach } = sessionData;

  const prompt = `
    You are an AI coach that analyzes conversations. Summarize the key facts, thoughts, and highlights from this coaching session.
    Provide these as short, actionable points and refer to the user by their name.
    
    
    User: ${user.name}
    Session Topic: ${topic}
    Coach: ${coach.name}

    Session Transcript:
    ${JSON.stringify(history)}

    Please return only the a json array of text strings of the key facts, thoughts, and highlights from the session as an array of short sentences.
  `;

  try {
    // Generate the session summary using Gemini LLM
    const model = genAI.getGenerativeModel({
      model: "gemini-1.5-flash",
      generationConfig: {
        responseMimeType: "application/json",
      },
    });

    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();

    // Assume the LLM returns an array of thoughts/highlights as a JSON string
    const highlightsArray = JSON.parse(generatedText);

    // Return the extracted highlights for further use
    return highlightsArray;

  } catch (error) {
    console.error("Error in llmSessionReport:", error);
    throw error;
  }
}

export async function llmSessionReport(sessionData) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      user_name: { type: SchemaType.STRING },
      session_topic: { type: SchemaType.STRING },
      session_date: { type: SchemaType.STRING },
      session_duration: { type: SchemaType.STRING },
      summary: { type: SchemaType.STRING },
      coach_name: { type: SchemaType.STRING },
      achievements: {
        type: SchemaType.ARRAY,
        items: { type: SchemaType.STRING }
      },
      goals: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.OBJECT,
          properties: {
            name: { type: SchemaType.STRING },
            progress: { type: SchemaType.NUMBER }
          }
        }
      },
      action_items: {
        type: SchemaType.ARRAY,
        items: { type: SchemaType.STRING }
      },
      coach_feedback: { type: SchemaType.STRING },
      next_steps: { type: SchemaType.STRING }
    },
    required: [
      "user_name",
      "session_topic",
      "summary",
      "session_date",
      "session_duration",
      "coach_name",
      "achievements",
      "goals",
      "action_items",
      "coach_feedback",
      "next_steps"
    ],
  };

  const { history, topic, user, coach } = sessionData;

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const prompt = `
    You are an expert coach certified by ICF. You are evaluating a session transcript between
    a user and an AI coach to give a full nuanced report about the session. Please provide
    a comprehensive summary including achievements, goals progress, action items, feedback,
    and next steps.

    Session Details:
    User: ${user.name}
    Topic: ${topic}
    Coach: ${coach.name}
    Date: ${new Date().toISOString()}
    Duration: ${sessionData.duration || '60 minutes'}

    Conversation history:
    ${JSON.stringify(history)}

    Please analyze the session and provide a report following the specified schema.
    Be specific, insightful, and actionable in your analysis.
  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();
    return JSON.parse(generatedText);
  } catch (error) {
    console.error("Error in llmSessionReport:", error);
    throw error;
  }
}

export async function evaluateAICoachingSession(sessionData) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      session_id: { type: SchemaType.STRING },
      ai_response_quality: { type: SchemaType.NUMBER },
      conversation_coherence: { type: SchemaType.NUMBER },
      user_engagement_level: { type: SchemaType.NUMBER },
      goal_alignment: { type: SchemaType.NUMBER },
      personalization: { type: SchemaType.NUMBER },
      error_handling: { type: SchemaType.NUMBER },
      session_pacing: { type: SchemaType.NUMBER },
      overall_effectiveness: { type: SchemaType.NUMBER },
      strengths: {
        type: SchemaType.ARRAY,
        items: { type: SchemaType.STRING }
      },
      areas_for_improvement: {
        type: SchemaType.ARRAY,
        items: { type: SchemaType.STRING }
      },
      technical_issues: {
        type: SchemaType.ARRAY,
        items: { type: SchemaType.STRING }
      },
      user_satisfaction_estimate: { type: SchemaType.NUMBER },
      ai_coach_adaptability: { type: SchemaType.NUMBER },
      feature_utilization: {
        type: SchemaType.OBJECT,
        properties: {
          interactive_cards: { type: SchemaType.NUMBER },
          goal_setting_tools: { type: SchemaType.NUMBER },
          progress_tracking: { type: SchemaType.NUMBER }
        }
      },
      recommendation: { type: SchemaType.STRING }
    },
    required: [
      "session_id", "ai_response_quality", "conversation_coherence",
      "user_engagement_level", "goal_alignment", "personalization",
      "error_handling", "session_pacing", "overall_effectiveness",
      "strengths", "areas_for_improvement", "technical_issues",
      "user_satisfaction_estimate", "ai_coach_adaptability",
      "feature_utilization", "recommendation"
    ]
  };

  const { id, history, topic, user, coach, feedback_response } = sessionData;

  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const prompt = `
    As an AI product quality analyst, evaluate this AI coaching session transcript. 
    Focus on technical metrics and product quality indicators. Provide a detailed 
    analysis of the AI's performance, user interaction, and overall product effectiveness.

    Session Details:
    Session ID: ${id}
    Topic: ${topic}
    User: ${user.name}
    AI Coach: ${coach.name}
    Date: ${new Date(sessionData.datetime).toISOString()}

    Conversation History:
    ${JSON.stringify(history)}

    User Feedback:
    ${JSON.stringify(feedback_response)}

    Evaluation Criteria:
    1. AI Response Quality: Assess the relevance, accuracy, and helpfulness of AI responses.
    2. Conversation Coherence: Evaluate the logical flow and consistency of the dialogue.
    3. User Engagement: Analyze the level of user participation and interaction.
    4. Goal Alignment: Determine how well the session aligned with the stated objectives.
    5. Personalization: Assess the AI's ability to tailor responses to the user's specific needs.
    6. Error Handling: Evaluate how well the AI managed misunderstandings or off-topic queries.
    7. Session Pacing: Analyze the rhythm and timing of the conversation.
    8. Feature Utilization: Assess how effectively various product features were used.
    9. AI Coach Adaptability: Evaluate the AI's ability to adjust its approach based on user responses.
    10. Technical Issues: Identify any technical problems or glitches during the session.

    Provide scores (1-10) for quantifiable metrics and detailed insights for qualitative aspects.
    Be critical and objective, focusing on product improvement rather than user experience.
    Identify specific strengths, areas for improvement, and any technical issues observed.
    Estimate user satisfaction based on the conversation and feedback.
    Provide a concise recommendation for product enhancement based on this session analysis.

    Generate a comprehensive evaluation report following the specified schema.
  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();
    return JSON.parse(generatedText);
  } catch (error) {
    console.error("Error in evaluateAICoachingSession:", error);
    throw error;
  }
}

export async function evaluateYCRoleplay(roleplayData) {
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

  const schema = {
    type: SchemaType.OBJECT,
    properties: {
      overallScore: {
        type: SchemaType.NUMBER,
        minimum: 0,
        maximum: 10
      },
      metrics: {
        type: SchemaType.OBJECT,
        properties: {
          "Pitch Clarity 🎯": { type: SchemaType.NUMBER, minimum: 0, maximum: 10 },
          "Answer Depth 📊": { type: SchemaType.NUMBER, minimum: 0, maximum: 10 },
          "Conciseness 💫": { type: SchemaType.NUMBER, minimum: 0, maximum: 10 },
          "Data-Driven 📈": { type: SchemaType.NUMBER, minimum: 0, maximum: 10 }
        },
        required: ["Pitch Clarity 🎯", "Answer Depth 📊", "Conciseness 💫", "Data-Driven 📈"]
      },
      keyMoments: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.STRING,
          minLength: 10,
          maxLength: 100
        },
        minItems: 3,
        maxItems: 3
      },
      strengths: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.STRING,
          minLength: 10,
          maxLength: 100
        },
        minItems: 3,
        maxItems: 3
      },
      improvements: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.STRING,
          minLength: 10,
          maxLength: 100
        },
        minItems: 3,
        maxItems: 3
      },
      tacticalSteps: {
        type: SchemaType.ARRAY,
        items: {
          type: SchemaType.STRING,
          minLength: 10,
          maxLength: 100
        },
        minItems: 3,
        maxItems: 3
      }
    },
    required: ["overallScore", "metrics", "keyMoments", "strengths", "improvements", "tacticalSteps"]
  };


  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    generationConfig: {
      responseMimeType: "application/json",
      responseSchema: schema,
    },
  });

  const { history, user, roleplayCharacter } = roleplayData;

  const prompt = `
  Provide an objective analysis of this YC mock interview by evaluating the demonstrated capabilities rather than reactions to the interviewer's direct style.
  Generate evaluation data matching the exact card specifications.
  You must stick to short concise bulletpoints in rapid fire format.


Input:
${JSON.stringify(history)}

1. Score each core metric (1-10):
   Pitch Clarity 🎯
   - Value proposition clarity
   - Complex concepts explained simply
   - Core business model understanding

   Answer Depth 📊
   - Depth of market insights
   - Command of unit economics
   - Product/technical knowledge

   Conciseness 💫
   - Direct, clear responses
   - Efficient communication
   - High signal-to-noise ratio

   Data-Driven 📈
   - Specific metrics usage
   - Evidence-based claims
   - Quantified statements

Overall Score:
Average of four metrics, rounded to one decimal:
(Pitch Clarity + Answer Depth + Conciseness + Data-Driven) / 4

Score Ranges:
9.0-10: Top 5% - Exceptional YC Pitch
8.0-8.9: Top 10% - Outstanding
7.0-7.9: Top 25% - Strong
Below 7.0: Shows Potential


  `;

  try {
    const result = await model.generateContent(prompt);
    const generatedText = result.response.text();
    const evaluation = JSON.parse(generatedText);

    // // Format strings to ensure consistent capitalization and punctuation
    // evaluation.strengths = evaluation.strengths.map(s =>
    //   s.charAt(0).toUpperCase() + s.slice(1).trim().replace(/\.+$/, '')
    // );
    // evaluation.areasForImprovement = evaluation.areasForImprovement.map(a =>
    //   a.charAt(0).toUpperCase() + a.slice(1).trim().replace(/\.+$/, '')
    // );
    // evaluation.nextSteps = evaluation.nextSteps.map(n =>
    //   n.charAt(0).toUpperCase() + n.slice(1).trim().replace(/\.+$/, '')
    // );

    return evaluation;
  } catch (error) {
    console.error("Error in evaluateYCRoleplay:", error);
    throw error;
  }
}
// export async function evaluateYCRoleplay(roleplayData) {
//   const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);

//   const schema = {
//     type: SchemaType.OBJECT,
//     properties: {
//       scenario: { type: SchemaType.STRING },
//       overallScore: { type: SchemaType.NUMBER },
//       metrics: {
//         type: SchemaType.OBJECT,
//         properties: {
//           clarity: { type: SchemaType.NUMBER },
//           conciseness: { type: SchemaType.NUMBER },
//           conviction: { type: SchemaType.NUMBER },
//           knowledge: { type: SchemaType.NUMBER },
//           adaptability: { type: SchemaType.NUMBER },
//           engagement: { type: SchemaType.NUMBER },
//           authenticity: { type: SchemaType.NUMBER },
//           storytelling: { type: SchemaType.NUMBER }
//         },
//         required: [
//           "clarity", "conciseness", "conviction", "knowledge",
//           "adaptability", "engagement", "authenticity", "storytelling"
//         ]
//       },
//       strengths: {
//         type: SchemaType.ARRAY,
//         items: { type: SchemaType.STRING }
//       },
//       areasForImprovement: {
//         type: SchemaType.ARRAY,
//         items: { type: SchemaType.STRING }
//       },
//       nextSteps: {
//         type: SchemaType.ARRAY,
//         items: { type: SchemaType.STRING }
//       }
//     },
//     required: [
//       "scenario", "overallScore", "metrics",
//       "strengths", "areasForImprovement", "nextSteps"
//     ]
//   };

//   const model = genAI.getGenerativeModel({
//     model: "gemini-1.5-flash",
//     generationConfig: {
//       responseMimeType: "application/json",
//       responseSchema: schema,
//     },
//   });

//   const { history, user, roleplayCharacter } = roleplayData;

//   const prompt = `
//     As an experienced Y Combinator partner, evaluate this mock YC interview transcript.
//     Provide a detailed analysis of the founder's performance using YC's key evaluation criteria.

//     Roleplay Details:
//     Founder: ${user.name}
//     YC Partner: ${roleplayCharacter.name}

//     Interview Transcript:
//     ${JSON.stringify(history)}

//     Evaluation Guidelines:

//     1. Metrics Scoring (1-10):
//        - Clarity: How clearly did they explain their startup?
//        - Conciseness: Did they get to the point quickly?
//        - Conviction: How convincing was their belief in their vision?
//        - Knowledge: How well did they know their market/product?
//        - Adaptability: How well did they handle tough questions?
//        - Engagement: How engaging was their communication?
//        - Authenticity: How genuine did their responses feel?
//        - Storytelling: How compelling was their narrative?

//     2. Key Areas to Analyze:
//        - Product understanding and vision
//        - Market opportunity articulation
//        - Growth and traction demonstration
//        - Team capabilities and dynamics
//        - Business model clarity
//        - Competitive advantage explanation
//        - Response to challenging questions
//        - Overall interview presence

//     3. Consider YC-Specific Factors:
//        - Ability to demonstrate rapid growth potential
//        - Clear value proposition
//        - Understanding of target market
//        - Technical capability
//        - Problem-solution fit
//        - Unit economics understanding
//        - Go-to-market strategy

//     Provide:
//     1. Numerical scores for each metric (1-10)
//     2. Overall score (1-10)
//     3. Key strengths demonstrated (bullet points)
//     4. Areas needing improvement (bullet points)
//     5. Specific next steps for preparation (bullet points)

//     Be direct and constructive in your feedback, focusing on actionable insights.
//     Keep strengths and improvements focused on what matters most to YC partners.
//     Ensure next steps are specific and directly address the main weaknesses observed.

//     Generate a comprehensive evaluation report following the specified schema.
//   `;

//   try {
//     const result = await model.generateContent(prompt);
//     const generatedText = result.response.text();
//     return JSON.parse(generatedText);
//   } catch (error) {
//     console.error("Error in evaluateYCRoleplay:", error);
//     throw error;
//   }
// }



