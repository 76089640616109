
import React from 'react';
import { TrendingUp, TrendingDown, Trophy, Share2, Star } from 'lucide-react';

const RoleplayEvaluationCard = ({ inputData }) => {
  // const inputData = {
  //   overallScore: 8.2,
  //   metrics: {
  //     "Pitch Clarity 🎯": 8.4,
  //     "Answer Depth 📊": 7.8,
  //     "Conciseness 💫": 8.6,
  //     "Data-Driven 📈": 8.0
  //   },
  //   keyMoments: [
  //     "Excellent market size breakdown with clear segmentation",
  //     "Strong articulation of competitive moat and tech advantages",
  //     "Impressive customer acquisition strategy with clear unit economics"
  //   ],
  //   strengths: [
  //     "Clear problem-solution narrative backed by market data",
  //     "Concrete growth metrics with detailed cohort analysis",
  //     "Strong technical differentiation with clear IP advantages"
  //   ],
  //   improvements: [
  //     "Further quantify customer pain points with specific ROI examples",
  //     "Strengthen go-to-market strategy timeline",
  //     "Add more detail to scaling infrastructure plans"
  //   ],
  //   tacticalSteps: [
  //     "Create ROI calculator with top 3 customer case studies",
  //     "Develop detailed 18-month product roadmap with metrics",
  //     "Prepare competitive matrix highlighting technical moat"
  //   ]
  // };

  const getPercentileDisplay = (score) => {
    if (score >= 9.0) return {
      text: "Top 5% - Exceptional YC Pitch",
      percentile: "95th percentile"
    };
    if (score >= 8.0) return {
      text: "Top 10% - Outstanding",
      percentile: "90th percentile"
    };
    if (score >= 7.0) return {
      text: "Top 25% - Strong",
      percentile: "75th percentile"
    };
    return {
      text: "Shows Potential",
      percentile: "Keep improving!"
    };
  };

  const renderMetricBar = (value) => (
    <div className="w-full bg-gray-700 rounded-full h-2">
      <div
        className="bg-purple-500 h-2 rounded-full transition-all duration-500"
        style={{ width: `${(value / 10) * 100}%` }}
      ></div>
    </div>
  );

  const percentileDisplay = getPercentileDisplay(inputData.overallScore);

  return (
    <div className="w-full max-w-4xl bg-gradient-to-br from-gray-900 to-indigo-950 rounded-lg overflow-hidden shadow-xl text-white">
      <div className="p-6 flex flex-col h-full">
        {/* Score Section */}
        <div className="flex justify-between items-center p-6 rounded-lg mb-6 bg-gradient-to-r from-purple-500 to-indigo-500">
          <div>
            <h2 className="text-2xl font-bold text-white">YC Interview Score</h2>
            <p className="text-lg font-medium text-white">{percentileDisplay.text}</p>
            <p className="text-sm font-medium text-white opacity-90">{percentileDisplay.percentile}</p>
          </div>
          <div className="flex items-center gap-3">
            <Trophy className="text-yellow-300" size={24} />
            <span className="text-6xl font-bold text-white">{inputData.overallScore.toFixed(1)}</span>
          </div>
        </div>

        {/* Metrics Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          {Object.entries(inputData.metrics).map(([key, value]) => (
            <div key={key} className="bg-indigo-950 bg-opacity-50 p-4 rounded-lg backdrop-blur-sm">
              <div className="flex justify-between items-center mb-2">
                <span className="text-base font-bold text-white">{key}</span>
                <span className="text-base font-bold text-white">{value.toFixed(1)}</span>
              </div>
              {renderMetricBar(value)}
            </div>
          ))}
        </div>

        {/* Key Interview Moments */}
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-3 text-white">Key Insights Demonstrated</h3>
          <div className="bg-indigo-950 bg-opacity-50 p-4 rounded-lg space-y-2 backdrop-blur-sm">
            {inputData.keyMoments.map((moment, index) => (
              <p key={index} className="text-base font-semibold text-white">
                <span className="mr-3 text-indigo-400">•</span>

                {moment}
              </p>
            ))}
          </div>
        </div>

        {/* Strengths & Improvements */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h3 className="text-xl font-bold mb-3 text-white">Winning Points</h3>
            <ul className="space-y-2">
              {inputData.strengths.map((strength, index) => (
                <li key={index} className="flex items-center text-base bg-indigo-950 bg-opacity-50 p-3 rounded-lg backdrop-blur-sm">
                  <TrendingUp className="mr-3 text-green-400" />
                  <span className="text-white">{strength}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-3 text-white">Areas to Strengthen</h3>
            <ul className="space-y-2">
              {inputData.improvements.map((improvement, index) => (
                <li key={index} className="flex items-center text-base bg-indigo-950 bg-opacity-50 p-3 rounded-lg backdrop-blur-sm">
                  <TrendingDown className="mr-3 text-red-400" />
                  <span className="text-white">{improvement}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Tactical Improvements */}
        <div className="mb-6">
          <h3 className="text-xl font-bold mb-3 text-white">Pre-YC Training Plan</h3>
          <div className="bg-indigo-950 bg-opacity-50 p-4 rounded-lg space-y-2 backdrop-blur-sm">
            {inputData.tacticalSteps.map((step, index) => (
              <p key={index} className="text-base flex items-center text-white">
                <span className="mr-3 text-indigo-400">•</span>
                {step}
              </p>
            ))}
          </div>
        </div>

        {/* CTAs */}
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={() => window.location = '/user/sessions'}
            className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-6 py-4 rounded-lg hover:from-indigo-700 hover:to-purple-700 transition duration-300 flex items-center justify-center gap-2 font-bold text-lg">
            <Trophy size={22} />
            Beat Your Score
          </button>
          <button
            onClick={() => window.location = 'https://www.producthunt.com/products/mentara'}
            className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-6 py-4 rounded-lg hover:from-indigo-700 hover:to-purple-700 transition duration-300 flex items-center justify-center gap-2 font-bold text-lg">
            <Share2 size={22} />
            Challenge Co-Founders!
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleplayEvaluationCard;
