import React, { useState, useEffect } from 'react';
import { useGenerator } from '../../ContentGenerator';
import Card from './Card';
import { dbUtils } from '../../services/core/dbService';
import { storageUtils } from '../../services/core/fileStorageService';
import { Save, RefreshCw, Info, Upload } from 'lucide-react';
import { randInt } from 'three/src/math/MathUtils.js';
import { generateRoleplayScenario } from '../../utils/llm';

const CardCreator = ({ onSave, initialCard = null }) => {
  const [cardTemplates, setCardTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [inputData, setInputData] = useState(initialCard?.inputData || {});
  const [customPrompt, setCustomPrompt] = useState('');
  const [roleplayPrompt, setRoleplayPrompt] = useState('');
  const [cardName, setCardName] = useState('');

  const [previewCard, setPreviewCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [useAIGeneration, setUseAIGeneration] = useState(false);

  const { generateCard } = useGenerator();

  useEffect(() => {
    fetchCardTemplates();
  }, []);

  useEffect(() => {
    if (initialCard && initialCard.type) {
      const template = cardTemplates.find(t => t.type === initialCard.type);
      if (template) {
        setSelectedTemplate(template);
        setInputData(initialCard.inputData);
      }
    }
  }, [initialCard, cardTemplates]);

  const fetchCardTemplates = async () => {
    try {
      setLoading(true);
      const templates = await dbUtils.cardTemplates.getAll();
      setCardTemplates(templates);
      if (templates.length > 0 && !initialCard) {
        setSelectedTemplate(templates[0]);
      }
    } catch (error) {
      setError('Failed to fetch card templates');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateChange = (e) => {
    const template = cardTemplates.find(t => t.id === e.target.value);
    setSelectedTemplate(template);
    setInputData({});
    setPreviewCard(null);
    setUseAIGeneration(false);
  };

  const generateRoleplayCard = async () => {
    try {
      setLoading(true);
      const generatedScenario = await generateRoleplayScenario(roleplayPrompt);
      setInputData(generatedScenario);
      setPreviewCard({ type: 'roleplay', inputData: generatedScenario });
    } catch (error) {
      setError('Error generating roleplay scenario');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (key, value) => {
    setInputData(prev => ({ ...prev, [key]: value }));
  };

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setUploading(true);
        const extension = file.name.split('.').pop();
        const fileUrl = await storageUtils.saveFile(file, extension, `image/${extension}`);
        handleInputChange('avatar', fileUrl);
      } catch (err) {
        console.error('Failed to upload avatar:', err);
        setError('Failed to upload avatar');
      } finally {
        setUploading(false);
      }
    }
  };

  const generateCustomCard = async () => {
    try {
      setLoading(true);
      const generatedCard = await generateCard(customPrompt);
      setPreviewCard(generatedCard);
    } catch (error) {
      setError('Error generating custom card');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    const cardData = {
      id: randInt(0, 100000) + '',
      type: selectedTemplate.type,
      name: cardName || '',
      history: [],
      status: 'not-started',
      inputData: selectedTemplate.type === 'custom' ? previewCard?.inputData : inputData,
    };
    onSave(cardData);
  };

  const renderInputFields = () => {
    if (!selectedTemplate) return null;

    if (selectedTemplate.type === 'roleplay' && useAIGeneration) {
      return (
        <div>
          <textarea
            value={roleplayPrompt}
            onChange={(e) => setRoleplayPrompt(e.target.value)}
            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white mb-4"
            rows={6}
            placeholder="Describe the roleplay scenario you want to generate..."
          />
          <button
            onClick={generateRoleplayCard}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition duration-300"
          >
            Generate Roleplay Scenario
          </button>
        </div>
      );
    }

    return (
      <>
        {/* Default Name Field */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-1">Name</label>
          <input
            type="text"
            required='required'
            value={cardName || ''}
            onChange={(e) => setCardName(e.target.value)}
            className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
            placeholder="Enter card name in slug format ex. nice-card"
          />
        </div>
        {selectedTemplate.input_fields.map(field => {
          if (field.key === 'avatar' && selectedTemplate.type === 'roleplay') {
            return (
              <div key={field.key} className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">{field.label}</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="text"
                    value={inputData[field.key] || ''}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    className="flex-grow p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                    placeholder="Avatar URL"
                  />
                  <label className="flex items-center px-4 py-2 bg-purple-600 text-white rounded-lg cursor-pointer hover:bg-purple-700 transition">
                    <Upload className="mr-2" />
                    {uploading ? 'Uploading...' : 'Upload Avatar'}
                    <input type="file" onChange={handleAvatarUpload} className="hidden" accept="image/*" />
                  </label>
                </div>
                {inputData[field.key] && (
                  <img src={inputData[field.key]} alt="Avatar" className="w-16 h-16 rounded-full mt-2" />
                )}
              </div>
            );
          } else {
            return (
              <div key={field.key} className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">{field.label}</label>
                {field.type === 'textarea' ? (
                  <textarea
                    value={inputData[field.key] || ''}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                    rows={4}
                  />
                ) : (
                  <input
                    type={field.type}
                    value={inputData[field.key] || ''}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                  />
                )}
              </div>
            );
          }
        })}
        {selectedTemplate.type === 'roleplay' && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-300 mb-1">Voice ID</label>
            <input
              type="text"
              value={inputData.voice || ''}
              onChange={(e) => handleInputChange('voice', e.target.value)}
              className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
              placeholder="Enter voice ID"
            />
          </div>
        )}
      </>
    );
  };

  if (loading) {
    return <div className="flex justify-center items-center h-full">
      <RefreshCw className="animate-spin text-purple-500" size={24} />
    </div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="h-full flex flex-col bg-gray-800 text-white p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-purple-400">Card Creator Hub</h2>
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-300 mb-1">Select Card Template</label>
        <select
          value={selectedTemplate?.id || ''}
          onChange={handleTemplateChange}
          className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
        >
          {cardTemplates.map(template => (
            <option key={template.id} value={template.id}>{template.name}</option>
          ))}
        </select>
      </div>
      {selectedTemplate && selectedTemplate.type === 'roleplay' && (
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={useAIGeneration}
              onChange={(e) => setUseAIGeneration(e.target.checked)}
              className="mr-2"
            />
            Use AI to generate roleplay scenario
          </label>
        </div>
      )}
      {selectedTemplate && (
        <div className="mb-4 p-2 bg-gray-700 rounded-md flex items-start">
          <Info className="text-purple-400 mr-2 mt-1 flex-shrink-0" size={16} />
          <p className="text-sm text-gray-300">{selectedTemplate.description}</p>
        </div>
      )}
      <div className="flex-grow flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 overflow-hidden">
        <div className="w-full md:w-1/2 overflow-y-auto">
          <h3 className="text-lg font-semibold mb-4 text-purple-300">Input Data</h3>
          {selectedTemplate?.type === 'custom' ? (
            <div>
              {/* Default Name Field */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                <input
                  type="text"
                  required='required'
                  value={cardName || ''}
                  onChange={(e) => setCardName(e.target.value)}
                  className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white"
                  placeholder="Enter card name in slug format ex. nice-card"
                />
              </div>
              <textarea
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded-md text-white mb-4"
                rows={6}
                placeholder="Enter your custom card prompt here..."
              />
              <button
                onClick={generateCustomCard}
                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition duration-300"
              >
                Generate Custom Card
              </button>
            </div>
          ) : (
            renderInputFields()
          )}
        </div>
        <div className="w-full md:w-1/2 overflow-y-auto">
          <h3 className="text-lg font-semibold mb-4 text-purple-300">Card Preview</h3>
          <div className="border border-gray-600 rounded-lg p-4 h-full bg-gray-700">
            {selectedTemplate?.type === 'custom' && previewCard ? (
              <Card content={previewCard} />
            ) : (
              <Card content={{ type: selectedTemplate?.type, inputData }} />
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300 flex items-center"
        >
          <Save size={16} className="mr-2" /> Save Card
        </button>
      </div>
    </div>
  );
};

export default CardCreator;