import React, { useState, useEffect } from 'react';

const RoomConfigurationEditor = ({ value, onChange }) => {
  const [config, setConfig] = useState(value || {});

  const configSections = {
    general: {
      title: "General Settings",
      fields: {
        preloadCard: {
          type: "text",
          label: "Preload Card ID",
          description: "Card to show when session starts"
        },
        startInitialGreeting: {
          type: "boolean",
          label: "Start with Greeting",
          description: "Begin session with AI greeting"
        },
        endCallCard: {
          type: "text",
          label: "End Call Card ID",
          description: "Card to show when session ends"
        }
      }
    },
    controls: {
      title: "Control Settings",
      fields: {
        hideMicControl: { type: "boolean", label: "Hide Mic Control" },
        hideVideoControl: { type: "boolean", label: "Hide Video Control" },
        hideScreenshareControl: { type: "boolean", label: "Hide Screenshare" },
        hideTextModeControl: { type: "boolean", label: "Hide Text Mode" },
        hideCardControl: { type: "boolean", label: "Hide Card Control" },
        hideEndControl: { type: "boolean", label: "Hide End Call" },
        disableMicControl: { type: "boolean", label: "Disable Mic Control" },
        disableVideoControl: { type: "boolean", label: "Disable Video Control" },
        disableScreenshareControl: { type: "boolean", label: "Disable Screenshare" },
        disableTextModeControl: { type: "boolean", label: "Disable Text Mode" },
        disableCardControl: { type: "boolean", label: "Disable Card Control" }
      }
    }
  };

  const handleFieldChange = (section, field, value) => {
    const newConfig = {
      ...config,
      [field]: value
    };
    setConfig(newConfig);
    onChange(newConfig);
  };

  return (
    <div className="space-y-6">
      {Object.entries(configSections).map(([sectionKey, section]) => (
        <div key={sectionKey} className="bg-gray-800 p-4 rounded-lg">
          <h3 className="text-lg font-medium text-white mb-4">{section.title}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.entries(section.fields).map(([fieldKey, field]) => (
              <div key={fieldKey} className="space-y-2">
                <label className="flex items-center space-x-2">
                  {field.type === "boolean" ? (
                    <input
                      type="checkbox"
                      checked={config[fieldKey] || false}
                      onChange={(e) => handleFieldChange(sectionKey, fieldKey, e.target.checked)}
                      className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                    />
                  ) : (
                    <input
                      type="text"
                      value={config[fieldKey] || ""}
                      onChange={(e) => handleFieldChange(sectionKey, fieldKey, e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 bg-gray-700 text-white"
                    />
                  )}
                  <span className="text-sm text-gray-300">{field.label}</span>
                </label>
                {field.description && (
                  <p className="text-xs text-gray-400">{field.description}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RoomConfigurationEditor