// src/components/admin/feedback/FeedbackResults.js
import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { dbUtils } from '../../../../services/core/adminService';
import { Star, ThumbsUp, ThumbsDown } from 'lucide-react';

const surveyData = [
  {
    question_id: "1",
    question: "How would you rate your overall experience with Mentara AI Coach today?",
    type: "rating",
    scale: { min: 0, max: 10 }
  },
  {
    question_id: "2",
    question: "Did you find the session with Mentara AI Coach useful?",
    type: "multiple_choice",
    options: [
      { label: "Yes", value: "A" },
      { label: "No", value: "B" }
    ]
  },
  {
    question_id: "3",
    question: "Would you consider scheduling another session with Mentara AI Coach in the future?",
    type: "multiple_choice",
    options: [
      { label: "Yes", value: "A" },
      { label: "No", value: "B" }
    ]
  },
  {
    question_id: "4",
    question: "What aspects of the session did you like the most?",
    type: "text_input"
  },
  {
    question_id: "5",
    question: "What aspects of the session do you think could be improved?",
    type: "text_input"
  },
  {
    question_id: "6",
    question: "How likely are you to recommend Mentara AI Coach to a friend or colleague?",
    type: "rating",
    scale: { min: 0, max: 10 }
  },
  {
    question_id: "7",
    question: "How did you find the Interactive cards generated by Mentara AI during the session?",
    type: "multiple_choice",
    options: [
      { label: "No cards generated", value: "A" },
      { label: "I found them innovative and engaging", value: "B" },
      { label: "I found them helpful in understanding the concepts better", value: "C" },
      { label: "I found them distracting and not very useful", value: "D" }
    ]
  }
];

const FeedbackResults = () => {
  const [sessions, setSessions] = useState([]);
  const [aggregateData, setAggregateData] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [view, setView] = useState('individual');

  useEffect(() => {
    fetchFeedbackData();
  }, []);

  const fetchFeedbackData = async () => {
    try {
      const allSessions = await dbUtils.sessions.getAll();
      const sessionsWithFeedback = allSessions.filter(session => session.feedback_response);
      setSessions(sessionsWithFeedback);
      calculateAggregateData(sessionsWithFeedback.map(s => s.feedback_response));
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    }
  };

  const calculateAggregateData = (data) => {
    const aggregate = {};
    surveyData.forEach(question => {
      aggregate[question.question_id] = {
        question: question.question,
        type: question.type,
        answers: {},
        average: 0,
        count: 0
      };
    });

    data.forEach(feedback => {
      Object.entries(feedback).forEach(([questionId, answer]) => {
        if (aggregate[questionId]) {
          if (aggregate[questionId].type === 'rating') {
            aggregate[questionId].average += Number(answer);
            aggregate[questionId].count += 1;
          } else {
            if (!aggregate[questionId].answers[answer]) {
              aggregate[questionId].answers[answer] = 0;
            }
            aggregate[questionId].answers[answer] += 1;
          }
        }
      });
    });

    Object.values(aggregate).forEach(question => {
      if (question.type === 'rating' && question.count > 0) {
        question.average = question.average / question.count;
      }
    });

    setAggregateData(Object.values(aggregate));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'scheduled': return 'bg-blue-500';
      case 'in-progress': return 'bg-green-500';
      case 'completed': return 'bg-purple-500';
      case 'cancelled': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };

  const renderChart = (data, title, type) => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill={type === 'rating' ? "#8884d8" : "#82ca9d"} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

  const renderSessionCard = (session) => {
    const overallRating = session.feedback_response["1"];
    const isUseful = session.feedback_response["2"] === "A";
    const wouldScheduleAgain = session.feedback_response["3"] === "A";

    return (
      <div key={session.id} className="bg-gray-800 rounded-lg overflow-hidden shadow-lg flex justify-between items-center mb-4">
        <div className="p-4 flex-grow flex items-center">
          <img
            src={session?.user?.photo || 'https://via.placeholder.com/40'}
            alt={session?.user?.name}
            className="w-10 h-10 rounded-full object-cover mr-4"
          />
          <div>
            <div className="flex justify-between items-start mb-2">
              <h3 className="text-lg font-semibold">{session?.user?.name}</h3>
              <span className={`px-2 py-1 text-xs font-semibold rounded-full ${getStatusColor(session.status)}`}>
                {session.status}
              </span>
            </div>
            <p className="text-sm text-gray-400 mb-2">{session.topic}</p>
            <p className="text-xs text-gray-500">
              {new Date(session.datetime).toLocaleString()}
            </p>
          </div>
        </div>
        <div className="bg-gray-700 p-4 flex flex-col items-center">
          <div className="flex items-center mb-2">
            <Star className="text-yellow-500 mr-1" size={16} />
            <span>{overallRating}/10</span>
          </div>
          <div className="flex items-center mb-2">
            {isUseful ? <ThumbsUp className="text-green-500 mr-1" size={16} /> : <ThumbsDown className="text-red-500 mr-1" size={16} />}
            <span>{isUseful ? 'Useful' : 'Not Useful'}</span>
          </div>
          <div className="flex items-center">
            {wouldScheduleAgain ? <ThumbsUp className="text-green-500 mr-1" size={16} /> : <ThumbsDown className="text-red-500 mr-1" size={16} />}
            <span>{wouldScheduleAgain ? 'Would Schedule Again' : 'Would Not Schedule Again'}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderTextResponses = (questionId, question, feedbackData) => (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-4">{question}</h3>
      <ul className="list-disc pl-5">
        {Array.isArray(feedbackData)
          ? feedbackData.map((feedback, index) => (
            feedback[questionId] && <li key={index} className="mb-2">{feedback[questionId]}</li>
          ))
          : feedbackData[questionId] && <li className="mb-2">{feedbackData[questionId]}</li>
        }
      </ul>
    </div>
  );

  const renderAggregateView = () => (
    <div>
      <h2 className="text-2xl font-bold mb-6">Aggregate Feedback Results</h2>
      {aggregateData.map((question) => {
        if (question.type === 'rating') {
          return renderChart([{ name: question.question, value: question.average }], question.question, 'rating');
        } else if (question.type === 'multiple_choice') {
          const chartData = Object.entries(question.answers).map(([answer, count]) => ({
            name: answer,
            value: count
          }));
          return renderChart(chartData, question.question, 'multiple_choice');
        } else {
          return renderTextResponses(question.question_id, question.question, sessions.map(s => s.feedback_response));
        }
      })}
    </div>
  );

  const renderIndividualView = () => (
    <div>
      <h2 className="text-2xl font-bold mb-6">Individual Session Feedback</h2>
      <div className="space-y-4 mb-8">
        {sessions.map((session) => (
          <div key={session.id} onClick={() => setSelectedSession(session)}>
            {renderSessionCard(session)}
          </div>
        ))}
      </div>
      {selectedSession && (
        <div className="bg-gray-700 p-6 rounded-lg">
          <h3 className="text-xl font-bold mb-4">Feedback for {selectedSession.topic}</h3>
          {surveyData.map((question) => {
            const answer = selectedSession.feedback_response[question.question_id];
            if (question.type === 'rating') {
              return (
                <div key={question.question_id} className="mb-4">
                  <h4 className="text-lg font-semibold">{question.question}</h4>
                  <p className="text-2xl font-bold">{answer}/10</p>
                </div>
              );
            } else if (question.type === 'multiple_choice') {
              const option = question.options.find(opt => opt.value === answer);
              return (
                <div key={question.question_id} className="mb-4">
                  <h4 className="text-lg font-semibold">{question.question}</h4>
                  <p className="text-xl">{option ? option.label : 'N/A'}</p>
                </div>
              );
            } else {
              return renderTextResponses(question.question_id, question.question, selectedSession.feedback_response);
            }
          })}
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div className="mb-6">
        <button
          className={`mr-4 px-4 py-2 rounded ${view === 'aggregate' ? 'bg-purple-600' : 'bg-gray-600'}`}
          onClick={() => setView('aggregate')}
        >
          Aggregate View
        </button>
        <button
          className={`px-4 py-2 rounded ${view === 'individual' ? 'bg-purple-600' : 'bg-gray-600'}`}
          onClick={() => setView('individual')}
        >
          Individual Sessions
        </button>
      </div>
      {view === 'aggregate' ? renderAggregateView() : renderIndividualView()}
    </div>
  );
};

export default FeedbackResults;