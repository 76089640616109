import React, { useState, useEffect } from 'react';
import { CheckCircle2, XOctagon, AlertCircle } from 'lucide-react';

const LinkedInEmbedDetector = () => {
  const [detectionResults, setDetectionResults] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const runTests = () => {
      const results = {};

      // Test 1: Check referrer
      results.referrerTest = {
        name: "Referrer Check",
        result: document.referrer.includes('linkedin.com'),
        description: "Checks if the referrer URL contains 'linkedin.com'",
        details: `Current referrer: ${document.referrer || 'None'}`
      };

      // Test 2: Check window.parent
      try {
        const hasParent = window.parent !== window;
        const canAccessParent = (() => {
          try {
            return Boolean(window.parent.location.href);
          } catch (e) {
            return false;
          }
        })();
        results.parentWindowTest = {
          name: "Parent Window Check",
          result: hasParent && !canAccessParent,
          description: "Checks if page is in iframe and can't access parent (indicating cross-origin frame)",
          details: `Has parent: ${hasParent}, Can access parent: ${canAccessParent}`
        };
      } catch (e) {
        results.parentWindowTest = {
          name: "Parent Window Check",
          result: true,
          description: "Security error accessing parent window (likely cross-origin)",
          details: e.message
        };
      }

      // Test 3: Check LinkedIn specific query parameters
      const urlParams = new URLSearchParams(window.location.search);
      results.queryParamTest = {
        name: "LinkedIn Query Parameters",
        result: urlParams.has('original_referer') || urlParams.has('linkedInShare'),
        description: "Checks for LinkedIn-specific URL parameters",
        details: `URL parameters: ${Array.from(urlParams.entries()).map(([k, v]) => `${k}=${v}`).join(', ') || 'None'}`
      };

      // Test 4: Check window dimensions (LinkedIn embeds often have specific sizes)
      const isCommonEmbedSize = window.innerWidth === 552 || window.innerHeight === 367;
      results.dimensionsTest = {
        name: "Window Dimensions",
        result: isCommonEmbedSize,
        description: "Checks if window dimensions match common LinkedIn embed sizes",
        details: `Current dimensions: ${window.innerWidth}x${window.innerHeight}`
      };

      // Test 5: Check for LinkedIn-specific objects/globals
      results.globalObjectsTest = {
        name: "LinkedIn Global Objects",
        result: Boolean(window.IN || window.liap),
        description: "Checks for LinkedIn-specific global objects",
        details: `Found objects: ${['IN', 'liap'].filter(obj => window[obj]).join(', ') || 'None'}`
      };

      // Test 6: Check iframe context
      results.iframeContextTest = {
        name: "Iframe Context",
        result: window !== window.top,
        description: "Checks if page is loaded in an iframe",
        details: `Is iframe: ${window !== window.top}`
      };

      // Test 7: User Agent Check
      results.userAgentTest = {
        name: "User Agent Check",
        result: navigator.userAgent.toLowerCase().includes('linkedin'),
        description: "Checks if user agent contains LinkedIn-specific strings",
        details: `User Agent: ${navigator.userAgent}`
      };

      setDetectionResults(results);
      setIsLoading(false);
    };

    // Run tests after a short delay to ensure everything is loaded
    setTimeout(runTests, 500);
  }, []);

  const getStatusIcon = (result) => {
    if (result) {
      return <CheckCircle2 className="w-5 h-5 text-green-500" />;
    }
    return <XOctagon className="w-5 h-5 text-red-500" />;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-500"></div>
      </div>
    );
  }


  const isLikelyLinkedIn = Object.values(detectionResults).filter(test => test.result).length >= 2;

  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <div className="flex items-center justify-between mb-8">
              <h1 className="text-2xl font-bold text-gray-900">
                LinkedIn Embed Detection Tests
              </h1>
              <div className="flex items-center space-x-2">
                <AlertCircle className={`w-6 h-6 ${isLikelyLinkedIn ? 'text-green-500' : 'text-yellow-500'}`} />
                <span className="text-sm font-medium">
                  {isLikelyLinkedIn ? 'Likely LinkedIn Embed' : 'Probably Not LinkedIn Embed'}
                </span>
              </div>
            </div>

            <div className="space-y-6">
              {Object.entries(detectionResults).map(([key, test]) => (
                <div
                  key={key}
                  className="border-b border-gray-200 pb-6 last:border-0 last:pb-0"
                >
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <div className="flex items-center space-x-3">
                        {getStatusIcon(test.result)}
                        <h3 className="text-lg font-medium text-gray-900">
                          {test.name}
                        </h3>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">
                        {test.description}
                      </p>
                      <p className="mt-1 text-xs font-mono bg-gray-50 p-2 rounded">
                        {test.details}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-50 px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <span className="text-sm text-gray-500">
                  Tests Passed: {Object.values(detectionResults).filter(test => test.result).length}
                </span>
                <span className="text-gray-300">|</span>
                <span className="text-sm text-gray-500">
                  Total Tests: {Object.keys(detectionResults).length}
                </span>
              </div>
              <div className="text-sm text-gray-500">
                {new Date().toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedInEmbedDetector;