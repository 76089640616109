// src/services/sessionTemplateService.js

import { dbUtils } from '../core/dbService';

export const sessionTemplateService = {
  async createSessionFromTemplate(templateId, userId, selectedCoach, userInput, selectedTopics) {
    try {
      // Step 1: Fetch the session template
      const template = await dbUtils.sessionTemplates.getById(templateId);

      // Step 2: Fetch the user data
      const user = await dbUtils.users.getProfile(userId);

      // Step 3: Process the prompt template
      const processedPrompt = await this.processPromptTemplate(template.prompt.content, {
        user,
        coach: template.coach,
        // Add more variables as needed
      });

      if (template.room_configuration)
        template.room_configuration.topics = selectedTopics

      // Step 4: Create the new session
      const newSession = {
        user_id: userId,
        coach_id: template.coach_id,
        prompt_id: template.prompt_id,
        topic: template.topic,
        objectives: template.objectives,
        cards: template.cards,
        is_demo: template.is_demo,
        room_configuration: template.room_configuration,
        session_prompt: processedPrompt,
        status: 'new',
        datetime: new Date().toISOString(),
        // Add any other fields that need to be set for a new session
      };

      const session = await dbUtils.sessions.create(newSession);

      return session;
    } catch (error) {
      console.error("Error creating session from template:", error);
      throw error;
    }
  },

  async processPromptTemplate(promptTemplate, variables) {
    // Basic variable replacement
    let processedPrompt = promptTemplate.replace(/\{\{(.*?)\}\}/g, (match, p1) => {
      const keys = p1.trim().split('.');
      let value = variables;
      for (const key of keys) {
        value = value[key];
        if (value === undefined) return match; // Keep original if not found
      }
      return value;
    });

    // Here you can add more complex processing logic
    // For example, you could use a template engine library for more advanced features

    return processedPrompt;
  },

  // Add more methods as needed, e.g., for updating templates, listing available templates, etc.
};