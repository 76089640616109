import { useState, useCallback, useEffect, useRef } from 'react';
import { useAudioPlayer } from "./hooks/useAudioPlayer";
import { useMicrophone } from "./hooks/useMicrophone";
import { useVideoStream } from "./hooks/useVideoStream";
import { useScreenShare } from "./hooks/useScreenShare";
import { useCardScreenshot } from "./hooks/useCardScreenshot";

import { transcribeSpeech } from "./services/multimodal/transcribeService";
import { textToSpeech, encodeWAV } from "./services/multimodal/speechService";
import { llmCall, llmGenerateMemory, llmSessionReport, evaluateYCRoleplay } from './utils/llm';
import { updateUserMemory } from './services/ai/MemoryRAG';

import { useAppState } from './hooks/useAppState';
import { useGenerator } from './ContentGenerator';
import { sendEmail } from './services/core/emailService';
import { dbUtils } from './services/core/dbService';
import { useNavigate } from 'react-router-dom';

export const ConversationManager = (roomConfig, sessionData, updateSessionData, updateParticipant) => {
  const { generateCard } = useGenerator();
  const [currentContent, setCurrentContent] = useState({});
  const [conversationState, setConversationState] = useState();
  const [currentAIResponse, setCurrentAIResponse] = useState('');

  const currentContentRef = useRef(currentContent); // Create a ref


  const navigate = useNavigate();

  const { audioContextRef, audioSourceRef } = useAppState();

  // const { conversationState } = sessionData
  const { stop: playerStop, play: playerStart, audioLevel: playerAudioLevel } = useAudioPlayer(audioContextRef, audioSourceRef);
  const { isVideoOn, videoRef, startCamera, stopCamera, captureFrame: captureVideoFrame } = useVideoStream();
  const { isScreenSharing, screenRef, startScreenShare, stopScreenShare, captureFrame: captureScreenFrame } = useScreenShare();
  const { captureCardScreenshot } = useCardScreenshot(currentContentRef.current);

  const [hasInitialGreetingSent, setHasInitialGreetingSent] = useState(false);
  const initialGreetingSentRef = useRef(false);
  // // Use refs to always have access to the latest state
  const sessionDataRef = useRef(sessionData);


  useEffect(() => {
    currentContentRef.current = currentContent; // Always keep the ref updated
  }, [currentContent]);

  const conversationStateRef = useRef(conversationState);

  // Keep the ref up to date with the latest state
  useEffect(() => {
    conversationStateRef.current = conversationState;
  }, [conversationState]);


  // Add simple cleanup
  useEffect(() => {
    return () => {
      stopSpeaking();
      stopListening();
    };
  }, []);



  const startSession = async () => {

    const shouldStartGreeting = roomConfig?.startInitialGreeting;

    if (sessionDataRef.current?.history?.length === 0 && shouldStartGreeting && !initialGreetingSentRef.current) {
      initialGreetingSentRef.current = true;
      setHasInitialGreetingSent(true);
      sendInitialGreeting();
    }
  }

  const sendInitialGreeting = async () => {

    initialGreetingSentRef.current = true;
    await setSessionStatus('in-progress');
    await sendText("hi", "audio");

  };

  const updateUserData = async (key, updatedData) => {
    try {
      await dbUtils.usersData.set(sessionData.user.id, key, updatedData);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };


  // Modify the useEffect to prevent infinite loops
  useEffect(() => {
    sessionDataRef.current = sessionData;

    // Only handle status changes that require card updates
    if (sessionData.status === 'completed' && currentContentRef.current.type == null) {
      if (sessionData.feedback_response) {
        updateParticipant('ai', 'isScreenSharingOn', true);
        updateParticipant('ai', 'status', 'Session Report');
        setCurrentContent({
          'type': 'sessionReport',
          'content': {}
        });
      } else if (sessionData.room_configuration?.endCallCard) {
        updateParticipant('ai', 'isScreenSharingOn', true);
        updateParticipant('ai', 'status', 'Feedback Survey');
        const card = loadCard(sessionData.room_configuration?.endCallCard);
        setCurrentCard(card);
      }
    }
    // Handle roleplaying status separately to avoid infinite loops
    else if (sessionData.status === 'roleplaying' && currentContentRef.current.type == null) {
      const card = inviteRoleplay();
      if (card) {
        setCurrentCard(card);
        updateParticipant('ai', 'isScreenSharingOn', true);
        updateRoomConfiguration('disableMicControl', false)
        updateRoomConfiguration('disableTextModeControl', false)

      }
    }
    // Handle preload card only on initial load or when specifically needed
    else if (roomConfig.preloadCard) {
      const card = loadCard(roomConfig.preloadCard);
      if (card && card.status === 'not-started') {
        if (!sessionData.is_demo)
          updateCardStatus(card.id, 'started');
        setCurrentCard(card);
        updateParticipant('ai', 'isScreenSharingOn', true);
      }
    }
  }, [sessionData]);

  const setSessionStatus = async (state) => {
    if (sessionData)
      await dbUtils.sessions.update(sessionData.id, { status: state });

    updateSessionData('status', state)
  }

  const updateFeedbackResponse = async (state) => {
    if (sessionData)
      await dbUtils.sessions.update(sessionData.id, { feedback_response: state });
  }

  const setCurrentCard = (card) => {
    if (!card) {
      updateParticipant('ai', 'isScreenSharingOn', false)
      setCurrentContent(card)

    }
    else {
      updateParticipant('ai', 'isScreenSharingOn', true)
      if (card.status_bar)
        updateParticipant('ai', 'status', card.status_bar)
      setCurrentContent(card)
    }

  }

  const updateHistory = (messages) => {
    if (currentContentRef.current?.type === 'roleplay') {
      // Update history for the most recent roleplay card
      updateSessionData('cards', (prevCards = []) => {
        // Find the index of the most recent roleplay card
        const lastRoleplayIndex = prevCards
          .slice()
          .reverse()
          .findIndex(card => card.type === 'roleplay');

        // If a roleplay card exists, update its history
        if (lastRoleplayIndex !== -1) {
          const roleplayIndex = prevCards.length - 1 - lastRoleplayIndex; // Get actual index in the array
          return prevCards.map((card, index) => {
            if (index === roleplayIndex) {
              return {
                ...card,
                history: [...(card.history || []), ...messages] // Append new messages to roleplay history
              };
            }
            return card;
          });
        }

        return prevCards; // Return cards as-is if no roleplay found
      });
    } else {
      // Update the general session history
      updateSessionData('history', (prevHistory = []) => [...prevHistory, ...messages]);
    }
  };

  const handleSpeechStart = useCallback(() => {
    console.log('start:' + conversationStateRef.current)
    stopSpeaking();
    setConversationState('listening')

  }, []);

  const handleSpeechEnd = useCallback(async (audio) => {
    const wavBlob = encodeWAV(audio);
    console.log('end:' + conversationStateRef.current)

    const speech = await getAICoachRespose(wavBlob, "audio", "audio");
    if (speech)
      await speakWords(speech, 'listening')
    console.log(speech)

  }, []);

  const { startListening: vadStart, stopListening: vadStop, audioLevel: micAudioLevel } = useMicrophone(handleSpeechStart, handleSpeechEnd);

  const speakWords = async (text, onEndStatus, useHeadCoachVoice = false) => {
    let voice_id = currentContentRef.current?.type == 'roleplay' ? currentContentRef.current.inputData.voice : sessionData.coach.voice.id
    voice_id = useHeadCoachVoice ? sessionData.coach.voice.id : voice_id

    const voice = await textToSpeech(text, voice_id);

    if (!voice.ok) throw new Error("Voice synthesis failed");

    playerStart(voice.body, () => {
      if (onEndStatus)
        setConversationState(onEndStatus)
    });
  }

  const getAICoachRespose = async (input, inputType = 'text', outputType = 'text') => {

    //CHANGE OF STATE: 
    setConversationState('processing');

    let cameraImage = null
    let screenImage = null
    let cardImage = null

    cameraImage = await captureVideoFrame()
    screenImage = await captureScreenFrame()
    if (currentContentRef.current?.type)
      cardImage = await captureCardScreenshot();



    let images = []

    if (cameraImage) images.push(cameraImage);
    if (screenImage) images.push(screenImage);
    if (cardImage) images.push(cardImage);



    let session = sessionDataRef.current
    let history = sessionDataRef.current.history
    let tools = sessionDataRef.current.coach?.tools

    if (currentContentRef.current?.type == 'roleplay') {
      session.session_prompt = currentContentRef.current.inputData.prompt;
      history = sessionDataRef.current.cards[0].history
      tools = currentContentRef.current?.inputData?.tools || ['web_search']
    }
    console.log(`HISTORY:${history}`)

    try {
      let processedInput = inputType === 'audio' ? await transcribeSpeech(input) : input;
      if (!processedInput) throw new Error("Input processing failed");

      const onboardingConfig = {
        userInput: {
          text: processedInput,
          images: images,
          isVideoOn: isVideoOn,
          isScreenSharing: isScreenSharing,
        },
        context: {
          history: history,
          user: sessionDataRef.current.user, // user information?

        },
        tools: tools,
        uiFunctions: {
          generateCard: generateCard,
          endCall: endCall,
          updateRoomConfiguration: updateRoomConfiguration,
          loadCard: loadCard,
          getLastSessionSummary: getLastSessionSummary,
          updateCardData, updateCardData,
          setConversationState: setConversationState,
          speakWords: speakWords,
          startRoleplay: inviteRoleplay,
          endRoleplay: stopRoleplay
        },
        session: session
      };
      const { generatedContent, finalResponse, outputResponseType } = await llmCall(
        onboardingConfig
      );



      //CHANGE OF STATE: 

      updateHistory([{ role: "user", content: processedInput }, { role: "assistant", content: finalResponse }]);


      let cleanResponse = finalResponse
      if (cleanResponse)
        setCurrentAIResponse(finalResponse)

      if (outputType === 'audio') {

        //CHANGE OF STATE: 
        if (generatedContent && outputResponseType == 'card') {
          setCurrentCard(null)
          setCurrentCard(generatedContent);
          // save card to cards
          //addCardToSession(generatedContent)


        }
        setConversationState('responding');
        // await speakWords(cleanResponse, 'listening')
        return cleanResponse

      } else {
        //CHANGE OF STATE: 
        if (generatedContent && outputResponseType == 'card')
          setCurrentCard(generatedContent);
        setConversationState('responding');
      }
    } catch (error) {
      console.error("Error processing request:", error);
      await speakWords("i'm sorry i didn't get that can you repeat this again.", "listening")
      //alert("An error occurred while processing your request: " + error.message);
    }
  };

  const startListening = async () => {

    vadStart();
    setConversationState('listening');        //CHANGE OF STATE: 

    if (sessionDataRef.current.status != 'roleplaying')
      await setSessionStatus('in-progress')

  };

  const stopListening = () => {
    vadStop()
    if (conversationState == 'listening')
      setConversationState('idle');        //CHANGE OF STATE: 

  };

  const stopSpeaking = () => {
    playerStop();
  };

  const updateCardData = (key, value) => {
    setCurrentContent(prevContent => ({
      ...prevContent,
      inputData: {
        ...prevContent?.inputData,
        [key]: value
      }
    }));
    return `${key} updated to ${value}. Current Updated Card Data: ${JSON.stringify(currentContent?.inputData)}`
  };

  const endCall = async (brief) => {
    await setSessionStatus('completed')
    vadStop()
    stopSpeaking();


    // update user memory in pinecone
    const memories = await llmGenerateMemory(sessionData)
    if (memories)
      await updateUserMemory(memories, sessionData.user?.id, sessionData?.id, sessionData?.topic, 'sessions')


    // generate session summary and save it
    const report = await llmSessionReport(sessionData);
    if (report)
      await dbUtils.sessions.saveSessionReport(sessionData?.id, report);

    //TODO: later on this could be a workflow to be invoked on "session end" onEnd:()=> {configured funcs}
    if (sessionData.room_configuration?.sessionType == 'onboarding' ||
      sessionData.room_configuration?.sessionType == 'ycsimulator'
    ) {
      await setOnboardingStatus(true)
    }

    if (sessionData.room_configuration?.endCallCard) {
      const card = loadCard(sessionData.room_configuration?.endCallCard)
      return card
    } else
      return alert('no end card specified')



  };

  const loadCard = (roleplayId) => {
    let roleplayCard = sessionDataRef.current?.cards.find(card => card.id === roleplayId
      || card.name === roleplayId);
    return roleplayCard || 'no card was found for this '

  }

  const updateRoomConfiguration = async (key, value, presist) => {
    try {
      // Update the local state
      updateSessionData('room_configuration', prevConfig => ({
        ...prevConfig,
        [key]: value
      }));

      // Update the database
      if (sessionData.id && presist) {
        await dbUtils.sessions.update(sessionData.id, {
          room_configuration: {
            ...sessionData.room_configuration,
            [key]: value
          }
        });
      }

      console.log(`Room configuration updated: ${key} set to ${value}`);
      return `Room configuration updated: ${key} set to ${value}`;
    } catch (error) {
      console.error('Error updating room configuration:', error);
      throw error;
    }
  };
  const inviteRoleplay = (roleplayId = null) => {
    updateRoomConfiguration('disableMicControl', true)
    updateRoomConfiguration('disableTextModeControl', true)

    // If a specific roleplayId is provided, find that card
    let roleplayCard = sessionData.cards.find(card => card.type === 'roleplay' && card.status != 'completed');

    updateParticipant('ai', 'status', `${roleplayCard?.inputData?.name || ''} (Role Play)`);


    // If no specific roleplayId, use the most recent roleplay card
    if (!roleplayCard) {
      roleplayCard = [...sessionData.cards].reverse().find(card => card.type === 'roleplay');
    }

    if (!roleplayCard) {
      alert("Technical Error: No roleplay found in session.");
      return "Technical Error: No roleplay found.";
    }

    console.log(roleplayCard)

    return roleplayCard;
  };

  const addCardToSession = (newCard) => {
    // Check if a card with the same ID already exists in the session
    const cardExists = sessionDataRef.current.cards.some(card => card.id === newCard.id);

    if (cardExists) {
      console.warn(`Card with ID ${newCard.id} already exists in the session. Skipping.`);
      return; // Prevent adding duplicate card
    }

    // Use updateSessionData to add a new card to the 'cards' array if it doesn't already exist
    updateSessionData('cards', (prevCards = []) => {
      return [...prevCards, newCard];
    });
  };

  const updateCardStatus = (cardId, newStatus) => {
    setCurrentContent(prevContent => ({
      ...prevContent,
      status: newStatus
    }));

    updateSessionData('cards', prevCards =>
      prevCards.map(card =>
        card.id === cardId ? { ...card, status: newStatus } : card
      )
    );
  };

  const getLastSessionSummary = async () => {

    const lastSession = await dbUtils.sessions.getLastSession(sessionData?.user?.id, sessionData?.id);
    return JSON.stringify(lastSession) || ''

  }

  const startRoleplay = async (card) => {
    await setSessionStatus('roleplaying')
    updateRoomConfiguration('disableMicControl', false)
    updateRoomConfiguration('disableTextModeControl', false)


    if (card.history?.length === 0)
      await sendText("hey there", "audio")
    else
      await sendText("i'm back", "audio")

  };

  const stopRoleplay = async () => {
    ///
    //@TODO: This should be dynamic and not hard-coded
    ///////
    if (sessionDataRef.current.room_configuration.sessionType == 'ycsimulator')
      return await stopYCRoleplay()

    await setSessionStatus('in-progress')



    updateCardStatus(currentContent.id, 'completed');

    updateParticipant('ai', 'isScreenSharingOn', false)
    updateParticipant('ai', 'status', sessionData.coach?.name)

    setCurrentContent({})

    const roleplayCard = sessionDataRef.current.cards[0]

    // Create a formatted transcript of the roleplay, replacing 'assistant' with the roleplay character's name
    const roleplayTranscript = roleplayCard.history
      .map(entry => {
        const role = entry.role === 'assistant' ? roleplayCard.inputData?.name : entry.role;
        return `${role}: ${entry.content}`;
      })
      .join('\n');

    // Prepare a conversational message from the user to the AI coach
    const userMessage = `I just finished my roleplay with ${roleplayCard.inputData.name}. I've got the transcript here:

${roleplayTranscript}

Can you give a conversational constructive feedback on how it went. No bulletpoints just a conversational response.`;

    // The user sends this message to the AI coach
    await sendText(userMessage, "audio");
    return null


  };



  ///
  //@TODO: This should be dynamic and not hard-coded
  ///////
  const stopYCRoleplay = async () => {
    await setSessionStatus('in-progress')

    stopSpeaking()
    await speakWords(`
      : Well done on completing your YC interview simulation! 👏 Your detailed feedback report is ready - just take 2 minutes to tell us about you and your startup to unlock your personalized interview analysis and help us improve the platform.
      `, 'responding', true)

    updateRoomConfiguration('disableMicControl', true)
    updateRoomConfiguration('disableTextModeControl', true)

    const card = loadCard('founder-survey')
    const rolelpayCard = loadCard('roleplay')
    const evaluationCard = loadCard('roleplay-evaluation')


    card.onSave = async (answers) => {
      updateCardStatus(currentContent.id, 'completed');

      vadStop()
      stopSpeaking()

      updateUserData('founder_profile', answers);

      updateParticipant('ai', 'isScreenSharingOn', false)
      updateParticipant('ai', 'status', `Coach ${sessionData.coach?.name} (AI)`)

      setConversationState('processing')

      const evaluationData = await evaluateYCRoleplay({
        user: sessionData.user,
        roleplayCharacter: { name: 'Michael Siebel' },
        history: rolelpayCard.history
      })

      evaluationCard.inputData = evaluationData
      setCurrentCard(evaluationCard)




      const roleplayName = rolelpayCard?.inputData?.name

      // Create a formatted transcript of the roleplay, replacing 'assistant' with the roleplay character's name
      const roleplayTranscript = rolelpayCard?.history
        .map(entry => {
          const role = entry.role === 'assistant' ? roleplayName : entry.role;
          return `${role}: ${entry.content}`;
        })
        .join('\n');

      // Prepare a conversational message from the user to the AI coach
      const userMessage = `I just finished my roleplay with ${roleplayName}. I've got the transcript here:

${roleplayTranscript}

Can you give a conversational constructive feedback on how it went. No bulletpoints just a conversational response.`;

      // The user sends this message to the AI coach
      await sendText(userMessage, "audio");
      await setSessionStatus('completed')

      return null

    }

    setCurrentCard(card);

    return


  };

  const sendText = async (text, outputType = "text") => {
    stopSpeaking()
    const speech = await getAICoachRespose(text, "text", outputType);
    if (speech)
      await speakWords(speech, 'listening')
    return speech
  };

  const setOnboardingStatus = async (status = true) => {
    const auth = await dbUtils.auth.setOnboardingStatus(status)
    return auth
  }


  return {
    updateUserData,
    updateRoomConfiguration,
    currentAIResponse,
    startSession,
    startRoleplay,
    inviteRoleplay,
    stopRoleplay,
    updateFeedbackResponse,
    setSessionStatus,
    conversationState,
    setConversationState,
    playerAudioLevel,
    micAudioLevel,
    currentContent,
    startListening,
    stopListening,
    stopSpeaking,
    sendText,
    endCall,
    setCurrentCard,
    isVideoOn,
    videoRef,
    startCamera,
    stopCamera,
    isScreenSharing,
    speakWords,
    screenRef,
    startScreenShare,
    stopScreenShare,
    captureVideoFrame,
    captureScreenFrame
  };
};