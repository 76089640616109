import React, { useState } from 'react';
import { Play, X } from 'lucide-react';
import Avatar from './Avatar';
import LoginButton from './LoginButton';

const RolePlayCard = ({
  sessionData,
  card,
  conversationState,
  audioLevel,
  endRoleplay,
  startRoleplay,
  dimmingOpacity = 0.3,
  currentAIResponse
}) => {
  const [isRoleplayStarted, setIsRoleplayStarted] = useState(false);

  const handleStartRoleplay = () => {
    if (sessionData?.is_demo) {
      document.getElementById('login-button').click()
      return
    }
    setIsRoleplayStarted(true);
    startRoleplay(card);
  };

  const handleEndRoleplay = () => {
    setIsRoleplayStarted(false);
    endRoleplay();
  };

  const backgroundImage = card.inputData.backgroundImage || 'https://fdlzsjkpvowbwuxwhnvn.supabase.co/storage/v1/object/public/user_media/session-background2.avif';

  return (
    <div className="relative h-full w-full overflow-hidden">
      {/* Full-screen background image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          zIndex: 0
        }}
      />

      {/* Dimming overlay */}
      <div
        className="absolute inset-0 bg-black transition-opacity duration-300"
        style={{
          opacity: isRoleplayStarted ? dimmingOpacity + 0.1 : dimmingOpacity,
          zIndex: 1
        }}
      />

      {/* Content overlay */}
      <div className="absolute inset-0 flex flex-col z-10">
        <div className="p-4">
          <h2 className="text-white text-xl font-bold">{card.inputData.name || 'AI Coach'}</h2>
          {isRoleplayStarted && (
            <div className="mt-2 bg-red-500 bg-opacity-40 text-white px-3 py-1 rounded-full inline-flex items-center">
              <span className="w-2 h-2 bg-white rounded-full mr-2 animate-pulse"></span>
              Roleplay Active
            </div>
          )}
        </div>

        <div className="flex-grow relative">
          <Avatar
            photo={card.inputData.avatar}
            isHost={'ai'}
            letter={card.inputData.name?.charAt(0) || 'A'}
            audioLevel={isRoleplayStarted ? audioLevel : null}
            conversationState={isRoleplayStarted ? conversationState : null}
            className="w-full h-full"
            captions={currentAIResponse}
          />
        </div>

        <div className="w-full flex justify-center p-4">
          {!isRoleplayStarted ? (
            <button
              onClick={handleStartRoleplay}
              className="bg-green-500 bg-opacity-70 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 flex items-center"
            >
              <Play size={20} className="mr-2" />
              {card.history?.length === 0 ? 'Start' : 'Resume'} Roleplay
            </button>
          ) : (
            <button
              onClick={handleEndRoleplay}
              className="bg-gray-500 bg-opacity-70 hover:bg-red-600 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 flex items-center"
            >
              <X size={20} className="mr-2" />
              Exit Roleplay
            </button>
          )}
        </div>

        <LoginButton className='hidden' />

      </div>
    </div>
  );
};

export default RolePlayCard;